import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import * as S from './mapCliList.styles';
import { updateFrequenceVisit } from 'client/api/mapaCli';
import { toast } from 'react-toastify';
import { InputNumber } from 'primereact/inputnumber';
import { useMap } from '../../regioes.context';
import { ClientesProps } from '../../regioes.types';
import { useState } from 'react';
import MapEditAddress from '../mapEditAddress/mapEditAddress.index';
import { trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import InputText from 'components/Inputs/InputText/text.input';
import MapVinculoCli from '../mapVinculoCli/mapVinculoCli.index';

export default function MapCliList() {
  try {
    const { clientes, screenMode, params, handleGetAllData } = useMap();

    const [selectedCLiToEditCoords, setSelectedCLiToEditCoords] = useState(null);

    const [showEditAddressModal, setShowEditAddressModal] = useState(false);

    const [showVinculoSetor, setShowVinculoSetor] = useState<boolean>(false);

    const [cliToEditVinculo, setCliToEditVinculo] = useState<ClientesProps>(null);

    const clienteNameTemplate = (rowData: ClientesProps) => {
      return (
        <span>
          [{rowData.CODCLI}] {rowData.RAZAOSOCIAL}
        </span>
      );
    };

    const setorNameTemplate = (rowData: ClientesProps) => {
      if (rowData.CODCLI != null && rowData.CODSETOR != -1 && rowData.CODSETOR != null) {
        return (
          <span>
            [{rowData.CODSETOR}] {rowData.NOMESETOR}
          </span>
        );
      } else {
        return <span>Sem Setor</span>;
      }
    };

    const cidadeTemplate = (rowData: ClientesProps) => {
      return (
        <span>
          [{rowData.ENDUF}] {rowData.ENDCIDADE}
        </span>
      );
    };

    const editCoordsTemplate = (rowData: ClientesProps) => {
      return (
        <Button
          color="blue"
          rounded
          icon={<icons.MapPin />}
          tooltip="Editar coordenadas"
          onClick={() => {
            setSelectedCLiToEditCoords(rowData);
            setShowEditAddressModal(true);
          }}
        />
      );
    };

    const editVinculoSectorCliTemplate = (rowData: ClientesProps) => {
      return (
        <Button
          color="dark"
          rounded
          icon={<icons.Intersect />}
          tooltip="Vincular Setor"
          onClick={() => {
            setCliToEditVinculo(rowData);
            setShowVinculoSetor(true);
          }}
        />
      );
    };

    const handleDataInput = (value: string) => {
      const period = 'end';
      let v: any = value;

      v = v.replace(/\D/g, '');

      if (v.length >= 8) {
        v = v.match(/(\d{0,2})(\d{0,2})(\d{0,4})/);

        v = !v[2] ? v[1] : v[1] + '/' + v[2] + '/' + v[3];

        let splitDate = v.split('/');

        if (splitDate[1] == '02') {
          if (+splitDate[2].slice(2, 2) % 4 == 0 && +splitDate[0] > 28) {
            if (period == 'end') {
              splitDate[0] = '28';
            } else {
              splitDate[0] = '01';
            }
          }
        } else {
          if (
            (splitDate[1] == '04' || splitDate[1] == '06' || splitDate[1] == '09' || splitDate[1] == '11') &&
            splitDate[0] > '30'
          ) {
            if (period == 'end') {
              splitDate[0] = '30';
            } else {
              splitDate[0] = '01';
            }
          }
        }
        if (+splitDate[1] > 12) {
          splitDate[1] = '12';
        }
        if (+splitDate[0] > 31) {
          splitDate[0] = '31';
        }

        value = splitDate.join('/');
      }
      return value;
    };

    const dateEditor = (options: any) => {
      const dateToBeEdited = options.value.split('T')[0].split('-').reverse().join('/');
      return (
        <InputText
          type="text"
          value={dateToBeEdited}
          onChange={(e) => {
            options.editorCallback(handleDataInput(e));
          }}
        />
      );
    };

    const numberEditor = (options: any) => {
      return <InputNumber value={options.value} onValueChange={(e: any) => options.editorCallback(e.value)} />;
    };

    const onCellEditComplete = async (e: any) => {
      let { rowData, newValue, field, originalEvent: event } = e;

      const newRowData = {
        codEmpresa: +params.codEmpresa,
        codMapa: +params.codMapa,
        codCli: rowData.CODCLI,
        freqVisita: field == 'FREQVISITA' ? +newValue : +rowData.FREQVISITA,
        tempoMinVisita: field == 'TEMPOMINVISITA' ? +newValue : +rowData.TEMPOMINVISITA,
        dtProxVisita:
          field == 'DT_PROX_VISITA' && newValue != null
            ? newValue
            : rowData.DT_PROX_VISITA && rowData.DT_PROX_VISITA != null
            ? rowData.DT_PROX_VISITA
            : '',
        sequencia: field == 'SEQUENCIA' ? +newValue : +rowData.SEQUENCIA,
        bloqRoteiro: rowData.BLOQ_ROTEIRO ?? 'N',
        agendamentoDoCliente: rowData.AGENDAMENTO_DO_CLIENTE ?? 'N',
        domingoManha: rowData.DOMINGO_MANHA ?? 'N',
        domingoTarde: rowData.DOMINGO_TARDE ?? 'N',
        segundaManha: rowData.SEGUNDA_MANHA ?? 'N',
        segundaTarde: rowData.SEGUNDA_TARDE ?? 'N',
        tercaManha: rowData.TERCA_MANHA ?? 'N',
        tercaTarde: rowData.TERCA_TARDE ?? 'N',
        quartaManha: rowData.QUARTA_MANHA ?? 'N',
        quartaTarde: rowData.QUARTA_TARDE ?? 'N',
        quintaManha: rowData.QUINTA_MANHA ?? 'N',
        quintaTarde: rowData.QUINTA_TARDE ?? 'N',
        sextaManha: rowData.SEXTA_MANHA ?? 'N',
        sextaTarde: rowData.SEXTA_TARDE ?? 'N',
        sabadoManha: rowData.SABADO_MANHA ?? 'N',
        sabadoTarde: rowData.SABADO_TARDE ?? 'N',
      };

      await updateFrequenceVisit(newRowData)
        .then((res) => {
          rowData[field] = newValue;
          handleGetAllData();
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(`Falha ao editar '${field}' do cliente!`);
          }
        });
    };

    const dataTemplate = (rowData: any) => {
      const dateToShow = rowData?.DT_PROX_VISITA?.includes('/')
        ? rowData.DT_PROX_VISITA
        : new Date(rowData.DT_PROX_VISITA).toLocaleDateString('pt-BR');
      return <div>{dateToShow != 'Invalid Date' ? dateToShow : rowData.DT_PROX_VISITA}</div>;
    };

    return (
      <S.Container
        style={{
          zIndex: screenMode == 'G' ? '2' : '0',
          opacity: screenMode == 'G' ? '1' : '0',
          height: screenMode == 'G' ? '100%' : '0%',
          pointerEvents: screenMode == 'G' ? 'auto' : 'none',
        }}
      >
        <S.ListTable>
          <DataTable
            value={clientes && clientes.list ? clientes.list : []}
            tableStyle={{ minWidth: '100%' }}
            paginator
            rows={100}
            style={{ fontSize: '.8rem' }}
            rowClassName={(rowData) => (rowData.ativo === 'N' ? 'text-danger' : '')}
            responsiveLayout="scroll"
            scrollHeight="calc(90vh - 48px)"
            scrollDirection="both"
            size="small"
          >
            <Column
              field="CODSETOR"
              header="SETOR"
              body={setorNameTemplate}
              sortable
              headerClassName="editableColumn"
            />
            <Column
              field="RAZAOSOCIAL"
              header="CLIENTE"
              body={clienteNameTemplate}
              sortable
              headerClassName="editableColumn"
            />

            <Column
              field="DT_PROX_VISITA"
              header="PRÓXIMA VISITA"
              sortable
              align="right"
              editor={(options) => dateEditor(options)}
              onCellEditComplete={onCellEditComplete}
              className="editableColumn"
              headerClassName="editableColumn"
              body={dataTemplate}
            />
            <Column
              field="SEQUENCIA"
              header="SEQUÊNCIA"
              sortable
              align="right"
              editor={(options) => numberEditor(options)}
              onCellEditComplete={onCellEditComplete}
              className="editableColumn"
              headerClassName="editableColumn"
            />
            <Column
              field="FREQVISITA"
              header="FREQ.VISITA"
              sortable
              align="right"
              editor={(options) => numberEditor(options)}
              onCellEditComplete={onCellEditComplete}
              className="editableColumn"
              headerClassName="editableColumn"
            />
            <Column
              field="TEMPOMINVISITA"
              header="TEMPO_MINIMO_VISITA"
              sortable
              align="right"
              editor={(options) => numberEditor(options)}
              onCellEditComplete={onCellEditComplete}
              className="editableColumn"
              headerClassName="editableColumn"
            />

            <Column field="ENDCIDADE" header="CIDADE" sortable body={cidadeTemplate} headerClassName="editableColumn" />
            <Column field="ENDLOGRADOURO" header="LOGRADOURO" sortable headerClassName="editableColumn" />
            <Column field="LATITUDE" header="LATITUDE" sortable headerClassName="editableColumn" />
            <Column field="LONGITUDE" header="LONGITUDE" sortable headerClassName="editableColumn" />
            <Column field="VL_POTENCIAL" header="POTENCIAL" sortable headerClassName="editableColumn" />
            <Column field="VL_MES_ATUAL" header="VENDA ULT. TRI." sortable headerClassName="editableColumn" />
            <Column field="VL_MED_ULT_TRIM" header="VENDA MÊS" sortable headerClassName="editableColumn" />

            <Column field="" header="" body={editVinculoSectorCliTemplate} headerClassName="editableColumn" />
            <Column field="" header="" body={editCoordsTemplate} headerClassName="editableColumn" />
          </DataTable>
        </S.ListTable>
        {showEditAddressModal && (
          <MapEditAddress
            showModal={showEditAddressModal}
            setShowModal={setShowEditAddressModal}
            cliData={selectedCLiToEditCoords}
          />
        )}

        {showVinculoSetor && (
          <MapVinculoCli
            showVinculoSetor={showVinculoSetor}
            setShowVinculoSetor={setShowVinculoSetor}
            cliToEdit={cliToEditVinculo}
          />
        )}
      </S.Container>
    );
  } catch (err) {
    trackGCatchError(err, 'mapCliList.index.tsx');
  }
}
