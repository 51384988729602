import styled from 'styled-components';

interface IMapProps {
  showMenu?: boolean;
  codColorSetor?: string;
}

export const RouterSideBarContent = styled.section<IMapProps>`
  background-color: #fafafa;
  height: 100%;
  width: 100%;
  opacity: ${(props) => (props.showMenu ? 'none' : '0')};
  transition: ${(props) => (!props.showMenu ? 'opacity 0.4s ease' : '')};
  padding: 0px 2px;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const MapSideBarMainBox = styled.div<IMapProps>`
  position: relative;
  z-index: 10;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 100%;
  transition: all 0.4s ease;
  width: ${(props) => (props.showMenu ? '68%' : '2vw')};

  @media (max-width: 992px) {
    z-index: 99;
    width: 100%;
    height: 80vh;
    top: ${(props) => (props.showMenu ? '30px' : 'calc(100% - 35px)')};
    border-radius: none;
    margin: 0;
    right: 0;
  }
`;

export const SideBarHeaderClosed = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  writing-mode: vertical-rl;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;

  @media (max-width: 992px) {
    writing-mode: horizontal-tb;
    top: 26%;
    z-index: 99;
  }
`;

export const SideBarHeader = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 5px;
  position: sticky;
  top: 0;
  background-color: #fafafa;
  z-index: 5;
`;

export const Input = styled.input`
  width: 100%;
  background-color: #fafafa;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;

  flex-direction: column;
  transition: all 0.4s ease;
  border: none;
  outline: none;
  height: 2rem;
`;

export const SetorTableIconTd = styled.div`
  font-size: 1.75rem;
  cursor: pointer;
  padding: 1 !important;
  display: flex;
  gap: 5px;
`;

export const SetorCodColorBackground = styled.div<IMapProps>`
  color: var(--white);
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  font-weight: bolder;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  justify-content: left;
  height: 65px;
  opacity: 0.8;
  margin-left: -7px;
  background-color: ${(props) => (props.codColorSetor ? '#' + props.codColorSetor : 'var(--dark-900)')};

  @media (max-width: 992px) {
    height: 1px;
    width: 100%;
    padding: 3px;
    border-radius: 0px 0px 10px 10px;
  }
`;

export const MapSideBarOpenBtn = styled.div<IMapProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  width: 30px;
  height: 50px;
  background-color: #495057;
  z-index: 999999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;

  svg {
    color: var(--white);
    transition: all 1.2s ease;
    transform: ${(props) => (!props.showMenu ? 'rotate(180deg)' : 'none')};
  }

  @media (max-width: 992px) {
    top: -30px;
    right: 50%;
    width: 50px;
    height: 30px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transform: translateX(45%);
    margin: 0 auto;

    svg {
      color: var(--white);
      transition: all 1.2s ease;
      transform: ${(props) => (!props.showMenu ? 'rotate(-90deg)' : 'rotate(90deg)')};
    }
  }
`;

export const SideBarHeaderBox = styled.div`
  position: relative;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

export const SideBarHeaderInputBox = styled.div`
  position: relative;
  background-color: #fafafa;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  height: 3.25rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`;

export const SideBarHeaderInput = styled.input`
  position: relative;
  width: 85%;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  border: none;
  outline: none;
  padding-left: 10px;
`;
export const SideBarHeaderInputButton = styled.div`
  background-color: rgb(61 65 71);
  color: #fff;
  width: 15%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
`;
