import styled from 'styled-components';

type ICorOption = {
  cor: string;
};
interface IMapHeaderProps {
  mapGridShow?: boolean;
}

export const MapHeaderMainBoxWrapper = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
  width: 100%;
  height: 50svh;
`;

export const MapHeaderMainBox = styled.section`
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: row;
  gap: 15px;
  position: absolute;
  justify-content: flex-start;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 99;
  padding: 0 10px 0 15px;
  /* overflow-x: scroll; */
  /* overflow-y: visible; */
`;

export const SearchClientMainBoxInput = styled.div`
  padding: 6px !important;
  font-size: 1rem;
  color: var(--dark-900);
  outline: none;
  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border-radius: 2px;
  transition: all 0.4s ease;
  border: none;
  width: 15%;
  min-width: 20%;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 3rem;

  svg {
    transition: all 0.4s ease;
    font-size: 1.25rem;
    color: var(--dark-900);
    cursor: pointer;

    :hover {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 1024px) {
    min-width: 35%;
  }
`;

export const MapHeaderBackBtn = styled.button`
  height: 3rem;
  width: 3rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  padding: 6px !important;
  font-size: 1.5rem;
  border: none;
  border-radius: 5px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SearchInput = styled.input`
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 1.25rem;
  width: 100%;
`;

export const ClieSuggestionBox = styled.div`
  position: absolute;
  top: 120%;
  left: 0;
  height: 40vh;
  max-height: 80vh;
  width: 200%;
  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.4s ease;

  padding-right: 5px;
`;

export const ClieSuggestionCard = styled.span`
  transition: all 0.4s ease;
  background-color: #fafafa;
  cursor: pointer;
  padding: 7.5px;
  font-weight: 500;
  white-space: nowrap;
  background-color: #fafafa;
  max-width: 100%;
  padding-left: 10px;

  :hover {
    background-color: #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 9px;
  }
`;

export const OptionSetorCode = styled.span<ICorOption>`
  font-size: 0.8rem !important;
  color: ${(props) => '#' + props.cor};
  padding: 2px;
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
`;

export const MapGridButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  border: none;

  font-size: 1rem;
  color: var(--dark-900);
  outline: none;
  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  transition: all 0.4s ease;
  border: none;
  width: 100px;
  height: 3rem;
  border-radius: 5px;
`;

export const Icon = styled.div<IMapHeaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  text-align: center;
  font-size: 2rem;
  padding: 5px !important;
  transition: all 0.4s ease;

  background-color: ${(props) => (props.mapGridShow ? 'var(--gray-500)' : '')};
  color: ${(props) => (props.mapGridShow ? 'var(--dark-900)' : 'rgb(77, 77, 77)')};
  border-radius: ${(props) => (props.mapGridShow ? '7px' : '')};

  :hover {
    background-color: var(--gray-500);
    color: var(--dark-900);
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SelectedSetorCard = styled.span<ICorOption>`
  font-size: 0.8rem !important;
  border-left: 2px solid ${(props) => '#' + props.cor};
  color: ${(props) => '#' + props.cor};
  padding: 2px;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: #ffffff;
  height: 3rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border-radius: 5px;
  white-space: nowrap;

  svg {
    color: #000;
    font-size: 1.5rem;
    &:hover {
      cursor: pointer;
    }
  }
`;
