import styled, { keyframes } from 'styled-components';
import { ButtonIconProps, ButtonProps, ButtonTextProps } from './button.types';

export const ButtonMainBox = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap !important;
  white-space: nowrap !important;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;

  height: ${(props) => (props.tag ? '1.75rem' : props.size == 'sm' ? '2.25rem' : '2.75rem')};
  border-radius: 5px;
  border-radius: ${(props) => (props.rounded ? '2.5rem' : 'none')};

  gap: ${(props) => (props.tag ? '5px' : '10px')};
  padding: ${(props) => (props.tag ? '2px 7px' : props.size == 'sm' ? '7px' : '7px')};
  font-size: ${(props) => (props.size == 'sm' || props.tag ? '0.8rem' : props.size == 'lg' ? '1.5rem' : '1.25rem')};

  cursor: ${(props) => (props.tag ? 'auto' : 'pointer')};

  svg {
    font-size: ${(props) => (props.size == 'sm' || props.tag ? '1.15rem' : props.size == 'lg' ? '1.75rem' : '1.5rem')};
    cursor: ${(props) => (props.tag ? 'auto' : 'pointer')};
  }

  &.black,
  &.gray {
    background-color: ${(props) =>
      props.bgOnlyOnHover || props.disabled ? 'transparent' : props.textMode ? '#00000010' : '#00000010'};
    color: var(--v-black-dark) !important;
    &:hover {
      background-color: ${(props) => (props.disabled ? 'transparent' : '#00000020 !important')};
      svg {
        color: var(--v-black-dark) !important;
      }
    }
    svg {
      color: var(--v-black) !important;
      &:hover {
        color: var(--v-black-dark) !important;
      }
    }
  }

  &.green {
    background-color: ${(props) =>
      props.bgOnlyOnHover || props.disabled ? 'transparent' : props.textMode ? 'var(--v-green-20)' : 'var(--v-green)'};
    color: ${(props) => (props.textMode || props.bgOnlyOnHover ? 'var(--v-green) !important' : '#FFF')};
    border: ${(props) => (props.border ? '1px solid var(--v-green)' : 'none')};
    &:hover {
      background-color: ${(props) =>
        props.disabled
          ? 'transparent'
          : props.textMode || props.border
          ? 'var(--v-green-40) !important'
          : 'var(--v-green-dark) !important'};
      svg {
        color: ${(props) => (props.textMode || props.bgOnlyOnHover ? 'var(--v-green-dark) !important' : '#FFF')};
      }
    }
    svg {
      color: ${(props) => (props.textMode || props.bgOnlyOnHover ? 'var(--v-green) !important' : '#FFF')};
      &:hover {
        color: ${(props) => (props.textMode || props.bgOnlyOnHover ? 'var(--v-green-dark) !important' : '#FFF')};
      }
    }
  }

  &.red {
    background-color: ${(props) =>
      props.bgOnlyOnHover || props.disabled ? 'transparent' : props.textMode ? 'var(--v-red-20)' : 'var(--v-red)'};
    color: ${(props) => (props.textMode || props.bgOnlyOnHover ? 'var(--v-red-dark) !important' : '#FFF')};
    border: ${(props) => (props.border ? '1px solid var(--v-red)' : 'none')};
    &:hover {
      background-color: ${(props) =>
        props.disabled
          ? 'transparent'
          : props.textMode || props.border
          ? 'var(--v-red-40) !important'
          : 'var(--v-red-dark) !important'};
      svg {
        color: ${(props) => (props.textMode || props.bgOnlyOnHover ? 'var(--v-red-dark) !important' : '#FFF')};
      }
    }
    svg {
      color: ${(props) => (props.textMode || props.bgOnlyOnHover ? 'var(--v-red) !important' : '#FFF')};
      &:hover {
        color: ${(props) => (props.textMode || props.bgOnlyOnHover ? 'var(--v-red-dark) !important' : '#FFF')};
      }
    }
  }

  &.blue {
    background-color: ${(props) =>
      props.bgOnlyOnHover || props.disabled ? 'transparent' : props.textMode ? 'var(--v-blue-20)' : 'var(--v-blue)'};
    color: ${(props) => (props.textMode || props.bgOnlyOnHover ? 'var(--v-blue-dark) !important' : '#FFF')};
    border: ${(props) => (props.border ? '1px solid var(--v-blue)' : 'none')};
    &:hover {
      background-color: ${(props) =>
        props.disabled
          ? 'transparent'
          : props.textMode || props.border
          ? 'var(--v-blue-40) !important'
          : 'var(--v-blue-dark) !important'};
      svg {
        color: ${(props) => (props.textMode || props.bgOnlyOnHover ? 'var(--v-blue-dark) !important' : '#FFF')};
      }
    }
    svg {
      color: ${(props) => (props.textMode || props.bgOnlyOnHover ? 'var(--v-blue) !important' : '#FFF')};
      &:hover {
        color: ${(props) => (props.textMode || props.bgOnlyOnHover ? 'var(--v-blue-dark) !important' : '#FFF')};
      }
    }
  }

  &.dark {
    background-color: ${(props) =>
      props.bgOnlyOnHover || props.disabled ? 'transparent' : props.textMode ? 'var(--v-dark-20)' : 'var(--v-dark)'};
    color: #fff;
    border: ${(props) => (props.border ? '1px solid var(--v-dark)' : 'none')};
    &:hover {
      background-color: ${(props) => (props.disabled ? 'transparent' : 'var(--v-dark-dark) !important')};
      svg {
        color: #fff;
      }
    }
    svg {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }

  &.white {
    background-color: ${(props) =>
      props.bgOnlyOnHover || props.disabled ? 'transparent' : props.textMode ? '#FFF' : '#FFF'};
    color: #fff;
    &:hover {
      background-color: ${(props) => (props.disabled ? 'transparent' : '#FAFAFA !important')};
      svg {
        color: #000;
      }
    }
    svg {
      color: #000;
      &:hover {
        color: #000;
      }
    }
  }
`;

export const ButtonIconBox = styled.div<ButtonIconProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 7px;
`;

export const ButtonTextBox = styled.div<ButtonTextProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

export const DotsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0px 5px;
`;

export const Dot = styled.div<{ color: string }>`
  width: 7px;
  height: 7px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  animation: ${bounce} 1.5s infinite ease-in-out;

  &:nth-child(1) {
    animation-delay: 0s;
  }

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }

  &:nth-child(4) {
    animation-delay: 0.6s;
  }
`;
