import { addLocale, locale } from 'primereact/api';
import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { LayoutProvider } from './_metronic/layout/core';
import { MasterInit } from './_metronic/layout/MasterInit';
import pt from './primereact-pt.json';
import GlobalStyle from './styles/global';
import { LoaderEffectIndependentPage } from 'components/LoaderEffect';

function App() {
  useEffect(() => {
    addLocale('pt', pt);

    locale('pt');
  }, []);

  return (
    <Suspense fallback={<LoaderEffectIndependentPage show />}>
      <LayoutProvider>
        <Outlet />
        <MasterInit />
        <GlobalStyle />
      </LayoutProvider>
    </Suspense>
  );
}

export default App;
