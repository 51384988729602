import { useUser } from '../../client/hooks/usuarios';
import { useEffect, useState } from 'react';
import { getVendedoresByUsuario } from 'client/api/vendedores';
import Dropdown from 'components/Dropdown/dropdown';

type SelectedVendedorProps = {
  codVendedor: number;
  nomeVendedor?: string;
  bloqueio?: string;
  codEmpresa?: number;
  codFilial?: number;
  codGerente?: number;
  codSupervisor?: number;
  dtLatlong?: string | null;
  dtTermino?: string | null;
  latitude?: string;
  longitude?: string;
  nome?: string;
};

const UseSelectVendedor = (codEmpresa: number, codUsuario: number, codGerente?: number, codSupervisor?: number) => {
  const { data: user } = useUser();
  const [vendedores, setVendedores] = useState<any[]>([]);

  const defaultVendedor = () => {
    const rca = JSON.parse(localStorage.getItem('vmRcaData') || 'null');
    return (
      rca || {
        codVendedor: rca ? rca.codVendedor : -1,
        nomeVendedor: '',
      }
    );
  };

  const [selectedVendedor, setSelectedVendedor] = useState<SelectedVendedorProps>(defaultVendedor);

  const handleSelectVendedorByCode = (codRca: number) => {
    try {
      const [filteredRca] = vendedores?.filter((rca: SelectedVendedorProps) => +rca.codVendedor == +codRca);

      if (filteredRca) {
        setSelectedVendedor(filteredRca);
      }
    } catch (err) {
      console.log('Error selecting vendedor :');
    }
  };

  useEffect(() => {
    localStorage.removeItem('vmRcaData');
    if (user && codEmpresa && codUsuario) {
      getVendedoresByUsuario(codEmpresa, codUsuario).then((rcas: any[]) => {
        const formattedRcas = [];
        for (const v of rcas) {
          if (
            (codGerente != undefined && codGerente != -1 && +v.codGerente == +codGerente) ||
            (codSupervisor != undefined && codSupervisor != -1 && +v.codSupervisor == +codSupervisor) ||
            (codGerente == undefined && codSupervisor == undefined) ||
            (codGerente == -1 && codSupervisor == -1)
          ) {
            formattedRcas.push({
              ...v,
              label: `[${v.codVendedor}] - ${v.nome}`,
              nomeVendedor: v.nome,
            });
          }
        }
        setVendedores(formattedRcas);
      });
    }
  }, [user, codEmpresa, codEmpresa, codUsuario, codGerente, codSupervisor]);

  const handleRcaChange = (rca: SelectedVendedorProps) => {
    localStorage.setItem('vmRcaData', rca ? JSON.stringify(rca) : null);
    setSelectedVendedor(rca ?? defaultVendedor);
  };

  const SelectVendedor = ({
    onChange,
    width,
    label = 'Vendedor',
    placeholder = 'Selecione um Vendedor',
  }: {
    onChange?: (vendedor: SelectedVendedorProps) => void;
    width?: string;
    label?: string;
    placeholder?: string;
  }) => {
    const handleChange = (rca: SelectedVendedorProps) => {
      handleRcaChange(rca);
      if (onChange) {
        onChange(rca);
      }
    };

    return (
      <Dropdown
        value={selectedVendedor}
        onChange={(e) => handleChange(e.value)}
        options={vendedores}
        optionLabel="label"
        filter
        placeholder={placeholder}
        emptyMessage="Nenhum dado encontrado"
        emptyFilterMessage="Nenhum dado encontrado"
        width={width}
        label={label}
        showClear
      />
    );
  };

  return {
    SelectVendedor,
    selectedVendedor,
    setSelectedVendedor,
    handleSelectVendedorByCode,
    vendedores,
  };
};

export default UseSelectVendedor;
