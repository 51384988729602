import * as S from './kpisTable.styles';

import { RegraProps, RegraRestricaoProps } from 'client/interfaces';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Accordion } from 'reactivus';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import NovoKpiModal from '../novoKpiModal/novoKpiModal.index';
import { getAllKpiOnCampanhas, postDeleteKpiOnCampanha } from 'client/api/kpis';
import { trackGCatchError } from 'utils/analytics';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';

type itensTableProps = {
  selectedRegra: RegraProps;
  codEmpr: number;
  handleGetRegrasComerciais: () => void;
};

export default function KpisTable({ selectedRegra, codEmpr, handleGetRegrasComerciais }: itensTableProps) {
  try {
    const [kpisList, setKpisList] = useState([]);
    const [showNovaRestricaoModal, setShowNovaRestricaoModal] = useState(false);

    useEffect(() => {
      if (selectedRegra && selectedRegra.codregra) {
        handleGetKpisOnCampanhas();
      }
    }, [selectedRegra]);

    const handleGetKpisOnCampanhas = () => {
      getAllKpiOnCampanhas(codEmpr, selectedRegra.codregra).then((res) => {
        const kpis = adjustKpisListIndexes(res.data ?? [], 'nroKpi', 'tituloKpi');
        setKpisList(kpis ?? []);
      });
    };

    function adjustKpisListIndexes(arr: any[], txt1: string, txt2: string): any[] {
      const newTxt1 = 'codigorestricao';
      const newTxt2 = 'descricao';
      const keyMap: { [key: string]: string } = {
        [txt1]: newTxt1,
        [txt2]: newTxt2,
      };
      return arr.map((obj) => {
        const renamedObject: any = {};
        for (const key in obj) {
          if (obj.hasOwnProperty(key) && keyMap[key]) {
            const newKey = keyMap[key];
            renamedObject[newKey] = obj[key];
          }
        }
        return renamedObject;
      });
    }

    const removeRestricaoBodyTemplate = (rowData: RegraRestricaoProps) => {
      return (
        <Button
          icon={<icons.RemoveFill />}
          color={'red'}
          onClick={(e: any) => {
            handleRemoveKpiOnCampanha(rowData.codigorestricao);
          }}
          size="sm"
          bgOnlyOnHover
          rounded
        />
      );
    };

    const handleRemoveKpiOnCampanha = (nroKpi: number) => {
      const kpiToRemove = {
        codCampanha: selectedRegra.codregra,
        nroKpiList: [nroKpi],
      };
      postDeleteKpiOnCampanha(kpiToRemove)
        .then((res) => {
          if (res.succeeded) {
            handleGetRegrasComerciais();
          }
        })
        .catch((err) => {
          toast.error('Falha inesperada ao remover vinculo KPI');
          console.log('err :', err);
        });
    };

    const AccordionHeaderTemplate = () => {
      return (
        <S.AccordionHeaderTemplateBox>
          <span>KPIs</span>
          <Button
            icon={<icons.Plus />}
            color={selectedRegra ? 'green' : 'black'}
            text="Adicionar KPI"
            size="sm"
            onClick={(e: any) => {
              e.stopPropagation();
              selectedRegra && setShowNovaRestricaoModal(true);
            }}
            disabled={!selectedRegra}
            textMode
            tag
          />
        </S.AccordionHeaderTemplateBox>
      );
    };

    return (
      <Accordion title={'KPIs'} headerTemplate={<AccordionHeaderTemplate />}>
        <S.RestricoesTableMainBox>
          <S.RestricoesBodyBox>
            <DataTable
              value={kpisList ?? []}
              paginator={false}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rows={100}
              rowHover
              stripedRows
              scrollable
              scrollHeight="62dvh"
              style={{ maxWidth: '100%', minHeight: '62dvh' }}
              emptyMessage={
                selectedRegra ? "Esta regra não possui KPI's" : "Selecione uma regra pra visualizar os KPI's"
              }
              size="small"
            >
              <Column
                field="codigorestricao"
                header="Cód."
                sortable
                style={{
                  maxWidth: '100px',
                }}
              />
              <Column
                field="descricao"
                header="Descrição"
                sortable
                style={{
                  minWidth: '40%',
                }}
              />
              <Column field="" header="Remover" body={removeRestricaoBodyTemplate} />
            </DataTable>
          </S.RestricoesBodyBox>
        </S.RestricoesTableMainBox>
        <NovoKpiModal
          showNovaRestricaoModal={showNovaRestricaoModal}
          setShowNovaRestricaoModal={setShowNovaRestricaoModal}
          codEmpresa={codEmpr}
          handleGetRegrasComerciais={handleGetRegrasComerciais}
          selectedRegra={selectedRegra}
          campanhaKpis={kpisList ?? []}
        />
      </Accordion>
    );
  } catch (err) {
    trackGCatchError(err, 'regrasComerciais/components/kpisTable/kpisTable.index.tsx');
  }
}
