import { useMapsByUser } from 'client/hooks/mapa';
import { Mapa } from 'client/interfaces';
import { styleHeaderCenter } from 'components/datatable';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import * as S from '../style';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useMapSectorsStore } from 'storesZustand/mapSectorsStore';
import { InputSwitch, InputSwitchChangeParams } from 'primereact/inputswitch';
import { updateMapa } from 'client/api';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import { trackGCatchError } from 'utils/analytics';
import { useLoaderEffect } from 'providers/loaderEffect';

interface IParamsListMapsByCompany {
  codCompany: number;
  codUser: number;
  status: string;
  searchText: string;
}

export const ListMapsByCompany = ({ codCompany, codUser, status, searchText }: IParamsListMapsByCompany) => {
  try {
    const { data: mapas } = useMapsByUser(codCompany, codUser);

    const { setLoaderHeader } = useLoaderEffect();

    const { setCodUser, setCodCompanyUser } = useMapSectorsStore();

    const navigate = useNavigate();

    const mapNameTemplate = (rowData: Mapa) => {
      return (
        <span>
          [{rowData.cod}] {rowData.nome.toUpperCase()}
        </span>
      );
    };

    const actionBodyTemplate = (rowData: Mapa) => {
      return (
        <S.MapEditTemplate onClick={() => navigate(`./editar/${codCompany}/${rowData.cod}`)}>
          <Button
            icon={<icons.Edit />}
            iconPosition="left"
            onClick={() => navigate(`./editar/${codCompany}/${rowData.cod}`)}
            color="black"
            rounded
            bgOnlyOnHover
            tooltip="Editar"
            tooltipPosition="right"
          />
        </S.MapEditTemplate>
      );
    };

    const mapLinkTemplate = (rowData: Mapa) => {
      return (
        <S.MapLinkTemplate
          onClick={() => {
            window.location.pathname = `/mapa/regioes/${rowData.codEmpresa}/${codUser}/${rowData.cod}`;
          }}
        >
          <Button
            icon={<icons.Map />}
            iconPosition="left"
            onClick={() => {
              window.location.pathname = `/mapa/regioes/${rowData.codEmpresa}/${codUser}/${rowData.cod}`;
            }}
            color="green"
            rounded
            bgOnlyOnHover
            tooltip="Visualizar"
            tooltipPosition="right"
            textMode
          />
        </S.MapLinkTemplate>
      );
    };

    const filteredItems = useMemo(() => {
      if (status === 'S') {
        return mapas?.filter((item) => item?.ativo);
      } else if (status === 'N') {
        return mapas?.filter((item) => !item?.ativo);
      } else {
        return mapas;
      }
    }, [mapas, status]);

    useEffect(() => {
      setCodUser(codUser);
      setCodCompanyUser(codCompany);
    }, [codUser, codCompany]);

    useEffect(() => {
      setLoaderHeader(false);
    }, [mapas]);

    const queryClient = useQueryClient();

    const MapStatusTemplate = ({ map }: { map: Mapa }) => {
      const [isMapActive, setIsMapActive] = useState(map.ativo);
      const handleEditMapAtivoField = (map: any, ativo: InputSwitchChangeParams) => {
        const toEditMapData = { ...map };
        toEditMapData.ativo = ativo.value;
        try {
          updateMapa({ ...toEditMapData, cod: parseInt(toEditMapData.cod) })
            .then(() => {
              toast.success(`Mapa ${ativo.value ? 'ativado' : 'desativado'}`);
              queryClient.invalidateQueries(['mapas']);
              setIsMapActive(ativo.value);
            })
            .catch(() => {
              toast.error(`Falha ao ${ativo.value ? 'ativar' : 'desativar'}mapa`);
              setIsMapActive(!ativo.value);
            });
        } catch (err) {
          toast.error(`Falha ao ${ativo.value ? 'ativar' : 'desativar'}mapa`);
          setIsMapActive(ativo.value);
        }
      };
      return (
        <InputSwitch
          checked={isMapActive}
          onChange={(e) => {
            handleEditMapAtivoField(map, e);
          }}
        />
      );
    };

    return (
      <DataTable
        value={
          searchText == ''
            ? filteredItems
            : filteredItems?.filter(
                (map) =>
                  map.nome.toUpperCase().includes(searchText) || map.cod.toString().toUpperCase().includes(searchText),
              )
        }
        emptyMessage="Nenhum Mapa Encontrado"
        responsiveLayout="scroll"
        size="small"
        rowHover
        paginator
        rows={9}
      >
        <Column header="CodEmpresa" field="codEmpresa" sortable />
        <Column header="CodFilial" field="codFilial" sortable headerClassName="testHead" />
        <Column header="Nome" field="nome" body={(rowData: Mapa) => mapNameTemplate(rowData)} sortable />
        <Column
          header="Mapa / Setor"
          body={(rowData: Mapa) => mapLinkTemplate(rowData)}
          align="center"
          alignHeader="center"
        />
        <Column
          header={'Editar'}
          body={(rowData: Mapa) => actionBodyTemplate(rowData)}
          align="center"
          alignHeader="center"
        />
        <Column
          header="Ativo"
          headerStyle={styleHeaderCenter}
          field="ativo"
          body={(rowData: Mapa) => {
            return <MapStatusTemplate map={rowData} />;
          }}
          sortable
          alignHeader="center"
          align="center"
        />
      </DataTable>
    );
  } catch (err) {
    trackGCatchError(err, 'CadastroMapa/ListMapByCompany/index.tsx');
  }
};
