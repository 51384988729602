/* eslint-disable @typescript-eslint/restrict-plus-operands */

import { useState } from 'react';
import * as S from './sideBar.styles';
import RouterDailyPaginator from '../dailyPaginator/dailyPaginator.index';
import { AiOutlineSearch } from 'react-icons/ai';
import RouterStopsList from '../stopList';
import { trackGCatchError } from 'utils/analytics';

export default function SideBar() {
  try {
    const [showMenu, setShowMenu] = useState<boolean>(false);

    const [searchText, setSearchText] = useState('');

    return (
      <S.MapSideBarMainBox showMenu={showMenu}>
        {!showMenu && <S.SideBarHeaderClosed>ROTAS</S.SideBarHeaderClosed>}

        <S.MapSideBarOpenBtn showMenu={showMenu} onClick={(e) => setShowMenu(!showMenu)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http:// www.w3.org/2000/svg"
            className="mh-50px"
            color="#7bcda0"
          >
            <path
              opacity="0.5"
              d="M9.63433 11.4343L5.45001 7.25C5.0358 6.83579 5.0358 6.16421 5.45001 5.75C5.86423 5.33579 6.5358 5.33579 
            6.95001 5.75L12.4929 11.2929C12.8834 11.6834 12.8834 12.3166 12.4929 12.7071L6.95001 18.25C6.5358 18.6642 5.86423 18.6642 
            5.45001 18.25C5.0358 17.8358 5.0358 17.1642 5.45001 16.75L9.63433 12.5657C9.94675 12.2533 9.94675 11.7467 9.63433 11.4343Z"
              fill="currentColor"
            />
            <path
              d="M15.6343 11.4343L11.45 7.25C11.0358 6.83579 11.0358 6.16421 11.45 5.75C11.8642 5.33579 
             12.5358 5.33579 12.95 5.75L18.4929 11.2929C18.8834 11.6834 18.8834 12.3166 18.4929 12.7071L12.95 18.25C12.5358 18.6642 
             11.8642 18.6642 11.45 18.25C11.0358 17.8358 11.0358 17.1642 11.45 16.75L15.6343 12.5657C15.9467 12.2533 15.9467 11.7467 
             15.6343 11.4343Z"
              fill="currentColor"
            />
          </svg>
        </S.MapSideBarOpenBtn>

        <S.RouterSideBarContent showMenu={showMenu}>
          {/* HEADER BOX DO MENU  */}
          <S.SideBarHeader>
            <S.SideBarHeaderInputBox>
              <S.SideBarHeaderInput
                type={'text'}
                placeholder="Buscar por Cliente"
                value={searchText}
                onChange={(e: any) => {
                  setSearchText(e.target.value.toUpperCase());
                }}
              />
              <S.SideBarHeaderInputButton>
                <AiOutlineSearch />
              </S.SideBarHeaderInputButton>
            </S.SideBarHeaderInputBox>
            {/* PAGINADOR COM OS DIAS */}
            <RouterDailyPaginator />
          </S.SideBarHeader>

          {/* LISTA DE PONTOS */}
          <RouterStopsList searchText={searchText} />
        </S.RouterSideBarContent>
      </S.MapSideBarMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'AnaliseRoteiro/components/sideBar/sideBar.index.tsx');
  }
}
