import { Container, Header } from 'components/Containers';
import styled from 'styled-components';

export const AccessDataMainBox = styled(Container)``;

export const ContainerTable = styled.div`
  width: 100%;
`;

export const HeaderBox = styled(Header)``;

export const BoxSelect = styled.div`
  padding-left: 10px;
`;

interface IBoxTab {
  isActive: boolean;
}

export const BoxTab = styled.div`
  width: 300px;
  height: 30px;
  background-color: ${(props: IBoxTab) => (props.isActive ? 'var(--primary-green)' : '#e4e7f1')};

  display: flex;
  align-items: center;

  cursor: pointer;

  p {
    margin-left: 10px;
    color: ${(props: IBoxTab) => (props.isActive ? 'white' : '#646670')};
  }
`;

export const ContainerTabs = styled.div`
  display: flex;
  margin-left: 50px;
  margin-right: 50px;

  border-bottom: 1px solid var(--dark-200);
`;
