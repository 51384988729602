import * as S from './styles';
import { useState } from 'react';
import AnaliseModal from '../analiseModal';
import { editVisita } from 'client/api';
import { toast } from 'react-toastify';
import { useUser } from 'client/hooks';
import { trackGCatchError } from 'utils/analytics';
import { dialog } from 'reactivus';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';

export default function ActionBar({ selectedCli, setSelectedCli, handleGetMainData }: any) {
  try {
    const [showModal, setShowModal] = useState(false);

    const { data: user } = useUser();

    const handleLiberar = () => {
      setShowModal(true);
    };

    const handleRejeitar = (toUpdateDataObject: any) => {
      editVisita(toUpdateDataObject)
        .then((res: any) => {
          toast.success('Analise rejeitada!');
          setSelectedCli([]);
          handleGetMainData();
        })
        .catch((err: any) => {
          console.log('err :', err);
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          }
        });
    };

    const handleRejeitarConfirmation = (updateCadastro: 'S' | 'N') => {
      let updateDataObject = {
        Codempresa: selectedCli.codempresa,
        CodVendedor: selectedCli.codusur,
        Codcli: selectedCli.codcli,
        Status: 'R',
        Observacao: 'Rejeitado pelo painel web Vmais',
        latitude: selectedCli.latitude ?? '0',
        longitude: selectedCli.longitude ?? '0',
        AtualizaCadastro: updateCadastro,
        Codfuncanalise: user.codUsuario,
        Nomefuncanalise: user.nome,
      };
      setShowModal(false);

      dialog
        .show({
          title: 'Tem certeza que deseja rejeitar esta localização ?',
          icon: 'question',
          confirmButtonText: 'Sim, rejeitar',
          cancelButtonText: 'Cancelar',
          showCloseButton: true,
          showCancelButton: true,
          showConfirmButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            handleRejeitar(updateDataObject);
          }
        });
    };

    return (
      <S.ActionBarMainBox>
        <Button
          text="Liberar"
          color="green"
          icon={<icons.Check />}
          onClick={() => handleLiberar()}
          tooltip="Liberar Visita"
        />
        <Button
          text="Rejeitar"
          color="red"
          icon={<icons.X />}
          onClick={() => handleRejeitarConfirmation('N')}
          tooltip="Rejeitar Visita"
        />
        {showModal && (
          <AnaliseModal
            selectedCli={selectedCli}
            showModal={showModal}
            setShowModal={setShowModal}
            setSelectedCli={setSelectedCli}
            handleGetMainData={handleGetMainData}
          />
        )}
      </S.ActionBarMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'AnaliseVisitas/components/actionBar/index.tsx');
  }
}
