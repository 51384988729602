import { BoxTable, Container, Header } from 'components/Containers';
import styled, { css } from 'styled-components';

interface IResponsiveProps {
  mobile?: boolean;
  tablet?: boolean;
}

export const FotosPageMainBox = styled(Container)``;

export const FotosPageHeader = styled(Header)``;

export const FotosImageBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  img {
    max-height: 50px;
    width: auto;
    max-width: 50px;
  }
`;

export const BoxImage = styled.div`
  min-height: '100px';

  img {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
`;

export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerTable = styled(BoxTable)<IResponsiveProps>`
  width: 100%;
  overflow: hidden;

  ${(props) =>
    props.mobile &&
    css`
      @media (max-width: 768px) {
        padding: 10px;
        padding-bottom: 30px;
      }
    `}

  ${(props) =>
    props.tablet &&
    css`
      @media (max-width: 992px) {
        padding: 10px;
        padding-bottom: 30px;
      }
    `}
`;
