import { PropagateLoader } from 'react-spinners';

import logo from 'assets/images/logo/v-mais.svg';

import * as S from './styles';
import { useLoaderEffect } from 'providers/loaderEffect';
import { useEffect, useState } from 'react';

export default function LoaderEffect() {
  const { loader } = useLoaderEffect();

  return (
    <S.Box show={loader.show}>
      <img src={logo} />
      <S.Text>{loader.text ?? ''}</S.Text>
      <PropagateLoader size="13px" color="#228F6B" />
    </S.Box>
  );
}

interface IIndependentPage {
  show: boolean;
  text?: string;
}

export function LoaderEffectIndependentPage({ show, text }: IIndependentPage) {
  return (
    <S.BoxIndependent show={show}>
      <img src={logo} />
      <S.Text>{text ?? 'Por favor aguarde...'}</S.Text>
      <PropagateLoader size="13px" color="#228F6B" />
    </S.BoxIndependent>
  );
}

export function LoaderEffectHeader() {
  const { loaderHeader } = useLoaderEffect();

  return <S.LoaderEffectHeader show={loaderHeader} />;
}
