import axios, { AxiosRequestConfig } from 'axios';
import { FormularioCab, FormularioItem, RespostaCab, Restricao, RestricaoUpdate, FormTipo } from 'client/interfaces';
import api, { BASE_API_URL, ORIGIN } from 'services/api';
import storage from 'services/storage';

const _controller = 'form';

export const getCabecalho = async (codEmpresa: number | string): Promise<FormularioCab[]> => {
  const { data } = await api.get(`${_controller}/cabecalho?CodEmpresa=${codEmpresa}`);
  return data;
};

export const getCabecalhoWithItems = async (codEmpresa: number): Promise<FormularioCab[]> => {
  const { data } = await api.get(`${_controller}/cabecalho/items`, { params: { codEmpresa } });
  return data;
};

export const getCabecalhoById = async (id: number): Promise<FormularioCab> => {
  const { data } = await api.get(`${_controller}/cabecalho/${id}`);
  return data;
};

export const deleteCabecalho = async (id: number): Promise<void> => {
  await api.delete(`${_controller}/cabecalho/${id}`);
};

export const getCabecalhoItems = async (id: number, codEmpresa: number): Promise<FormularioItem[]> => {
  const { data } = await api.get(`${_controller}/cabecalho/${id}/items`, { params: { codEmpresa } });
  return data;
};

export const saveForm = async (form: FormularioCab): Promise<number> => {
  const { data } = await api.post(`${_controller}/cabecalho`, form);
  return data;
};

export const updateForm = async (data: FormularioCab): Promise<void> => {
  await api.put(`${_controller}/cabecalho`, data);
};

export const updateItems = async (id: number, items: FormularioItem[]): Promise<void> => {
  await api.put(`${_controller}/cabecalho/${id}/items`, items);
};

export const updateItemsFormData = async (id: number, items: FormularioItem[]): Promise<void> => {
  var formData = new FormData();

  // TRATA ARRAY DE IMAGENS
  const filesArray = [];
  for (let i = 0; i < items.length; i++) {
    filesArray.push(items[i].img1);
  }
  filesArray.forEach((file, index) => {
    formData.append(`files`, file ?? null);
  });

  const newItens = JSON.stringify(items);
  formData.append(`itens`, newItens);

  const token = storage.getItem<string>('token');

  const config: AxiosRequestConfig = {
    baseURL: BASE_API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': ORIGIN,
      Authorization: `Bearer ${token ?? ''}`,
    },
  };
  const response = await axios.put(`${_controller}/cabecalho/${id}/itemsFormulario`, formData, config);
  return response.data;
};

export const duplicateItems = async (from: number, to: number, codEmpresa: number): Promise<void> => {
  await api.post(`${_controller}/cabecalho/${from}/items/clone/${to}`, { params: { codEmpresa } });
};

export const duplicateRestricoes = async (from: number, to: number, codEmpresa: number): Promise<void> => {
  await api.post(`${_controller}/cabecalho/${from}/restricoes/clone/${to}`, { params: { codEmpresa } });
};

export const getRestricoes = async (id: number, codEmpresa: number): Promise<Restricao[]> => {
  const { data } = await api.get(`${_controller}/cabecalho/${id}/restricoes`, { params: { codEmpresa } });
  return data;
};

export const updateRestricoes = async (id: number, items: RestricaoUpdate[]): Promise<void> => {
  await api.put(`${_controller}/cabecalho/${id}/restricoes`, items);
};

export const getRespostas = async (start: Date, end: Date, codEmpresa: number): Promise<RespostaCab[]> => {
  const { data } = await api.get(`${_controller}/respostas/${codEmpresa}`, { params: { start, end, codEmpresa } });
  return data;
};

export const getRespostaWithItems = async (resposta: string, codEmpresa: number): Promise<RespostaCab> => {
  const { data } = await api.get(`${_controller}/respostas/items`, { params: { resposta, codEmpresa } });
  return data;
};

export const getTipos = async (): Promise<FormTipo[]> => {
  const { data } = await api.get(`${_controller}/tipos`);
  return data;
};

export const postImageFormExemplo = async (data: any): Promise<any> => {
  var formData = new FormData();

  formData.append(`file`, data.img1 ?? null);

  const token = storage.getItem<string>('token');

  const config: AxiosRequestConfig = {
    baseURL: BASE_API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': ORIGIN,
      Authorization: `Bearer ${token ?? ''}`,
    },
  };

  const response = await axios.post(
    `Form/PostFotoExemplo?codEmpresa=${data.codEmpresa.toString()}&NroForm=${data.nroForm.toString()}&Ordem=${data.ordem.toString()}`,
    formData,
    config,
  );
  return response.data;
};

export const postDeleteImageFormExemplo = async (
  imageName: string,
  codEmpresa: number,
  nroForm: number,
  ordem: number,
): Promise<any> => {
  const { data } = await api.post(
    `Form/DeleteFotoExemplo?imageName=${imageName}&codEmpresa=${codEmpresa}&NroForm=${nroForm}&Ordem=${ordem}`,
  );
  return data;
};

export const postImageFormCategoria = async (data: any): Promise<any> => {
  var formData = new FormData();

  formData.append(`file`, data.fotoCategoria ?? null);

  const token = storage.getItem<string>('token');

  const config: AxiosRequestConfig = {
    baseURL: BASE_API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': ORIGIN,
      Authorization: `Bearer ${token ?? ''}`,
    },
  };

  const response = await axios.post(
    `${_controller}/PostFotoCategoriaForm?codEmpresa=${data.codEmpresa.toString()}&NroForm=${data.nroForm.toString()}`,
    formData,
    config,
  );
  return response.data;
};

export const postDeleteImageFormCategoria = async (
  imageName: string,
  codEmpresa: number,
  NroForm: number,
): Promise<any> => {
  const { data } = await api.post(
    `${_controller}/DeleteFotoCategoriaForm?imageName=${imageName}&codEmpresa=${codEmpresa}&NroForm=${NroForm}`,
  );
  return data;
};
