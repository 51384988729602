import { BoxTable, Container, Header } from 'components/Containers';
import styled from 'styled-components';

export const CadastroMenuMainBox = styled(Container)`
  @media (max-width: 1080px) {
    padding: 10px;
    padding-bottom: 40px;
    .p-datatable .p-datatable-tbody tr td .v-input-switch {
      margin-right: 30px;
    }
  }
`;

export const CadastroMenuHeader = styled(Header)`
  div {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: flex-start;
    align-items: flex-end;
  }

  justify-content: space-between;
`;

export const CadastroMenuTableBox = styled(BoxTable)``;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const RowItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 10px;
`;
