import { Column } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';
import { MenuSubDto, MenuWithSub } from 'client/interfaces';
import { textEditor } from 'components/datatable';
import { useModalAddFunctionalityToCompany } from 'storesZustand/modalAddFunctionalityToCompany';
import ModalAddFunctionalityToCompanySubMenu, {
  IRowDataSubMenu,
} from './modalsSubMenu/ModalAddFunctionalityToCompanySubMenu';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';
import { trackGCatchError } from 'utils/analytics';

interface SubItemMenuProps {
  menu: MenuWithSub;
  createNew: (codMenu: number) => void;
  removeItem: (menuSub: MenuSubDto) => void;
  setEdited: (codMenu: number) => void;
}

const SubItemMenu = ({ menu, createNew, removeItem, setEdited }: SubItemMenuProps) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onCellEditComplete = (e: any) => {
      const { rowData, newValue, field, originalEvent: event } = e;

      if (newValue.trim().length > 0) {
        rowData[field] = newValue;
        setEdited(menu.codMenu);
      } else event.preventDefault();
    };

    const header = (
      <div className="d-grid gap-2 d-md-flex">
        <Button icon={<icons.Plus />} text="Novo" color="green" onClick={() => createNew(menu.codMenu)} />
      </div>
    );

    const confirmDeleteItem = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: MenuSubDto) => {
      confirmPopup({
        target: event.currentTarget,
        message: 'Tem certeza que deseja excluir esse submenu?',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: () => removeItem(data),
      });
    };

    const deleteTemplate = (data: MenuSubDto) => (
      <Button
        type="button"
        color="red"
        bgOnlyOnHover
        textMode
        icon={<icons.Remove />}
        onClick={(e) => confirmDeleteItem(e, data)}
        rounded
      />
    );

    const actionBodyTemplate = (handleAddFunctionalityToCompany: () => void) => {
      return (
        <Button
          type="button"
          color="green"
          bgOnlyOnHover
          textMode
          icon={<icons.LockOpen />}
          onClick={handleAddFunctionalityToCompany}
          rounded
        />
      );
    };

    const { setStatus, setSelectedCompany } = useModalAddFunctionalityToCompany();

    const [openModalAddFunctionalityMenu, setModalAddFunctionalityMenu] = useState(false);

    const [rowDataMenu, setRowDataMenu] = useState<IRowDataSubMenu>({
      codMenu: 0,
      codMenuSub: 0,
      CodSubMenu: 0,
      nomePermissao: '',
    });

    function handleAddFunctionalityToCompany(rowData: IRowDataSubMenu) {
      setModalAddFunctionalityMenu(true);
      setRowDataMenu(rowData);
      setStatus('default');
      setSelectedCompany({
        codEmpresa: 0,
        cnpj: '',
        razaosocial: '',
        fantasia: '',
        caminhoLogo: '',
      });
    }

    return (
      <>
        <DataTable
          value={menu.menuSub}
          header={header}
          sortMode="single"
          sortField="grupo"
          sortOrder={1}
          editMode="cell"
          dataKey="codMenuSub"
          emptyMessage="Nenhum submenu adicionado"
          size="small"
        >
          <Column field="codSubMenu" header="Código" headerStyle={{ width: '3em' }} />
          <Column
            field="nomePermissao"
            header="Permissão"
            editor={textEditor}
            onCellEditComplete={onCellEditComplete}
          />
          <Column headerStyle={{ width: '3em' }} body={deleteTemplate} />
          <Column
            headerStyle={{ width: '3em' }}
            body={(rowData: IRowDataSubMenu) => actionBodyTemplate(() => handleAddFunctionalityToCompany(rowData))}
          />
        </DataTable>
        <ModalAddFunctionalityToCompanySubMenu
          setOpenModal={setModalAddFunctionalityMenu}
          openModal={openModalAddFunctionalityMenu}
          data={rowDataMenu}
        />
      </>
    );
  } catch (err) {
    trackGCatchError(err, 'itensMenu/pages/SubItemMenu.tsx');
  }
};

export default SubItemMenu;
