'use client';

import { createContext, SetStateAction, useContext, useState } from 'react';
import { ParamsRcaObjectProps, ParamsRcaProps, ParamsRcaTableHeader } from './paramRca.types';
import { getParametros } from 'client/api/parametros';
import paramsLocalList from './paramRca.list';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import UseSelectUsuario from 'hooks/UseSelectUsuario/selectUsuario';

export interface ProdContextProps {
  paramsRca: ParamsRcaObjectProps;
  setParamsRca: React.Dispatch<SetStateAction<ParamsRcaObjectProps>>;
  selectedParamRca: ParamsRcaProps;
  setSelectedParamRca: React.Dispatch<SetStateAction<ParamsRcaProps>>;
  selectedToEditParamRca: ParamsRcaProps;
  setSelectedToEditParamRca: React.Dispatch<SetStateAction<ParamsRcaProps>>;
  tableConfig: ParamsRcaTableHeader[];
  setTableConfig: React.Dispatch<SetStateAction<ParamsRcaTableHeader[]>>;
  handleGetParamsRcaList: (codEmpresa: number, forceRefetch: boolean, codRca?: number) => void;
  handleGetSingleParamRca: (
    codEmpresa: number,
    codRca: number,
    updateSelectedState: boolean,
    forceRefetch: boolean,
  ) => ParamsRcaProps;
  SelectEmpresa: any;
  selectedEmpresa: any;
  SelectUsuario: any;
  selectedUsuario: any;
}

export const ParamsRcaContext = createContext({} as ProdContextProps);

export const ParamsRcaProvider = ({ children }: any) => {
  const [paramsRca, setParamsRca] = useState<ParamsRcaObjectProps>({
    filtered: [],
    unfiltered: [],
  });

  const [selectedParamRca, setSelectedParamRca] = useState<ParamsRcaProps>(null);
  const [selectedToEditParamRca, setSelectedToEditParamRca] = useState<ParamsRcaProps>(null);

  const [tableConfig, setTableConfig] = useState<ParamsRcaTableHeader[]>(paramsLocalList);

  const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();
  const { SelectUsuario, selectedUsuario } = UseSelectUsuario(selectedEmpresa.codEmpresa);

  const handleGetParamsRcaList = (codEmpresa: number, forceRefetch: boolean, codRca?: number) => {
    if (selectedUsuario.codEmpresa == selectedEmpresa.codEmpresa) {
      getParametros(selectedEmpresa.codEmpresa, selectedUsuario.codUsuario).then((res) => {
        if (codRca && !forceRefetch) {
          handleGetSingleParamRca(codEmpresa, codRca, true, false, res);
        }
        setParamsRca({
          filtered: res ?? [],
          unfiltered: res ?? [],
        });
      });
    } else {
      setParamsRca({
        filtered: [],
        unfiltered: [],
      });
    }
  };

  const handleGetSingleParamRca = (
    codEmpresa: number,
    codRca: number,
    updateSelectedState: boolean,
    forceRefetch: boolean,
    params?: ParamsRcaProps[],
  ) => {
    if (paramsRca.unfiltered.length > 0 && !forceRefetch) {
      const paramRca = paramsRca.unfiltered.filter((param) => param.codUsur == +codRca);
      if (paramRca && paramRca[0]) {
        if (updateSelectedState) {
          setSelectedParamRca(paramRca[0]);
          setSelectedToEditParamRca(paramRca[0]);
        }
        return paramRca[0];
      }
    } else if (params && params.length > 0) {
      const paramRca = params.filter((param) => param.codUsur == +codRca);
      if (paramRca && paramRca[0]) {
        if (updateSelectedState) {
          setSelectedParamRca(paramRca[0]);
          setSelectedToEditParamRca(paramRca[0]);
        }
        return paramRca[0];
      }
    } else {
      handleGetParamsRcaList(codEmpresa, forceRefetch, codRca);
    }
  };

  return (
    <ParamsRcaContext.Provider
      value={{
        paramsRca,
        setParamsRca,
        selectedParamRca,
        setSelectedParamRca,
        tableConfig,
        setTableConfig,
        selectedToEditParamRca,
        setSelectedToEditParamRca,
        handleGetParamsRcaList,
        handleGetSingleParamRca,
        SelectEmpresa,
        selectedEmpresa,
        SelectUsuario,
        selectedUsuario,
      }}
    >
      {children}
    </ParamsRcaContext.Provider>
  );
};

export const useParamsRca = () => useContext(ParamsRcaContext);
