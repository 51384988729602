import { Container } from 'components/Containers';
import styled from 'styled-components';

export const RegraComercialMainBox = styled(Container)`
  .r-accordion-header-box {
    overflow: scroll !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
      height: 0px !important;
      width: 0px !important;
    }
  }
  .r-accordion-header-box::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
  }
`;
