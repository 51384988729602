import useMediaQuery from 'hooks/useMediaQuery';
import * as S from './editRegraModal.styles';
import { Dialog } from 'primereact/dialog';
import { EditRegraModalProps, RegraProps } from 'client/interfaces';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { postEditRegra } from 'client/api/regraComercial';
import styled from 'styled-components';
import { InputTextarea } from 'primereact/inputtextarea';
import { trackGCatchError } from 'utils/analytics';
import InputText from 'components/Inputs/InputText/text.input';
import InputDate from 'components/Inputs/InputDate/date.input';
import { formatDate } from 'utils/formatDate';
import InputSwitch from 'components/Inputs/InputSwitch/switch.input';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import UseSelectFilial from 'hooks/UseSelectFilial/selectFilial';

export default function EditRegraModal({
  showEditRegraModal,
  setShowEditRegraModal,
  handleGetRegrasComerciais,
  regraToEdit,
  codEmpresa,
}: EditRegraModalProps) {
  try {
    const isWebScreen = useMediaQuery('(min-width: 1060px)');

    const { SelectFilial, selectedFilial, handleSelectFilialByCode, filiais } = UseSelectFilial(codEmpresa, 0);

    useEffect(() => {
      if (selectedFilial && selectedFilial.codFilial) {
        setEditRegra((prevState: RegraProps) => {
          return {
            ...prevState,
            filial: selectedFilial.codFilial,
          };
        });
      }
    }, [selectedFilial]);

    const [editRegra, setEditRegra] = useState(null);

    const textareaRef = useRef(null);

    useEffect(() => {
      if (showEditRegraModal) {
        setEditRegra(regraToEdit);
        filiais && handleSelectFilialByCode(regraToEdit.filial);
      }
    }, [regraToEdit, showEditRegraModal, filiais]);

    const validateFormFields = () => {
      for (const key in editRegra) {
        if (editRegra.hasOwnProperty(key)) {
          if (
            editRegra[key] === '' &&
            key !== 'sql' &&
            key !== 'descricao' &&
            key !== 'img1' &&
            key !== 'img2' &&
            key !== 'img3' &&
            key !== 'nomefunccad' &&
            key !== 'descricao'
          ) {
            toast.warn(`O campo '${key}' está vazio.`);
            return;
          }
          if (key === 'filial' && editRegra[key] === 0) {
            toast.warn(`O campo '${key}' está vazio.`);
            return;
          }
        }
      }
      handleEditRegra();
    };

    const handleEditRegra = () => {
      const regraToEdit = { ...editRegra };
      regraToEdit.descricao = textareaRef.current ? textareaRef.current.value : '';
      postEditRegra(regraToEdit)
        .then((res) => {
          if (res.succeeded) {
            handleGetRegrasComerciais();
            toast.success('Regra editada com sucesso');
            setShowEditRegraModal(false);
          }
        })
        .catch((err) => {
          toast.error('Falha inesperada ao editar regra');
          console.log('err :', err);
        });
    };

    const StyledTextarea = styled(InputTextarea)`
      resize: none;
    `;

    return (
      <Dialog
        header={editRegra ? `Editar regra [${editRegra.codregra}] - ${editRegra.nomeregra}` : 'Editar regra'}
        visible={showEditRegraModal}
        style={{ width: isWebScreen ? '35vw' : '90%' }}
        onHide={() => {
          setShowEditRegraModal(false);
        }}
      >
        <S.EditRegraModalFormBox>
          <S.EditRegraModalFormRow>
            <InputText
              label="Regra"
              placeholder="Nome da regra"
              value={editRegra ? editRegra.nomeregra : ''}
              onChange={(e) => {
                e = e.toUpperCase();
                setEditRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    nomeregra: e,
                  };
                });
              }}
              width="clamp(40%, 220px, 45%)"
            />

            <SelectFilial label={'Filial'} width="clamp(40%, 220px, 45%)" />
          </S.EditRegraModalFormRow>

          <S.EditRegraModalFormRow>
            <InputDate
              label="Data Inicial"
              value={
                editRegra && editRegra.dataInicio
                  ? new Date(`${editRegra.dataInicio.split('/').reverse().join('-')}T00:00`)
                  : new Date()
              }
              onChange={(e) => {
                setEditRegra((prevState: RegraProps) => ({
                  ...prevState,
                  dataInicio: formatDate(e, 'BR'),
                }));
              }}
            />

            <InputDate
              label="Data Final"
              value={
                editRegra && editRegra.dataFim
                  ? new Date(`${editRegra.dataFim.split('/').reverse().join('-')}T00:00`)
                  : new Date()
              }
              onChange={(e) => {
                setEditRegra((prevState: RegraProps) => ({
                  ...prevState,
                  dataFim: formatDate(e, 'BR'),
                }));
              }}
            />
            <InputSwitch
              label="Ativa"
              checked={editRegra && editRegra.ativa == 'S'}
              onChange={(e) => {
                setEditRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    ativa: e ? 'S' : 'N',
                  };
                });
              }}
              style={{
                maxWidth: '100px',
              }}
            />

            <InputSwitch
              label="Envia FV"
              checked={editRegra && editRegra.enviafv == 'S'}
              onChange={(e) => {
                setEditRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    enviafv: e ? 'S' : 'N',
                  };
                });
              }}
              style={{
                maxWidth: '100px',
              }}
            />
          </S.EditRegraModalFormRow>

          <S.EditRegraModalFormRow>
            <S.RegraInputBox>
              <label htmlFor="report">Decrição da regra</label>
              <StyledTextarea
                ref={textareaRef}
                defaultValue={editRegra ? editRegra.descricao : ''}
                rows={5}
                cols={65}
              />
            </S.RegraInputBox>
          </S.EditRegraModalFormRow>

          <S.EditRegraModalFormFooter>
            <Button
              color="green"
              text="Salvar"
              icon={<icons.Check />}
              tooltip="Salvar nova regra"
              onClick={() => {
                validateFormFields();
              }}
            />
          </S.EditRegraModalFormFooter>
        </S.EditRegraModalFormBox>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'regrasComerciais/components/editRegraModal/editRegraModal.index.tsx');
  }
}
