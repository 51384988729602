export const OPTIONS_ACTIVE_INATIVE = [
  {
    value: 'S',
    label: 'Ativa',
  },
  {
    value: 'N',
    label: 'Inativa',
  },
];
