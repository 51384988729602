import * as S from './creditCard.styles';

import { useState } from 'react';

import mask from './cardMasks';
import { getBandeiraCartaoCheckout } from 'client/api';
import { trackGCatchError } from 'utils/analytics';

export default function CreditCard({ card, setCard }: any) {
  try {
    const [rotateCard, setRotateCard] = useState<boolean>(false);

    const hideCardFront = {
      transform: 'rotateY(180deg)',
      opacity: '0',
    };

    const displayCardBack = {
      transform: 'rotateY(0deg)',
      opacity: '1',
    };

    const handleGetCardBandeira = (cardNumber: string) => {
      cardNumber = cardNumber.replace(/\D/g, '');
      if (cardNumber.length == 6 || cardNumber.length == 16) {
        getBandeiraCartaoCheckout(cardNumber.length > 6 ? cardNumber.slice(0, 6) : cardNumber)
          .then((res) => {
            if (res.provider) {
              setCard((prev: any) => {
                return {
                  ...prev,
                  bandeira: res.provider == 'MASTERCARD' ? 'MASTER' : res.provider,
                };
              });
            }
          })
          .catch((err: any) => {
            console.log('err :', err);
          });
      }
    };

    return (
      <S.CcMainBox>
        <S.CardMainBox id={'creditCard'}>
          <S.CardTitleBox>
            <S.CardTitleText>Dados Do Pagamento</S.CardTitleText>
            <S.CardTitleEnviroment>
              <span>Ambiente Protegido </span>|<span> Vmais Pay</span>
            </S.CardTitleEnviroment>
          </S.CardTitleBox>
          <S.CardBox className={'cardBox'} style={rotateCard ? hideCardFront : {}}>
            <S.CardChip>
              <img src={'/assets/images/golden-credit-card-chip.png'}></img>
            </S.CardChip>
            <S.CardNumber>{card.numeroCartao}</S.CardNumber>
            <S.CardName>{card.nome}</S.CardName>
            <S.CardDate>{card.dataExpiracao}</S.CardDate>
            <S.CardBrand>
              {card.bandeira && (
                <img
                  src={`/assets/images/bandeiras-cartoes/${
                    card.bandeira ? card.bandeira.toString().toLowerCase() : ''
                  }.png`}
                />
              )}
            </S.CardBrand>
          </S.CardBox>

          <S.CardBoxVerso className={'cardBoxVerso'} style={rotateCard ? displayCardBack : {}}>
            <S.CardCvv>cvv: {card.codigoSeguranca}</S.CardCvv>
          </S.CardBoxVerso>
        </S.CardMainBox>
        <S.CardFormBox>
          <S.InputBox>
            <S.Label>Nome:</S.Label>
            <S.Input
              type="text"
              placeholder="Nome como no cartão"
              value={card.nome}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCard((prev: any) => {
                  return {
                    ...prev,
                    nome: mask.handleCardName(e.target.value),
                  };
                });
              }}
            />
          </S.InputBox>

          <S.InputBox>
            <S.Label>Número:</S.Label>
            <S.Input
              type="text"
              placeholder="Número cartão"
              value={card.numeroCartao}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleGetCardBandeira(e.target.value);
                setCard((prev: any) => {
                  return {
                    ...prev,
                    numeroCartao: mask.handleCardNumero(e.target.value),
                    bandeira: mask.handleCardNumero(e.target.value).replace(/\D/g, '').length < 6 ? '' : prev.bandeira,
                  };
                });
              }}
            />
          </S.InputBox>

          <S.InputBox width="48%">
            <S.Label>Data:</S.Label>
            <S.Input
              type="text"
              placeholder="Data de Expiração"
              value={card.dataExpiracao}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCard((prev: any) => {
                  return {
                    ...prev,
                    dataExpiracao: mask.handleCardDate(e.target.value),
                  };
                });
              }}
            />
          </S.InputBox>

          <S.InputBox width="48%">
            <S.Label>CVV:</S.Label>
            <S.Input
              type="text"
              placeholder="CVV do cartão"
              value={card.codigoSeguranca}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCard((prev: any) => {
                  return {
                    ...prev,
                    codigoSeguranca: mask.handleCardCvv(e.target.value),
                  };
                });
              }}
              onMouseEnter={() => {
                // setRotateCard(true);
              }}
              onClick={() => {
                setRotateCard(true);
              }}
              onBlur={() => {
                setRotateCard(false);
              }}
            />
          </S.InputBox>
        </S.CardFormBox>
      </S.CcMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'checkout/components/creditCard/creditCard.tsx');
  }
}
