import { useSetores } from 'client/hooks/setores';
import Dropdown from 'components/Dropdown/dropdown';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import storage from 'services/storage';
import { useScreenwriterStore } from 'storesZustand/screenwriter';
import { trackGCatchError } from 'utils/analytics';

export const HeaderScreenwriter = () => {
  try {
    const mapSelectedScreenwriter = JSON.parse(storage.getItem('selectedScreenwriter'));
    const { paramsCodScreenwriter } = useScreenwriterStore();

    const [selectedSector, setSelectedSector] = useState(mapSelectedScreenwriter);
    const { data: setores } = useSetores(Number(paramsCodScreenwriter.codMapa));

    const navigate = useNavigate();

    const onChangeSector = (option: any) => {
      setSelectedSector(option.target.value);
      storage.setItem('selectedScreenwriter', JSON.stringify(option.target.value));
      navigate(
        `/roteirizador/${option.target.value.codMapa}/${option.target.value.codSetor}/${option.target.value.codRca}/${option.target.value.codEmpresa}`,
      );
    };

    useEffect(() => {
      setSelectedSector(JSON.parse(storage.getItem('selectedScreenwriter')));
    }, []);

    return (
      <Dropdown
        value={selectedSector}
        onChange={onChangeSector}
        className="form-control form-control-lg form-control-solid"
        options={setores}
        placeholder="Alterar setor"
        dataKey="codSetor"
        optionLabel="setor"
        filterBy="setor"
        style={{
          height: '30px',
          width: 'clamp(250px, 300px, 350px)',
          display: 'flex',
          alignItems: 'center',
          marginRight: '10px',
        }}
        width='clamp(250px, 300px, 350px)'
      />
    );
  } catch (err) {
    trackGCatchError(err, 'Roteirizador/HeaderScreenwriter.tsx');
  }
};
