import * as S from './styles';
import { UserData } from 'client/interfaces';
import ContainerFilial from './ContainerFilial';
import ContainerSupervisor from './ContainerSupervisor';
import ContainerManager from './ContainerManager';
import { useUser } from 'client/hooks';
import { IGetAccessData } from 'client/interfaces/acessoDados.interface';
import { deleteAccessData } from 'client/api/acessoDados';
import { toast } from 'react-toastify';
import { BsFillTrash3Fill } from 'react-icons/bs';
import { useQueryClient } from '@tanstack/react-query';
import { trackGCatchError } from 'utils/analytics';
import { dialog } from 'reactivus';

interface IAccessData {
  selectedUsuario: UserData;
}

export enum COD_NOME {
  codFilial = 'TODAS FILIAIS',
  codSupervisor = 'TODOS SUPERVISORES',
  codGerente = 'TODOS GERENTES',
}

const AccessData = ({ selectedUsuario }: IAccessData) => {
  try {
    const queryClient = useQueryClient();

    const { data: user } = useUser();

    const handleDelete = async (rowData: IGetAccessData) => {
      await dialog
        .show({
          title: 'Tem certeza ?',
          text: `Retirar acesso do codUser[${rowData.codUsuario}] - ${rowData.nome}[${rowData.codEmpresa}]`,
          icon: 'question',
          showConfirmButton: true,
          confirmButtonText: 'Confirmar',
          showCancelButton: true,
          cancelButtonText: 'Não, voltar',
        })
        .then((result) => {
          if (result.isConfirmed) {
            const data = {
              CodEmpresa: rowData.codEmpresa,
              Pk: rowData.pk,
            };

            deleteAccessData(data.CodEmpresa, data.Pk)
              .then(() => {
                queryClient.invalidateQueries(['acesso-dados']);

                toast.success('Acesso retirado com sucesso!');
              })
              .catch(() => {
                toast.error('Falha ao retirar acesso!');
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const itemListTemplate = ({ data, cod }) => {
      return (
        <S.BoxRow>
          <div>
            <p className="font-bold">{data.codAcesso === 999 ? COD_NOME[cod] : `${data?.nome}`}</p>
            <p className="font-bold">{data.codAcesso === 999 ? '' : `[${data.codAcesso}]`}</p>
          </div>
          <BsFillTrash3Fill color="red" onClick={() => handleDelete(data)} />
        </S.BoxRow>
      );
    };

    return (
      <S.AccessDataMainBox>
        <S.ContainerBox>
          {selectedUsuario == null ? (
            <S.ContainerDefault />
          ) : (
            <>
              <ContainerFilial itemListTemplate={itemListTemplate} selectedUsuario={selectedUsuario} user={user} />
              <ContainerManager itemListTemplate={itemListTemplate} selectedUsuario={selectedUsuario} user={user} />
              <ContainerSupervisor itemListTemplate={itemListTemplate} selectedUsuario={selectedUsuario} user={user} />
            </>
          )}
        </S.ContainerBox>
      </S.AccessDataMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'acessoUsuario/AccessData/index.tsx');
  }
};

export default AccessData;
