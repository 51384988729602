import { BoxTable, Container, Header } from 'components/Containers';
import styled, { css } from 'styled-components';

interface IResponsiveProps {
  mobile?: boolean;
  tablet?: boolean;
}

export const IconTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const MapasMainBox = styled(Container)``;

export const HeaderBox = styled(Header)`
  z-index: 66;

  form {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 15px;
  }

  @media (max-width: 1024px) {
    padding: 20px 20px 20px 5px;
    max-width: 100dvw;
    overflow-x: scroll;
  }
`;

export const ContainerTable = styled(BoxTable)<IResponsiveProps>`
  max-height: calc(100dvh - 172px);
  position: relative;
  position: sticky;

  .p-datatable {
    max-height: 100%;
    overflow: auto;
    border-radius: 5px;
  }

  .p-datatable-wrapper {
    max-height: 100%;
    overflow: auto;
  }

  .p-datatable-thead {
    position: sticky;
    top: 0px;
    z-index: 99;

    th {
      background-color: #ffffff !important;
      color: #000;
      &:hover {
        background-color: #00000010 !important;
      }
    }
  }
`;

export const Dropdown = styled.div`
  .p-dropdown p-component p-inputwrapper p-inputwrapper-filled {
    width: 1040px;
  }
`;

// ------- NEW MAP FORM -------

export const GroupTemplate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 10px;
  gap: 20px;
`;

export const GrupoTitle = styled.span``;

export const GrupoTimeInputBox = styled.span`
  position: relative;
  padding: 10px 0;
  label {
    position: absolute;
    bottom: 80%;
    left: 0;
    font-size: 0.8rem;
    padding: 0 5px;
  }
`;

export const GrupoTimeInput = styled.input`
  border: 1px solid #60606090;
  border-radius: 2.5px;
  padding: 2px 5px 2px 10px;
`;

export const MapFormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`;

export const TableButtonBox = styled.div`
  display: flex;
  justify-content: center;

  button svg {
    font-size: 0.9rem;
  }
`;

export const MapLinkTemplate = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const MapEditTemplate = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

interface IResponsiveProps {
  mobile?: boolean;
  tablet?: boolean;
}

export const Select = styled.select<IResponsiveProps>`
  height: 40px;
  border: solid 1px #ced4da;
  background-color: #ffffff;
  border-radius: 6px;
  color: #495057;
  font-size: 1rem;
  padding: 5px;

  ${(props) =>
    props.mobile &&
    css`
      @media (max-width: 768px) {
        width: 100px;
        font-size: 10px;
        padding: 3px;
      }
    `}

  ${(props) =>
    props.tablet &&
    css`
      @media (max-width: 992px) {
        width: 100px;
        font-size: 10px;

        padding: 3px;
      }
    `}
`;

export const Option = styled.option`
  height: 20px;
  border: solid 1px #ced4da;
  background-color: #ffffff;
  color: #495057;
  font-size: 1rem;
  margin: 10px;
`;
