import styled from 'styled-components';

import { BoxColumn, BoxTable, Header } from 'components/Containers';

export const SqlMainBox = styled(BoxColumn)`
  box-shadow: none;
  background-color: transparent;
  padding: 0px;
  width: 100%;
`;

export const SqlHeaderBox = styled(Header)`
  width: 100%;
`;

export const SqlBoxButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 0px;
  gap: 15px;
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  z-index: 80;
  height: 100px;
  background-color: #f5f8fa;

  @media (max-width: 768px) {
    padding-right: 20px;
  }
`;

export const Container = styled.div`
  @media (max-width: 768px) {
    padding: 10px 0px 40px;
  }
`;

export const ContainerSQL = styled(BoxColumn)`
  width: 100%;
  justify-content: stretch;

  > div {
    width: 100% !important;
  }

  textarea {
    width: 100% !important;
  }
`;

export const ContainerResultSQL = styled(BoxTable)`
  width: 100%;
`;

export const BoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const BoxButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: end;
  justify-content: end;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  padding-bottom: 15px;
`;

export const InputDropdownBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: first baseline;
  text-align: left;
  gap: 6px;

  label {
    font-weight: bolder;
    font-size: 1.1rem;
    color: #000;
  }

  .p-calendar .p-inputtext,
  .p-dropdown {
    max-height: 3.5rem;
    border-radius: 7.5px;
  }

  .p-placeholder {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
  }

  .p-inputtext,
  .p-inputwrapper {
    background-color: var(--gray-300);
    border: none;
  }

  .p-multiselect {
    max-width: 200px !important;
  }
`;
