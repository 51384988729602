import styled from 'styled-components';

interface IMapProps {
  showMenu?: boolean;
  codColorSetor?: string;
  isMobile?: boolean;
}

export const Content = styled.section<IMapProps>`
  background-color: #fafafa;
  height: 100%;
  width: 100%;
  opacity: ${(props) => (props.showMenu ? 'none' : '0')};
  transition: ${(props) => (!props.showMenu ? 'opacity 0.4s ease' : '')};
  padding: 5px;
  overflow: scroll;
  scrollbar-width: none;
`;

export const MapSideBarMainBox = styled.div<IMapProps>`
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 10;
  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 100dvh;
  transition: all 0.4s ease;
  border-radius: none;
  width: ${(props) => (props.showMenu ? '25vw' : '2vw')};

  @media (max-width: 1080px) {
    z-index: 99;
    width: 100%;
    height: ${(props) => (props.showMenu ? '90dvh' : '45px')};
    top: ${(props) => (props.showMenu && props.isMobile ? '65%' : props.showMenu ? '95px' : 'calc(100% - 45px)')};
    padding: 5px 0px 20px 0px;
    border-radius: none;
    margin: 0;
    right: 0;
  }
`;

export const SideBarHeaderClosed = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  writing-mode: vertical-rl;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;

  @media (max-width: 992px) {
    writing-mode: horizontal-tb;
    top: 26%;
    z-index: 99;
    padding: 5px;
  }
`;

export const SideBarHeader = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
  position: static;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 5px;
  overflow: hidden;
`;

export const Input = styled.input`
  width: 100%;
  background-color: #fafafa;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;

  flex-direction: column;
  transition: all 0.4s ease;
  border: none;
  outline: none;
  height: 2rem;
`;

export const SetorTableIconTd = styled.div`
  font-size: 1.75rem;
  cursor: pointer;
  padding: 1 !important;
  display: flex;
  gap: 5px;
`;

export const SetorCodColorBackground = styled.div<IMapProps>`
  color: var(--white);
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  font-weight: bolder;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  justify-content: left;
  height: 65px;
  opacity: 0.8;
  margin-left: -7px;
  background-color: ${(props) => (props.codColorSetor ? '#' + props.codColorSetor : 'var(--dark-900)')};

  @media (max-width: 992px) {
    height: 1px;
    width: 100%;
    padding: 3px;
    border-radius: 0px 0px 10px 10px;
  }
`;

export const VagoTag = styled.span`
  background-color: var(--orange-400);
  border-radius: 15px;
  border: 1px solid var(--orange-400);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 30%;
  cursor: pointer;
  color: var(--white);
`;

export const SetorCodBackground = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  color: var(--dark-900);
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  font-weight: bolder;
  font-size: 0.9rem;
  cursor: pointer;
  opacity: 0.8;
  position: relative;
  white-space: nowrap;
`;

export const MapSideBarOpenBtn = styled.div<IMapProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  width: 30px;
  height: 50px;
  background-color: var(--red);
  z-index: 999999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;

  svg {
    color: var(--white);
    transition: all 1.2s ease;
    transform: ${(props) => (!props.showMenu ? 'rotate(180deg)' : 'none')};
  }

  @media (max-width: 992px) {
    top: -30px;
    right: 50%;
    width: 50px;
    height: 30px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transform: translateX(45%);
    margin: 0 auto;

    svg {
      color: var(--white);
      transition: all 1.2s ease;
      transform: ${(props) => (!props.showMenu ? 'rotate(-90deg)' : 'rotate(90deg)')};
    }
  }
`;

export const SideBarHeaderBox = styled.div`
  position: relative;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

export const SideBarHeaderFilterBox = styled.div<IMapProps>`
  width: 100%;
  position: relative;
  background-color: #fafafa;
  min-width: 50px;
  border-radius: 2.5px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  box-shadow: ${(props) => (props.isMobile ? 'none' : 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px')};
  min-height: 2.5rem;
  padding: 5px;
  cursor: pointer;

  .filterTitle {
    width: ${(props) => (props.isMobile ? '100%' : '')};
    border-bottom: ${(props) => (props.isMobile ? '.5px solid #60606060' : '')};
  }

  .filterStatus {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .filterStatus svg {
    color: #095dd1;
    font-size: 1.5rem;
  }

  .filterOptions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 0px;
    opacity: 0;
  }

  .filterOptionsOpen {
    height: auto;
    overflow: visible;
    opacity: 1;
    transition: opacity 0.7s ease;
  }

  .filterOption {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 2.5px;
  }

  .filterOptionButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .filterOptionButton button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 2.5px;
    background-color: #2375ac;
    padding: 5.5px 10px;
    outline: none;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 1.2rem;
  }

  .filterOption select {
    background-color: #fafafa;
    color: #606060;
    outline: none;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    width: 100%;
    padding: 10px 5px;
    font-size: 1.15rem;
  }

  @media (max-width: 1080px) {
    .p-dropdown {
      padding: ${(props) => (props.isMobile ? '5px' : '')};
    }

    .p-dropdown * {
      font-size: ${(props) => (props.isMobile ? '1rem' : '')};
    }

    .filterOptionButton button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 2.5px;
      background-color: #2375ac;
      padding: 7px 12px;
      outline: none;
      border: none;
      border-radius: 5px;
      color: #fff;
      font-size: 1.5rem;
    }
  }
`;

export const SideBarHeaderInputBox = styled.div`
  position: relative;
  background-color: #fafafa;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 3.25rem;
`;

export const SideBarHeaderInput = styled.input`
  position: relative;
  width: 85%;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  border: none;
  outline: none;
  padding-left: 10px;
`;
export const SideBarHeaderInputButton = styled.div`
  background-color: rgb(61 65 71);
  color: #fff;
  width: 15%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
`;

export const SideBarStatusCard = styled.div`
  background-color: #fafafa;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: #fff;
  width: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  padding: 10px 10px;
  color: rgb(81 80 81);
  border-left: 5px solid #d4c8eb;
  margin: 10px auto;
  pointer-events: none !important;

  svg {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 5px;
    font-size: 2.5rem;
    border-radius: 50%;
  }
`;

export const SideBarStatusCardName = styled.span`
  width: 60%;
  text-align: left;
  color: rgb(137 137 137);
`;
export const SideBarStatusCardCount = styled.div`
  width: 20%;
  font-size: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 2px 5px;
  border-radius: 5px;
  text-align: center;
`;

export const SideBarStatusTagsBox = styled.div`
  width: 100%;
  font-size: 0.8rem;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// TIPAGEM DO COMPONENTE DE CARD
interface TagType {
  type?: 'success' | 'warning' | 'danger';
}

export const SideBarStatusTag = styled.div<TagType>`
  font-size: 0.9rem;
  border-radius: 5px;
  width: 30%;
  text-align: center;
  font-weight: 500;
  color: #fff;
  background-color: ${(props) =>
    props.type === 'success' ? 'rgb(35 159 88 / 84%)' : props.type === 'warning' ? 'rgb(255 120 0)' : 'rgb(231 76 60)'};
`;
