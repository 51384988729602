import * as S from './styles';
import { useEffect, useState } from 'react';
import { TableGroupClients } from './TableGroupClients';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import InputText from 'components/Inputs/InputText/text.input';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import UseSelectUsuario from 'hooks/UseSelectUsuario/selectUsuario';
import UseSelectFilial from 'hooks/UseSelectFilial/selectFilial';
import UseSelectStatus from 'hooks/UseSelectStatus/selectStatus';
import icons from 'components/Icons/icons.index';
import UseSelectCliente from 'hooks/UseSelectCliente/selectCliente';
import Button from 'components/Button/button.index';
import NovoGroupModal from './NovoGrupoModal/novoGrupoModal.index';

export const ListGroupClients = () => {
  try {
    useEffect(() => {
      trackGPageView('/grupo-clientes');
      alterPageTitle('Grupo Cliente');
    }, []);

    const { SelectStatus, selectedStatus } = UseSelectStatus();
    const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();
    const { SelectUsuario, selectedUsuario } = UseSelectUsuario(selectedEmpresa.codEmpresa);
    const { SelectFilial, selectedFiliais } = UseSelectFilial(selectedEmpresa.codEmpresa, selectedUsuario.codUsuario);
    const { SelectCliente, selectedCliente } = UseSelectCliente(selectedEmpresa.codEmpresa, selectedUsuario.codUsuario);

    const [searchText, setSearchText] = useState('');

    const [showNewGroupModal, setShowNewGroupModal] = useState(false);

    return (
      <S.GrupoClientesMainBox>
        <S.GrupoClientesHeaderBox>
          <SelectEmpresa label="Empresa *" />
          <SelectUsuario label="Usuário *" />
          <SelectFilial label="Filial *" multiselect />
          <SelectCliente label="Cliente" />

          <InputText
            value={searchText}
            onChange={(e) => {
              setSearchText(e);
            }}
            placeholder="Filtrar por Nome do Grupo"
            width="200px"
            label="Pesquisar"
          />

          <SelectStatus label="Ativo/Inativo" width="120px" value={selectedStatus} />

          <Button
            icon={<icons.Plus />}
            text="Adicionar"
            tooltip="Novo Grupo"
            color="green"
            onClick={() => {
              setShowNewGroupModal(true);
            }}
          />
        </S.GrupoClientesHeaderBox>

        <TableGroupClients
          codEmpresa={selectedEmpresa.codEmpresa.toString()}
          codFiliais={selectedFiliais}
          active={selectedStatus}
          searchText={searchText}
          selectedCliente={selectedCliente ? selectedCliente.CODCLI : 0}
        />
        {showNewGroupModal && (
          <NovoGroupModal
            visible={showNewGroupModal}
            onHide={() => {
              setShowNewGroupModal(false);
            }}
            codUsuario={selectedUsuario.codUsuario}
          />
        )}
      </S.GrupoClientesMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'groupClients/ListGroupClients/index.tsx');
  }
};
