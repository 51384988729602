import styled from 'styled-components';

export const ModalMainBox = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ModalSubBox = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  padding: 2% 0;
  gap: 15px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ModalSubMapBox = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  padding: 2% 0;
  gap: 36px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const InputRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;
