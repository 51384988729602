import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import * as S from './paramGeralList.styles';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import { useNavigate } from 'react-router-dom';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { useEffect, useState } from 'react';
import { alterPageTitle } from 'utils/alterPageTitle';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnEditorOptions, ColumnEventParams } from 'primereact/column';
import { useParamsGeral } from 'modules/parametrosGeral/paramGeral.context';
import InputText from 'components/Inputs/InputText/text.input';
import { ParamsGeralItemProps } from 'modules/parametrosGeral/paramGeral.types';
import CloneParamGeral from 'modules/parametrosGeral/components/CloneParamGeral/cloneParamGeral.index';
import MultiSelect from 'components/Multiselect/multiselect';

export default function ParamGeralList() {
  const {
    paramsGeralItem,
    setParamsGeralItem,
    handleGetItemParamsGeralList,
    codParItemList,
    selectedEmpresas,
    setSelectedEmpresas,
  } = useParamsGeral();
  try {
    const navigate = useNavigate();
    const { SelectEmpresa, selectedEmpresa, empresas, setSelectedEmpresa } = UseSelectEmpresa();

    const [toEditParamItemList, setToEditParamItemList] = useState<ParamsGeralItemProps[]>([]);
    const [toCloneParamItem, setToCloneParamItem] = useState<ParamsGeralItemProps>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [searchText, setSearchText] = useState('');

    const [selectedCodParList, setSlectedCodParList] = useState([]);

    useEffect(() => {
      handleGetItemParamsGeralList();
    }, [selectedEmpresa]);

    useEffect(() => {
      trackGPageView(`/parametros/geral/lista`);
      alterPageTitle('Parâmetros Gerais');
      handleGetItemParamsGeralList();
    }, []);

    const handleFilterParamItemList = () => {
      const searchByText = searchText
        ? paramsGeralItem?.unfiltered?.filter(
            (item) =>
              item.parametroDescricao.toString().toUpperCase().includes(searchText.toUpperCase()) ||
              item.valor.toString().toUpperCase().includes(searchText.toUpperCase()),
          )
        : paramsGeralItem?.unfiltered;

      const searchByCodPar =
        selectedCodParList && selectedCodParList.length > 0
          ? searchByText?.filter((item) => selectedCodParList.some((codpar) => +codpar.codpar == +item.codpar))
          : searchByText;

      setParamsGeralItem((prev) => ({
        ...prev,
        filtered: searchByCodPar,
      }));
    };

    useEffect(() => {
      handleFilterParamItemList();
    }, [paramsGeralItem.unfiltered, searchText, selectedCodParList, selectedEmpresas]);

    useEffect(() => {
      setSelectedEmpresas(empresas);
    }, [empresas]);

    const cloneParamItemTemplate = (item: ParamsGeralItemProps) => {
      return (
        <Button
          icon={<icons.Copy />}
          tooltip="Clonar item"
          rounded
          bgOnlyOnHover
          onClick={() => {
            setToCloneParamItem(item);
          }}
        />
      );
    };

    const stringEditor = (options: ColumnEditorOptions) => {
      return (
        <S.ParamGeralConfigInputText
          width="100%"
          value={options.value ?? ''}
          onChange={(e) => options.editorCallback?.(e)}
        />
      );
    };

    const handleEditComplete = (options: ColumnEventParams) => {
      const { newValue, rowData, field } = options;
      rowData[field] = newValue;

      if (toEditParamItemList?.some((item) => +item.codpar == +rowData.codpar)) {
        const toEditParamOnTheEditArray = toEditParamItemList?.filter((item) => +item.codpar == +rowData.codpar);

        if (toEditParamOnTheEditArray[0] && toEditParamOnTheEditArray[0][field] == newValue) {
          setToEditParamItemList((prev: ParamsGeralItemProps[]) => {
            return prev?.filter((item) => +item.codpar != +rowData.codpar);
          });
        } else {
          setToEditParamItemList((prev: ParamsGeralItemProps[]) => {
            return prev?.map((item) => {
              if (+item.codpar == +rowData.codpar) {
                return rowData;
              } else {
                return item;
              }
            });
          });
        }
      } else {
        setToEditParamItemList((prev: ParamsGeralItemProps[]) => {
          return [...prev, ...[rowData]];
        });
      }
    };

    const codEmpresaTemplate = (item: ParamsGeralItemProps) => {
      return <div>{`[${item.codEmpresa}] ${item.empresa}`}</div>;
    };

    return (
      <S.ParamGeralListMainBox>
        <S.ParamGeralListHeaderBox>
          <S.ContentBox>
            <MultiSelect
              value={selectedEmpresas}
              onChange={(e) => {
                setSelectedEmpresas(e);
              }}
              options={empresas}
              optionLabel=""
              filter
              itemTemplate={(emp) => `[${emp.codEmpresa}] ${emp.fantasia}`}
              placeholder="Empresas"
              emptyFilterMessage="Nenhum dado encontrado"
              label={'Empresas'}
              showClear
              width="200px"
            />

            <MultiSelect
              value={selectedCodParList}
              onChange={(e) => {
                setSlectedCodParList(e);
              }}
              options={codParItemList}
              optionLabel=""
              filter
              itemTemplate={(codpar) => `${codpar.codpar}`}
              placeholder="CODPAR"
              emptyFilterMessage="Nenhum dado encontrado"
              label={'Cód. Param'}
              showClear
              width="100px"
            />

            <InputText
              label="Buscar"
              placeholder="Buscar por DESCRIÇÃO ou VALOR"
              onChange={(e) => {
                setSearchText(e);
              }}
              width="220px"
            />

            <Button
              text="Salvar"
              color="green"
              icon={<icons.Check />}
              disabled={toEditParamItemList.length == 0}
              loading={isLoading}
              tooltip={
                toEditParamItemList.length == 0
                  ? 'Nenhum registro alterado'
                  : `Salvar alterações em ${toEditParamItemList.length} registros`
              }
            />
          </S.ContentBox>

          <S.ContentBox>
            <Button
              icon={<icons.SettingsColored />}
              color="black"
              tooltip="Configurar Parâmetros"
              rounded
              onClick={() => {
                navigate(`/parametros/geral/configurar`);
              }}
            />
          </S.ContentBox>
        </S.ParamGeralListHeaderBox>

        <S.ParamGeralListTableBox>
          <DataTable
            value={paramsGeralItem.filtered ?? []}
            paginator={(paramsGeralItem.filtered ?? []).length > 7}
            rows={100}
            rowHover
            size="small"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          >
            <Column field="codEmpresa" header="Empresa" sortable body={codEmpresaTemplate} />
            <Column field="codpar" header="Cód. Param" sortable />
            <Column field="parametroDescricao" header="Descrição" sortable />
            <Column field="codfilial" header="Cód. Filial" sortable />
            <Column
              field="valor"
              header="Valor"
              sortable
              editor={stringEditor}
              onCellEditComplete={handleEditComplete}
            />
            <Column field="" header="Clonar" body={cloneParamItemTemplate} />
          </DataTable>
        </S.ParamGeralListTableBox>
        <CloneParamGeral toCloneParamItem={toCloneParamItem} setToCloneParamItem={setToCloneParamItem} />
      </S.ParamGeralListMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'parametrosGeral/paramGeralList/paramGeralList.index.tsx');
  }
}
