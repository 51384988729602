import useMediaQuery from 'hooks/useMediaQuery';
import * as S from './styles';

import { Checkbox } from 'primereact/checkbox';
import { MapContext } from 'providers/map';
import { useContext } from 'react';
import { trackGCatchError } from 'utils/analytics';

export default function MarkersControl() {
  try {
    const { markersControler, setMarkersControler } = useContext(MapContext);

    const isWebScreen = useMediaQuery('(min-width: 1060px)');

    return (
      <S.ControlBox isWebScreen={isWebScreen}>
        <S.Option>
          <img src={`${process.env.REACT_APP_BASE_URL}/images/icon_maps/icon_cliente_agendado_router.png`} />
          <Checkbox
            onChange={(e) => {
              setMarkersControler((prev: any) => {
                return {
                  ...prev,
                  nextWeeks: !markersControler.nextWeeks,
                };
              });
            }}
            checked={markersControler.nextWeeks}
          />
          <label htmlFor="detailed">Agenda Prox Semanas</label>
        </S.Option>
        <S.Option>
          <img src={`${process.env.REACT_APP_BASE_URL}/images/icon_maps/icon_cliente_nao_router.png`} />
          <Checkbox
            onChange={() => {
              setMarkersControler((prev: any) => {
                return {
                  ...prev,
                  notInRoute: !markersControler.notInRoute,
                };
              });
            }}
            checked={markersControler.notInRoute}
          />
          <label htmlFor="checkinout">Não Roteirizado</label>
        </S.Option>
      </S.ControlBox>
    );
  } catch (err) {
    trackGCatchError(err, 'Roteirizador/markersControl.tsx');
  }
}
