import { Container, Header } from 'components/Containers';
import styled from 'styled-components';

export const ReportMainBox = styled(Container)`
  gap: 10px;
`;

export const ReportFormBox = styled(Header)``;

export const ReportViewModeBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 10px;
`;

export const ReportViewOption = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const ReportHeaderButtonsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
