import { useEffect } from 'react';

import logoVendaMais from 'assets/images/logovendamais.png';
import { useTitlePageStore } from 'storesZustand/titlePageStore';

import styles from './DashboardPage.module.scss';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { alterPageTitle } from 'utils/alterPageTitle';

const DashboardPage = () => {
  try {
    const { titlePage, setTitlePage } = useTitlePageStore();

    useEffect(() => {
      trackGPageView('/dashboard');
      alterPageTitle('Dashboard');
    }, []);

    return (
      <div className={styles.mainContainer}>
        <img src={logoVendaMais} alt="logo vendamais" />
      </div>
    );
  } catch (err) {
    trackGCatchError(err, 'main/pages/Dashboard/DashboardPage.tsx');
  }
};

export default DashboardPage;
