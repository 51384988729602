import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Aside from '../Aside/Aside';
import { PageDataProvider } from '_metronic/layout/core';
import { MenuComponent } from '_metronic/assets/ts/components';

import HeaderWrapper from '../Header';
import VmContainer from 'modules/main/components/Container/index';
import VmContent from 'modules/main/components/Content/index';
import { LoaderEffectProvider } from 'providers/loaderEffect';
import { PageTitleProvider } from 'providers/pageTitle';
import LoaderEffect from 'components/LoaderEffect';

const MainLayout = () => {
  const [useVmHeader, setUseVmHeader] = useState<boolean>(true);

  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
    if (
      location.pathname == '/pedido' ||
      location.pathname == '/pedido/novo' ||
      location.pathname.includes('/mapa/regioes') ||
      location.pathname.includes('/regioes') ||
      location.pathname.includes('/controle-visitas') ||
      location.pathname.includes('/analise-visitas')
    ) {
      setUseVmHeader(false);
    } else if (!useVmHeader) {
      setUseVmHeader(true);
    }
  }, [location.key]);

  return (
    <LoaderEffectProvider>
      <PageTitleProvider>
        <PageDataProvider>
          <VmContainer>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
              style={{
                paddingTop: useVmHeader ? '65px' : '0px',
              }}
            >
              <Aside />
              {useVmHeader && <HeaderWrapper />}

              <VmContent>
                <Outlet />
              </VmContent>
            </div>
            <LoaderEffect />
          </VmContainer>
        </PageDataProvider>
      </PageTitleProvider>
    </LoaderEffectProvider>
  );
};

export default MainLayout;
