import useMediaQuery from 'hooks/useMediaQuery';
import { HeaderScreenwriter } from 'modules/mapa/pages/Roteirizador/components/HeaderScreenwriter';
import { trackGCatchError } from 'utils/analytics';

export const SwitchComponentHeader = ({ handledPathName }: { handledPathName: string }) => {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');
  try {
    if (handledPathName == 'roteirizador' && isWebScreen) {
      return <HeaderScreenwriter />;
    } else {
      return <></>;
    }
  } catch (err) {
    trackGCatchError(err, 'main/components/Header/components/SwitchComponentHeader/index.tsx');
  }
};
