/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useEffect, useMemo, useRef, useState } from 'react';

import * as S from './mapMenu.styles';
import useMediaQuery from 'hooks/useMediaQuery';
import { Checkbox } from 'primereact/checkbox';
import { Container } from 'reactivus';
import { useMap } from '../../regioes.context';
import { SetoresProps } from 'client/interfaces';
import MapEditSetor from '../mapEditSetor/mapEditSetor.index';
import SectorCard from './components/sectorCard.index';
import SectorCardLoading from './components/sectorCardLoading.index';
import NoDataFound from 'components/NoDataFound/noDataFound.index';
import { trackGCatchError } from 'utils/analytics';
import InputText from 'components/Inputs/InputText/text.input';
import Dropdown from 'components/Dropdown/dropdown';

export default function MapMenu() {
  try {
    const {
      setores,
      setSelectedSetores,
      selectedSetores,
      setoresFilter,
      setSetoresFilter,
      setoresFilterOptions,
      selectedCli,
      showMapMenu,
      showSectorsNoDataFound,
    } = useMap();

    const isWebScreen = useMediaQuery('(min-width: 1024px)');

    const setorSearchRef: any = useRef(null);

    const [showMenu, setShowMenu] = useState(true);

    const [setoresList, setSetoresList] = useState([]);

    const [setorToEdit, setSetorToEdit] = useState(null);

    const [showMapEditSetor, setShowMapEditSetor] = useState(false);

    useEffect(() => {
      if (selectedCli) {
        setShowMenu(false);
        handleHideMainMenu();
      }
    }, [selectedCli]);

    useEffect(() => {
      if (!showMapMenu) {
        setShowMenu(false);
        handleHideMainMenu();
      }
    }, [showMapMenu]);

    const handleHideMainMenu = () => {
      const ktBody = document.getElementById('kt_body');
      if (ktBody) {
        ktBody.setAttribute('data-kt-aside-minimize', 'on');
      }
    };

    useEffect(() => {
      setSetoresList(setores.unfiltered);
      setTimeout(() => {
        handleSetoresSearch();
      }, 1000);
    }, [setores]);

    const handleSetoresSearch = () => {
      if (setorSearchRef.current) {
        let filter =
          setores && setores?.unfiltered?.length
            ? setores?.unfiltered?.filter(
                (set: SetoresProps) =>
                  set.NOMESETOR.toUpperCase().includes(setorSearchRef.current.value.toUpperCase()) ||
                  (set.NOMERCA && set.NOMERCA.includes(setorSearchRef.current.value.toUpperCase())) ||
                  (set.COD_RCA && set.COD_RCA.toString().includes(setorSearchRef.current.value)) ||
                  (set.CODSETOR && set.CODSETOR.toString().includes(setorSearchRef.current.value)),
              )
            : [];

        if (setoresFilter && setorSearchRef.current && setorSearchRef.current.value == '') {
          if (filter && setoresFilter.code === 'A') {
            filter = filter?.filter((map) => map && map.ATIVO == 'S');
          } else if (filter && setoresFilter.code === 'I') {
            filter = filter?.filter((map) => map && map.ATIVO == 'N');
          } else {
            filter = filter;
          }
        }
        setSetoresList(filter);
      } else {
        setSetoresList(setores.unfiltered);
      }
    };

    useEffect(() => {
      handleSetoresSearch();
    }, [setoresFilter, setores]);

    const SelectAll = () => {
      return (
        <Container alignItems="center" gap="10px">
          <Checkbox
            inputId={'selectAll'}
            checked={selectedSetores && selectedSetores.length == setores.unfiltered.length}
            onChange={() => {
              if (selectedSetores && selectedSetores.length == setores.unfiltered.length) {
                setSelectedSetores(null);
              } else if (setSelectedSetores) {
                setSelectedSetores(setores.unfiltered);
              }
            }}
          />
          <span>Selecionar todos</span>
        </Container>
      );
    };

    const renderedSectors = useMemo(() => {
      let toRenderCards: any = [1, 2, 3, 4, 5, 6].map((_, index) => <SectorCardLoading key={index} />);
      if (
        !(setoresList && setoresList.length > 0) &&
        setores.unfiltered.length > 0 &&
        setorSearchRef &&
        setorSearchRef.current &&
        setorSearchRef.current.value != ''
      ) {
        return <NoDataFound />;
      } else if (!(setoresList && setoresList.length > 0)) {
        return showSectorsNoDataFound ? <NoDataFound /> : toRenderCards;
      } else {
        toRenderCards =
          setoresList && setoresList.length > 0 ? (
            setoresList?.map((sector: SetoresProps) => (
              <SectorCard
                sector={sector}
                key={sector.CODSETOR}
                setShowMapEditSetor={setShowMapEditSetor}
                setSetorToEdit={setSetorToEdit}
                setShowMenu={setShowMenu}
              />
            ))
          ) : showSectorsNoDataFound ? (
            <NoDataFound />
          ) : (
            [1, 2, 3, 4, 5, 6].map((_, index) => <SectorCardLoading key={index} />)
          );
      }

      return toRenderCards;
    }, [selectedSetores, setoresList, showSectorsNoDataFound]);

    return (
      <S.MapSideBarMainBox showMenu={showMenu}>
        {!showMenu && <S.SideBarHeaderClosed>SETORIZAÇÃO DE CLIENTES</S.SideBarHeaderClosed>}

        <S.MapSideBarOpenBtn showMenu={showMenu} onClick={(e) => setShowMenu(!showMenu)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http:// www.w3.org/2000/svg"
            className="mh-50px"
            color="#7bcda0"
          >
            <path
              opacity="0.5"
              d="M9.63433 11.4343L5.45001 7.25C5.0358 6.83579 5.0358 6.16421 5.45001 5.75C5.86423 5.33579 6.5358 5.33579 
            6.95001 5.75L12.4929 11.2929C12.8834 11.6834 12.8834 12.3166 12.4929 12.7071L6.95001 18.25C6.5358 18.6642 5.86423 18.6642 
            5.45001 18.25C5.0358 17.8358 5.0358 17.1642 5.45001 16.75L9.63433 12.5657C9.94675 12.2533 9.94675 11.7467 9.63433 11.4343Z"
              fill="currentColor"
            />
            <path
              d="M15.6343 11.4343L11.45 7.25C11.0358 6.83579 11.0358 6.16421 11.45 5.75C11.8642 5.33579 
             12.5358 5.33579 12.95 5.75L18.4929 11.2929C18.8834 11.6834 18.8834 12.3166 18.4929 12.7071L12.95 18.25C12.5358 18.6642 
             11.8642 18.6642 11.45 18.25C11.0358 17.8358 11.0358 17.1642 11.45 16.75L15.6343 12.5657C15.9467 12.2533 15.9467 11.7467 
             15.6343 11.4343Z"
              fill="currentColor"
            />
          </svg>
        </S.MapSideBarOpenBtn>

        {showMenu && (
          <S.SideBarHeader>
            <p>Setorização de Clientes</p>
            <S.HeaderFilterBox>
              <InputText
                id="name"
                placeholder="Pesquisar Setor / Vendedor"
                ref={setorSearchRef}
                onChange={(e) => {
                  e = e.toUpperCase();
                  handleSetoresSearch();
                }}
                width={'clamp(60%, 80%, 90%)'}
              />

              <Dropdown
                value={setoresFilter}
                onChange={(e) => setSetoresFilter(e.value)}
                options={setoresFilterOptions}
                optionLabel="name"
                placeholder="Todos"
                className="f-lg-4"
                style={{ width: !isWebScreen ? '100px' : '120px' }}
              />
            </S.HeaderFilterBox>
            <SelectAll />
          </S.SideBarHeader>
        )}

        <S.Content showMenu={showMenu}>{renderedSectors}</S.Content>

        <MapEditSetor
          showMapEditSetor={showMapEditSetor}
          setShowMapEditSetor={setShowMapEditSetor}
          setor={setorToEdit}
        />
      </S.MapSideBarMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'mapMenu.index.tsx');
  }
}
