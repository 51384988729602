import { useUser } from '../../client/hooks/usuarios';
import { useEffect, useState } from 'react';
import { getClienteByRca } from 'client/api';
import { getClients } from 'client/api/groupClients';
import Dropdown from 'components/Dropdown/dropdown';

type SelectedClienteProps = {
  CODCLI: number;
  RAZAOSOCIAL: string;
  FANTASIA: string | null;
  CNPJCPF: string;
  END_LOGRADOURO: string;
  END_NUMERO: string;
  END_COMPL: string | null;
  BAIRRO: string;
  CIDADE: string;
  END_UF: string;
  END_CEP: string;
  END_PTOREF: string | null;
  CONT_NOME: string | null;
  CON_FIXO: string;
  CONT_CEL: string | null;
  CONT_EMAIL: string;
  EMAILNFE: string;
  PERMITEVENDA: string;
  MOTIVONAOVENDA: string | null;
  COR: string;
  COD_COBRANCA: string;
  NIVEL_COB: number;
  COD_PRAZO_PAGTO: number;
  TABELA: number;
  TIPOFJ: string;
  PER_DESC: number;
  PER_ACRE: number;
  ACREDESCTABELA: number;
  VALORVERBA: number;
  LIMCRED: string;
  LIMITE_DISPONIVEL: string;
  BLOQUEIO: string;
  LETRAPLPAGCLI: string;
  CODCLIPRINC: number;
  CODRAMO: number;
  CODPRACA: number;
  IMPORTADO: number;
  CODUSUR: number;
  IEENT: string;
  OBSERVACAO: string;
  ALTERA_DADOS_CLI: string;
  LATITUDE: string;
  LONGITUDE: string;
  TEMPOMINIMOCLI: number;
  CODGRUPO: string;
  DESCTABELA: string;
  OBS2: string | null;
  OBS3: string;
  OBS4: string | null;
  OBS5: string | null;
  label?: string;
};

const UseSelectCliente = (codEmpresa: number, codUsuario: number, codVendedor?: number) => {
  const { data: user } = useUser();
  const [clientes, setClientes] = useState<any[]>([]);

  const handleDefaultClient = () => {
    const cli = JSON.parse(localStorage.getItem('vmCliData') || 'null');
    return (
      cli || {
        CODCLI: cli ? cli.CODCLI : 0,
      }
    );
  };

  const [selectedCliente, setSelectedCliente] = useState<SelectedClienteProps>(handleDefaultClient);

  useEffect(() => {
    localStorage.removeItem('vmCliData');
    if (user && codEmpresa && codUsuario) {
      if (codVendedor) {
        getClienteByRca(codEmpresa, codVendedor).then((clis: any[]) => {
          const formattedClis = [];
          for (const c of clis) {
            formattedClis.push({
              ...c,
              label: `[${c.CODCLI}] - ${c.RAZAOSOCIAL}`,
            });
          }
          setClientes(formattedClis);
        });
      } else {
        getClients(codEmpresa).then((clis) => {
          const formattedClis = [];
          for (const c of clis) {
            formattedClis.push({
              ...c,
              label: `[${c.codCli}] - ${c.razaosocial}`,
              CODCLI: c.codCli,
              RAZAOSOCIAL: c.razaosocial,
              CIDADE: c.endCidade,
            });
          }
          setClientes(formattedClis);
        });
      }
    }
  }, [user, codEmpresa, codUsuario, codVendedor]);

  const handleCliChange = (emp: SelectedClienteProps) => {
    setSelectedCliente(emp ?? null);
    localStorage.setItem('vmCliData', JSON.stringify(emp ? emp : null));
  };

  const SelectCliente = ({
    onChange,
    width,
    label = 'Cliente',
  }: {
    onChange?: (codUsuario: number) => void;
    width?: string;
    label?: string;
  }) => {
    const handleChange = (emp: SelectedClienteProps) => {
      handleCliChange(emp);
      if (onChange) {
        onChange(emp.CODCLI);
      }
    };

    return (
      <Dropdown
        value={selectedCliente}
        onChange={(e) => handleChange(e.value)}
        options={clientes}
        optionLabel="label"
        filter
        placeholder="Selecione um Cliente"
        emptyMessage="Nenhum dado encontrado"
        emptyFilterMessage="Nenhum dado encontrado"
        label={label}
        width={width}
        showClear
        virtualScrollerOptions={{ itemSize: 50 }}
      />
    );
  };

  return {
    SelectCliente,
    selectedCliente,
    setSelectedCliente,
  };
};

export default UseSelectCliente;
