import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import { useUser } from 'client/hooks';
import { SwitchComponentHeader } from './components/SwitchComponentHeader';
import * as S from './styles';
import Topbar from './Topbar';
import { UseExpireTokenAndStopApi } from 'hooks/utils/UseExpireTokenAndStopApi';
import useMediaQuery from 'hooks/useMediaQuery';
import MarkersControl from 'modules/mapa/pages/Roteirizador/components/markersControl/markersControl';
import { logout } from 'modules/auth/reducer/auth';
import { useAppDispatch } from 'store';
import { usePageTitle } from 'providers/pageTitle';
import PathTree from './components/PathTree/pathTree.index';
import { trackGCatchError } from 'utils/analytics';
import { LoaderEffectHeader } from 'components/LoaderEffect';

const HeaderWrapper = () => {
  try {
    const { data: user, error: errorUseUser, status: statusUseUser } = useUser();
    const isWebScreen = useMediaQuery('(min-width: 1060px)');
    const dispatch = useAppDispatch();

    const { pageTitle, pageTitlesList, setPageTitle } = usePageTitle();

    useEffect(() => {
      try {
        const tokenString = localStorage.getItem('vmais_token');
        const storageToken = tokenString ? JSON.parse(tokenString) : null;

        if (!user && !storageToken && !window.location.pathname.includes('login')) {
          dispatch(logout());
        }
      } catch (err) {
        console.log('Err validating user:', err);
      }
    }, [user]);

    UseExpireTokenAndStopApi({ errorUseUser, statusUseUser });

    const { pathname } = useLocation();

    const [handledPathName, setHandledPathName] = useState('');

    useEffect(() => {
      const filteredPathname = pathname
        .split('/')
        .slice(1, 4)
        .filter((segment) => !/\d/.test(segment))
        .join('/');
      setHandledPathName(filteredPathname);

      setPageTitle((prev) => pageTitlesList[filteredPathname]);
    }, [pathname]);

    return (
      <div id="kt_header" className="header align-items-stretch">
        <S.BoxHeader>
          <S.Header>
            <div className="container-fluid d-flex align-items-stretch justify-content-between">
              <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                <S.Box mobile tablet>
                  <PathTree pageTitle={pageTitle} />
                  {handledPathName == 'roteirizador' && <SwitchComponentHeader handledPathName={handledPathName} />}
                  {handledPathName == 'roteirizador' && isWebScreen && <MarkersControl />}
                </S.Box>

                {isWebScreen && (
                  <div className="d-flex align-items-stretch flex-shrink-0">
                    <S.BoxInfo>
                      {user ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingRight: '10px',
                          }}
                        >
                          <p
                            style={{
                              fontSize: '13px',
                            }}
                          >
                            {user.nome.split(' ')[0] + '...'} [{user.codUsuario}]
                          </p>
                          <p>
                            {user.codEmpresa} - {user.nomeEmpresa}
                          </p>
                        </div>
                      ) : (
                        <span>
                          <PropagateLoader size="4px" color="#228F6B" />
                        </span>
                      )}
                      <Topbar />
                    </S.BoxInfo>
                  </div>
                )}
              </div>
            </div>
          </S.Header>
        </S.BoxHeader>
        <LoaderEffectHeader />
      </div>
    );
  } catch (err) {
    trackGCatchError(err, 'main/components/Header/HeaderWrapper.tsx');
  }
};

export default HeaderWrapper;
