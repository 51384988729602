import * as S from '../styles';
import { UserData } from 'client/interfaces';
import { useManagerAccessData } from 'hooks/useAccessData/useManagerAccessData';
import { Controller } from 'react-hook-form';
import { MultiSelect } from 'primereact/multiselect';
import { OrderList } from 'primereact/orderlist';
import { IGetAccessData } from 'client/interfaces/acessoDados.interface';
import { trackGCatchError } from 'utils/analytics';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';

interface IContainerManager {
  selectedUsuario: UserData;
  user: UserData;
  itemListTemplate: ({ data, cod }: { data: IGetAccessData; cod: string }) => JSX.Element;
}

const ContainerManager = ({ selectedUsuario, itemListTemplate, user }: IContainerManager) => {
  try {
    const { values, accessDataManager, optionsManagers, handleSubmit, control, onSubmitManager, setValue } =
      useManagerAccessData({
        codEmpresa: selectedUsuario?.codEmpresa,
        codUsuario: selectedUsuario?.codUsuario,
        codfuncLib: user?.codUsuario,
      });

    const onShow = () => {
      let selectAllCheckbox = document.querySelector('.p-multiselect-header > .p-checkbox');
      selectAllCheckbox.after(' TODOS GERENTES');
    };

    return (
      <S.Box>
        <form onSubmit={handleSubmit(onSubmitManager)}>
          <p>Gerente Geral (GG)</p>
          <Controller
            name="manager"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => (
              <MultiSelect
                disabled={!optionsManagers?.length}
                style={{ width: '100%', marginBottom: '10px' }}
                id="multiselect"
                placeholder="Selecione um Gerente"
                value={field.value}
                options={optionsManagers ? optionsManagers.sort((a, b) => a.label.localeCompare(b.label)) : []}
                onChange={(e) => setValue('manager', e.value)}
                onShow={onShow}
                filter
                filterBy="label"
              />
            )}
          />

          <Button
            text="Salvar Gerente"
            color="green"
            icon={<icons.Check />}
            type="submit"
            disabled={!values || !values?.manager?.length}
          />
        </form>

        <S.ListPermissions>
          <OrderList
            value={accessDataManager}
            header="Acessos a Gerentes"
            filter
            dataKey="pk"
            filterBy="nome"
            itemTemplate={(rowData: IGetAccessData) => itemListTemplate({ data: rowData, cod: 'codGerente' })}
          />
        </S.ListPermissions>
      </S.Box>
    );
  } catch (err) {
    trackGCatchError(err, 'acessoUsuario/AccessData/ContainerManager/index.tsx');
  }
};

export default ContainerManager;
