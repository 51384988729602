'use client';

import { getClientesWithoutSector, getClientesWithSector, getSectorList } from 'client/api';
import { createContext, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  ClientesObjectProps,
  ClientesProps,
  ColorsListProps,
  LocalCliToUpdateCoordinateProps,
  NoSectorControlProps,
  ParamsProps,
  ScreenModeProps,
  SetoresFilterObjectProps,
  SetoresObjectProps,
  SetoresProps,
} from './regioes.types';
import { dialog } from 'reactivus';
import { toast } from 'react-toastify';
import { removeCliSetorVinculo } from 'client/api/areaMapa';
import L from 'leaflet';

export interface MapContextProps {
  params: ParamsProps;
  setParams: React.Dispatch<SetStateAction<ParamsProps>>;
  clientes: ClientesObjectProps;
  setClientes: React.Dispatch<SetStateAction<ClientesObjectProps>>;
  cliSearchText: string;
  setCliSearchText: React.Dispatch<SetStateAction<string>>;
  selectedCli: ClientesProps;
  setSelectedCli: React.Dispatch<SetStateAction<ClientesProps>>;
  screenMode: ScreenModeProps;
  setScreenMode: React.Dispatch<SetStateAction<ScreenModeProps>>;
  handleCliSearchText: () => void;
  isDrawing: boolean;
  setIsDrawing: React.Dispatch<SetStateAction<boolean>>;
  selectedSetores: SetoresProps[];
  setSelectedSetores: React.Dispatch<SetStateAction<SetoresProps[]>>;
  setores: SetoresObjectProps;
  setSetores: React.Dispatch<SetStateAction<SetoresObjectProps>>;
  setoresFilter: SetoresFilterObjectProps;
  setSetoresFilter: React.Dispatch<SetStateAction<SetoresFilterObjectProps>>;
  setoresFilterOptions: SetoresFilterObjectProps[];
  noSectorControl: NoSectorControlProps;
  setNoSectorControl: React.Dispatch<SetStateAction<NoSectorControlProps>>;
  handleRemoveCliSector: () => void;
  handleGetAllData: (updateMapCenter?: boolean) => void;
  colorsList: ColorsListProps[];
  setColorsList: React.Dispatch<SetStateAction<ColorsListProps[]>>;
  cliSearchRef: React.RefObject<HTMLInputElement>;
  showMapMenu: boolean;
  setShowMapMenu: React.Dispatch<SetStateAction<boolean>>;
  showMapNoDataFound: boolean;
  setShowMapNoDataFound: React.Dispatch<SetStateAction<boolean>>;
  showSectorsNoDataFound: boolean;
  setShowSectorsNoDataFound: React.Dispatch<SetStateAction<boolean>>;
  handleGetSectorByCliCodSetor: (codSetor: number) => SetoresProps;
  allowMapCenterAndZoom: React.MutableRefObject<boolean>;
  mapInstance: React.MutableRefObject<any>;
  updateMapCenter: (client: ClientesProps, mapInstance: any, zoom?: number, ignoreTiming?: boolean) => any;
  lastCoordinateZoomed: React.MutableRefObject<[number, number]>;
  setLocalCliToUpdateCoordinate: React.Dispatch<SetStateAction<LocalCliToUpdateCoordinateProps>>;
  zoomLevel: number;
  setZoomLevel: React.Dispatch<SetStateAction<number>>;
  showMapLoading: boolean;
  setShowMapLoading: React.Dispatch<SetStateAction<boolean>>;
}

export const MapContext = createContext({} as MapContextProps);

export const MapProvider = ({ children }: any) => {
  const [params, setParams] = useState<ParamsProps>({
    codEmpresa: 2072021,
    codUser: -1,
    codMapa: -1,
  });

  const [clientes, setClientes] = useState<ClientesObjectProps>({
    filtered: [],
    unfiltered: [],
    list: [],
    noSector: [],
  });

  const [setores, setSetores] = useState<SetoresObjectProps>({
    filtered: [],
    unfiltered: [],
    list: [],
  });

  const [cliSearchText, setCliSearchText] = useState('');

  const [selectedCli, setSelectedCli] = useState(null);

  const [screenMode, setScreenMode] = useState<ScreenModeProps>('M'); // M-(map) or G-(grid)

  const [setoresFilter, setSetoresFilter] = useState<SetoresFilterObjectProps>({
    code: 'A',
    name: 'Ativos',
  }); // A-(ativos), I-(inativos) or T-(todos)

  const setoresFilterOptions: SetoresFilterObjectProps[] = [
    {
      code: 'A',
      name: 'Ativos',
    },
    {
      code: 'I',
      name: 'Inativos',
    },
    {
      code: 'T',
      name: 'Todos',
    },
  ];

  const [showMapLoading, setShowMapLoading] = useState(false);

  const [isDrawing, setIsDrawing] = useState(false);

  const [selectedSetores, setSelectedSetores] = useState<SetoresProps[]>();

  const [noSectorControl, setNoSectorControl] = useState<NoSectorControlProps>({
    isFetched: false,
    isSelected: false,
    error: false,
  });

  const [colorsList, setColorsList] = useState<ColorsListProps[]>([]);

  const [showMapMenu, setShowMapMenu] = useState(true);

  const [showMapNoDataFound, setShowMapNoDataFound] = useState(false);

  const [showSectorsNoDataFound, setShowSectorsNoDataFound] = useState(false);

  const allowMapCenterAndZoom = useRef<boolean>(true);

  const mapInstance = useRef<any>(null);

  const lastCoordinateZoomed = useRef<any>([-19.9286, -43.9409]);

  const [localCliToUpdateCoordinate, setLocalCliToUpdateCoordinate] = useState<LocalCliToUpdateCoordinateProps>(null);

  const [zoomLevel, setZoomLevel] = useState(7);

  useEffect(() => {
    if (params && params.codMapa != undefined && params.codMapa != -1) {
      handleGetAllData(true);
    }
  }, [params]);

  const handleGetAllData = (updateMapCenter?: boolean) => {
    setSetores({
      filtered: [],
      unfiltered: [],
      list: [],
    });
    allowMapCenterAndZoom.current = updateMapCenter != undefined ? updateMapCenter : false;
    handleGetPontos();
    handleGetSetores();
  };

  const handleGetPontos = () => {
    setShowMapLoading(true);
    getClientesWithSector(+params.codEmpresa, +params.codUser, +params.codMapa)
      .then((res) => {
        const newPontos = (res ?? []).map((cli) => ({
          lat: +(cli.LATITUDE ?? 0),
          lng: +(cli.LONGITUDE ?? 0),
          color: '#' + cli.COR,
          radius: 8,
          ...cli,
        }));
        setShowMapNoDataFound(newPontos.length == 0);

        const allCLiList: ClientesProps[] = newPontos;
        let allNewCliList: ClientesProps[] = [];

        if (selectedSetores) {
          for (let j = 0; j < allCLiList.length; j++) {
            const actualCli: ClientesProps = allCLiList[j];
            for (let i = 0; i < selectedSetores.length; i++) {
              const actualSector: SetoresProps = selectedSetores[i];
              if (actualCli.CODSETOR == actualSector.CODSETOR) {
                allNewCliList.push(actualCli);
              }
            }
          }
        } else {
          allNewCliList = newPontos;
        }

        if (noSectorControl.isSelected && noSectorControl.isFetched) {
          allNewCliList = [...allNewCliList, ...clientes.noSector];
        }
        allNewCliList.push(allNewCliList[0]);
        setClientes((prevClientes: ClientesObjectProps) => {
          return {
            filtered: allNewCliList,
            unfiltered: newPontos,
            list: newPontos?.sort((a, b) => a.RAZAOSOCIAL.localeCompare(b.RAZAOSOCIAL)),
            noSector: prevClientes.noSector ?? [],
          };
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setShowMapLoading(false);
      });
  };

  const handleGetPontosSemSetor = () => {
    if (setores?.unfiltered?.length > 0) {
      setShowMapLoading(true);
      getClientesWithoutSector(+params.codEmpresa, +params.codUser, +params.codMapa)
        .then((res) => {
          const newPontos = (res ?? []).map((cli) => ({
            lat: +(cli.LATITUDE ?? 0),
            lng: +(cli.LONGITUDE ?? 0),
            color: '#' + cli.COR,
            radius: 8,
            ...cli,
          }));
          setNoSectorControl({
            isFetched: true,
            isSelected: true,
            error: false,
          });
          setClientes((prevClientes: ClientesObjectProps) => {
            return {
              filtered: [...prevClientes.unfiltered, ...newPontos],
              unfiltered: [...prevClientes.unfiltered, ...newPontos],
              list: [...prevClientes.unfiltered, ...newPontos]?.sort(
                (a, b) => a.RAZAOSOCIAL?.localeCompare(b.RAZAOSOCIAL ?? '') ?? 0,
              ),
              noSector: newPontos ?? [],
            };
          });
        })
        .catch((err) => {
          setNoSectorControl({
            isFetched: false,
            isSelected: true,
            error: true,
          });
          console.log(err);
        })
        .finally(() => {
          setShowMapLoading(false);
        });
    }
  };

  const handleGetSetores = () => {
    getSectorList(+params.codEmpresa, +params.codUser, +params.codMapa).then((res) => {
      let setoresListAll: SetoresProps[] = Array.isArray(res) ? res : [];
      const customSort = (a: SetoresProps, b: SetoresProps) => {
        const codSetorA = Number(a.CODSETOR);
        const codSetorB = Number(b.CODSETOR);
        return codSetorA - codSetorB;
      };
      setoresListAll = setoresListAll.length ? setoresListAll?.sort(customSort) : setoresListAll;
      for (let i = 0; i < setoresListAll.length; i++) {
        const sector = setoresListAll[i];
        if (sector.CODSETOR == 0) {
          sector.CODSETOR = -1;
        }
        sector.polygono = [];
        setoresListAll[i] = sector;
      }
      setShowSectorsNoDataFound(setoresListAll.length == 0);
      setSetores({
        filtered: setoresListAll,
        unfiltered: setoresListAll,
        list: setoresListAll,
      });
      const setoresToFirstSelect: SetoresProps[] = noSectorControl.isSelected
        ? setoresListAll
        : setoresListAll?.filter((sector: SetoresProps) => sector.CODSETOR != 0 && sector.CODSETOR != -1);

      const updatedSetoresToFirstSelect = [];
      for (let i = 0; i < setoresToFirstSelect.length; i++) {
        if (selectedSetores && selectedSetores.some((setor) => +setor.CODSETOR === +setoresToFirstSelect[i].CODSETOR)) {
          updatedSetoresToFirstSelect.push(setoresToFirstSelect[i]);
        }
      }

      setSelectedSetores(
        selectedSetores && selectedSetores.length > 0 ? updatedSetoresToFirstSelect : setoresToFirstSelect,
      );
    });
  };

  function debounce<T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
    let timer: ReturnType<typeof setTimeout> | null = null;
    return (...args: Parameters<T>) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => func(...args), delay);
    };
  }

  const worker = new Worker(new URL('./filterWorker.ts', import.meta.url));

  const cliSearchRef = useRef(null);
  const handleCliSearchText = useCallback(
    debounce(() => {
      if (!clientes || !clientes.unfiltered || !selectedSetores) return;

      const allCLiList = clientes.unfiltered;
      const allNewCliList = allCLiList;

      const searchText = cliSearchRef.current?.value || '';
      worker.postMessage({ allNewCliList, searchText });

      worker.onmessage = (e: MessageEvent) => {
        const filteredList: ClientesProps[] = e.data;
        setClientes((prevCliList) => ({
          ...prevCliList,
          list: filteredList?.sort((a, b) => a.RAZAOSOCIAL.localeCompare(b.RAZAOSOCIAL)),
        }));
      };

      setCliSearchText(searchText);
    }, 300),
    [clientes, selectedSetores],
  );

  useEffect(() => {
    if (selectedSetores && selectedSetores.length == setores.unfiltered.length) {
      const isNoSectorReallySelected = selectedSetores.some((setor) => setor.CODSETOR === -1);
      setNoSectorControl({
        isFetched: false,
        isSelected: isNoSectorReallySelected,
        error: false,
      });
    } else if (selectedSetores) {
      let isNoSectorSelected = selectedSetores.some((setor) => setor.CODSETOR === -1);
      setNoSectorControl({
        isFetched: false,
        isSelected: isNoSectorSelected,
        error: false,
      });
    }

    if (!selectedSetores || selectedSetores?.length == 0) {
      setClientes((prevCliList: ClientesObjectProps) => {
        return {
          filtered: [],
          unfiltered: prevCliList.unfiltered,
          list: prevCliList.list?.sort((a, b) => a.RAZAOSOCIAL.localeCompare(b.RAZAOSOCIAL)),
          noSector: prevCliList.noSector,
        };
      });
    }
  }, [selectedSetores]);

  useEffect(() => {
    if (noSectorControl && noSectorControl.isSelected == true && noSectorControl.isFetched == false) {
      if (clientes.noSector.length == 0) {
        handleGetPontosSemSetor();
      } else {
        try {
          const newPontosSemSetor =
            localCliToUpdateCoordinate &&
            localCliToUpdateCoordinate.codCli != 0 &&
            localCliToUpdateCoordinate.lat != 0 &&
            localCliToUpdateCoordinate.lng != 0
              ? clientes.noSector?.map((ponto) => {
                  if (+ponto.CODCLI == +localCliToUpdateCoordinate.codCli) {
                    ponto.LATITUDE = localCliToUpdateCoordinate.lat.toString();
                    ponto.lat = localCliToUpdateCoordinate.lat;
                    ponto.LONGITUDE = localCliToUpdateCoordinate.lng.toString();
                    ponto.lng = localCliToUpdateCoordinate.lng;
                  }
                  return ponto;
                })
              : clientes.noSector ?? [];

          localCliToUpdateCoordinate &&
            localCliToUpdateCoordinate.codCli != 0 &&
            localCliToUpdateCoordinate.lat != 0 &&
            localCliToUpdateCoordinate.lng != 0 &&
            setLocalCliToUpdateCoordinate(null);
          setClientes((prevCliList: ClientesObjectProps) => {
            return {
              filtered: [...prevCliList.filtered, ...newPontosSemSetor],
              unfiltered: prevCliList.unfiltered,
              list: prevCliList.list?.sort((a, b) => a.RAZAOSOCIAL.localeCompare(b.RAZAOSOCIAL)),
              noSector: newPontosSemSetor,
            };
          });
        } catch (err) {
          window.location.reload();
        }
      }
    }
  }, [noSectorControl]);

  const handleRemoveCliSector = () => {
    dialog
      .show({
        title: `Deseja remover o cliente do Setor ?`,
        text: `[${selectedCli?.CODCLI}] ${selectedCli?.RAZAOSOCIAL}`,
        icon: 'danger',
        confirmButtonText: 'Sim',
        showConfirmButton: true,
        cancelButtonText: 'Não, voltar',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          let toRemoveCliSetorVinculo = {
            codSetor: +selectedCli.CODSETOR,
            codCli: +selectedCli.CODCLI,
            codFilial: 1,
            codEmpresa: +params.codEmpresa,
          };
          removeCliSetorVinculo(toRemoveCliSetorVinculo)
            .then(() => {
              handleGetAllData();
              toast.success('Vinculo do cliente x setor removido!');
            })
            .catch((err: Error) => {
              console.error(err);
              toast.error('Falha ao remover vinculo do cliente x setor!');
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (selectedSetores && clientes && clientes.unfiltered.length > 0) {
      handleCliFilterBySectors();
    } else {
      setClientes((prevCliList: ClientesObjectProps) => {
        return {
          filtered: [],
          unfiltered: prevCliList.unfiltered,
          list: [],
          noSector: prevCliList.noSector,
        };
      });
    }
  }, [selectedSetores]);

  const handleCliFilterBySectors = () => {
    const allCLiList: ClientesProps[] = clientes.unfiltered;
    const allNewCliList: ClientesProps[] = [];

    for (let j = 0; j < allCLiList.length; j++) {
      const actualCli: ClientesProps = allCLiList[j];
      for (let i = 0; i < selectedSetores.length; i++) {
        const actualSector: SetoresProps = selectedSetores[i];
        if (actualCli.CODSETOR == actualSector.CODSETOR) {
          allNewCliList.push(actualCli);
        }
      }
    }
    setClientes((prevCliList: ClientesObjectProps) => {
      return {
        filtered: allNewCliList,
        unfiltered: prevCliList.unfiltered,
        list: (cliSearchText && cliSearchText != ''
          ? allNewCliList?.filter(
              (cli: ClientesProps) =>
                cli.CODCLI.toString().includes(cliSearchText) ||
                cli.RAZAOSOCIAL.toString().toUpperCase().includes(cliSearchText?.toUpperCase()),
            )
          : allNewCliList
        )?.sort((a, b) => a.RAZAOSOCIAL.localeCompare(b.RAZAOSOCIAL)),
        noSector: prevCliList.noSector,
      };
    });
  };

  const handleGetSectorByCliCodSetor = (codSetor: number) => {
    const [actualSector] = setores?.unfiltered?.filter((sector) => +sector.CODSETOR == +codSetor);
    return actualSector;
  };

  const updateMapCenter = (client: ClientesProps, mapInstance: L.Map, zoom?: number, ignoreTiming?: boolean) => {
    if (client) {
      lastCoordinateZoomed.current = [client.lat, client.lng];
      const targetPoint = mapInstance.latLngToContainerPoint([client.lat, client.lng]);
      const offsetPoint = L.point(targetPoint.x, targetPoint.y - 0);
      const newCenter = mapInstance.containerPointToLatLng(offsetPoint);
      mapInstance.setView(newCenter, zoom ? zoom : 5);
    }
  };

  return (
    <MapContext.Provider
      value={{
        params,
        setParams,
        clientes,
        setClientes,
        cliSearchText,
        setCliSearchText,
        selectedCli,
        setSelectedCli,
        screenMode,
        setScreenMode,
        handleCliSearchText,
        isDrawing,
        setIsDrawing,
        selectedSetores,
        setSelectedSetores,
        setores,
        setSetores,
        setoresFilter,
        setSetoresFilter,
        setoresFilterOptions,
        noSectorControl,
        setNoSectorControl,
        handleRemoveCliSector,
        handleGetAllData,
        colorsList,
        setColorsList,
        cliSearchRef,
        showMapMenu,
        setShowMapMenu,
        showMapNoDataFound,
        setShowMapNoDataFound,
        showSectorsNoDataFound,
        setShowSectorsNoDataFound,
        handleGetSectorByCliCodSetor,
        allowMapCenterAndZoom,
        mapInstance,
        updateMapCenter,
        lastCoordinateZoomed,
        setLocalCliToUpdateCoordinate,
        zoomLevel,
        setZoomLevel,
        showMapLoading,
        setShowMapLoading,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const useMap = () => useContext(MapContext);
