import { FC } from 'react';
import userAvatar from 'assets/images/user-avatar.png';
import * as S from './styles';
import { useUser } from 'client/hooks';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { logout } from 'modules/auth/reducer/auth';
import { trackGCatchError } from 'utils/analytics';

const Topbar: FC = () => {
  try {
    const dispatch = useAppDispatch();
    const { data: user } = useUser();

    const navigate = useNavigate();

    const handleLogout = () => {
      dispatch(logout());
    };

    return (
      <S.BoxAvatar
        className="cursor-pointer symbol symbol-30px symbol-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <div>
          <img style={{ height: '20px', width: '20px', opacity: 0.6 }} src={userAvatar} alt="user" />
        </div>

        <div
          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
          data-kt-menu="true"
        >
          <div className="menu-item px-3">
            <div className="menu-content d-flex align-items-center px-3">
              <div className="symbol symbol-50px me-5">
                <img alt="Logo" src={userAvatar} />
              </div>
              <S.BoxUser>
                <p className="fw-bolder fs-4">{user?.nome}</p>
                <span
                  onClick={() => {
                    navigate('/usuario');
                  }}
                >
                  {'> MEUS DADOS'}
                </span>
              </S.BoxUser>
            </div>
          </div>

          <div className="separator my-2" />
          <div className="menu-item px-5">
            <a onClick={handleLogout} className="menu-link px-5">
              Sair
            </a>
          </div>
        </div>
      </S.BoxAvatar>
    );
  } catch (err) {
    trackGCatchError(err, 'main/components/Header/Topbar.tsx');
  }
};

export default Topbar;
