export function formatDate(
  input: string | Date,
  locale: 'EN' | 'BR',
  includeTime?: boolean
): string {
  const date = input instanceof Date ? input : new Date(input);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid date input');
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const formattedDate =
    locale === 'EN' ? `${year}-${month}-${day}` : `${day}/${month}/${year}`;

  if (includeTime) {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${formattedDate} ${hours}:${minutes}:${seconds}`;
  }

  return formattedDate;
}
