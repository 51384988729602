import { useContext } from 'react';

import gif from 'assets/gift/cliMapLoader.gif';
import { MapContext } from 'providers/map';

import * as S from './styles';
import { trackGCatchError } from 'utils/analytics';

interface ILoad {
  title?: string;
}

const LoadCliMap = ({ title }: ILoad) => {
  try {
    const { loadCliMap, loadCliMapMessage } = useContext(MapContext);

    if (loadCliMap) {
      return (
        <S.Container>
          <img src={gif} />
          {title || (loadCliMapMessage && <span>{title ?? loadCliMapMessage}</span>)}
        </S.Container>
      );
    } else {
      return <></>;
    }
  } catch (err) {
    trackGCatchError(err, 'mapa/components/loadCliMap/loadCliMap.index.tsx');
  }
};

export default LoadCliMap;
