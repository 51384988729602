export const hourMask = (value: string | number) => {
  const cleanValue = value.toString().replace(/\D/g, '');

  if (cleanValue.length < 3) {
    return cleanValue;
  }

  const hours = cleanValue.slice(0, 2);
  const minutes = cleanValue.slice(2, 4);

  return `${hours}:${minutes}`;
};
