import { useUser } from '../../client/hooks/usuarios';
import { useEffect, useState } from 'react';
import { getUsers } from 'client/api';
import Dropdown from 'components/Dropdown/dropdown';

type SelectedUserProps = {
  codUsuario: number;
  nomeUsuario?: string;
  codEmpresa: number;
};

const UseSelectUsuario = (codEmpresa: number, showToAllusers?: boolean) => {
  const { data: user } = useUser();
  const [usuarios, setUsuarios] = useState<any[]>([]);

  const defaultUsuario = () => {
    const usr = JSON.parse(localStorage.getItem('vmUsrData') || 'null');
    return (
      usr || {
        codUsuario: user ? user.codUsuario : -1,
        nomeUsuario: '',
      }
    );
  };

  const [selectedUsuario, setSelectedUsuario] = useState<SelectedUserProps>(defaultUsuario);

  useEffect(() => {
    if (user) {
      if (user.codEmpresa === 2072021 || (showToAllusers && showToAllusers == true)) {
        getUsers(codEmpresa).then((users: any[]) => {
          const formattedUsers = users.map((u) => ({
            ...u,
            label: `[${u.codUsuario}] - ${u.nome}`,
            nomeUsuario: u.nome,
          }));
          setUsuarios(formattedUsers);

          const toSelectUsuario = formattedUsers?.filter((usr) => usr.codUsuario == selectedUsuario.codUsuario)[0];
          if (showToAllusers && showToAllusers == true && toSelectUsuario) {
            setSelectedUsuario(toSelectUsuario);
          }
        });
      } else {
        const defaultUsuario = {
          codUsuario: user.codUsuario,
          nomeUsuario: '',
          codEmpresa: user.codEmpresa,
        };
        setSelectedUsuario(defaultUsuario);
        localStorage.setItem('vmUsrData', JSON.stringify(defaultUsuario));
      }
    }
  }, [user, usuarios.length, codEmpresa]);

  const handleUserChange = (usr: SelectedUserProps) => {
    localStorage.setItem('vmUsrData', usr ? JSON.stringify(usr) : null);
    setSelectedUsuario(usr ?? defaultUsuario);
  };

  const SelectUsuario = ({
    onChange,
    width,
    label = 'Usuário',
    placeholder = 'Selecione um Usuário',
  }: {
    onChange?: (usuario: SelectedUserProps) => void;
    width?: string;
    label?: string;
    placeholder?: string;
  }) => {
    const handleChange = (emp: SelectedUserProps) => {
      handleUserChange(emp);
      if (onChange) {
        onChange(emp);
      }
    };

    if ((user && user.codEmpresa === 2072021) || (showToAllusers && showToAllusers == true)) {
      return (
        <Dropdown
          value={selectedUsuario}
          onChange={(e) => handleChange(e.value)}
          options={usuarios}
          optionLabel="label"
          filter
          itemTemplate={(emp) => `[${emp.codUsuario}] - ${emp.nomeUsuario}`}
          placeholder={placeholder}
          emptyMessage="Nenhum dado encontrado"
          emptyFilterMessage="Nenhum dado encontrado"
          width={width}
          label={label}
          showClear
        />
      );
    }
    return null;
  };

  return {
    SelectUsuario,
    getSelectededUsuario: () => selectedUsuario,
    selectedUsuario,
    setSelectedUsuario,
  };
};

export default UseSelectUsuario;
