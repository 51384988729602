import * as S from './vendedorTable.styles';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Accordion } from 'reactivus';
import { VendedorProps } from 'modules/verbas/verbas.types';
import { useVerbas } from 'modules/verbas/verbas.context';
import { trackGCatchError } from 'utils/analytics';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';

interface SupervTableProps {
  codEmpr: number;
}

export default function VendedorTable({ codEmpr }: SupervTableProps) {
  try {
    const { vendedores } = useVerbas();

    const vendedorTemplate = (superv: VendedorProps) => {
      return <div>{`[${superv.codVendedor}] ${superv.vendedor}`}</div>;
    };

    const clienteTemplate = (superv: VendedorProps) => {
      return <div>{`[${superv.codCli}] ${superv.cliente}`}</div>;
    };

    const extratoTemplate = () => {
      return <Button icon={<icons.List />} color="gray" tooltip="Extrato" rounded />;
    };

    return (
      <Accordion title={'Valores por vendedor'}>
        <S.VerbasTableMainBox>
          <S.VerbasBodyBox>
            <DataTable
              value={vendedores ?? []}
              paginator={false}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rows={100}
              rowHover
              stripedRows
              scrollable
              scrollHeight="57dvh"
              style={{ maxWidth: '100%', minHeight: '57dvh' }}
              emptyMessage={'Nenhum vendedor vinculado'}
              size="small"
            >
              <Column field="codVendedor" header="Vendedor" sortable align="left" body={vendedorTemplate} />
              <Column field="codCli" header="Cliente" sortable align="left" body={clienteTemplate} />
              <Column field="vlDistVendedor" header="Vl. Dist. RCA" sortable align="left" />
              <Column field="vlAplic" header="Vl. Aplic." sortable align="left" />
              <Column field="vlSaldo" header="Vl. Saldo" sortable align="left" />
              <Column field="" header="Extrato" sortable align="left" body={extratoTemplate} />
            </DataTable>
          </S.VerbasBodyBox>
        </S.VerbasTableMainBox>
      </Accordion>
    );
  } catch (err) {
    trackGCatchError(err, 'verbas/components/vendedorTable/vendedorTable.index.tsx');
  }
}
