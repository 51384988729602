import InputSwitch from 'components/Inputs/InputSwitch/switch.input';
import InputText from 'components/Inputs/InputText/text.input';
import UseSelectDate from 'hooks/UseSelectDate/selectDate';

const EditString = (options: any) => {
  return (
    <InputText
      {...options}
      style={{
        maxWidth: '50% !important',
      }}
      mask={options.mask ?? undefined}
    />
  );
};

const EditBoolean = (options: any) => {
  return <InputSwitch {...options} />;
};

const EditNumber = (options: any) => {
  return <InputText {...options} number />;
};

const EditDate = (options: any) => {
  const { defaultValue, ...rest } = options;
  const { SelectDate } = UseSelectDate(defaultValue ? new Date(defaultValue) : undefined);
  return <SelectDate {...rest} />;
};

const returnParamsRcaInputs = (fieldType: string, options: any) => {
  if (fieldType == 'string') {
    return <EditString {...options} />;
  } else if (fieldType == 'boolean') {
    return <EditBoolean {...options} />;
  } else if (fieldType == 'number') {
    return <EditNumber {...options} />;
  } else if (fieldType == 'date') {
    return <EditDate {...options} />;
  }
};

export default returnParamsRcaInputs;
