import Button from 'components/Button/button.index';
import { ParamsRcaTableHeader } from './paramRca.types';

import * as S from './ParamRcaEdit/paramRcaEdit.styles';
import icons from 'components/Icons/icons.index';
import { hourMask } from 'utils/masks';

const paramsLocallist: ParamsRcaTableHeader[] = [
  {
    id: 1,
    header: 'VENDEDOR',
    label: 'Vendedor',
    labelHeader: 'Vendedor',
    display: true,
    listOnAll: false,
    listOnGrid: false,
    listOnHeader: true,
    columns: [
      {
        field: 'nomeVendedor',
        header: 'VENDEDOR',
        label: 'Vendedor',
        display: true,
        whiteSpace: 'nowrap',
        className: 'fixed-vendedor-column',
        editable: false,
        type: 'string',
      },
    ],
  },
  {
    id: 2,
    header: 'DADOS VENDEDOR',
    label: (params: any) => {
      if (params) {
        return (
          <S.ParamRcaToggleRowTitle>
            <S.ParamRcaToggleRowTitleText>Vendedor</S.ParamRcaToggleRowTitleText>
            {params && params.ultversao != params.versaoAtual ? (
              <Button
                icon={<icons.X />}
                text="Versão Desatualizada"
                color="red"
                tag
                textMode
                tooltip="A versão do aparelho esta diferente da última versão disponível"
              />
            ) : (
              <Button
                icon={<icons.Check />}
                text="Versão Atualizada"
                color="green"
                tag
                textMode
                tooltip="A versão do aparelho está atualizada"
              />
            )}
          </S.ParamRcaToggleRowTitle>
        );
      } else {
        return 'Vendedor';
      }
    },
    labelHeader: 'Vendedor',
    display: true,
    listOnAll: true,
    listOnGrid: true,
    listOnHeader: true,
    columns: [
      {
        field: 'codEmpresa',
        header: 'EMPRESA',
        label: 'Empresa',
        display: false,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'string',
      },
      {
        field: 'codFilial',
        header: 'FILIAL',
        label: 'Filial',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'string',
      },
      {
        field: 'gerente',
        header: 'GERENTE',
        label: 'Gerente',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'string',
      },
      {
        field: 'supervisor',
        header: 'SUPERVISOR',
        label: 'Supervisor',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'string',
      },
      {
        field: 'versaoAtual',
        header: 'VERSÃO APARELHO',
        label: 'Versão Aparelho',
        display: true,
        whiteSpace: 'nowrap',
        className: (params: any) => {
          return params && params.ultversao != params.versaoAtual ? 'danger-input' : 'success-input';
        },
        editable: false,
        type: 'string',
      },
      {
        field: 'ultversao',
        header: 'VERSÃO DISPONÍVEL',
        label: 'Última Disponível',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'string',
      },
      {
        field: 'dtaltversao',
        header: 'DT. ALTERA VERSÃO',
        label: 'Dt. Altera Versão',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'date',
      },
      {
        field: 'diasOrcamento',
        header: 'DIAS ORÇAMENTO',
        label: 'Dias Orçamento',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'number',
      },
      {
        field: 'diasConexao',
        header: 'DIAS OFFLINE',
        label: 'Dias Offline',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'number',
      },
      {
        field: 'bloqueio_conexao',
        header: 'BLOQUEAR CONEXÃO',
        label: 'Bloquear Conexão',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
      {
        field: 'motivo_bloqueio',
        header: 'MOTIVO BLOQ. CONEXÃO',
        label: 'Motivo Bloqueio',
        display: (params: any) => {
          return params && params.bloqueio_conexao === true;
        },
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'string',
      },
      {
        field: 'usuario_bloqueio',
        header: 'DT. ULT. CONEXÃO',
        label: 'Dt. Ult. Conexão',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'string',
      },
      {
        field: 'dt_bloqueio',
        header: 'DT. BLOQUEIO',
        label: 'Dt. Bloqueio',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'string',
      },
      {
        field: 'dt_ult_conexao',
        header: 'DT. ULT. CONEXÃO',
        label: 'Dt. Ult. Conexão',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'string',
      },
    ],
  },
  {
    id: 3,
    header: 'ACESSOS',
    label: 'Acessos',
    labelHeader: 'Acessos',
    display: true,
    listOnAll: true,
    listOnGrid: true,
    listOnHeader: true,
    columns: [
      {
        field: 'alteraped',
        header: 'ALTERA PEDIDO',
        label: 'Altera Pedido',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
      {
        field: 'cancelaped',
        header: 'CANCELA PEDIDO',
        label: 'Cancela Pedido',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
      {
        field: 'cadcliente',
        header: 'CADASTRA CLIENTE',
        label: 'Cadastra Ciente',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
      {
        field: 'alteraContatoCli',
        header: 'ALTERA CADASTRO',
        label: 'Altera Cadastro',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
      {
        field: 'controleGps',
        header: 'CONTROLE GPS',
        label: 'Controle GPS',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
      {
        field: 'permitelb',
        header: 'PERMITE LB',
        label: 'Permite-LB',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
      {
        field: 'obrigaleremail',
        header: 'LER MENSAGEM',
        label: 'Ler Mensagem',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
      {
        field: 'conectaNuvem',
        header: 'CLI/ROTA NUVEM',
        label: 'Cli/Rota Nuvem',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
      {
        field: 'pedidosNuvem',
        header: 'PEDIDOS NUVEM',
        label: 'Pedidos Nuvem',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
      {
        field: 'usaDescCxmaster',
        header: 'DESCONTO CX',
        label: 'Desconto CX',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
      {
        field: 'percSugCxmaster',
        header: '% SUG. CX',
        label: '% Sug. CX',
        display: (params: any) => {
          return params && params.usaDescCxmaster === true;
        },
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'number',
      },
      {
        field: 'contrasenha',
        header: 'CONTRA-SENHA',
        label: 'Contra-Senha',
        display: false,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
    ],
  },
  {
    id: 4,
    header: 'GPS',
    label: 'GPS',
    display: (params: any) => {
      return params && params.controleGps === true;
    },
    labelHeader: 'GPS',
    listOnAll: true,
    listOnGrid: true,
    listOnHeader: (params: any) => {
      return params && params.controleGps === true;
    },
    columns: [
      {
        field: 'raioCheckin',
        header: 'RAIO CHECKIN',
        label: 'Raio Checkin',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'number',
      },
      {
        field: 'cliForaDaRota',
        header: 'CLI. FORA ROTA',
        label: 'Cli. Fora Rota',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'number',
      },
      {
        field: 'qtCliAusenteRetorno',
        header: 'QT. CLI AUSENTE RETORNO',
        label: 'Qt. Cli. Ausente Retorno',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'number',
      },
      {
        field: 'gpsForaRota',
        header: 'GPS FORA ROTA',
        label: 'GPS Fora Rota',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
      {
        field: 'seguirSequenciaRota',
        header: 'SEGUIR SEQ. ROTA',
        label: 'Seguir Seq. Rota',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
    ],
  },
  {
    id: 5,
    header: 'JORNADA DE TRABALHO',
    label: 'Jornada de Trabalho',
    labelHeader: 'Jornada de Trabalho',
    display: true,
    listOnAll: true,
    listOnGrid: true,
    listOnHeader: true,
    columns: [
      {
        field: 'hrIniJornada',
        header: 'INÍCIO JORNADA',
        label: 'Início Jornada',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'string',
        mask: hourMask,
      },
      {
        field: 'hrFimJornada',
        header: 'FIM JORNADA',
        label: 'Fim Jornada',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'string',
        mask: hourMask,
      },
      {
        field: 'trabalhaSabado',
        header: 'TRABALHA SÁBADO',
        label: 'Trabalha Sábado',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
      {
        field: 'trabalhaDomingo',
        header: 'TRABALHA DOMINGO',
        label: 'Trabalha Domingo',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
      {
        field: 'controlarAlmoco',
        header: 'CONTROLE ALMOÇO',
        label: 'Controle Almoço',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'boolean',
      },
      {
        field: 'hrIniAlmoco',
        header: 'INÍCIO ALMOÇO',
        label: 'Início Almoço',
        display: (params: any) => {
          return params && params.controlarAlmoco === true;
        },
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'string',
        mask: hourMask,
      },
      {
        field: 'hrFimAlmoco',
        header: 'FIM ALMOÇO',
        label: 'Fim Almoço',
        display: (params: any) => {
          return params && params.controlarAlmoco === true;
        },
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'string',
        mask: hourMask,
      },
      {
        field: 'almocoMinutos',
        header: 'MINUTOS ALMOÇO',
        label: 'Minutos Almoço',
        display: (params: any) => {
          return params && params.controlarAlmoco === true;
        },
        whiteSpace: 'nowrap',
        className: null,
        editable: true,
        type: 'number',
      },
    ],
  },
  {
    id: 6,
    header: 'DISPOSITIVO',
    label: 'Dispositivo',
    labelHeader: 'Dispositivo',
    display: true,
    listOnAll: true,
    listOnGrid: true,
    listOnHeader: true,
    columns: [
      {
        field: 'imei1',
        header: 'IMEI 1',
        label: 'Imei 1',
        display: false,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'string',
      },
      {
        field: 'imei2',
        header: 'IMEI 2',
        label: 'Imei 2',
        display: false,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'string',
      },
      {
        field: 'imei3',
        header: 'IMEI 3',
        label: 'Imei 3',
        display: false,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'string',
      },
      {
        field: 'serial1',
        header: 'SERIAL1',
        label: 'Serial 1',
        display: false,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'string',
      },
      {
        field: 'fabricante',
        header: 'FABRICANTE',
        label: 'Fabricante',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'string',
      },
      {
        field: 'marca',
        header: 'MARCA',
        label: 'Marca',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'string',
      },
      {
        field: 'modelo',
        header: 'MODELO',
        label: 'Modelo',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'string',
      },
      {
        field: 'tamanhoTela',
        header: 'TAMANHO TELA',
        label: 'Tamanho Tela',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'number',
      },
      {
        field: 'versaoAndroid',
        header: 'VERSÃO ANDROID',
        label: 'Versão Android',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'string',
      },
      {
        field: 'tipoAparelho',
        header: 'TIPO APARELHO',
        label: 'Tipo Aparelho',
        display: true,
        whiteSpace: 'nowrap',
        className: null,
        editable: false,
        type: 'string',
      },
    ],
  },
];

export default paramsLocallist;
