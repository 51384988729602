import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { loginAsync, registerAsync, validateUser } from 'client/api';
import { BaseMenuDto, MissingProperties, UserData, UserValidateStatus } from 'client/interfaces';
import storage from 'services/storage';

import type { RootState } from 'store/rootReducer';
import { getAxiosStatusCode, handleAxiosError } from 'utils/handleError';

interface LoginState {
  cpfCodErp: string;
  password: string;
}

interface RegisterState extends LoginState {
  celular: string | undefined;
  cpf: string | undefined;
  email: string | undefined;
  dtNascimento: Date | undefined;
  nome: string | undefined;
}

interface AuthState {
  user: UserData | null;
  cpfCodErp: string | null;
  empresaCNPJ: string | null;
  status: UserValidateStatus | null;
  menus: BaseMenuDto[];
  nomeUsuario: string | null;
  missingProperties: MissingProperties[];
  token: string | null;
  error: unknown;
}

const initialState: AuthState = {
  user: null,
  cpfCodErp: storage.getItem('cpfCodErp'),
  empresaCNPJ: storage.getItem('empresaCNPJ'),
  error: null,
  status: storage.getItem('status'),
  nomeUsuario: storage.getItem('nomeUsuario'),
  token: storage.getItem('token'),
  missingProperties: storage.getItem('missingProperties') ?? [],
  menus: [],
};

interface IValidateUser {
  user: string;
  isCpf: boolean;
}

export const validateUserInfo = createAsyncThunk(
  'auth/validate-user',
  async ({ user, isCpf }: IValidateUser, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const result = await validateUser({ user, empresa: state.auth.empresaCNPJ ?? '', isCpf });
      storage.setItem('status', result.status);
      storage.setItem('missingProperties', result.missingProperties);
      storage.setItem('nomeUsuario', result.nomeUsuario);

      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(getAxiosStatusCode(error));
    }
  },
);

export const login = createAsyncThunk('auth/login', async ({ cpfCodErp, password }: LoginState, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const result: any = await loginAsync({
      usuario: cpfCodErp,
      senha: password,
      empresa: state.auth.empresaCNPJ ?? '',
    });
    if (result && result.cod == 0 && result.message == 'Alterar Senha') {
      const isUserCpf = cpfCodErp.toString().replace(/[^\w\s]/g, '').length == 11;
      await thunkAPI.dispatch(validateUserInfo({ user: cpfCodErp, isCpf: isUserCpf }) as any);
    } else {
      storage.setItem('token', result.token);
      return result;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(getAxiosStatusCode(error));
  }
});

export const registerUser = createAsyncThunk(
  'auth/register',
  async ({ cpfCodErp, password, ...payload }: RegisterState, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      await registerAsync({ ...payload, usuario: cpfCodErp, senha: password, empresa: state.auth.empresaCNPJ ?? '' });
      storage.setItem('status', UserValidateStatus.Registered);
      storage.setItem('missingProperties', []);
      storage.setItem('nomeUsuario', '');

      return UserValidateStatus.Registered;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
    },
    setEmpresaCNPJ: (state, action: PayloadAction<string | null>) => {
      state.empresaCNPJ = action.payload;
      storage.setItem('empresaCNPJ', action.payload);
    },
    setUserCpf: (state, action: PayloadAction<string | null>) => {
      state.cpfCodErp = action.payload;
      storage.setItem('cpfCodErp', action.payload);
    },
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.user = action.payload;
    },
    setMenus: (state, action: PayloadAction<BaseMenuDto[]>) => {
      state.menus = action.payload;
    },
    logout: (state) => {
      const cpfCodErp = storage.getItem<string>('cpfCodErp');
      const empresaCNPJ = storage.getItem<string>('empresaCNPJ');
      storage.clearStorage();
      setEmpresaCNPJ(null);
      setUserCpf(null);
      // state.token = null;
      // state.user = null;
      // state.cpfCodErp = cpfCodErp;
      // state.empresaCNPJ = empresaCNPJ;
      storage.setItem('cpfCodErp', cpfCodErp);
      storage.setItem('empresaCNPJ', empresaCNPJ);
      localStorage.removeItem('vmUsrData');
      localStorage.removeItem('vmEmpData');
      !window.location.pathname.includes('login') && window.location.reload();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.status = action.payload;
      state.missingProperties = [];
      state.nomeUsuario = action.payload;
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.cpfCodErp = action.payload.cod;
      state.error = null;
    });
    builder.addCase(validateUserInfo.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.missingProperties = action.payload.missingProperties;
      state.nomeUsuario = action.payload.nomeUsuario;
      state.error = null;
    });
  },
});

export const { clearState, setEmpresaCNPJ, setUserData, setUserCpf, setMenus, logout } = authSlice.actions;

export const userSelector = (state: RootState): UserData | null => state.auth.user;
export const isAdminSelector = (state: RootState): boolean => state.auth.user?.admin ?? false;
export const empresaCNPJSelector = (state: RootState): string | null => state.auth.empresaCNPJ;
export const cpfCodErpSelector = (state: RootState): string | null => state.auth.cpfCodErp;
export const loginStatusSelector = (state: RootState): UserValidateStatus | null => state.auth.status;
export const missingPropertiesSelector = (state: RootState): MissingProperties[] => state.auth.missingProperties;
export const nomeUsuarioSelector = (state: RootState): string => state.auth.nomeUsuario;

export const isLoggedInSelector = (state: RootState): boolean => !(state.auth.token == null);
export const errorsSelector = (state: RootState): unknown => state.auth.error;
export const menusSelector = (state: RootState): BaseMenuDto[] => state.auth.menus;

export default authSlice.reducer;
