import * as S from './addressModal.styles';
import { AxiosError } from 'axios';
import { Dialog } from 'primereact/dialog';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { editClientPoint, registerClientPoint } from 'client/api/ponto';
import useMediaQuery from 'hooks/useMediaQuery';

import { MapContainer, TileLayer, Marker, LayersControl, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import InputText from 'components/Inputs/InputText/text.input';
import { useJsApiLoader } from '@react-google-maps/api';
import redPinMarker from 'assets/svgs/redPin';
import { mapLayersList } from 'components/MapLayers/mapLayers.index';
import { trackGaGeolocation, trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';

const redPinIcon = new L.DivIcon({
  html: redPinMarker,
  className: '',
  iconSize: [44, 44],
  iconAnchor: [22, 44],
  popupAnchor: [0, -44],
});

interface IAddressModal {
  isAdress?: boolean;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  cliData?: any;
  isEdit?: boolean;
  toEditPoint?: any;
  codVendedor?: string | number;
  title?: string;
  getPointList?: () => void;
  codMapaParam: string;
  codEmpresaParam: string;
  codVendedorParam?: number;
}

interface ICoordinates {
  lat: number;
  lng: number;
}

interface AddressProps {
  desc: string;
  endereco: string;
  numero: string;
  bairro: string;
  cidade: string;
  uf: string;
  cep: string;
}

const AddressModal = ({
  isAdress,
  showModal,
  setShowModal,
  cliData,
  isEdit,
  toEditPoint,
  codVendedor,
  title,
  getPointList,
  codEmpresaParam,
}: IAddressModal) => {
  try {
    const isWebScreen = useMediaQuery('(min-width: 1060px)');

    const mapRef: any = useRef(null);

    const [currentCoords, setCurrentCoords] = useState<ICoordinates>({ lat: 0, lng: 0 });

    const defaultAddress = {
      desc: '',
      endereco: '',
      numero: '',
      bairro: '',
      cidade: '',
      uf: '',
      cep: '',
    };

    const [address, setAddress] = useState<AddressProps>(defaultAddress);

    const clearAllData = () => {
      setCurrentCoords({ lat: 0, lng: 0 });
      setAddress(defaultAddress);
    };
    const handleFieldsFill = (data: any) => {
      setAddress({
        desc: data.descricao,
        endereco: data.endereco,
        numero: data.numero,
        bairro: data.bairro,
        cidade: data.cidade,
        uf: data.uf,
        cep: data.cep,
      });
    };

    const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: 'AIzaSyAUHxQUnO76uq2HBu2X6xzaLZPapIFv--0',
      libraries: ['drawing', 'places'],
    });

    const geolocalizar = () => {
      let query = '';
      query = address.endereco !== '' ? address.endereco : '';
      query = address.numero !== '' && query !== '' ? query + ', ' + address.numero : query + address.numero;
      query = address.bairro !== '' && query !== '' ? query + ', ' + address.bairro : query + address.bairro;
      query = address.cidade !== '' && query !== '' ? query + ', ' + address.cidade : query + address.cidade;
      query = address.uf !== '' && query !== '' ? query + ', ' + address.uf : query + address.uf;
      query = address.cep !== '' && query !== '' ? query + ', ' + address.cep : query + address.cep;

      query = query
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replaceAll('%', '%25');

      const geocoder = new window.google.maps.Geocoder();
      trackGaGeolocation();
      geocoder.geocode({ address: query }, (results, status) => {
        if (status === 'OK') {
          const location = results[0].geometry.location;
          const coords = { lat: location.lat() ?? 0, lng: location.lng() ?? 0 };
          setCurrentCoords(coords);
          if (mapRef.current != null) {
            mapRef.current.flyTo(coords, 18, {
              animate: true,
              duration: 1.2,
            });
          }
        } else {
          toast.error('Endereço não localizado!');
          console.error('Geocode was not successful for the following reason:', status);
        }
      });
    };

    useEffect(() => {
      if (cliData != null) {
        handleFieldsFill(cliData);
      }
    }, [cliData, showModal]);

    useEffect(() => {
      if (toEditPoint) {
        handleFieldsFill(toEditPoint);
        setCurrentCoords({ lat: +(toEditPoint.latitude ?? 0), lng: +(toEditPoint.longitude ?? 0) });
      }
    }, [toEditPoint, showModal]);

    useEffect(() => {
      if (showModal == false) {
        clearAllData();
      }
    }, [showModal]);

    const DraggableMarker = () => {
      const [dragging, setDragging] = useState(false);

      const markerRef = useRef<L.Marker>(null);

      const handleDragStart = () => setDragging(true);
      const handleDragEnd = () => {
        const marker = markerRef.current;
        if (marker) {
          setCurrentCoords(marker.getLatLng());
          setDragging(false);
        }
      };

      return (
        <Marker
          draggable
          position={currentCoords}
          icon={redPinIcon}
          ref={markerRef}
          eventHandlers={{
            dragstart: handleDragStart,
            dragend: handleDragEnd,
          }}
        />
      );
    };

    async function handleGeoRegister() {
      try {
        if (!address.desc) {
          toast.error('Campo "Descrição" não preenchido!');
          return;
        }

        const toRegisterPoint = {
          descricao: address.desc.toString(),
          codVendedor: codVendedor.toString(),
          latitude: currentCoords.lat.toString(),
          longitude: currentCoords.lng.toString(),
          codEmpresa: codEmpresaParam.toString(),
          casa: 'N',
          endereco: address.endereco,
          numero: address.numero,
          bairro: address.bairro,
          cidade: address.cidade,
          uf: address.uf,
          cep: address.cep,
        };

        if (isAdress) {
          if (currentCoords.lat === 0 || currentCoords.lng === 0) {
            alert('Dados incompletos!');
            return;
          }
          editClientPoint(toRegisterPoint)
            .then(() => {
              toast.success('Ponto editado com sucesso!');
              setShowModal(false);
              clearAllData();
              getPointList();
            })
            .catch(() => {
              toast.error('Erro ao editar ponto!');
            });
        } else {
          if (address.desc === '' || currentCoords.lat === 0 || currentCoords.lng === 0) {
            alert('Dados incompletos!');
            return;
          }

          let toEditPointData: any = {};

          if (isEdit) {
            toEditPointData = {
              codPonto: toEditPoint.codPonto.toString(),
              descricao: address.desc.toString(),
              codVendedor: toEditPoint.codVendedor.toString(),
              latitude: currentCoords.lat.toString().slice(0, 20),
              longitude: currentCoords.lng.toString().slice(0, 20),
              casa: toEditPoint.casa.toString(),
              codEmpresa: toEditPoint.codEmpresa.toString(),
              endereco: address.endereco,
              numero: address.numero,
              bairro: address.bairro,
              cidade: address.cidade,
              uf: address.cep,
              cep: address.uf,
            };
          }

          if (isEdit) {
            editClientPoint(toEditPointData)
              .then(() => {
                toast.success('Ponto editado com sucesso!');
                setShowModal(false);
                clearAllData();
                getPointList();
              })
              .catch((err: AxiosError) => {
                if (err?.request.status) toast.error('Erro ao editar ponto!');
              });
          } else {
            registerClientPoint(toRegisterPoint)
              .then(() => {
                toast.success('Ponto inserido com sucesso!');
                setShowModal(false);
                clearAllData();
                getPointList();
              })
              .catch((err: AxiosError) => {
                console.log('err :', err);
                if (err?.request.status) toast.error('Erro ao inserir ponto!');
              });
          }
        }
      } catch (err) {
        toast.error(err.message ? `Falha inesperada: ${err.message}` : 'Falha inesperada ao editar coordenadas');
      }
    }

    function UpdateMapCenter({ center }: { center: [number, number] }) {
      const map = useMap();
      useEffect(() => {
        if (center) {
          map.setView(center);
        }
      }, [center, map]);
      return null;
    }

    return (
      <Dialog
        header={title ? title : isEdit ? 'Editar Local' : 'Novo Local'}
        visible={showModal}
        onHide={() => {
          setShowModal(false);
          clearAllData();
        }}
        style={{ width: isWebScreen ? '50vw' : '90vw' }}
      >
        <S.ModalMainBox>
          <S.ModalSubBox>
            <InputText
              label="Descrição *"
              id="desc"
              value={address.desc}
              onChange={(e) => {
                setAddress((prev: AddressProps) => ({ ...prev, desc: e }));
              }}
              width="100%"
              placeholder="Descrição"
            />

            <InputText
              id="endereco"
              value={address.endereco}
              onChange={(e) => {
                setAddress((prev: AddressProps) => ({ ...prev, endereco: e }));
              }}
              placeholder="Endereço"
              label="Endereço"
              width="100%"
            />

            <S.InputRow className="d-flex">
              <InputText
                id="numero"
                value={address.numero}
                onChange={(e) => {
                  setAddress((prev: AddressProps) => ({ ...prev, numero: e }));
                }}
                placeholder="Número"
                label="Número"
                width="47%"
              />

              <InputText
                id="bairro"
                value={address.bairro}
                onChange={(e) => {
                  setAddress((prev: AddressProps) => ({ ...prev, bairro: e }));
                }}
                placeholder="Bairro"
                label="Bairro"
                width="47%"
              />
            </S.InputRow>

            <S.InputRow className={'d-flex'}>
              <InputText
                id="cidade"
                value={address.cidade}
                onChange={(e) => {
                  setAddress((prev: AddressProps) => ({ ...prev, cidade: e }));
                }}
                placeholder="Cidade"
                label="Cidade"
                width="47%"
              />

              <InputText
                id="uf"
                value={address.uf}
                onChange={(e) => {
                  setAddress((prev: AddressProps) => ({ ...prev, uf: e }));
                }}
                placeholder="UF"
                label="UF"
                width="47%"
              />
            </S.InputRow>

            <InputText
              id="cep"
              value={address.cep}
              onChange={(e) => {
                setAddress((prev: AddressProps) => ({ ...prev, cep: e }));
              }}
              placeholder="CEP"
              label="CEP"
              width="47%"
            />

            <S.InputRow className="d-flex">
              <Button
                text="Geolocalizar"
                icon={<icons.MapPin />}
                disabled={address.desc === '' && address.endereco === ''}
                tooltip={
                  address.desc === '' && address.endereco === ''
                    ? 'Descrição e ou endereço não informado'
                    : 'Geolocalizar endereço'
                }
                color="blue"
                onClick={() => geolocalizar()}
              />
              <Button
                text="Salvar"
                icon={<icons.Check />}
                disabled={address.desc === '' && address.endereco === ''}
                tooltip={
                  address.desc === '' && address.endereco === '' ? 'Descrição e ou endereço não informado' : 'Salvar'
                }
                color="green"
                onClick={() => handleGeoRegister()}
              />
            </S.InputRow>
          </S.ModalSubBox>

          <S.ModalSubMapBox>
            <S.InputRow className="d-flex">
              <InputText
                id="Lat"
                value={currentCoords.lat}
                onChange={(e) =>
                  setCurrentCoords((prev: any) => {
                    return {
                      ...prev,
                      lat: e,
                    };
                  })
                }
                placeholder="Lat"
                label="Lat"
                width="47%"
              />

              <InputText
                id="Lng"
                value={currentCoords.lng}
                onChange={(e) =>
                  setCurrentCoords((prev: any) => {
                    return {
                      ...prev,
                      lng: e,
                    };
                  })
                }
                placeholder="Lng"
                label="Lng"
                width="47%"
              />
            </S.InputRow>
            <MapContainer center={currentCoords} zoom={18} style={{ height: '40vh', width: '100%' }}>
              <LayersControl position="topright">
                {mapLayersList?.map((layer, i) => {
                  return (
                    <LayersControl.BaseLayer checked={i == 0} name={layer.name}>
                      <TileLayer url={layer.url} />
                    </LayersControl.BaseLayer>
                  );
                })}
              </LayersControl>
              <DraggableMarker />
              <UpdateMapCenter center={[currentCoords.lat, currentCoords.lng]} />
            </MapContainer>
          </S.ModalSubMapBox>
        </S.ModalMainBox>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'mapa/components/AddressModal/addressModal.index.tsx');
  }
};

export default AddressModal;
