import styled from 'styled-components';

export const PathTreeMainBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 95dvw;
  overflow: hidden;
  @media only screen and (max-width: 1024px) {
    margin-left: -20px;
  }
`;

export const PathTreeTextBox = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 95dvw;
  white-space: nowrap;
`;

interface PathTreeTexProps {
  current?: boolean;
}

export const PathTreeText = styled.p<PathTreeTexProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 5px 5px;
  color: var(--dark-400);
  color: ${(props) => (props.current ? 'var(--v-text-gray)' : '#000')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PathTreeLink = styled.a<PathTreeTexProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 5px;
  color: var(--dark-400);
  color: ${(props) => (props.current ? 'var(--v-text-gray)' : '#000')};

  &:hover {
    text-decoration: underline !important;
    cursor: pointer;
  }

  svg {
    font-size: 1.1rem;
    cursor: pointer;
  }
`;

export const PathTreeMobileMenuButton = styled.div`
  display: none;
  cursor: pointer;
  @media only screen and (max-width: 1024px) {
    display: flex;
    padding: 5px 10px;

    svg {
      font-size: 1.5rem;
    }
  }
`;
