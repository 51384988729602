import * as S from './styles';
import { useEffect } from 'react';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';

export default function Relatorios7000Designer() {
  try {
    useEffect(() => {
      trackGPageView('/relatorios/designer');
      alterPageTitle('Designer Relatórios');
    }, []);

    return (
      <S.ReportMainBox>
        <S.ReportIFrameBox src="http://${process.env.REACT_APP_REPORT_BASE_URL}/" title="Vmais Report Designer" />
      </S.ReportMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'relatorios/relatoriosDesigner/index.tsx');
  }
}
