import { PageTitleProps } from 'providers/pageTitle';
import * as S from './pathTree.styles';
import { useNavigate } from 'react-router-dom';
import { PiHouseFill } from 'react-icons/pi';
import { IoIosArrowForward } from 'react-icons/io';
import { trackGCatchError } from 'utils/analytics';
import { AiOutlineMenu } from 'react-icons/ai';

export default function PathTree({ pageTitle }: { pageTitle: PageTitleProps }) {
  try {
    const navigate = useNavigate();

    if (pageTitle) {
      return (
        <S.PathTreeMainBox>
          <S.PathTreeMobileMenuButton>
            <AiOutlineMenu id={'kt_aside_mobile_toggle'} />
          </S.PathTreeMobileMenuButton>
          {pageTitle && pageTitle.text != 'Dashboard' && (
            <S.PathTreeLink
              href={'/dashboard'}
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
            >
              <PiHouseFill />
            </S.PathTreeLink>
          )}

          {pageTitle.tree && (
            <S.PathTreeTextBox>
              <S.PathTreeText>
                <IoIosArrowForward />
              </S.PathTreeText>
            </S.PathTreeTextBox>
          )}
          {pageTitle?.tree?.map((tree, index) => {
            return (
              <S.PathTreeTextBox>
                {tree.path ? (
                  <S.PathTreeLink
                    href={tree.path}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(tree.path);
                    }}
                  >
                    {tree.text}
                  </S.PathTreeLink>
                ) : (
                  <S.PathTreeText>{tree.text}</S.PathTreeText>
                )}
                <S.PathTreeText>
                  <IoIosArrowForward />
                </S.PathTreeText>
              </S.PathTreeTextBox>
            );
          })}
          <S.PathTreeTextBox>
            {!pageTitle.tree && (
              <S.PathTreeText>
                <IoIosArrowForward />
              </S.PathTreeText>
            )}
            <S.PathTreeText current>{pageTitle.text}</S.PathTreeText>
          </S.PathTreeTextBox>
        </S.PathTreeMainBox>
      );
    } else {
      return <></>;
    }
  } catch (err) {
    trackGCatchError(err, 'main/components/Header/components/pathTree/index.tsx');
  }
}
