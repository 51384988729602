import styled from 'styled-components';

export const MapMainBox = styled.section`
  width: 100%;
  position: relative;
  overflow-x: hidden;
`;

export const MobileHeader = styled.section`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100%;
  height: 2.5rem;
  z-index: 99;
`;
