import useMediaQuery from 'hooks/useMediaQuery';
import { Dialog } from 'primereact/dialog';
import { SetStateAction, useState } from 'react';

import * as S from './novoParamGeral.styles';
import { trackGCatchError } from 'utils/analytics';
import InputText from 'components/Inputs/InputText/text.input';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';
import { ParametroGeralCab } from 'client/interfaces';
import { toast } from 'react-toastify';
import { useUser } from 'client/hooks';
import { postParametrosGeralCab } from 'client/api';
import { useParamsGeral } from 'modules/parametrosGeral/paramGeral.context';

interface NovoParamGeralProps {
  showNovoParamModal: boolean;
  setShowNovoParamModal: React.Dispatch<SetStateAction<boolean>>;
}

export default function NovoParamGeral({ showNovoParamModal, setShowNovoParamModal }: NovoParamGeralProps) {
  const { data: user } = useUser();
  const { handleGetCabParamsGeralList } = useParamsGeral();
  try {
    const isWebScreen = useMediaQuery('(min-width: 1060px)');

    const [toCreatetParam, setToCreatetParam] = useState<ParametroGeralCab>(null);

    const [isLoading, setIsLoading] = useState(false);

    const handleCreateParam = () => {
      if (toCreatetParam.descricao == '') {
        toast.warn("Campo 'Descrição' não informado");
        return;
      } else if (toCreatetParam.valorPadrao == '') {
        toast.warn("Campo 'Descrição' não informado");
        return;
      }
      const toInsertParam: ParametroGeralCab = {
        codpar: 0,
        descricao: toCreatetParam.descricao,
        valorPadrao: toCreatetParam.valorPadrao,
        dtAltera: new Date().toISOString(),
        codFuncAltera: user.codUsuario.toString(),
      };
      setIsLoading(true);
      postParametrosGeralCab(toInsertParam)
        .then((res) => {
          if (res.succeeded) {
            toast.success('Sucesso ao inserir novo parâmetro');
            handleGetCabParamsGeralList();
            setShowNovoParamModal(false);
          } else {
            toast.error('Falha inesperada ao inserir novo parâmetro');
          }
        })
        .catch((err) => {
          console.log('err :', err);
          toast.error('Falha inesperada ao inserir novo parâmetro');
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    return (
      <Dialog
        header={'Novo Parâmetro'}
        visible={showNovoParamModal}
        style={{ width: isWebScreen ? '45vw' : '90%' }}
        onHide={() => {
          setShowNovoParamModal(false);
        }}
      >
        <S.EditParamModalFormBox>
          <S.EditParamModalFormRow>
            <InputText
              label="Descrição"
              id="DESCRICAO"
              placeholder=""
              value={toCreatetParam ? toCreatetParam.descricao : ''}
              onChange={(e) => {
                setToCreatetParam((prevState: any) => {
                  return {
                    ...prevState,
                    descricao: e,
                  };
                });
              }}
              width="clamp(200px, 250px, 300px)"
            />
            <InputText
              label="Valor Padrão"
              id="VALORP"
              placeholder=""
              value={toCreatetParam ? toCreatetParam.valorPadrao : ''}
              onChange={(e) => {
                setToCreatetParam((prevState: any) => {
                  return {
                    ...prevState,
                    valorPadrao: e,
                  };
                });
              }}
              width="clamp(200px, 250px, 300px)"
            />

            <Button
              text="Salvar"
              color="green"
              icon={<icons.Check />}
              onClick={() => {
                !isLoading && handleCreateParam();
              }}
              tooltip="Salvar novo parâmetro"
              loading={isLoading}
            />
          </S.EditParamModalFormRow>
        </S.EditParamModalFormBox>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'parametrosGeral/components/CloneParamModal/cloneParamModal.index.tsx');
  }
}
