import * as S from './kpiView.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { CoresOptionsProps, ItemKpiFilterProps } from 'modules/kpi/kpi.types';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { useKpi } from 'modules/kpi/kpi.context';
import { Progress } from 'reactivus';
import useMediaQuery from 'hooks/useMediaQuery';
import { KpiItemProps, KpiProps, PeriodKpiProps } from 'client/interfaces';
import { InputNumber } from 'primereact/inputnumber';
import { putItemKpi } from 'client/api/kpis';
import { toast } from 'react-toastify';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import InputText from 'components/Inputs/InputText/text.input';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import { usePageTitle } from 'providers/pageTitle';
import Dropdown from 'components/Dropdown/dropdown';

export default function KpiView() {
  try {
    const {
      itensKpi,
      coresOptions,
      selectedKpi,
      setSelectedKpi,
      setItemKpiFilters,
      kpis,
      periodKpis,
      SelectEmpresa,
      handleGetKpiList,
      handleConfirmKpiReRun,
      formatKpiNumber,
      selectedPeriodKpi,
      setSelectedPeriodKpi,
    } = useKpi();

    const { setPageTitle } = usePageTitle();

    const { id } = useParams();
    const navigate = useNavigate();

    const isWebScreen = useMediaQuery('(min-width: 1060px)');

    const [viewMode, setViewMode] = useState('clock'); // grid | map | clock

    useEffect(() => {
      if (id) {
        handleStartViewPage();
      } else {
        const newId = +id
          ? +id
          : selectedPeriodKpi && selectedPeriodKpi.kPIs && selectedPeriodKpi.kPIs[0]
          ? selectedPeriodKpi.kPIs[0].nrokpi
          : null;
        newId ? navigate(`/kpi/${newId}`) : handleStartViewPage();
      }
    }, [id, kpis, periodKpis]);

    function getCurrentMonthInPortuguese(): string {
      const monthNames = [
        'JANEIRO',
        'FEVEREIRO',
        'MARÇO',
        'ABRIL',
        'MAIO',
        'JUNHO',
        'JULHO',
        'AGOSTO',
        'SETEMBRO',
        'OUTUBRO',
        'NOVEMBRO',
        'DEZEMBRO',
      ];

      const currentMonthIndex = new Date().getMonth();
      return monthNames[currentMonthIndex];
    }

    const handleStartViewPage = () => {
      if (periodKpis.unfiltered && periodKpis.unfiltered[0]) {
        const actualKpi = periodKpis.unfiltered[0].kPIs?.filter((kpi: KpiProps) => +kpi.nrokpi == +id)[0] ?? null;
        if (!actualKpi) {
          if (
            periodKpis &&
            periodKpis.unfiltered[0] &&
            periodKpis.unfiltered[0].kPIs[0] &&
            periodKpis.unfiltered[0].kPIs[0].nrokpi
          ) {
            navigate(`/kpi/${periodKpis.unfiltered[0].kPIs[0].nrokpi}`);
          }
        } else {
          const actualMonth = getCurrentMonthInPortuguese();
          const actualPeriodKpi =
            periodKpis.unfiltered?.filter((period: PeriodKpiProps) => period.descPeriodo.includes(actualMonth))[0] ??
            periodKpis.unfiltered[0];
          !selectedPeriodKpi && setSelectedPeriodKpi(actualPeriodKpi);
          setSelectedKpi(actualKpi);
        }
      } else {
        if (selectedKpi) {
          setPageTitle((prev) => ({
            text: `KPI [${selectedKpi.nrokpi}] ${selectedKpi.titulo}`,
            customObject: prev.customObject,
            tree: prev.tree,
          }));
          trackGPageView(`/kpi/${selectedKpi.nrokpi}`);
          alterPageTitle('KPI');
        } else {
          setPageTitle((prev) => ({
            text: `KPI`,
            customObject: prev.customObject,
            tree: prev.tree,
          }));
          trackGPageView(`/kpi`);
          alterPageTitle('KPI');
        }
      }
    };

    useEffect(() => {
      if (selectedKpi) {
        setPageTitle((prev) => ({
          text: `KPI [${selectedKpi.nrokpi}] ${selectedKpi.titulo}`,
          customObject: prev.customObject,
          tree: prev.tree,
        }));
      }
    }, [selectedKpi]);

    const bodyNameTemplate = (rowData: KpiItemProps) => {
      return <div>{`[${rowData.CODIGO ?? 0}] ${rowData.NOME ?? ''}`}</div>;
    };

    const bodyRealPercentageTemplate = (rowData: KpiItemProps) => {
      const progressColor = coresOptions.filter((cor: CoresOptionsProps) => +cor.code == +rowData.COR)[0];
      return (
        <S.KpiProgressBar1>
          <label>{`${rowData.PERC_REAL}%`}</label>
          <Progress
            style={{
              width: '8rem',
            }}
            percentage={rowData.PERC_REAL}
            color={progressColor ? progressColor.name : ''}
          />
        </S.KpiProgressBar1>
      );
    };

    const BodyCardPercentageTemplate = ({ item }: { item: KpiItemProps }) => {
      const progressColor = coresOptions.filter((cor: CoresOptionsProps) => +cor.code == +item.COR)[0];
      return (
        <S.KpiMapProgressBox>
          <S.KpiMapProgressBoxLabels>
            <label>{` ${item.NOME ? item.NOME.toUpperCase() : '#'}`}</label>
            <label>{`${item.DESC_GRUPOB ? item.DESC_GRUPOB.toUpperCase() : ''}`}</label>
          </S.KpiMapProgressBoxLabels>
          <S.KpiMapProgressBar>
            <label>{`${item.PERC_REAL}%`}</label>
            <Progress
              style={{
                width: '100%',
              }}
              percentage={item.PERC_REAL}
              color={progressColor ? progressColor.name : ''}
            />
          </S.KpiMapProgressBar>

          <S.KpiMapObectives>
            <S.KpiMapObectiveSpan>{`OBJ: ${formatKpiNumber(item.VLOBJETIVO ?? 0)}`}</S.KpiMapObectiveSpan>
            <span>{`|`}</span>
            <S.KpiMapRealSpan>{`REAL: ${formatKpiNumber(item.VLREALIZADO ?? 0)}`}</S.KpiMapRealSpan>
          </S.KpiMapObectives>
        </S.KpiMapProgressBox>
      );
    };

    const BodyCardPercentageClockTemplate = ({ item }: { item: KpiItemProps }) => {
      const progressColor = coresOptions.filter((cor: CoresOptionsProps) => +cor.code == +item.COR)[0];
      return (
        <S.KpiMapProgressBoxClock>
          <S.KpiMapProgressBoxClockLabels>
            <label>{`${item.NOME ? item.NOME.toUpperCase() : '#'}`}</label>
            <label>{`${item.DESC_GRUPOB ? item.DESC_GRUPOB.toUpperCase() : ''}`}</label>
          </S.KpiMapProgressBoxClockLabels>
          <S.KpiMapProgressBarClock>
            <Progress
              style={{
                width: isWebScreen ? '100px' : '85px',
              }}
              percentage={item.PERC_REAL}
              color={item.PERC_REAL == 0 ? '#60606010' : progressColor ? progressColor.name : ''}
              rounded
              label={`${item.PERC_REAL}%`}
              stroke={2.5}
            />
          </S.KpiMapProgressBarClock>
          <S.KpiMapObectivesClock>
            <S.KpiMapObectiveSpanClock>
              <S.KpiMapObectiveSpanClockLabel>OBJ:</S.KpiMapObectiveSpanClockLabel>
              <span>{formatKpiNumber(item.VLOBJETIVO ?? 0)}</span>
            </S.KpiMapObectiveSpanClock>
            <S.KpiMapRealSpanClock>
              <S.KpiMapObectiveSpanClockLabel>REAL:</S.KpiMapObectiveSpanClockLabel>
              <span>{formatKpiNumber(item.VLREALIZADO ?? 0)}</span>
            </S.KpiMapRealSpanClock>
          </S.KpiMapObectivesClock>
        </S.KpiMapProgressBoxClock>
      );
    };

    const numberEditor = (options) => {
      return (
        <InputNumber
          value={options.value}
          onValueChange={(e) => options.editorCallback(e.value)}
          mode="decimal"
          min={0}
        />
      );
    };

    const callHandleConfirmKpiReRun = () => {
      handleConfirmKpiReRun(selectedKpi);
    };

    const onCellEditComplete = (e) => {
      let { rowData, newValue, field } = e;
      if (rowData[field] == newValue) {
        return;
      }
      if (newValue == null) {
        rowData[field] = rowData[field];
        return;
      }
      rowData[field] = newValue;
      const kpiItensToEdit = rowData;

      putItemKpi(kpiItensToEdit)
        .then((res) => {
          if (res.succeeded) {
            handleGetKpiList();
            toast.success('Item do KPI atualizado com sucesso');
          } else {
            rowData[field] = rowData[field];
          }
        })
        .catch((err) => {
          toast.error('Falha inesperada ao atualizar item do KPI.');
          console.log('err :', err);
          rowData[field] = rowData[field];
        });
    };

    return (
      <S.KpiViewMainBox>
        <S.KpiHeaderBox>
          <S.KpiHeaderFormBox>
            <SelectEmpresa />

            <Dropdown
              value={selectedPeriodKpi ?? periodKpis.unfiltered[0]}
              onChange={(e) => {
                setSelectedPeriodKpi(e.value);
                setItemKpiFilters((prev: ItemKpiFilterProps) => {
                  return {
                    ...prev,
                    periodo: e.value.descPeriodo,
                  };
                });
              }}
              options={periodKpis.unfiltered}
              optionLabel={'descPeriodo'}
              filter
              filterBy="razaosocial"
              filterPlaceholder="Filtrar período"
              placeholder="Período Ref"
              label='Período Ref'
            />

              <Dropdown
                value={selectedKpi ? selectedKpi.nrokpi : 'KPI'}
                onChange={(e) => {
                  navigate(`/kpi/${e.value}`);
                }}
                options={selectedPeriodKpi ? selectedPeriodKpi.kPIs : []}
                filter
                filterBy="titulo"
                filterPlaceholder="Filtrar KPI"
                placeholder="KPI's"
                optionLabel="nrokpi"
                optionValue="nrokpi"
                itemTemplate={(kpi: KpiProps) => {
                  return `[${kpi.nrokpi}] ${kpi.titulo}`;
                }}
                valueTemplate={(kpi: KpiProps) => {
                  return kpi ? `[${kpi.nrokpi}] ${kpi.titulo}` : 'KPI';
                }}
                label='KPI'
              />

            <InputText
              label="Buscar"
              id="report"
              placeholder="Buscar KPI"
              onChange={(e) => {
                e = e.toUpperCase();
                setItemKpiFilters((prev: ItemKpiFilterProps) => {
                  return {
                    ...prev,
                    text: e,
                  };
                });
              }}
              width="clamp(175px, 200px, 250px)"
            />

            <InputText
              label="Buscar2"
              id="report"
              placeholder="Buscar KPI"
              onChange={(e) => {
                e = e.toUpperCase();
                setItemKpiFilters((prev: ItemKpiFilterProps) => {
                  return {
                    ...prev,
                    text: e,
                  };
                });
              }}
              width="clamp(175px, 200px, 250px)"
            />

            <S.KpiViewModeButtonBox>
              <S.KpiViewModeButton
                active={viewMode == 'clock'}
                onClick={() => {
                  setViewMode('clock');
                }}
              >
                <icons.Clock />
              </S.KpiViewModeButton>
              <S.KpiViewModeButton
                active={viewMode == 'map'}
                onClick={() => {
                  setViewMode('map');
                }}
              >
                <icons.Grid />
              </S.KpiViewModeButton>
              <S.KpiViewModeButton
                active={viewMode == 'grid'}
                onClick={() => {
                  setViewMode('grid');
                }}
              >
                <icons.List />
              </S.KpiViewModeButton>
            </S.KpiViewModeButtonBox>
          </S.KpiHeaderFormBox>

          <S.KpiHeaderButtonsBox>
            <Button
              icon={<icons.Refresh />}
              color="black"
              tooltip="Atualizar KPI"
              rounded
              onClick={() => {
                callHandleConfirmKpiReRun();
              }}
            />
            <Button
              icon={<icons.Settings />}
              color="black"
              tooltip="Configurar KPI's"
              rounded
              onClick={() => {
                navigate(`/kpi/configurar`);
              }}
            />
          </S.KpiHeaderButtonsBox>
        </S.KpiHeaderBox>

        {viewMode == 'grid' && (
          <S.KpiBodyTableBox>
            <DataTable
              value={itensKpi.filtered ?? []}
              paginator={(itensKpi.filtered ?? []).length > 7}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rows={100}
              rowHover
              stripedRows
              scrollable
              scrollHeight="68dvh"
              style={{ minWidth: '50rem' }}
              size="small"
            >
              <Column field="DESC_PERIODO" header="Período Ref." sortable />
              <Column field="NOME" header="Nome" sortable body={bodyNameTemplate} />
              <Column
                field="VLOBJETIVO"
                header="Vl. Objetivo"
                sortable
                editor={numberEditor}
                onCellEditComplete={onCellEditComplete}
                className="vlObjetivoColumn"
                headerClassName="vlObjetivoColumn"
                body={(rowData: KpiItemProps) => {
                  return <div>{formatKpiNumber(rowData.VLOBJETIVO ?? 0)}</div>;
                }}
                align="right"
                alignHeader="right"
              />
              <Column
                field="VLREALIZADO"
                header="Vl. Realizado"
                sortable
                body={(rowData: KpiItemProps) => {
                  return <div>{formatKpiNumber(rowData.VLREALIZADO ?? 0)}</div>;
                }}
                align="right"
                alignHeader="right"
              />
              <Column field="PERC_REAL" header="%Real" sortable body={bodyRealPercentageTemplate} />
            </DataTable>
          </S.KpiBodyTableBox>
        )}
        {viewMode == 'map' && (
          <S.KpiBodyMapBox>
            {itensKpi.filtered?.map((item: KpiItemProps) => {
              return <BodyCardPercentageTemplate key={Math.random()} item={item} />;
            })}
          </S.KpiBodyMapBox>
        )}
        {viewMode == 'clock' && (
          <S.KpiBodyMapClockBox>
            {itensKpi.filtered?.map((item: KpiItemProps) => {
              return <BodyCardPercentageClockTemplate key={Math.random()} item={item} />;
            })}
          </S.KpiBodyMapClockBox>
        )}
      </S.KpiViewMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'kpi/kpiView/kpiView.index.tsx');
  }
}
