import { trackGCatchError } from 'utils/analytics';
import * as S from './styles';
import FooterMenu from 'components/FooterMenu';

export default function VmContainer({ children }: any) {
  try {
    return (
      <S.ContainerMainBox translate="no">
        {children}
        {/* <FooterMenu /> */}
      </S.ContainerMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'main/components/Container/index.tsx');
  }
}
