import * as S from './kpiList.styles';
import { useEffect, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';
import { useKpi } from 'modules/kpi/kpi.context';
import { KpiProps } from 'client/interfaces';
import EditKpiModal from 'modules/kpi/components/editKpiModal/editKpiModal.index';
import NewKpiModal from 'modules/kpi/components/newKpiModal/newKpiModal.index';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import InputText from 'components/Inputs/InputText/text.input';

export default function KpiList() {
  try {
    const {
      agrupadorOptions,
      setSelectedKpi,
      kpis,
      handleKpiHeaderFilter,
      kpiFilters,
      setKpiFilters,
      SelectEmpresa,
      handleConfirmKpiReRun,
    } = useKpi();

    const navigate = useNavigate();

    useEffect(() => {
      trackGPageView('/kpi/configurar');
      alterPageTitle('Configurar KPI');
    }, []);

    const [showEditKpiModal, setShowEditKpiModal] = useState(null);
    const [showNewSqlModal, setShowNewKpiModal] = useState(null);

    const reportTableActionFields = (kpi: KpiProps) => {
      return (
        <S.KpiTableActionField>
          <Button
            icon={<icons.Play />}
            rounded
            color="black"
            tooltip="Visualizar"
            onClick={() => {
              navigate(`/kpi/${kpi.nrokpi}`);
            }}
          />
          <Button
            icon={<icons.Edit />}
            rounded
            color="black"
            tooltip="Editar"
            onClick={() => {
              setShowEditKpiModal(true);
              setSelectedKpi(kpi);
            }}
          />
          <Button
            icon={<icons.Refresh />}
            rounded
            color="black"
            tooltip="Atualizar"
            onClick={() => {
              handleConfirmKpiReRun(kpi);
            }}
          />
        </S.KpiTableActionField>
      );
    };

    useEffect(() => {
      if (kpis) {
        handleKpiHeaderFilter();
      }
    }, [kpiFilters]);

    const agrupadorTemplate = (rowData: KpiProps) => {
      const agrupadorAtual =
        agrupadorOptions?.filter((agrupador: any) => agrupador.code == rowData.agrupador)[0] ?? null;

      return <div>{agrupadorAtual ? `[${agrupadorAtual.code}] ${agrupadorAtual.name}` : `${rowData.agrupador}`}</div>;
    };

    return (
      <S.KpiMainBox>
        <S.KpiHeaderBox>
          <SelectEmpresa />
          <InputText
            label="KPI"
            id="report"
            placeholder="Buscar por título ou SQL"
            onChange={(e) => {
              e = e.toUpperCase();
              setKpiFilters((prevState: any) => {
                return {
                  ...prevState,
                  text: e,
                };
              });
            }}
            width="clamp(200px, 250px, 300px)"
          />

          <Button
            text="Novo"
            icon={<icons.Plus />}
            color="green"
            tooltip="Novo KPI"
            onClick={() => {
              setShowNewKpiModal(true);
            }}
          />
        </S.KpiHeaderBox>

        <S.KpiBodyBox>
          <DataTable
            value={kpis?.filtered ?? []}
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rows={100}
            rowHover
            stripedRows
            scrollable
            scrollHeight="68dvh"
            style={{ minWidth: '50rem' }}
            size="small"
          >
            <Column
              field="nrokpi"
              header="Cód."
              sortable
              style={{
                maxWidth: '7rem',
              }}
            />
            <Column field="titulo" header="Título" sortable />
            <Column
              field="agrupador"
              header="Agrupador"
              body={agrupadorTemplate}
              sortable
              style={{
                maxWidth: '15rem',
              }}
            />
            <Column
              field="freqatualiza"
              header="Freq. Atualiza"
              sortable
              style={{
                maxWidth: '10rem',
              }}
            />
            <Column
              field="dtproxatu"
              header="Dt. Próx. Atu."
              sortable
              style={{
                maxWidth: '10rem',
              }}
            />
            <Column field="" header="Ações" body={reportTableActionFields} />
          </DataTable>
        </S.KpiBodyBox>
        {showEditKpiModal && (
          <EditKpiModal showEditKpiModal={showEditKpiModal} setShowEditKpiModal={setShowEditKpiModal} />
        )}
        {showNewSqlModal && <NewKpiModal showNewKpiModal={showNewSqlModal} setShowNewKpiModal={setShowNewKpiModal} />}
      </S.KpiMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'kpi/kpiList/kpiList.index.tsx');
  }
}
