import styled from 'styled-components';

type containerProps = {
  fullSize?: boolean;
};

export const ContentMainBox = styled.section<containerProps>`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 92vh;
  height: ${(props) => (props.fullSize ? '100vh' : '92vh')};
  width: 100%;
  position: relative;
  padding-bottom: 10px;
  overflow-y: scroll;
  padding: ${(props) => (props.fullSize ? '0 0px 0px 0px' : '0 10px 10px 10px')};
  background-color: #F5F7FA;
  padding: 0;
  /* font-family: 'Inter', sans-serif !important; */

  & *:not([class^='pi-']):not([class^='p-']) {
    /* font-family: 'Inter', sans-serif !important; */
  }

  @media only screen and (max-width: 1024px) {
    height: ${(props) => (props.fullSize ? '100dvh' : '90dvh')};
    overflow: scroll;
    padding-bottom: 10px;
    margin-bottom: 10px;
    padding: 0;
    position: absolute;
    top: ${(props) => (props.fullSize ? '0' : '55px')};
  }
`;
