import { BoxTable, Container, Header } from 'components/Containers';
import styled from 'styled-components';

export const KpiMainBox = styled(Container)`
  gap: 25px;
`;

export const KpiHeaderBox = styled(Header)``;

export const KpiInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const KpiBodyBox = styled(BoxTable)`
  width: 100%;
  max-height: 72dvh;
  overflow: hidden;
`;

export const KpiTableScriptField = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
interface KpiTableAtivoFieldProps {
  ativo?: string;
}

export const KpiTableAtivoField = styled.div<KpiTableAtivoFieldProps>`
  color: ${(props) => (props.ativo == 'S' ? `green` : 'red')} !important;
  svg {
    font-weight: bolder !important;
    font-size: 1.5rem !important;
  }
`;

export const KpiTableActionField = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  svg {
    font-weight: bolder !important;
    font-size: 1.5rem !important;
  }
`;
