import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as S from './styles';
import { deleteRotaPonto } from 'client/api/ponto';
import { UseCreatePoint } from 'hooks/usePoint/useCreatePoint/useCreatePoint';
import { IRouterStartPointsData } from 'client/interfaces';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import UseSelectVendedor from 'hooks/UseSelectVendedor/selectVendedor';
import UseSelectUsuario from 'hooks/UseSelectUsuario/selectUsuario';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import AddressModal from 'modules/mapa/components/AddressModal/addressModal.index';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import { dialog } from 'reactivus';
import { usePageTitle } from 'providers/pageTitle';

const CadastroPonto = () => {
  try {
    const {
      codEmpresa: codEmpresaParam,
      codMapa: codMapaParam,
      codVendedor: codVendedorParam,
      codSetor: codSetorParam,
      codUsuario: codUsuarioParam,
    } = useParams<{ codEmpresa: string; codMapa: string; codVendedor: string; codSetor: string; codUsuario: string }>();
    const { getPointList, pontosList } = UseCreatePoint(+codEmpresaParam);

    const [showModal, setShowModal] = useState(false);

    const [isEdit, setIsEdit] = useState(false);

    const [toEditPoint, setToEditPoint] = useState<IRouterStartPointsData>(null);

    useEffect(() => {
      if (showModal == false) {
        setToEditPoint(null);
      }
    }, [showModal]);

    const { selectedEmpresa } = UseSelectEmpresa();
    const { selectedUsuario } = UseSelectUsuario(selectedEmpresa.codEmpresa);
    const { SelectVendedor, selectedVendedor, handleSelectVendedorByCode, vendedores } = UseSelectVendedor(
      selectedEmpresa.codEmpresa,
      selectedUsuario.codUsuario,
    );

    useEffect(() => {
      if (codVendedorParam) {
        handleSelectVendedorByCode(+codVendedorParam);
      }
    }, [codVendedorParam, vendedores]);

    const { setPageTitle } = usePageTitle();
    useEffect(() => {
      trackGPageView('/cadastro/ponto');
      alterPageTitle('Cadastro Ponto');
      setPageTitle((prev) => ({
        text: `Meus Lugares`,
        customObject: prev.customObject,
        tree: [
          {
            text: 'Regioes',
            icon: '',
            path: `/mapa/regioes/${codEmpresaParam}/${codUsuarioParam}/${codMapaParam}`,
          },
          {
            text: 'Roteirizador',
            icon: '',
            path: `/roteirizador/${codMapaParam}/${codSetorParam}/${codVendedorParam}/${codEmpresaParam}/${codUsuarioParam}`,
          },
        ],
      }));
    }, [codMapaParam, codSetorParam, codVendedorParam, codEmpresaParam, codUsuarioParam]);

    const editTemplate = (rowData: IRouterStartPointsData) => {
      return (
        <Button
          icon={<icons.Edit />}
          iconPosition="left"
          color="black"
          rounded
          bgOnlyOnHover
          onClick={() => {
            setIsEdit(true);
            setShowModal(true);
            setToEditPoint(rowData);
          }}
        />
      );
    };

    const removeTemplate = (rowData: IRouterStartPointsData) => {
      return (
        <Button
          icon={<icons.Remove />}
          iconPosition="left"
          color="red"
          rounded
          bgOnlyOnHover
          textMode
          onClick={() => {
            dialog
              .show({
                title: 'Tem certeza?',
                text:
                  `Deseja remover este ponto de referência? </br> ` + `"${rowData.codPonto} - ${rowData.descricao} "`,
                icon: 'question',
                showConfirmButton: true,
                confirmButtonText: 'Sim',
                showCancelButton: true,
                cancelButtonText: 'Não, voltar',
              })
              .then((result) => {
                if (result.isConfirmed) {
                  deleteRotaPonto({
                    codPonto: rowData.codPonto,
                    descricao: rowData.casa,
                    codVendedor: rowData.codVendedor,
                    latitude: rowData.latitude,
                    longitude: rowData.longitude,
                    casa: rowData.casa,
                    codEmpresa: rowData.codEmpresa,
                  })
                    .then(() => {
                      getPointList();
                      toast.success('Ponto removido com sucesso!');
                    })
                    .catch((err) => {
                      toast.success('Erro ao remover ponto!');
                    });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        />
      );
    };

    const vendedorTemplate = (rowData: IRouterStartPointsData) => {
      return (
        <div>
          {rowData.nomeVendedor}[{rowData.codVendedor}]
        </div>
      );
    };

    useEffect(() => {
      if (!showModal && isEdit) {
        setIsEdit(false);
        return;
      }
    }, [showModal]);

    useEffect(() => {
      getPointList();
    }, []);

    return (
      <S.CadastroPontoMainBox>
        <S.CadastroPontoHeaderBox>
          <SelectVendedor label="Vendedor" />
          <Button
            text="Novo Local"
            icon={<icons.Plus />}
            color="green"
            tooltip="Cadastrar novo ponto"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </S.CadastroPontoHeaderBox>

        <S.CadastroPontoTableBox>
          <DataTable
            value={
              selectedVendedor && selectedVendedor.codVendedor == -1
                ? pontosList
                : pontosList.filter(
                    (rca: any) => rca.codVendedor == (selectedVendedor ? selectedVendedor.codVendedor : 0),
                  )
            }
            tableStyle={{ minWidth: '100%' }}
            paginator
            rows={8}
            style={{ fontSize: '.8rem' }}
            size="small"
          >
            <Column field="codPonto" header="COD PONTO" sortable />
            <Column field="descricao" header="DESCRICAO" sortable />
            <Column field="codVendedor" header="VENDEDOR" sortable body={vendedorTemplate} />
            <Column field="latitude" header="LATITUDE" sortable />
            <Column field="longitude" header="LONGITUDE" sortable />
            <Column field="casa" header="CASA" sortable />
            <Column field="codEmpresa" header="COD EMPRESA" sortable />
            <Column field="edit" header="EDITAR" body={editTemplate} />
            <Column field="remove" header="REMOVER" body={removeTemplate} />
          </DataTable>
        </S.CadastroPontoTableBox>

        <AddressModal
          isAdress={false}
          isEdit={isEdit}
          showModal={showModal}
          setShowModal={setShowModal}
          toEditPoint={toEditPoint}
          getPointList={getPointList}
          codMapaParam={codMapaParam}
          codEmpresaParam={codEmpresaParam}
          codVendedorParam={+codVendedorParam}
          codVendedor={codVendedorParam}
        />
      </S.CadastroPontoMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'CadastroPonto/CadastroPonto.tsx');
  }
};

export default CadastroPonto;
