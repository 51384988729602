import useMediaQuery from 'hooks/useMediaQuery';
import * as S from './novoKpiModal';
import { Dialog } from 'primereact/dialog';
import { NovoKpiModalProps } from 'client/interfaces';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { useAllKpisCampanhas } from 'client/hooks/kpis';
import { getAllKpiOnCampanhas, postDeleteKpiOnCampanha, postNewKpiOnCampanha } from 'client/api/kpis';
import { trackGCatchError } from 'utils/analytics';
import InputText from 'components/Inputs/InputText/text.input';
import Button from 'components/Button/button.index';

export default function NovoKpiModal({
  showNovaRestricaoModal,
  setShowNovaRestricaoModal,
  codEmpresa,
  handleGetRegrasComerciais,
  selectedRegra,
  campanhaKpis,
}: NovoKpiModalProps) {
  try {
    const isWebScreen = useMediaQuery('(min-width: 1060px)');
    const { data: kpis, refetch: refetchKpis } = useAllKpisCampanhas(
      codEmpresa,
      selectedRegra ? selectedRegra.codregra : 0,
    );

    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [tableData, setTableData] = useState(kpis ?? []);
    const [isSaveButtonActive, setIsSaveButtonActive] = useState(false);
    const searchRef = useRef(null);

    useEffect(() => {
      handleCheckIfTheresAnyAction();
    }, [selectedItems]);

    useEffect(() => {
      setTableData(kpis);
    }, [kpis]);

    useEffect(() => {
      if (selectedRegra && selectedRegra.codregra) {
        refetchKpis();
        getAllKpiOnCampanhas(codEmpresa, selectedRegra.codregra).then((res) => {
          if (res.data) {
            const selectedKpis = [];
            for (let i = 0; i < kpis.length; i++) {
              for (let j = 0; j < campanhaKpis.length; j++) {
                if (campanhaKpis[j].codigorestricao == kpis[i].codigorestricao) {
                  selectedKpis.push(kpis[i]);
                }
              }
            }
            setSelectedItems(selectedKpis);
          }
        });
      }
    }, [selectedRegra, showNovaRestricaoModal]);

    useEffect(() => {
      if (showNovaRestricaoModal) {
        const itensToBeSelected = [];
        for (let i = 0; i < selectedRegra.regraRestricao.length; i++) {
          const newItem = selectedRegra.regraRestricao[i];
          newItem.dtaltera = new Date().toLocaleDateString().split('/').reverse().join('-');
          itensToBeSelected.push(newItem);
        }
        setSelectedItems(itensToBeSelected);
      }
    }, [showNovaRestricaoModal]);

    const handleTableDataFilter = () => {
      const searchText = searchRef.current ? searchRef.current.value.toString().toUpperCase() : '';
      let newTableData = kpis ?? [];
      if (searchText)
        newTableData = newTableData?.filter(
          (newData: any) =>
            newData.codigorestricao.toString().includes(searchText) ||
            newData.descricao.toString().toUpperCase().includes(searchText),
        );
      setTableData(newTableData);
    };

    const validateFormFields = () => {
      handleInsertOrEditRestricao();
    };

    const handleInsertOrEditRestricao = () => {
      const restricoesToInsertFiltered: any = selectedItems.filter(
        (item: any) => !(selectedRegra.regraRestricao ?? []).includes(item),
      );
      const restricoesToInsert = {
        codCampanha: selectedRegra.codregra,
        nroKpiList: restricoesToInsertFiltered.map((item: any) => {
          return item.nroKpi;
        }),
      };

      const restricoesToDelete: any = selectedRegra.regraRestricao.filter(
        (item: any) => !(selectedItems ?? []).includes(item),
      );
      const kpiToRemove = {
        codCampanha: selectedRegra.codregra,
        nroKpiList: restricoesToDelete.map((item: any) => {
          return item.nroKpi;
        }),
      };

      if (restricoesToInsert.nroKpiList.length > 0) {
        postNewKpiOnCampanha(restricoesToInsert)
          .then((res) => {
            if (res.succeeded) {
              handleGetRegrasComerciais();
              toast.success('Novo KPI vinculado com sucesso');
              setShowNovaRestricaoModal(false);
            }
          })
          .catch((err) => {
            toast.error('Falha inesperada ao vincular novo KPI');
            console.log('err :', err);
          });
      }

      if (kpiToRemove.nroKpiList.length > 0) {
        postDeleteKpiOnCampanha(kpiToRemove)
          .then((res) => {
            if (res.succeeded) {
              handleGetRegrasComerciais();
            }
          })
          .catch((err) => {
            toast.error('Falha inesperada ao remover vinculo KPI');
            console.log('err :', err);
          });
      }
    };

    const onSelectionChange = (e: { value: any[] }) => {
      setSelectedItems(e.value);
    };

    const rowSelectionTemplate = (rowData: any) => {
      return (
        <S.NovaRegraModalChecboxTemplate>
          <Checkbox
            checked={selectedItems.some((item) => item.id === rowData.id)}
            onChange={(e) => {
              let _selectedItems = [...selectedItems];
              if (e.checked) {
                _selectedItems.push(rowData);
              } else {
                _selectedItems = _selectedItems.filter((item) => item.id !== rowData.id);
              }
              setSelectedItems(_selectedItems);
            }}
            style={{
              marginLeft: '10px',
            }}
          />
        </S.NovaRegraModalChecboxTemplate>
      );
    };

    const handleCheckIfTheresAnyAction = () => {
      if (selectedRegra) {
        const restricoesToInsert = selectedItems.filter(
          (item: any) => !(selectedRegra.regraRestricao ?? []).includes(item),
        );
        const restricoesToEdit = selectedRegra.regraRestricao.filter((item: any) =>
          (selectedItems ?? []).includes(item),
        );
        const restricoesToDelete = selectedRegra.regraRestricao.filter(
          (item: any) => !(selectedItems ?? []).includes(item),
        );

        if (
          restricoesToInsert.length > 0 ||
          restricoesToDelete.length > 0 ||
          (JSON.stringify(selectedRegra.regraRestricao) != JSON.stringify(restricoesToEdit) &&
            restricoesToEdit.length > 0)
        ) {
          setIsSaveButtonActive(true);
        } else if (isSaveButtonActive) {
          setIsSaveButtonActive(false);
        }
      }
    };

    return (
      <Dialog
        header={
          selectedRegra
            ? `Gerenciar KPIs da regra - [${selectedRegra.codregra}] ${selectedRegra.nomeregra}`
            : 'Novo KPI'
        }
        visible={showNovaRestricaoModal}
        style={{ width: isWebScreen ? '50vw' : '90%' }}
        onHide={() => {
          setShowNovaRestricaoModal(false);
        }}
      >
        <S.NovaRegraModalFormBox>
          <S.NovaRegraModalFormRow>
            <S.NovaRegraModalFormInputs>
              <InputText
                label="Buscar"
                placeholder="Buscar pelo cód. ou desc."
                ref={searchRef}
                onChange={() => {
                  handleTableDataFilter();
                }}
                onKeyDown={(e) => {
                  if (e.key == 'Enter') {
                    handleTableDataFilter();
                  }
                }}
              />
            </S.NovaRegraModalFormInputs>

            <Button
              text="Salvar"
              color="green"
              disabled={!isSaveButtonActive}
              onClick={() => {
                isSaveButtonActive && validateFormFields();
              }}
            />
          </S.NovaRegraModalFormRow>

          <S.NovaRegraModalTable>
            <DataTable
              value={tableData ?? []}
              paginator={true}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rows={10}
              rowHover
              stripedRows
              scrollable
              scrollHeight="55dvh"
              style={{ minWidth: '100%', minHeight: '51dvh' }}
              emptyMessage={'Nenhum restrição desse tipo disponível'}
              selection={selectedItems}
              onSelectionChange={onSelectionChange}
              selectionMode="checkbox"
              size="small"
            >
              <Column
                selectionMode="multiple"
                body={rowSelectionTemplate}
                sortable
                align="left"
                style={{
                  maxWidth: '100px',
                }}
              />
              <Column field="codigorestricao" header="Código" sortable />
              <Column field="descricao" header="Descrição" sortable />
            </DataTable>
          </S.NovaRegraModalTable>
        </S.NovaRegraModalFormBox>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'regrasComerciais/components/novoKpiModal/novoKpiModal.index.tsx');
  }
}
