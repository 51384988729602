import { UseAddCompany } from 'hooks/useCompanies/useAddCompany';
import * as S from './styles';
import { Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import { OPTIONS_ACTIVE_INATIVE } from 'enums/selectOptions';
import { useErps } from 'client/hooks';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';

export const AddCompany = () => {
  try {
    const { onSubmit, handleSubmit, control, setValue } = UseAddCompany();

    useEffect(() => {
      trackGPageView('/empresas/adicionar');
      alterPageTitle('Adicionar Empresas');
    }, []);

    const navigate = useNavigate();
    const { data: erpsList, isLoading, error } = useErps();

    return (
      <S.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.RowButtons>
            <Button
              text="Salvar"
              icon={<icons.Check />}
              type="submit"
              color="green"
              tooltip="Salvar nova empresa"
              tooltipPosition="left"
            />
            <Button
              text="Cancelar"
              icon={<icons.X />}
              type="submit"
              color="red"
              onClick={() => {
                navigate('/empresas');
              }}
              tooltip="Cancelar cadastro de nova empresa"
              tooltipPosition="left"
            />
          </S.RowButtons>

          <S.ContainerBox>
            <S.ContainerTitle>
              <h3>Dados da Empresa</h3>
            </S.ContainerTitle>
            <S.ContainerRow>
              <Controller
                name="codEmpresa"
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => (
                  <S.Box style={{ width: '30%' }}>
                    <label>Código Empresa*</label>
                    <InputText max={6} {...field} placeholder="000000" />
                  </S.Box>
                )}
              />

              <Controller
                name="cnpj"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => {
                    const cleanedValue = value.replace(/\D/g, '');
                    return cleanedValue.length === 14 || 'CNPJ deve ter 14 dígitos';
                  },
                }}
                render={({ field, fieldState }) => (
                  <S.Box style={{ width: '30%' }}>
                    <label>CNPJ*</label>
                    <InputMask
                      {...field}
                      placeholder="XX.XXX.XXX/0001-XX"
                      style={{
                        padding: '6.5px',
                        border: '1px solid #ced4da ',
                        borderRadius: '3px',
                      }}
                      mask="99.999.999/9999-99"
                      name="cnpj"
                    />
                    {fieldState.error && <label style={{ color: 'red' }}>{fieldState.error.message}</label>}
                  </S.Box>
                )}
              />
              <Controller
                name="ativo"
                rules={{
                  required: true,
                }}
                control={control}
                defaultValue="S"
                render={({ field }) => (
                  <S.Box style={{ width: '30%' }}>
                    <label>Ativa/Inativa*</label>
                    <Dropdown
                      placeholder="Ativa/Inativa "
                      value={field.value}
                      options={OPTIONS_ACTIVE_INATIVE}
                      onChange={(e) => setValue('ativo', e.value)}
                      disabled
                    />
                  </S.Box>
                )}
              />
            </S.ContainerRow>

            <S.ContainerRow>
              <Controller
                name="razaosocial"
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => (
                  <S.Box style={{ width: '30%' }}>
                    <label>Razão Social*</label>
                    <InputText {...field} placeholder="Digite Razão Social..." />
                  </S.Box>
                )}
              />
              <Controller
                name="fantasia"
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => (
                  <S.Box style={{ width: '30%' }}>
                    <label>Nome Fantasia*</label>
                    <InputText {...field} placeholder="Digite Nome Fantasia..." />
                  </S.Box>
                )}
              />
            </S.ContainerRow>
          </S.ContainerBox>

          <S.ContainerBox>
            <S.ContainerTitle>
              <h3>Configurações</h3>
            </S.ContainerTitle>
            <S.ContainerRow>
              <Controller
                name="codSisERP"
                rules={{
                  required: false,
                }}
                control={control}
                render={({ field }) => (
                  <S.Box style={{ width: '30%' }}>
                    <label>Sistema ERP</label>
                    <Dropdown
                      placeholder="Selecione um Sistema ERP"
                      value={field.value}
                      options={erpsList}
                      onChange={(e) => setValue('codSisERP', e.value)}
                      itemTemplate={(erp) => (erp ? `[${erp.codSisErp}] ${erp.nomeErp}` : 'Selecione um Sistema ERP')}
                      valueTemplate={(erp) => {
                        const actualValue = erpsList?.filter((erp) => +erp.codSisErp == +field.value)[0];
                        return actualValue
                          ? `[${actualValue.codSisErp}] ${actualValue.nomeErp}`
                          : 'Selecione um Sistema ERP';
                      }}
                      optionValue="codSisErp"
                      optionLabel="nomeErp"
                    />
                  </S.Box>
                )}
              />
            </S.ContainerRow>

            <S.Separator />

            <S.ContainerColumn>
              <S.ContainerTitle>
                <p>URLS Servidor</p>
              </S.ContainerTitle>

              <S.ContainerRow>
                <Controller
                  name="urlServFV"
                  rules={{
                    required: false,
                  }}
                  control={control}
                  render={({ field }) => (
                    <S.Box style={{ width: '30%' }}>
                      <label>URL Servidor FV</label>
                      <InputText {...field} placeholder="www.servidorFV.com.br" />
                    </S.Box>
                  )}
                />

                <Controller
                  name="urlServGerencial"
                  rules={{
                    required: false,
                  }}
                  control={control}
                  render={({ field }) => (
                    <S.Box style={{ width: '30%' }}>
                      <label>URL Servidor Gerencial</label>
                      <InputText {...field} placeholder="www.servidorGerencial.com.br" />
                    </S.Box>
                  )}
                />

                <Controller
                  name="urlServRelatorio"
                  rules={{
                    required: false,
                  }}
                  control={control}
                  render={({ field }) => (
                    <S.Box style={{ width: '30%' }}>
                      <label>URL Servidor Relatório</label>
                      <InputText {...field} placeholder="www.servidorRelatório.com.br" />
                    </S.Box>
                  )}
                />
              </S.ContainerRow>
              <S.ContainerRow>
                <Controller
                  name="urlservApiClient"
                  rules={{
                    required: false,
                  }}
                  control={control}
                  render={({ field }) => (
                    <S.Box style={{ width: '30%' }}>
                      <label>URL Servidor API Cliente</label>
                      <InputText {...field} placeholder="www.servidorApiCliente.com.br" />
                    </S.Box>
                  )}
                />
              </S.ContainerRow>
            </S.ContainerColumn>

            <S.Separator />

            <S.ContainerColumn>
              <S.ContainerTitle>
                <p>Banco de Dados</p>
              </S.ContainerTitle>
              <S.ContainerRow>
                <Controller
                  name="bdNome"
                  rules={{
                    required: false,
                  }}
                  control={control}
                  render={({ field }) => (
                    <S.Box style={{ width: '30%' }}>
                      <label>BD Nome</label>
                      <InputText {...field} placeholder="BD Nome" />
                    </S.Box>
                  )}
                />

                <Controller
                  name="bdUSU"
                  rules={{
                    required: false,
                  }}
                  control={control}
                  render={({ field }) => (
                    <S.Box style={{ width: '30%' }}>
                      <label>BD USU</label>
                      <InputText {...field} placeholder="BD USU" />
                    </S.Box>
                  )}
                />

                <Controller
                  name="bdPSW"
                  rules={{
                    required: false,
                  }}
                  control={control}
                  render={({ field }) => (
                    <S.Box style={{ width: '30%' }}>
                      <label>BD PSW</label>
                      <InputText {...field} placeholder="BD PSW" />
                    </S.Box>
                  )}
                />
              </S.ContainerRow>
            </S.ContainerColumn>

            <S.ContainerRow>
              <Controller
                name="bdSGBD"
                rules={{
                  required: false,
                }}
                control={control}
                render={({ field }) => (
                  <S.Box style={{ width: '30%' }}>
                    <label>BD SGBD</label>
                    <InputText {...field} placeholder="BD SGBD" />
                  </S.Box>
                )}
              />

              <Controller
                name="bdHost"
                rules={{
                  required: false,
                }}
                control={control}
                render={({ field }) => (
                  <S.Box style={{ width: '30%' }}>
                    <label>BD HOST</label>
                    <InputText {...field} placeholder="BD HOST" />
                  </S.Box>
                )}
              />
            </S.ContainerRow>
          </S.ContainerBox>
        </form>
      </S.Body>
    );
  } catch (err) {
    trackGCatchError(err, 'companies/AddCompany/index.tsx');
  }
};
