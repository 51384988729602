import { trackGCatchError, trackGPageView } from 'utils/analytics';
import * as S from './paramGeralConfig.styles';
import { useEffect, useState } from 'react';
import { alterPageTitle } from 'utils/alterPageTitle';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnEditorOptions, ColumnEventParams } from 'primereact/column';
import { useParamsGeral } from 'modules/parametrosGeral/paramGeral.context';
import InputText from 'components/Inputs/InputText/text.input';
import { ParamsGeralCabProps } from 'modules/parametrosGeral/paramGeral.types';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import MultiSelect from 'components/Multiselect/multiselect';
import NovoParamGeral from 'modules/parametrosGeral/components/NovoParamGeral/novoParamGeral.index';
import { useUser } from 'client/hooks';
import { putParametrosGeralCab } from 'client/api';
import { toast } from 'react-toastify';
import { useLoaderEffect } from 'providers/loaderEffect';

export default function ParamGeralConfig() {
  const { paramsGeralCab, setParamsGeralCab, codParCabList, handleGetCabParamsGeralList } = useParamsGeral();
  const { data: user } = useUser();
  const { setLoader } = useLoaderEffect();
  try {
    const [searchText, setSearchText] = useState('');

    const [toEditParamCabList, setToEditParamCabList] = useState<ParamsGeralCabProps[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [selectedCodParList, setSlectedCodParList] = useState([]);

    const [showNovoParamModal, setShowNovoParamModal] = useState(false);

    useEffect(() => {
      trackGPageView(`/parametros/geral/lista`);
      alterPageTitle('Parâmetros Gerais');
    }, []);

    const handleFilterParamItemList = () => {
      const searchByText = searchText
        ? paramsGeralCab.unfiltered?.filter(
            (item) =>
              item.descricao.toString().toUpperCase().includes(searchText.toUpperCase()) ||
              item.valorPadrao.toString().toUpperCase().includes(searchText.toUpperCase()),
          )
        : paramsGeralCab.unfiltered;

      const searchByCodPar =
        selectedCodParList && selectedCodParList.length > 0
          ? searchByText?.filter((item) => selectedCodParList.some((codpar) => +codpar.codpar == +item.codpar))
          : searchByText;

      setParamsGeralCab((prev) => ({
        ...prev,
        filtered: searchByCodPar,
      }));
    };

    useEffect(() => {
      handleFilterParamItemList();

      setLoader({
        show: false,
        text: `Por favor aguarde...`,
      });
    }, [paramsGeralCab.unfiltered, searchText, selectedCodParList]);

    const stringEditor = (options: ColumnEditorOptions) => {
      return (
        <S.ParamGeralConfigInputText
          width="100%"
          value={options.value ?? ''}
          onChange={(e) => options.editorCallback?.(e)}
        />
      );
    };

    const handleEditComplete = (options: ColumnEventParams) => {
      const { newValue, rowData, field } = options;
      rowData[field] = newValue;

      if (toEditParamCabList?.some((item) => +item.codpar == +rowData.codpar)) {
        const toEditParamOnTheEditArray = toEditParamCabList?.filter((item) => +item.codpar == +rowData.codpar);

        if (toEditParamOnTheEditArray[0] && toEditParamOnTheEditArray[0][field] == newValue) {
          setToEditParamCabList((prev: ParamsGeralCabProps[]) => {
            return prev?.filter((item) => +item.codpar != +rowData.codpar);
          });
        } else {
          setToEditParamCabList((prev: ParamsGeralCabProps[]) => {
            return prev?.map((item) => {
              if (+item.codpar == +rowData.codpar) {
                return rowData;
              } else {
                return item;
              }
            });
          });
        }
      } else {
        setToEditParamCabList((prev: ParamsGeralCabProps[]) => {
          return [...prev, ...[rowData]];
        });
      }
    };

    const handleEditSave = async () => {
      setIsLoading(true);
      const newToEditParamCabList = [];
      for (let i = 0; i < toEditParamCabList.length; i++) {
        const toEditParam = toEditParamCabList[i];
        toEditParam.dtAltera = new Date().toISOString();
        toEditParam.codFuncAltera = user.codUsuario.toString();
        const updatedParam = await putParametrosGeralCab(toEditParam);
        if (!updatedParam.succeeded) {
          toast.error(`Falha ao editar parâmetro [${toEditParam.codpar}] ${toEditParam.descricao}`);
          newToEditParamCabList.push(toEditParam);
        }
      }
      handleGetCabParamsGeralList();
      setToEditParamCabList(newToEditParamCabList);
      setIsLoading(false);
      setLoader({
        show: true,
        text: `Por favor aguarde, atualizando lista de parâmetros...`,
      });
    };

    return (
      <S.ParamGeralConfigMainBox>
        <S.ParamGeralConfigHeaderBox>
          <InputText
            label="Buscar"
            placeholder="Buscar por DESCRIÇÃO ou VALOR PADRÃO"
            onChange={(e) => {
              setSearchText(e);
            }}
            width="270px"
          />
          <MultiSelect
            value={selectedCodParList}
            onChange={(e) => {
              setSlectedCodParList(e);
            }}
            options={codParCabList}
            optionLabel="label"
            filter
            itemTemplate={(codpar) => `${codpar.codpar}`}
            placeholder="CODPAR"
            emptyFilterMessage="Nenhum dado encontrado"
            label={'Cód. Param'}
            showClear
            width="100px"
          />

          <Button
            text="Novo"
            color="green"
            icon={<icons.Plus />}
            tooltip="Cadastrar Novo Parâmetro"
            onClick={() => setShowNovoParamModal(true)}
          />

          <Button
            text="Salvar"
            color="green"
            icon={<icons.Check />}
            disabled={toEditParamCabList.length == 0}
            loading={isLoading}
            tooltip={
              toEditParamCabList.length == 0
                ? 'Nenhum registro alterado'
                : `Salvar alterações em ${toEditParamCabList.length} registros`
            }
            onClick={() => {
              !isLoading && handleEditSave();
            }}
          />
        </S.ParamGeralConfigHeaderBox>

        <S.ParamGeralConfigTableBox>
          <DataTable
            value={paramsGeralCab.filtered ?? []}
            paginator={(paramsGeralCab.filtered ?? []).length > 7}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rows={100}
            rowHover
            stripedRows
            size="small"
            responsiveLayout="scroll"
          >
            <Column
              field="codpar"
              header="Cód. Param"
              sortable
              style={{
                maxWidth: '10rem',
              }}
            />
            <Column
              field="descricao"
              header="Descrição"
              sortable
              style={{
                maxWidth: '42rem',
              }}
              editor={stringEditor}
              onCellEditComplete={handleEditComplete}
            />
            <Column
              field="valorPadrao"
              header="Valor Padrão"
              sortable
              editor={stringEditor}
              onCellEditComplete={handleEditComplete}
            />
          </DataTable>
        </S.ParamGeralConfigTableBox>
        <NovoParamGeral showNovoParamModal={showNovoParamModal} setShowNovoParamModal={setShowNovoParamModal} />
      </S.ParamGeralConfigMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'parametrosGeral/paramGeralConfig/paramGeralConfig.index.tsx');
  }
}
