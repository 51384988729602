import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { useFotos } from 'client/hooks';
import { FotoProduto } from 'client/interfaces';
import { toLocalizedDatetime } from 'utils/datetime';
import * as S from './FotosProdutos.styles';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { BASE_URL } from 'services/api';
import { Image } from 'primereact/image';
import NewFotoModal from './components/newFotoModal/newFotoModal.index';
import InputText from 'components/Inputs/InputText/text.input';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import { useLoaderEffect } from 'providers/loaderEffect';

export default function FotosProdutos() {
  try {
    useEffect(() => {
      trackGPageView('/foto-produto');
      alterPageTitle('Fotos Produtos');
      setLoaderHeader(true);
    }, []);

    const { setLoaderHeader } = useLoaderEffect();

    const { data: fotos, isLoading, refetch } = useFotos();

    const [showNewFotoModal, setShowNewFotoModal] = useState(false);

    const [searchText, setSearchText] = useState<string>('');

    const openNew = () => {
      setShowNewFotoModal(true);
    };
    useEffect(() => {
      setLoaderHeader(false);
    }, [fotos]);

    const fotoTemplate = (rowData: any) => {
      return (
        <S.FotosImageBox>
          <Image
            src={`${BASE_URL}/foto_produtos/${rowData.fotoType === 0 ? '' : `${rowData.codEmpresa}%23`}${
              rowData.arquivo
            }`}
            alt={rowData.arquivo}
            width="100"
            preview
          />
        </S.FotosImageBox>
      );
    };

    return (
      <S.FotosPageMainBox>
        <S.FotosPageHeader>
          <InputText
            label="Buscar"
            placeholder="Buscar foto"
            width="200px"
            onChange={(text) => {
              setSearchText(text.toUpperCase());
            }}
          />
          <Button text="Adicionar" icon={<icons.Plus />} color="green" onClick={openNew} />
        </S.FotosPageHeader>

        <S.ContainerTable mobile tablet>
          <DataTable
            value={
              searchText
                ? fotos?.filter((foto) => foto.nome.toUpperCase().includes(searchText.toUpperCase()))
                : fotos ?? []
            }
            dataKey="codProd"
            paginator
            rows={10}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50]}
            rowHover
            filterDisplay="menu"
            loading={isLoading}
            emptyMessage="Nenhuma dado encontrado."
            currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} registros"
            size="small"
          >
            <Column field="codProd" header="Foto" body={fotoTemplate} alignHeader="center" />
            <Column field="codProd" header="Código" sortable />
            <Column field="nome" header="Nome" sortable />
            <Column
              field="dtAlteracao"
              header="Data Alteração"
              sortable
              dataType="date"
              body={(rowData: FotoProduto) =>
                rowData.dtAlteracao != null ? toLocalizedDatetime(rowData.dtAlteracao) : '#'
              }
            />
            <Column
              field="dtCadastro"
              header="Data Cadastro"
              sortable
              dataType="date"
              body={(rowData: FotoProduto) => toLocalizedDatetime(rowData.dtCadastro)}
            />
            <Column
              field="fotoType"
              header="Tipo"
              sortable
              dataType="numeric"
              body={(rowData: FotoProduto) => (rowData.fotoType === 0 ? 'EAN' : 'Específico')}
            />
          </DataTable>
        </S.ContainerTable>

        {showNewFotoModal && (
          <NewFotoModal
            isOpen={showNewFotoModal}
            onClose={() => {
              setShowNewFotoModal(false);
              refetch();
            }}
          />
        )}
      </S.FotosPageMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'cadastro/FotosProdutos.index.tsx');
  }
}
