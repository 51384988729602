import styled from 'styled-components';

export const NovaRegraModalFormBox = styled.section`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 25px;
  width: 100%;
`;

export const NovaRegraModalFormRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;
`;

export const NovaRegraModalFormFooter = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 25px;
`;

export const RegraInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  input::focus {
    outline: none;
    border: none;
  }
`;
