import { Container } from 'components/Containers';
import styled from 'styled-components';

export const NewReportMainBox = styled(Container)``;

export const NewReportContentBox = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;
  padding: 20px;
  border-radius: 7.5px;
  border: 1px solid #cccccc70;
  background-color: #fff;
  width: auto;
  position: sticky;
  top: 20px;
  left: 0px;
  z-index: 9;
`;

export const NewReportRowBox1 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: last baseline;
  justify-content: flex-start;

  @media (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;

export const NewReportRowBox2 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: 1080px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
`;

export const NewReportInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const NewReportScriptInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 60%;
  max-height: 68dvh;

  @media (max-width: 1080px) {
    width: 100%;
  }
`;

export const NewReportParamsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 30dvw;
  max-height: 65dvh;
  border: 1px solid #ced4da;
  border-radius: 3px;

  @media (max-width: 1080px) {
    width: 85svw;
    overflow: scroll;
  }

  .p-align-left {
    padding: 5px 7.5px !important;
  }
  .p-align-center {
    padding: 5px 7.5px !important;
  }
`;

export const NewReportParamsInputBox = styled.div`
  .p-inputtext {
    max-width: 85px !important;
  }
`;
