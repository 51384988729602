import { Container } from 'reactivus';
import styled from 'styled-components';

export const RegrasTableMainBox = styled.section`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const RegrasAccordionTitle = styled(Container)`
  gap: 10px;
  flex-wrap: nowrap;
  overflow: scroll;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  span {
    white-space: nowrap;
  }
`;

export const RegrasBodyBox = styled.section`
  width: 100%;
  border-radius: 7.5px;
  overflow: hidden;
  border: 1px solid #cccccc70;
  background-color: #fff;

  .p-datatable .selected-table-row {
    color: #2e86c1 !important;
    background-color: #2e86c110 !important;
    cursor: pointer !important;
    border-bottom: 1px solid #60606020;
  }
  .selected-table-row:hover {
    background-color: #2e86c130 !important;
  }

  .p-datatable .expirada-table-row {
    color: #cb2a29 !important;
    background-color: #cb2a2910 !important;
    cursor: pointer !important;
    border-bottom: 1px solid #60606020;
  }
  .expirada-table-row:hover {
    background-color: #cb2a2930 !important;
  }

  .p-datatable .vigente-table-row {
    color: #3f7d54 !important;
    background-color: #3f7d5410 !important;
    cursor: pointer !important;
    border-bottom: 1px solid #60606020;
  }
  .vigente-table-row:hover {
    background-color: #3f7d5430 !important;
  }
  .p-datatable .header-center {
    text-align: center !important;
  }
`;

export const RegrasTableIcon = styled.section`
  font-size: 1.5rem;
  padding-right: 10px;
  text-align: center !important;
  svg {
    color: #606060;
    font-size: 1.5rem;
  }

  img {
    max-width: 50px;
    max-height: 50px;
    margin: 0 auto;
  }
`;

export const RegrasFuncTemplate = styled.div`
  text-align: left !important;
`;
