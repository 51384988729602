import { Dropdown as PRDropdown, DropdownProps } from 'primereact/dropdown';
import { useState } from 'react';

import * as SD from 'styles/inputs.styles';

export default function Dropdown({
  onChange,
  width,
  label = '',
  placeholder = '',
  ...rest
}: {
  onChange?: (data: string) => void;
  width?: string;
  label?: string;
  placeholder?: string;
} & DropdownProps) {
  const [selectedValue, setSelectedValue] = useState<string>(null);
  const widthCalculated = +(label.length * 28) > 200 ? 200 : +(label.length * 28);
  const handleChange = (value: any) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <SD.InputMainBox width={width} widthCalculated={widthCalculated}>
      <label>{label}</label>
      <PRDropdown
        value={selectedValue}
        onChange={(e) => {
          handleChange(e);
        }}
        placeholder={placeholder}
        {...rest}
      />
    </SD.InputMainBox>
  );
}
