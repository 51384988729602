import * as S from './styles';
import { useEffect } from 'react';
import { trackGPageView } from 'utils/analytics';
import { alterPageTitle } from 'utils/alterPageTitle';

export default function AgendaFeriados() {
  useEffect(() => {
    trackGPageView('/cadastro/agenda-feriados');
    alterPageTitle('Agenda Feriados');
  }, []);

  return <S.AgendaMainBox></S.AgendaMainBox>;
}
