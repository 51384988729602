import { useEffect } from 'react';
import * as S from './verbasList.styles';
import { useVerbas } from 'modules/verbas/verbas.context';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import { VerbasFilterProps, VerbasFilterStatusProps } from 'modules/verbas/verbas.types';
import VerbasTable from 'modules/verbas/components/verbasTable/verbasTable.index';
import SupervTable from 'modules/verbas/components/supervTable/supervTable.index';
import VendedorTable from 'modules/verbas/components/vendedorTable/vendedorTable.index';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { alterPageTitle } from 'utils/alterPageTitle';
import InputText from 'components/Inputs/InputText/text.input';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import Dropdown from 'components/Dropdown/dropdown';

export default function VerbasList() {
  try {
    const { verbasFilter, setVerbasFilter, statusFilterOptions } = useVerbas();

    const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();

    useEffect(() => {
      trackGPageView('/verbas');
      alterPageTitle('Verbas');
    }, []);

    return (
      <S.VerbasMainBox>
        <S.VerbasHeaderMainBox>
          <S.VerbasHeaderRow>
            <SelectEmpresa />
            <InputText
              label="Verba"
              id="rule"
              placeholder="Buscar pelo código ou nome da verba"
              value={verbasFilter.text}
              onChange={(e) => {
                e = e.toUpperCase();
                setVerbasFilter((prevState: VerbasFilterProps) => {
                  return {
                    ...prevState,
                    text: e,
                  };
                });
              }}
              width="clamp(200px, 250px, 300px)"
            />
            <S.VerbasInputBox>
              <label htmlFor="report">Listar Verbas</label>
              <Dropdown
                value={
                  statusFilterOptions.filter(
                    (options: VerbasFilterStatusProps) => verbasFilter.status == options.code,
                  )[0]
                }
                options={statusFilterOptions}
                emptyMessage="Nenhum Status :/"
                optionLabel={`name`}
                onChange={(e) => {
                  setVerbasFilter((prevState: VerbasFilterProps) => {
                    return {
                      ...prevState,
                      status: e.value.code,
                    };
                  });
                }}
              />
            </S.VerbasInputBox>

            <Button text="Verba" icon={<icons.Plus />} color="green" tooltip="Nova verba" />
          </S.VerbasHeaderRow>
        </S.VerbasHeaderMainBox>

        <VerbasTable codEmpr={selectedEmpresa.codEmpresa} />
        <S.VerbasContentBox>
          <S.VerbasContentItem>
            <SupervTable codEmpr={selectedEmpresa.codEmpresa} />
          </S.VerbasContentItem>

          <S.VerbasContentItem>
            <VendedorTable codEmpr={selectedEmpresa.codEmpresa} />
          </S.VerbasContentItem>
        </S.VerbasContentBox>
      </S.VerbasMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'verbas/verbasList/verbasList.index.tsx');
  }
}
