import { trackGCatchError } from 'utils/analytics';
import { FomularioAnswerProps } from '../../AnaliseForms.types';
import * as S from './formDoubleImageCards.styles';
import { Image } from 'primereact/image';

import { BASE_URL } from 'services/api';

export default function FormDoubleImageCards({
  dataList,
  dataList2,
}: {
  dataList: FomularioAnswerProps[];
  dataList2: FomularioAnswerProps[];
}) {
  try {
    const formatDate = (date: string | Date) => {
      if (!date) return 'N/A';
      return new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(date));
    };

    const ImageCard = ({
      imageData,
      imageData2,
    }: {
      imageData: FomularioAnswerProps;
      imageData2: FomularioAnswerProps;
    }) => {
      return (
        <S.FormImagesCards>
          <S.FormImagesCardsImages>
            <Image src={`${BASE_URL}/fotos_formulario/${imageData.resposta}`} alt="Image" preview />
            <Image src={`${BASE_URL}/fotos_formulario/${imageData2.resposta}`} alt="Image" preview />
          </S.FormImagesCardsImages>
          <S.FormImagesCardsTitle>
            <span>{formatDate(imageData.dtresposta)}</span>
            <span>{`[${imageData.codrca}] ${imageData.vendedor ? imageData.vendedor.toUpperCase() : ''}`}</span>
            <span>{`[${imageData.codcli}] ${imageData.cliente ? imageData.cliente.toUpperCase() : ''}`}</span>
          </S.FormImagesCardsTitle>
        </S.FormImagesCards>
      );
    };

    return (
      <S.FormImagesCardsMainBox>
        {dataList?.map((img: FomularioAnswerProps, index: number) => (
          <ImageCard imageData={img} imageData2={dataList2[index]} />
        ))}
      </S.FormImagesCardsMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'AnaliseFormularios/components/FormDoubleImageCards/formDoubleImageCards.index.tsx');
  }
}
