import { Header } from 'components/Containers';
import styled from 'styled-components';

export const RegrasHeaderMainBox = styled(Header)``;

export const RegrasHeaderRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
  gap: 25px;

  .r-accordion-header-box::-webkit-scrollbar {
    display: none !important;
  }
`;
