import * as S from './controleVisitas.styles';
import { useEffect, useState } from 'react';
import ControlHeader from './components/controleHeader/index';
import ControlSideBar from './components/controleSideBar';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'store';
import { userSelector } from 'modules/auth/reducer/auth';
import { useVisitControlPointsData, useVisitControlStatusData } from 'client/hooks/roteirizador';
import ControlMap from './components/controlMap/controlMap.index';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { alterPageTitle } from 'utils/alterPageTitle';
import { useLoaderEffect } from 'providers/loaderEffect';

const Buffer = require('buffer').Buffer;

export default function ControleVisitas() {
  const { setLoader } = useLoaderEffect();

  try {
    useEffect(() => {
      trackGPageView('/controle-visitas');
      alterPageTitle('Controle Visitas');
    }, []);

    const user = useAppSelector(userSelector);

    const { ControlMapContainer } = ControlMap();

    const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(false);

    const { control, watch, setValue } = useForm({
      defaultValues: {
        codCompany: user?.codEmpresa ?? 2072021,
        codUserByCompany: user?.codUsuario,
        codSupervisor: '0',
        codSeller: '0',
        filterGlobal: '',
      },
    });

    const values = watch();

    const { data: dataPoints, refetch: refetchDataPoints } = useVisitControlPointsData(
      String(values.codCompany) ?? '2072021',
      values.codSeller,
      values.codSupervisor,
      String(values.codUserByCompany) ?? '-1',
    );
    const { data: dataStatus, refetch: refetchDataStatus } = useVisitControlStatusData(
      String(values.codCompany) ?? '2072021',
      values.codSeller,
      values.codSupervisor,
    );

    const getVisitControlFullData = () => {
      refetchDataStatus();
      refetchDataPoints().finally(() => {
        setTimeout(() => {
          setLoader({
            show: false,
            text: 'Aguarde, buscando dados...',
          });
        }, 2500);
      });
    };

    useEffect(() => {
      setLoader({
        show: true,
        text: 'Aguarde, buscando dados...',
      });
      getVisitControlFullData();
    }, [values.codCompany, values.codSeller, values.codSupervisor]);

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('t');
      if (token && token.length > 0) {
        const decodedToken = Buffer.from(token, 'base64').toString('utf8');
        const tokenData = decodedToken.split('|');
        if (tokenData[0] && tokenData[1]) {
          setValue('codCompany', tokenData[0]);
          setValue('codUserByCompany', tokenData[1]);
        }
      }
    }, []);

    return (
      <S.MapMainBox toTop={isFullScreenEnabled}>
        <ControlMapContainer markers={dataPoints} />

        <ControlHeader
          setIsFullScreenEnabled={setIsFullScreenEnabled}
          isFullScreenEnabled={isFullScreenEnabled}
          getVisitControlFullData={getVisitControlFullData}
        />

        <ControlSideBar dataStatus={dataStatus} values={values} setValue={setValue} control={control} />
      </S.MapMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'ControleVisitas/controleVisitas.index.tsx');
  }
}
