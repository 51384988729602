import { BoxColumn, BoxRow } from 'components/Containers';
import styled from 'styled-components';

export const FatAndCobDataBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  padding: 10px 0px;
  flex: 0.2;
`;

export const FaturamentoField = styled(BoxColumn)`
  display: flex;
  align-items: left;
  padding: 1rem 1rem;
  position: relative;
  flex: 1;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 2px;
    font-size: 0.9rem;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: self-start;
    justify-content: space-between;
    gap: 2px;
  }

  .pedFatSearch {
    cursor: pointer;
    font-size: 1.2rem;
  }
  .pedFatSearch:hover {
    color: #000000;
    font-size: 1.3rem;
  }
`;

export const PedFatChart = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  font-size: 1.2rem;
  width: 100px;
  height: 50px;

  .salesChart {
    position: relative;
    bottom: 0 !important;
    height: 100% !important;
    max-width: 100%;
  }
`;

export const CredCobField = styled(BoxColumn)`
  align-items: left;
  padding: 1rem 0.5rem;
  position: relative;
  flex: 1;
`;

export const CredCobChart = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  font-size: 1.2rem;
  width: 50px;
  height: 50px;
  color: #1dd32c !important;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.8rem;
  }
`;

export const CredCobSearch = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  font-size: 1.2rem;

  &:hover {
    color: #000000;
    font-size: 1.3rem;
  }
`;

export const CredCobValues = styled.span`
  width: auto;
`;

export const CredCodbValuesList = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    display: flex;
    flex-direction: row;

    font-size: 0.9rem;
    gap: 5px;
  }
`;
