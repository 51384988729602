import * as S from './styles';

import { useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { editVisita } from 'client/api';
import { toast } from 'react-toastify';
import { useUser } from 'client/hooks';
import { trackGCatchError } from 'utils/analytics';
import { dialog } from 'reactivus';
import InputText from 'components/Inputs/InputText/text.input';
import Button from 'components/Button/button.index';

export default function AnaliseModal({ selectedCli, showModal, setShowModal, setSelectedCli, handleGetMainData }: any) {
  try {
    const [obsInfo, setObsInfo] = useState('');

    const { data: user } = useUser();

    const handleLiberar = (toUpdateDataObject: any) => {
      editVisita(toUpdateDataObject)
        .then((res: any) => {
          toast.success('Analise liberada!');
          setSelectedCli(null);
          handleGetMainData();
        })
        .catch((err: any) => {
          console.log('err :', err);
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          }
        });
    };

    const handleLiberarConfirmation = (updateCadastro: 'S' | 'N') => {
      if (obsInfo == '') {
        toast.error('Nenhuma observação informada!');
        return;
      }
      let updateDataObject = {
        Codempresa: selectedCli.codempresa,
        CodVendedor: selectedCli.codusur,
        Codcli: selectedCli.codcli,
        Status: 'L',
        Observacao: obsInfo + (updateCadastro == 'S' ? ' [Atu.Cadastro]' : ''),
        latitude: selectedCli.latitude ?? '0',
        longitude: selectedCli.longitude ?? '0',
        AtualizaCadastro: updateCadastro, // S / N
        Codfuncanalise: user.codUsuario,
        Nomefuncanalise: user.nome,
      };
      setShowModal(false);

      dialog
        .show({
          title: 'Tem certeza que deseja liberar esta localização ?',
          icon: 'question',
          showConfirmButton: true,
          confirmButtonText: 'Sim, liberar',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          showCloseButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            handleLiberar(updateDataObject);
          }
        });
    };

    return (
      <Dialog
        visible={showModal}
        style={{ width: '70vh' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={'Liberar Localização'}
        modal
        className="p-fluid"
        onHide={() => {
          setShowModal(false);
        }}
      >
        <S.AnaliseModalMainBox>
          <InputText label="Observação" value={obsInfo} onChange={(e) => setObsInfo(e)} width="100%" />

          <S.AnaliseModalButtonsBox>
            <Button text="Liberar" color="green" onClick={() => handleLiberarConfirmation('N')} />
            <Button
              text="Liberar + Atualizar Localização Cliente"
              color="blue"
              onClick={() => handleLiberarConfirmation('S')}
            />
            <Button text="Cancelar" color="red" onClick={() => setShowModal(false)} />
          </S.AnaliseModalButtonsBox>
        </S.AnaliseModalMainBox>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'AnaliseVisitas/components/analiseModal/index.tsx');
  }
}
