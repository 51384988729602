import { Container, Header } from 'components/Containers';
import styled from 'styled-components';

export const PedidoMainBox = styled(Container)`
  padding-top: 20px;
  height: 100%;
  align-items: stretch;
  gap: 5px;
`;

export const PedidoHeaderMainBox = styled(Header)`
  flex: 0.1;
`;
