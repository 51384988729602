import Dropdown from 'components/Dropdown/dropdown';
import { useState } from 'react';

type SelectedStatusProps = {
  value?: string;
  label?: string | 'S' | 'N' | 'T';
};

const UseSelectStatus = () => {
  const statusList: SelectedStatusProps[] = [
    {
      value: 'S',
      label: 'Ativo',
    },
    {
      value: 'N',
      label: 'Inativo',
    },
    {
      value: 'T',
      label: 'Todos',
    },
  ];

  const [selectedStatus, setSelectedStatus] = useState<string>(statusList[0].value);

  const SelectStatus = ({
    onChange,
    width,
    label = 'Status',
    placeholder = 'Selecione um Status',
    value = 'T',
  }: {
    onChange?: (stat: string) => void;
    width?: string;
    label?: string;
    placeholder?: string;
    value?: string;
  }) => {

    const handleChange = (selected: string) => {
      setSelectedStatus(selected);
      if (onChange) {
        onChange(selected);
      }
    };

    return (
      <Dropdown
        value={value ?? selectedStatus}
        onChange={(e) => {
          handleChange(e.value ?? e);
        }}
        options={statusList}
        optionLabel="label"
        label={label}
        filter
        placeholder={placeholder}
        width={width}
        emptyMessage="Nenhum dado encontrado"
        emptyFilterMessage="Nenhum dado encontrado"
      />
    );
  };

  return {
    SelectStatus,
    selectedStatus,
    setSelectedStatus,
    statusList,
  };
};

export default UseSelectStatus;
