import { PageTitle } from '_metronic/layout/core';
import { SqlForm, SqlResultContainer } from 'modules/vmaissql/components';

import { useEffect } from 'react';
import { trackGPageView } from 'utils/analytics';
import { alterPageTitle } from 'utils/alterPageTitle';

import * as S from './styles';

const SqlPage = () => {
  useEffect(() => {
    trackGPageView('/sql');
    alterPageTitle('SQL');
  }, []);

  return (
    <S.SqlMainBox>
      <PageTitle breadcrumbs={[]}>VMaisSQL</PageTitle>
      <SqlForm />
      <SqlResultContainer />
    </S.SqlMainBox>
  );
};

export default SqlPage;
