import styled from 'styled-components';

export const MarkerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 440px !important;
`;

export const MarkerHeader = styled.div`
  width: 100%;
  height: 40px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: left;

  p {
    color: #5b5d73;
    font-size: 15px;
    font-weight: 600;
  }
`;

export const MarkerBody = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const MarkerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 38px;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > p {
      color: #9497b8;
      font-weight: 400;
      font-size: 14px;
    }
  }
`;

export const MarkerPotencialRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 38px;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > p {
      color: #9497b8;
      font-weight: 400;
      font-size: 14px;
    }
  }
`;
export const MarkerRowButtons = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  padding-top: 10px;
  gap: 10px;

  span {
    flex-direction: row;
    display: flex;

    > p {
    }
  }
`;

export const Badge = styled.div`
  width: 29px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: 1px solid #bfc9df;
  margin-right: 10px;
  svg {
    font-size: 1.25rem;
  }
`;

export const BadgeSquare = styled.div`
  width: fit-content;
  padding: 5px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px solid #bfc9df;
  min-width: 320px;
  width: 100%;

  span {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }
`;

export const Description = styled.div`
  color: #a8aabc;
  font-size: 14px;
  max-width: 75%;
`;

export const Separator = styled.div`
  background: #f6f6f6;
  height: 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 4px;
`;

export const SetorVendaPotencialRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  width: 90%;
  margin: 0 auto;
`;

export const SetorVendaPotencialRowContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 32px;
  gap: 3px;
  & > p:nth-of-type(1) {
    font-weight: 500;
  }
  & > span > span {
    font-weight: 500;
  }
`;

export const PercBarBox = styled.div`
  display: flex;
  background-color: #60606025;
  width: clamp(40px, 50px, 100%);
  height: 0.35rem;
  border-radius: 7.5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const PercBar = styled.div<PercBarProps>`
  height: 100%;
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.color}B9;
  position: absolute;
`;

type PercBarTextProps = {
  color: string;
};

type PercBarProps = {
  width: number;
  color: string;
};

export const PercBarText = styled.div<PercBarTextProps>`
  position: absolute;
  right: 5px;
  top: 0px;
  color: ${(props) => props.color};
  font-size: 0.7rem !important;
  height: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: first baseline;
  text-align: center;
`;

export const PercentageBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  span {
    background-color: #60606010;
    text-align: center;
    padding: 2px;
    border-radius: 2px;
  }
`;
