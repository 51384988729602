import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { DataTable, DataTableExpandedRows } from 'primereact/datatable';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { deleteMenusWithSub, saveMenusWithSub } from 'client/api';
import { useMenuWithSub, useUser } from 'client/hooks';
import { MenuSubDto, MenuWithSub, MenuWithSubUpdate } from 'client/interfaces';
import { numberEditor, textEditor } from 'components/datatable';
import useMediaQuery from 'hooks/useMediaQuery';
import { useModalAddFunctionalityToCompany } from 'storesZustand/modalAddFunctionalityToCompany';

import ModalAddFunctionalityToCompany from './modalsMenu/ModalAddFunctionalityToCompany';
import * as S from './styles';
import SubItemMenu from './SubItemMenu';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { alterPageTitle } from 'utils/alterPageTitle';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import InputSwitch from 'components/Inputs/InputSwitch/switch.input';

const getNextCod = (data: MenuWithSub[]) => {
  let index = data.length;
  while (data.some((d) => d.codMenu === ++index));
  return index;
};

const validateData = (menus: MenuWithSub[]) => {
  const duplicateCods = menus.map((v) => v.codMenu).filter((v, i, vIds) => vIds.indexOf(v) !== i);

  if (duplicateCods.length) {
    throw new Error(`codMenu ${duplicateCods[0]} duplicado!`);
  }

  menus.forEach((menu) => {
    if (!menu.codMenu) {
      throw new Error(`codMenu do módulo ${menu.modulo} não pode ser vazio!`);
    }
    if (!menu.grupo) {
      throw new Error(`grupo do módulo ${menu.modulo} não pode ser vazio!`);
    }
    if (!menu.modulo) {
      throw new Error(`modulo do codMenu ${menu.codMenu} não pode ser vazio!`);
    }

    if (menu.menuSub.some((s) => !s.nomePermissao)) {
      throw new Error(`uma das permissões do menu ${menu.modulo} está vazio!`);
    }
  });
};

export interface IRowDataMenu {
  codMenu: number;
  codigo: number;
  grupo: string;
  id: number;
  modulo: string;
  nomeMenu: string;
  publico: boolean;
}

const ItensMenu = () => {
  const { data: user } = useUser();
  const { data, refetch, isFetching } = useMenuWithSub(user?.codEmpresa);
  try {
    const [menus, setMenus] = useState<MenuWithSubUpdate[]>(data?.map((d) => ({ ...d, id: d.codMenu })) ?? []);
    const menusToRemove = useRef<MenuWithSub[]>([]);
    const [isTouched, setIsTouched] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const isWebScreen = useMediaQuery('(min-width: 1060px)');
    const [expandedRows, setExpandedRows] = useState<any[] | DataTableExpandedRows | undefined>(undefined);

    useEffect(() => {
      trackGPageView('/itens-menu');
      alterPageTitle('Itens Menu');
    }, []);

    useEffect(() => {
      if (!isTouched) {
        setMenus(data?.map((d) => ({ ...d, id: d.codMenu })) ?? []);
      }
    }, [data]);

    const createNewSub = (codMenu: number) => {
      setIsTouched(true);
      const _menu: MenuWithSubUpdate[] = menus.map((m) =>
        m.codMenu === codMenu
          ? {
              ...m,
              menuSub: [
                ...m.menuSub,
                {
                  codMenuSub: (m.menuSub.length + 1) * -1,
                  codMenu,
                  codSubMenu: m.menuSub.length + 1,
                  nomePermissao: '',
                },
              ],
              id: m.codMenu,
              edited: true,
            }
          : m,
      );
      setMenus(_menu);
    };

    const publicoTemplate = (rowData: MenuWithSub) => {
      const [checked, setChecked] = useState(
        rowData && rowData.publico != undefined && rowData.publico.toString() == 'S' ? true : false,
      );
      useEffect(() => {
        if (rowData && rowData.publico != undefined) {
          setChecked(rowData && rowData.publico && rowData.publico.toString() == 'S' ? true : false);
        }
      }, [rowData]);

      const objectToCellEditComplete = {
        rowData: rowData,
        newValue: {},
        field: 'publico',
        originalEvent: {},
      };

      return (
        <InputSwitch
          checked={checked}
          onChange={(e) => {
            rowData.publico = e ? 'S' : 'N';
            setChecked(e);
            objectToCellEditComplete.newValue = e ? 'S' : 'N';
            objectToCellEditComplete.rowData = rowData;
            onCellEditComplete(objectToCellEditComplete);
          }}
        />
      );
    };

    const removeSubItem = (menuSub: MenuSubDto) => {
      setIsTouched(true);
      const _menu = menus.map((m) =>
        m.codMenu === menuSub.codMenu
          ? { ...m, menuSub: m.menuSub.filter((s) => s.codMenuSub !== menuSub.codMenuSub), edited: true }
          : m,
      );
      setMenus(_menu);
    };

    const updateSubMenu = (codMenu: number) => {
      const _menu = menus.map((m) => (m.codMenu === codMenu ? { ...m, edited: true } : m));
      setMenus(_menu);
      setIsTouched(true);
    };

    const rowExpansionTemplate = (data: MenuWithSub) => (
      <SubItemMenu menu={data} createNew={createNewSub} removeItem={removeSubItem} setEdited={updateSubMenu} />
    );

    const onCellEditComplete = (e: any) => {
      const { rowData, newValue, field, originalEvent: event } = e;
      setIsTouched(true);

      switch (field) {
        case 'publico':
        case 'codigo':
          rowData[field] = newValue;
          rowData.edited = true;
          break;
        case 'codMenu':
          if (newValue !== '') {
            rowData[field] = newValue;
            rowData.edited = true;
          } else event.preventDefault();
          break;
        default:
          if (newValue.trim().length > 0) {
            rowData[field] = newValue;
            rowData.edited = true;
          } else event.preventDefault();
          break;
      }
    };

    const expandAll = () => {
      const _expandedRows: DataTableExpandedRows = {};
      menus?.forEach((p) => (_expandedRows[`${p.codMenu}`] = true));

      setExpandedRows(_expandedRows);
    };

    const collapseAll = () => {
      setExpandedRows(undefined);
    };

    const createNew = () => {
      setIsTouched(true);
      const cod = getNextCod(menus);
      setMenus([
        ...menus,
        {
          codMenu: cod,
          id: cod,
          grupo: '',
          menuSub: [],
          modulo: '',
          nomeMenu: '',
          codigo: '',
          publico: 'N',
          edited: true,
        },
      ]);
    };

    const queryClient = useQueryClient();

    const saveChanges = async () => {
      try {
        setIsSaving(true);
        validateData(menus);
        if (menusToRemove.current.length > 0) {
          await deleteMenusWithSub(menusToRemove.current.map((m) => m.codMenu));
        }
        await saveMenusWithSub(menus.filter((m) => m.edited));
        queryClient.invalidateQueries({
          queryKey: ['menu-with-sub'],
        });
        toast.success('Menus salvos com sucesso!');
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toast.error(`Erro ao salvar menus: ${error.response?.statusText as string}`);
        }
        toast.error(`${error as string}`);
      } finally {
        setIsSaving(false);
      }
    };

    const cancelChanges = async () => {
      const result = (await refetch()).data;
      setMenus(result?.map((d) => ({ ...d, id: d.codMenu })) ?? []);
      setIsTouched(false);
      menusToRemove.current = [];
    };

    const Header = () => {
      if (isWebScreen) {
        return (
          <S.CadastroMenuHeader>
            <div>
              <Button
                text="Novo"
                color="green"
                icon={<icons.Plus />}
                tooltip="Adicionar novo menu"
                onClick={createNew}
              />
              <Button
                text="Expandir Tudo"
                color="green"
                icon={<icons.Plus />}
                tooltip="Expandir todas as linhas"
                onClick={expandAll}
                border
                bgOnlyOnHover
              />
              <Button
                text="Agrupar Tudo"
                color="green"
                icon={<icons.Dash />}
                tooltip="Agrupar todas as linhas"
                onClick={collapseAll}
                border
                bgOnlyOnHover
              />
            </div>
            <div>
              <Button
                text="Cancelar"
                color="red"
                disabled={!isTouched}
                icon={<icons.X />}
                tooltip="Cancelar alterações"
                onClick={() => cancelChanges()}
              />
              <Button
                text="Salvar"
                color="green"
                loading={isSaving}
                disabled={!isTouched}
                icon={<icons.Check />}
                tooltip="Salvar alterações"
                onClick={() => saveChanges()}
              />
            </div>
          </S.CadastroMenuHeader>
        );
      } else {
        return (
          <S.CadastroMenuHeader>
            <Button text="Novo" color="green" icon={<icons.Plus />} tooltip="Adicionar novo menu" onClick={createNew} />
            <Button
              text="Expandir Tudo"
              color="green"
              icon={<icons.Plus />}
              tooltip="Expandir todas as linhas"
              onClick={expandAll}
              border
              bgOnlyOnHover
            />
            <Button
              text="Agrupar Tudo"
              color="green"
              icon={<icons.Plus />}
              tooltip="Agrupar todas as linhas"
              onClick={collapseAll}
              border
              bgOnlyOnHover
            />
            <Button
              text="Cancelar"
              color="red"
              icon={<icons.X />}
              tooltip="Cancelar alterações"
              onClick={() => cancelChanges()}
            />
            <Button
              text="Salvar"
              color="green"
              icon={<icons.Check />}
              tooltip="Salvar alterações"
              onClick={() => saveChanges()}
            />
          </S.CadastroMenuHeader>
        );
      }
    };

    const removeItem = (data: MenuWithSub) => {
      setIsTouched(true);
      const _menu = menus.filter((m) => m.codMenu !== data.codMenu);
      setMenus(_menu);
      menusToRemove.current.push(data);
    };

    const confirmDeleteItem = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: MenuWithSub) => {
      confirmPopup({
        target: event.currentTarget,
        message: 'Tem certeza que deseja excluir esse menu e seus submenus?',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: () => removeItem(data),
      });
    };

    const deleteTemplate = (data: MenuWithSub) => (
      <Button
        type="button"
        color="red"
        bgOnlyOnHover
        textMode
        icon={<icons.Remove />}
        onClick={(e) => confirmDeleteItem(e, data)}
        rounded
      />
    );

    const [openModalAddFunctionalityMenu, setModalAddFunctionalityMenu] = useState(false);

    const [rowDataMenu, setRowDataMenu] = useState<IRowDataMenu>({
      codMenu: 0,
      id: 0,
      grupo: '',
      modulo: '',
      nomeMenu: '',
      codigo: 0,
      publico: false,
    });

    const { setStatus, setSelectedCompany } = useModalAddFunctionalityToCompany();

    function handleAddFunctionalityToCompany(rowData: IRowDataMenu) {
      setModalAddFunctionalityMenu(true);
      setRowDataMenu(rowData);
      setStatus('default');
      setSelectedCompany({
        codEmpresa: 0,
        cnpj: '',
        razaosocial: '',
        fantasia: '',
        caminhoLogo: '',
      });
    }

    const actionBodyTemplate = (handleAddFunctionalityToCompany: () => void) => {
      return (
        <Button
          type="button"
          color="green"
          bgOnlyOnHover
          textMode
          icon={<icons.LockOpen />}
          onClick={handleAddFunctionalityToCompany}
          rounded
        />
      );
    };

    return (
      <S.CadastroMenuMainBox>
        <Header />

        <S.CadastroMenuTableBox>
          <DataTable
            value={menus}
            loading={isFetching}
            sortMode="single"
            sortField="grupo"
            sortOrder={1}
            editMode="cell"
            dataKey="codMenu"
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
            onSelectionChange={(e) => setRowDataMenu(e.value)}
            size="small"
          >
            <Column expander style={{ width: '3rem' }} />
            <Column
              field="codMenu"
              header="Código"
              editor={numberEditor}
              sortable
              onCellEditComplete={onCellEditComplete}
              headerStyle={{ width: '3rem' }}
            />
            <Column
              field="grupo"
              header="Grupo Menu"
              editor={textEditor}
              onCellEditComplete={onCellEditComplete}
              sortable
            />
            <Column
              field="nomeMenu"
              header="Descrição Menu"
              editor={textEditor}
              onCellEditComplete={onCellEditComplete}
            />
            <Column
              field="modulo"
              header="Modulo"
              editor={textEditor}
              onCellEditComplete={onCellEditComplete}
              sortable
            />
            <Column field="to" header="Path" editor={textEditor} onCellEditComplete={onCellEditComplete} sortable />
            <Column
              field="codigo"
              header="Código Menu"
              editor={(options: ColumnEditorOptions) => numberEditor(options, { maxLength: 4 })}
              onCellEditComplete={onCellEditComplete}
            />
            <Column
              field="publico"
              header="Público"
              headerStyle={{ width: '3rem' }}
              onCellEditComplete={onCellEditComplete}
              body={publicoTemplate}
              sortable
            />
            <Column headerStyle={{ width: '3rem' }} body={deleteTemplate} />
            <Column
              headerStyle={{ width: '3rem' }}
              body={(rowData: IRowDataMenu) => actionBodyTemplate(() => handleAddFunctionalityToCompany(rowData))}
            />
          </DataTable>
        </S.CadastroMenuTableBox>
        <ConfirmPopup />
        <ModalAddFunctionalityToCompany
          setOpenModal={setModalAddFunctionalityMenu}
          openModal={openModalAddFunctionalityMenu}
          data={rowDataMenu}
        />
      </S.CadastroMenuMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'itensMenu/ItensMenu.tsx');
  }
};

export default ItensMenu;
