import * as S from './itensTable.styles';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RegraItemProps, RegraProps } from 'client/interfaces';
import { Accordion } from 'reactivus';
import { useState } from 'react';
import NovoItemModal from '../novoItemModal/novoItemModal.index';
import { postDeleteRegraItem } from 'client/api/regraComercial';
import { toast } from 'react-toastify';
import { regraItemTypeDescriptions } from '../../regraComercial.types';
import { trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import useMediaQuery from 'hooks/useMediaQuery';

type ItensTableProps = {
  selectedRegra: RegraProps;
  codEmpr: number;
  handleGetRegrasComerciais: () => void;
};

export default function ItensTable({ selectedRegra, codEmpr, handleGetRegrasComerciais }: ItensTableProps) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');
  try {
    const [showNovoItemModal, setShowNovoItemModal] = useState(false);

    const AccordionHeaderTemplate = () => {
      return (
        <S.AccordionHeaderTemplateBox>
          <span>Itens</span>
          <Button
            icon={<icons.Plus />}
            color={selectedRegra ? 'green' : 'black'}
            text="Adicionar Itens"
            size="sm"
            onClick={(e: any) => {
              e.stopPropagation();
              selectedRegra && setShowNovoItemModal(true);
            }}
            disabled={!selectedRegra}
            textMode
            tag
          />
        </S.AccordionHeaderTemplateBox>
      );
    };

    const removeRestricaoBodyTemplate = (rowData: RegraItemProps) => {
      return (
        <Button
          icon={<icons.RemoveFill />}
          color={'red'}
          onClick={(e: any) => {
            handleRemoveRegraRestricao(rowData);
          }}
          textMode
          size="sm"
          bgOnlyOnHover
          rounded
        />
      );
    };

    const handleRemoveRegraRestricao = (restricaoToRemove: RegraItemProps) => {
      postDeleteRegraItem([restricaoToRemove])
        .then((res) => {
          if (res.succeeded) {
            handleGetRegrasComerciais();
          }
        })
        .catch((err) => {
          toast.error('Falha inesperada ao remover restrição');
          console.log('err :', err);
        });
    };

    return (
      <Accordion title={'Itens'} alwaysOpen={isWebScreen} headerTemplate={<AccordionHeaderTemplate />}>
        <S.ItensTableMainBox>
          <S.ItensBodyBox>
            <DataTable
              value={selectedRegra ? selectedRegra.regraItems : []}
              paginator={false}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rows={100}
              rowHover
              stripedRows
              scrollable
              scrollHeight="57dvh"
              style={{ maxWidth: '100%', minHeight: '57dvh' }}
              emptyMessage={'Esta regra não possui itens'}
              size="small"
            >
              <Column
                field="tiporestricao"
                header="Tipo"
                sortable
                style={{
                  maxWidth: '100px',
                }}
                body={(rowData: RegraItemProps) => {
                  return regraItemTypeDescriptions[rowData.tiporestricao];
                }}
              />
              <Column
                field="codigorestricao"
                header="Cód."
                sortable
                style={{
                  maxWidth: '100px',
                }}
              />
              <Column
                field="descricao"
                header="Descrição"
                sortable
                style={{
                  minWidth: '50%',
                }}
              />
              <Column field="" header="Remover" body={removeRestricaoBodyTemplate} />
            </DataTable>
          </S.ItensBodyBox>
        </S.ItensTableMainBox>
        {showNovoItemModal && (
          <NovoItemModal
            codEmpresa={codEmpr}
            handleGetRegrasComerciais={handleGetRegrasComerciais}
            selectedRegra={selectedRegra}
            setShowNovoItemModal={setShowNovoItemModal}
            showNovoItemModal={showNovoItemModal}
          />
        )}
      </Accordion>
    );
  } catch (err) {
    trackGCatchError(err, 'regrasComerciais/components/itensTable/itensTable.index.tsx');
  }
}
