import { BoxColumn, BoxRow, Container, Header } from 'components/Containers';
import styled from 'styled-components';

export const Body = styled(Container)`
  h3 {
    margin-top: 10px;
  }

  p {
    font-weight: bold;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;
export const RowButtons = styled(Header)``;

export const ContainerBox = styled(BoxColumn)``;
export const ContainerTitle = styled(BoxRow)`
  width: 100%;
  padding: 0px;
  box-shadow: none;
`;

export const ContainerColumn = styled(BoxColumn)`
  width: 100%;
  padding: 0px;
  box-shadow: none;
`;

export const ContainerRow = styled(BoxRow)`
  width: 100%;
  padding: 0px;
  box-shadow: none;
  @media (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 10px;
`;
export const Separator = styled.div`
  border-bottom: 1px solid #cccccc70;
  width: 100%;
  margin: 10px;
`;
