import { processRouterData } from 'client/api';
import { trackGCatchError } from 'utils/analytics';

// EXPORTA FUNÇÃO POR PADRÃO
export async function postRouterData(codSetor: string, codVendedor: string, codEmpresa: string, pProceessaDia: number) {
  try {
    const toProcessData = {
      CodEmpresa: codEmpresa,
      codUsuario: codVendedor,
      CodSetor: codSetor,
      dataFimCalculo: new Date(),
      motivoExecutcao: 'Processado manualmente pelo roteirizador!',
      pProcessaDia: pProceessaDia,
    };

    return await processRouterData(toProcessData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  } catch (err) {
    trackGCatchError(err, 'Roteirizador/executaRoteiro.tsx');
  }
}
