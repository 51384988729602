import * as S from './regioes.styles';
import MapContainer from './components/mapContainer/mapContainer';
import { useMap } from './regioes.context';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import MapHeader from './components/mapHeader/mapheader.index';
import MapCliList from './components/mapCliList/mapCliList.index';
import MapMenu from './components/mapMenu/mapMenu.index';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { alterPageTitle } from 'utils/alterPageTitle';

export default function RegioesV2() {
  const { setParams } = useMap();
  try {
    useEffect(() => {
      trackGPageView('/mapa/regioes');
      alterPageTitle('Mapas / Regiões');
    }, []);

    const {
      codEmpresa: codEmpresaParam,
      codUser: codUser,
      codMapa: codMapaParam,
    } = useParams<{ codEmpresa: string; codUser: string; codMapa: string }>();

    useEffect(() => {
      setParams({
        codEmpresa: codEmpresaParam,
        codUser: codUser,
        codMapa: codMapaParam,
      });
    }, [codEmpresaParam, codUser, codMapaParam]);

    return (
      <S.MapMainBox>
        <MapHeader />
        <MapCliList />
        <MapMenu />
        <MapContainer />
      </S.MapMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'regioes.index.tsx');
  }
}
