import styled, { keyframes } from 'styled-components';

const loaderAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;
interface BoxProps {
  show: boolean;
}

export const Box = styled.div<BoxProps>`
  height: 100%;
  width: 100%;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
  pointer-events: none !important;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 9999999 !important;
  pointer-events: auto !important;

  img {
    width: 10rem;
    height: 10rem;
    pointer-events: none !important;
  }
`;

export const Text = styled.div`
  padding: 10px 20px 10px 10px;
  font-size: 1.5rem;
`;

export const BoxIndependent = styled.div<BoxProps>`
  height: 100%;
  width: 100%;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
  pointer-events: none !important;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99 !important;
  pointer-events: auto !important;

  img {
    width: 10rem;
    height: 10rem;
    pointer-events: none !important;
  }
`;

export const LoaderEffectHeader = styled.div<BoxProps>`
  width: 100%;
  height: 3px;
  position: absolute;
  top: 100%;
  overflow: hidden;
  opacity: ${(props) => (props.show ? '1' : '0')};
  transition: opacity 0.4s ease;

  &::before {
    content: '';
    width: 95%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(34, 143, 107, 0) 0%,
      rgba(34, 143, 107, 0.75) 50%,
      rgba(34, 143, 107, 0) 100%
    );
    animation: ${loaderAnimation} 1.25s infinite;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }
`;
