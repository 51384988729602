import { BoxTable, Container, Header } from 'components/Containers';
import styled from 'styled-components';

export const CadastroPontoMainBox = styled(Container)`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
`;

export const Box = styled.label`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
`;

export const CadastroPontoHeaderBox = styled(Header)`
  align-items: flex-end;
`;

type ICloseOption = {
  show: boolean;
};

export const SelectOptionCloseButton = styled.span<ICloseOption>`
  position: absolute;
  display: ${(props) => (props.show ? 'block' : 'none')};
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
  font-size: 1.25rem;
  transition: all 0.4s ease;

  &:hover {
    font-size: 1.45rem;
  }
`;

export const CadastroPontoTableBox = styled(BoxTable)`
  width: 100%;

  @media (max-width: 768px) {
    padding: 10px;
    padding-bottom: 40px;
  }
  @media (max-width: 992px) {
    padding: 10px;
    padding-bottom: 40px;
  }
`;
