import * as S from './styles';
import { useEffect, useState } from 'react';
import { useUser } from 'client/hooks';
import { toast } from 'react-toastify';
import { Buffer } from 'buffer';
import { trackGCatchError } from 'utils/analytics';
import InputText from 'components/Inputs/InputText/text.input';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';

type ReportParamProps = {
  codrel: number;
  ordem: number;
  obrigatorio: string;
  nome_param: string;
  legenda: string;
  componente: string;
  valor_padrao: string;
  itens: string;
  consulta: string;
  busca: string;
};

export default function ReportParamsModal({ report, handleOpenDesigner, setShowParamsModal }: any) {
  try {
    const { data: user } = useUser();

    const [formData, setFormData] = useState<any>({});

    useEffect(() => {
      if (report) {
        handleDefaultParamsData();
      }
    }, [report]);

    async function handleDefaultParamsData() {
      const relatorio = report;

      const newFormData = {};
      (relatorio.parametros ? relatorio.parametros : [])?.map((param: ReportParamProps) => {
        newFormData[param.nome_param] = param.valor_padrao ?? '';
      });
      setFormData(newFormData);
    }

    const handleParamInputRender = (param: ReportParamProps) => {
      return (
        <InputText
          key={param.ordem}
          label={`${param.legenda ?? param.nome_param} ${param.obrigatorio == 'S' ? '*' : ''}`}
          defaultValue={param.valor_padrao ?? ''}
          type="text"
          onChange={(e) => {
            setFormData((prev: any) => {
              prev[param.nome_param] = e;
              return {
                ...prev,
              };
            });
          }}
        />
      );
    };

    const isAnyMandatoryParamEmpty = () => {
      const mandatoryFields: ReportParamProps[] = report.parametros?.filter(
        (p: ReportParamProps) => p.obrigatorio == 'S',
      );
      if (mandatoryFields.length == 0) {
        return false;
      } else {
        let isAnyMandatoryFieldEmpty = false;
        for (let i = 0; i < mandatoryFields.length; i++) {
          if (!formData[mandatoryFields[i].nome_param] || formData[mandatoryFields[i].nome_param] == '') {
            isAnyMandatoryFieldEmpty = true;
          }
        }
        return isAnyMandatoryFieldEmpty;
      }
    };

    async function handleExecRel() {
      if (isAnyMandatoryParamEmpty()) {
        toast.warn('Preencha os campos obrigatórios *');
        return;
      }

      const reportDataToExecIndexes = Object.keys(formData);
      const reportDataToExec = [];
      for (let i = 0; i < reportDataToExecIndexes.length; i++) {
        reportDataToExec.push({
          nome_param: reportDataToExecIndexes[i],
          valor_parametro:
            formData[reportDataToExecIndexes[i]] != ''
              ? `'${formData[reportDataToExecIndexes[i]]}'`
              : formData[reportDataToExecIndexes[i]],
        });
      }
      reportDataToExec.push({
        nome_param: 'codempresa',
        valor_parametro: user.codEmpresa.toString() ?? '0',
      });
      const paramsToken = Buffer.from(JSON.stringify(reportDataToExec), 'utf-8').toString('base64');

      handleOpenDesigner(report, paramsToken);
      setShowParamsModal(false);
    }

    return (
      <S.ReportParamsModalBody>
        <S.ReportPlayerFormInputsBox>
          {report &&
            report.parametros &&
            report.parametros?.map((param: ReportParamProps) => handleParamInputRender(param))}
        </S.ReportPlayerFormInputsBox>

        <S.ReportPlayerFormButtonsBox>
          <Button
            text="Apurar"
            color="green"
            icon={<icons.Play />}
            onClick={() => {
              handleExecRel();
            }}
            tooltip="Apurar relatório"
          />
        </S.ReportPlayerFormButtonsBox>
      </S.ReportParamsModalBody>
    );
  } catch (err) {
    trackGCatchError(err, 'relatorios/components/reportParamsModal/reportParamsModal.tsx');
  }
}
