import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Tree, TreeFilterOptions, TreeNodeTemplateOptions, TreeSelectionParams } from 'primereact/tree';
import { AsideMenuItem } from './AsideMenuItem';
import TreeNode from 'primereact/treenode';
import { classNames } from 'primereact/utils';
import { useMenuUsuario, useUser } from 'client/hooks';
import { checkIsActive } from '_metronic/helpers';
import * as S from './styles';
import { Link, useLocation } from 'react-router-dom';
import { AiOutlineAppstore } from 'react-icons/ai';
import { CiLock } from 'react-icons/ci';
import { MdLogout, MdOutlineAdminPanelSettings } from 'react-icons/md';
import { LuFileSearch } from 'react-icons/lu';
import { IoLockClosedOutline, IoLogOut, IoMapOutline, IoSettingsOutline } from 'react-icons/io5';
import { FaRegSquarePlus } from 'react-icons/fa6';
import { FiShoppingBag } from 'react-icons/fi';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { CgToggleOff } from 'react-icons/cg';
import { trackGCatchError } from 'utils/analytics';
import useMediaQuery from 'hooks/useMediaQuery';
import { logout } from 'modules/auth/reducer/auth';
import { dialog } from 'reactivus';
import { useAppDispatch } from 'store';

interface MenuNode extends TreeNode {
  to?: string;
  codigo?: string;
}

export function AsideMenuMain({ ktMenuMinimized }) {
  try {
    const [expandedKeys, setExpandedKeys] = useState<{ [key: string]: boolean }>({ '0': true });
    const { data: menus, refetch: refetchMenus } = useMenuUsuario();
    const { data: user } = useUser();
    const [filterValue, setFilterValue] = useState('');
    const filterInputRef = useRef<HTMLInputElement | null>(null);
    const [showDashboardMenu, setShowDashBoardMenu] = useState(true);
    const dispatch = useAppDispatch();

    const isWebScreen = useMediaQuery('(min-width: 1060px)');

    useEffect(() => {
      refetchMenus();
    }, [user]);

    const modules = menus
      ? menus.map((item) => ({
          cod: item.codMenu,
          grupo: item.grupo,
          nome: item.nomeMenu,
          codigo: item.codigo || '',
          icon: '',
          element: null,
          to: item.to,
        }))
      : [];

    const uniqueGrupos: string[] = [];
    const grupoSet = new Set<string>();

    menus?.forEach((item) => {
      if (!grupoSet.has(item.grupo)) {
        grupoSet.add(item.grupo);
        uniqueGrupos.push(item.grupo);
      }
    });

    const generateEnum = (values: string[]): { [key: string]: string } => {
      return values.reduce((acc, value) => {
        acc[value] = value;
        return acc;
      }, {} as { [key: string]: string });
    };

    const DynamicGrupos = generateEnum(uniqueGrupos);

    type Grupos = keyof typeof DynamicGrupos;

    interface Module {
      cod: number;
      grupo?: Grupos;
      nome: string;
      codigo?: string;
      icon: string;
      element?: ReactNode;
      to: string;
    }

    const capitalizeWords = (input: string): string => {
      return input
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    };

    const Node = ({ node, options }: { node: MenuNode; options: TreeNodeTemplateOptions }) => {
      const { pathname } = useLocation();
      const isActive = checkIsActive(pathname, node.to);

      return (
        <S.MenuItemBox className={isActive ? 'active' : 'inactive'} ktMenuMinimized={ktMenuMinimized}>
          {node.children?.length ? (
            <span>{capitalizeWords(node.label)}</span>
          ) : (
            <AsideMenuItem
              ktMenuMinimized={ktMenuMinimized}
              to={node.to as string}
              title={capitalizeWords(node.label) as string}
              codigo={node.codigo as string}
            />
          )}
        </S.MenuItemBox>
      );
    };

    const nodeTemplate = (node: MenuNode, options: TreeNodeTemplateOptions) => {
      return <Node node={node} options={options} />;
    };

    const userModules = useMemo(
      () =>
        modules
          .filter((m) => menus?.some((menu) => menu.codMenu === m.cod))
          .reduce<{ [key in Grupos | 'default']: Module[] }>((a, b) => {
            const menu = menus?.find((menu) => menu.codMenu === b.cod);
            if (menu) {
              b.nome = menu.codigo ? `${menu.codigo} ${menu.nomeMenu}` : menu.nomeMenu;
              b.grupo = menu.grupo as any;
              b.codigo = menu.codigo ?? '';
            }
            a[b.grupo ?? 'default'] = a[b.grupo ?? 'default'] || [];
            a[b.grupo ?? 'default'].push(b);
            return a;
          }, {} as { [key in Grupos | 'default']: Module[] }),
      [menus],
    );

    const onNodeSelect = (e: TreeSelectionParams) => {
      const _expandedKeys = { ...expandedKeys };
      const key = e.value as string;
      if (_expandedKeys[key]) {
        delete _expandedKeys[key];
      } else {
        _expandedKeys[key] = true;
      }
      setExpandedKeys(_expandedKeys);
    };
    interface CustomTreeNode extends TreeNode {
      to?: string; // Optional navigation property
      command?: () => void; // Optional function property
      codigo?: string;
    }

    const nodes: CustomTreeNode[] = useMemo(
      () => [
        ...Object.keys(userModules).flatMap((key) =>
          key === 'default'
            ? userModules.default.map((module) => ({
                key: module.cod.toString(),
                label: module.nome,
                to: module.to,
                codigo: module.codigo,
              }))
            : {
                key,
                label: key,
                to: '',
                codigo: '',
                children: userModules[key as Grupos].map((module) => ({
                  key: module.cod.toString(),
                  label: module.nome,
                  to: module.to,
                  codigo: module.codigo,
                })),
              },
        ),
      ],
      [userModules],
    );

    const myFilterFunction = (event: any, options: TreeFilterOptions) => {
      const newFilterValue = event.target?.value;
      setFilterValue(newFilterValue);
      options.filter?.(event);
    };

    const filterTemplate = (options: any) => {
      const { filterOptions } = options;

      return (
        <>
          <S.MenuSearchBox className="flex  menu-title mb-2" style={{ margin: '0 1rem' }}>
            <span style={{ width: '100%' }} className="p-input-icon-right">
              <input
                style={{ width: '100%' }}
                value={filterValue}
                ref={filterInputRef}
                onChange={(e) => {
                  myFilterFunction(e, filterOptions);
                  setShowDashBoardMenu(
                    e.target.value.toUpperCase().includes('DASHBOARD') ? true : e.target.value == '' ? true : false,
                  );
                }}
                type="text"
                placeholder="Pesquisar"
              />
              <i className="pi pi-search" />
            </span>
          </S.MenuSearchBox>
          {showDashboardMenu && (
            <S.MenuItemDashBoard>
              <Link to={'/dashboard'}>
                <S.MenuItemBoxIcon ktMenuMinimized={ktMenuMinimized}>
                  <AiOutlineAppstore />
                </S.MenuItemBoxIcon>

                <S.MenuItemDashBoardTitle ktMenuMinimized={ktMenuMinimized}>Dahsboard</S.MenuItemDashBoardTitle>
              </Link>
            </S.MenuItemDashBoard>
          )}
        </>
      );
    };

    const togglerTemplate = (node, options) => {
      if (node.to) {
        return <p className="p-ghost-content"></p>;
      }

      const expanded = options.expanded;
      const iconClassName = classNames('p-tree-toggler-icon pi pi-fw', {
        'pi-angle-right': !expanded,
        'pi-angle-down': expanded,
      });

      const menuIcons = {
        Admin: () => {
          return <MdOutlineAdminPanelSettings />;
        },
        Cadastros: () => {
          return <FaRegSquarePlus />;
        },
        Acessos: () => {
          return <IoLockClosedOutline />;
        },
        Parâmetros: () => {
          return <IoSettingsOutline />;
        },
        Mapa: () => {
          return <CiLock />;
        },
        Pesquisa: () => {
          return <LuFileSearch />;
        },
        Roteiro: () => {
          return <IoMapOutline />;
        },
        Venda: () => {
          return <FiShoppingBag />;
        },
        Relatorios: () => {
          return <HiOutlineDocumentReport />;
        },
        'Regras Comerciais': () => {
          return <CgToggleOff />;
        },
        Sair: () => {
          return <CgToggleOff />;
        },
      };

      return (
        <S.MenuItemBoxIcon tabIndex={-1} onClick={options.onClick} ktMenuMinimized={ktMenuMinimized}>
          {menuIcons[node.key] ? menuIcons[node.key]() : <span className={iconClassName} aria-hidden="true"></span>}
        </S.MenuItemBoxIcon>
      );
    };

    const handleConfirmLogout = () => {
      dialog
        .show({
          title: 'Deseja mesmo sair?',
          text: 'Você terá que efetuar o login novamente.',
          icon: 'question',
          showConfirmButton: true,
          confirmButtonText: 'Sim, sair',
          showCancelButton: true,
          cancelButtonText: 'Não, ficar',
        })
        .then((res) => {
          if (res.isConfirmed) {
            dispatch(logout());
          }
        });
    };

    return (
      <S.MenuTreeBox>
        <Tree
          style={{ border: 0, background: 'transparent', fontSize: '15px', gap: '10px' }}
          value={nodes}
          expandedKeys={expandedKeys}
          selectionMode="single"
          onToggle={(e) => setExpandedKeys(e.value)}
          onSelectionChange={onNodeSelect}
          nodeTemplate={nodeTemplate}
          filter
          filterTemplate={filterTemplate}
          togglerTemplate={togglerTemplate}
        />
        {!isWebScreen && (
          <S.MenuItemLogout onClick={() => handleConfirmLogout()}>
            <S.MenuItemBoxIcon ktMenuMinimized={ktMenuMinimized}>
              <MdLogout />
            </S.MenuItemBoxIcon>

            <S.MenuItemDashBoardTitle ktMenuMinimized={ktMenuMinimized}>Sair</S.MenuItemDashBoardTitle>
          </S.MenuItemLogout>
        )}
      </S.MenuTreeBox>
    );
  } catch (err) {
    trackGCatchError(err, 'main/components/Aside/AsideMenuMain.tsx');
  }
}
