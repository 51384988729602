import useMediaQuery from 'hooks/useMediaQuery';
import * as S from './button.styles';
import { ButtonProps } from './button.types';
import { Tooltip } from 'primereact/tooltip';

export default function Button(buttonOptions: ButtonProps) {
  const buttonId = `button-${Math.random().toString(36).substr(2, 9)}`;

  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const { disabled, ...options } = buttonOptions;

  const LoadingDots = ({ color = 'white', count = 3 }) => {
    const dots = new Array(count).fill(null);
    return (
      <S.DotsWrapper>
        {dots.map((_, index) => (
          <S.Dot key={index} color={color} />
        ))}
      </S.DotsWrapper>
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (options.onClick) {
      options.onClick(event);
    }
  };

  return (
    <>
      {options.tooltip && isWebScreen && (
        <Tooltip
          target={`#${buttonId}`}
          content={options.tooltip}
          position={options.tooltipPosition || 'top'}
          showDelay={200}
        />
      )}

      <S.ButtonMainBox
        id={buttonId}
        {...options}
        as="button"
        className={options.color ? (disabled ? 'black' : options.color) : 'black'}
        onClick={handleClick}
      >
        {options.loading ? (
          <LoadingDots />
        ) : (
          <>
            {options.icon && (!options.iconPosition || options.iconPosition !== 'right') && options.icon}

            {options.text && (
              <S.ButtonTextBox color={options.color} size={options.size}>
                {options.text}
              </S.ButtonTextBox>
            )}

            {options.icon && options.iconPosition === 'right' && options.icon}
          </>
        )}
      </S.ButtonMainBox>
    </>
  );
}
