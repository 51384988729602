import * as S from './containers.styles';

export function Container({ children, ...props }: any) {
  return <S.StyledContainer {...props}>{children}</S.StyledContainer>;
}

export function Header({ children, ...props }: any) {
  return <S.StyledHeader {...props}>{children}</S.StyledHeader>;
}

export function Box({ children, ...props }: any) {
  return <S.StyledBox {...props}>{children}</S.StyledBox>;
}

export function BoxTable({ children, ...props }: any) {
  return <S.StyledBoxTable {...props}>{children}</S.StyledBoxTable>;
}

export function BoxRow({ children, ...props }: any) {
  return <S.StyledRow {...props}>{children}</S.StyledRow>;
}

export function BoxColumn({ children, ...props }: any) {
  return <S.StyledColumn {...props}>{children}</S.StyledColumn>;
}
