import * as S from './regrasHeader.styles';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import { useEffect, useState } from 'react';
import { RegrasHeaderParams, SearchRegrasParams } from 'client/interfaces';
import NovaRegraModal from '../novaRegraModal/novaRegraModal.index';
import { trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import UseSelectStatus from 'hooks/UseSelectStatus/selectStatus';
import { formatDate } from 'utils/formatDate';
import InputDate from 'components/Inputs/InputDate/date.input';
import InputText from 'components/Inputs/InputText/text.input';

export default function RegrasHeader({
  searchRegrasParams,
  setSearchRegrasParams,
  setSelectedEmpr,
  handleGetRegrasComerciais,
}: RegrasHeaderParams) {
  try {
    const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();
    const { SelectStatus } = UseSelectStatus();

    useEffect(() => {
      setSelectedEmpr(selectedEmpresa);
    }, [selectedEmpresa]);

    const [showNovaRegraModal, setShowNovaRegraModal] = useState<boolean>(false);

    return (
      <S.RegrasHeaderMainBox>
        <S.RegrasHeaderRow>
          <SelectEmpresa />

          <InputText
            label="Regra"
            placeholder="Buscar pelo código ou nome da regra"
            width="clamp(200px, 250px, 300px)"
            onChange={(e) => {
              e = e.toUpperCase();
              setSearchRegrasParams((prevState: SearchRegrasParams) => {
                return {
                  ...prevState,
                  text: e,
                };
              });
            }}
          />

          <InputDate
            label="Dt. Ini"
            value={searchRegrasParams.dtIni ? new Date(`${searchRegrasParams.dtIni}T00:00`) : new Date()}
            onChange={(e) => {
              setSearchRegrasParams((prevState: SearchRegrasParams) => {
                return {
                  ...prevState,
                  dtIni: formatDate(e, 'EN'),
                };
              });
            }}
          />

          <InputDate
            label="Dt. Fim"
            value={searchRegrasParams.dtFim ? new Date(`${searchRegrasParams.dtFim}T00:00`) : new Date()}
            onChange={(e) => {
              setSearchRegrasParams((prevState: SearchRegrasParams) => {
                return {
                  ...prevState,
                  dtFim: formatDate(e, 'EN'),
                };
              });
            }}
          />

          <SelectStatus
            label="Ativo / Inativo"
            onChange={(e) => {
              setSearchRegrasParams((prevState: SearchRegrasParams) => {
                return {
                  ...prevState,
                  status: e,
                };
              });
            }}
            value={searchRegrasParams.status ?? 'T'}
            width="100px"
          />

          <Button
            text="Regra"
            icon={<icons.Plus />}
            color="green"
            onClick={() => setShowNovaRegraModal(true)}
            tooltip="Adicionar nova regra"
          />
        </S.RegrasHeaderRow>
        {showNovaRegraModal && (
          <NovaRegraModal
            showNovaRegraModal={showNovaRegraModal}
            setShowNovaRegraModal={setShowNovaRegraModal}
            codEmpresa={selectedEmpresa.codEmpresa}
            handleGetRegrasComerciais={handleGetRegrasComerciais}
          />
        )}
      </S.RegrasHeaderMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'regrasComerciais/components/regrasHeader/regrasHeader.index.tsx');
  }
}
