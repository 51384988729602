import { useUser } from '../../client/hooks/usuarios';
import { useEffect, useState } from 'react';
import { getSupervisorByRole } from 'client/api/vendedores';
import Dropdown from 'components/Dropdown/dropdown';

type SelectedSupervisorProps = {
  codEmpresa?: number;
  codSupervisor?: number;
  nomeSupervisor?: string;
  nomesup?: string;
  label?: string;
  codGerente?: number;
};

const UseSelectSupervisor = (codEmpresa: number, codUsuario: number, codGerente?: number) => {
  const { data: user } = useUser();
  const [supervisores, setSupervisores] = useState<any[]>([]);

  const defaultSupervisor = () => {
    const sup = JSON.parse(localStorage.getItem('vmSupData') || 'null');
    return (
      sup || {
        codSupervisor: sup ? sup.codSupervisor : -1,
        nomeSupervisor: '',
      }
    );
  };

  const [selectedSupervisor, setSelectedSupervisor] = useState<SelectedSupervisorProps>(defaultSupervisor);

  const handleSelectSupervisorByCode = (codSup: number) => {
    try {
      const [filteredSup] = supervisores?.filter((rca: SelectedSupervisorProps) => +rca.codSupervisor == +codSup);

      if (filteredSup) {
        setSelectedSupervisor(filteredSup);
      }
    } catch (err) {
      console.log('Error selecting supervisor :');
    }
  };

  useEffect(() => {
    localStorage.removeItem('vmSupData');
    if (user && codEmpresa && codUsuario) {
      getSupervisorByRole(codEmpresa, codUsuario).then((sups: any[]) => {
        const formattedSups = [];
        for (const s of sups) {
          if (codGerente == undefined || codGerente == -1 || +s.codGerente == +codGerente) {
            formattedSups.push({
              ...s,
              label: `[${s.codSupervisor}] - ${s.nomesup}`,
              nomeSupervisor: s.nomesup,
            });
          }
        }
        setSupervisores(formattedSups);
      });
    }
  }, [user, codEmpresa, codEmpresa, codUsuario, codGerente]);

  const handleUserChange = (sup: SelectedSupervisorProps) => {
    localStorage.setItem('vmSupData', sup ? JSON.stringify(sup) : null);
    setSelectedSupervisor(sup ?? defaultSupervisor);
  };

  const SelectSupervisor = ({
    onChange,
    width,
    label = 'Supervisor',
    placeholder = 'Selecione um Supervisor',
  }: {
    onChange?: (supervisor: SelectedSupervisorProps) => void;
    width?: string;
    label?: string;
    placeholder?: string;
  }) => {

    const handleChange = (sup: SelectedSupervisorProps) => {
      handleUserChange(sup);
      if (onChange) {
        onChange(sup);
      }
    };

    return (
      <Dropdown
        value={selectedSupervisor}
        onChange={(e) => handleChange(e.value)}
        options={supervisores}
        optionLabel="label"
        filter
        placeholder={placeholder}
        emptyMessage="Nenhum dado encontrado"
        emptyFilterMessage="Nenhum dado encontrado"
        width={width}
        label={label}
        showClear
      />
    );
  };

  return {
    SelectSupervisor,
    selectedSupervisor,
    setSelectedSupervisor,
    handleSelectSupervisorByCode,
    supervisores,
  };
};

export default UseSelectSupervisor;
