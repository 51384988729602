import { useState } from 'react';

import * as S from './styles';
import { trackGCatchError } from 'utils/analytics';

type selectedDay = {
  manha: string;
  tarde: string;
};

type daySelectionArray = {
  domingo: selectedDay;
  segunda: selectedDay;
  terca: selectedDay;
  quarta: selectedDay;
  quinta: selectedDay;
  sexta: selectedDay;
  sabado: selectedDay;
};

const UseMapRouterDaySelector = () => {
  try {
    const defaultDaySelectionArray = {
      domingo: {
        manha: 'N',
        tarde: 'N',
      },
      segunda: {
        manha: 'S',
        tarde: 'S',
      },
      terca: {
        manha: 'S',
        tarde: 'S',
      },
      quarta: {
        manha: 'S',
        tarde: 'S',
      },
      quinta: {
        manha: 'S',
        tarde: 'S',
      },
      sexta: {
        manha: 'S',
        tarde: 'S',
      },
      sabado: {
        manha: 'N',
        tarde: 'N',
      },
    };
    const [daySelection, setDaySelection] = useState<daySelectionArray>(defaultDaySelectionArray);

    const Selectdays = () => {
      return (
        <S.SelectDayMainBox>
          <S.DaysBox>
            <S.DaysBoxTable>
              <S.DaysBoxTableHead>
                <S.DaysBoxTableRow>
                  <S.DaysBoxTableHeaderColumn></S.DaysBoxTableHeaderColumn>
                  <S.DaysBoxTableHeaderColumn>Domingo</S.DaysBoxTableHeaderColumn>
                  <S.DaysBoxTableHeaderColumn>Segunda</S.DaysBoxTableHeaderColumn>
                  <S.DaysBoxTableHeaderColumn>Terça</S.DaysBoxTableHeaderColumn>
                  <S.DaysBoxTableHeaderColumn>Quarta</S.DaysBoxTableHeaderColumn>
                  <S.DaysBoxTableHeaderColumn>Quinta</S.DaysBoxTableHeaderColumn>
                  <S.DaysBoxTableHeaderColumn>Sexta</S.DaysBoxTableHeaderColumn>
                  <S.DaysBoxTableHeaderColumn>Sábado</S.DaysBoxTableHeaderColumn>
                </S.DaysBoxTableRow>
              </S.DaysBoxTableHead>
              <S.DaysBoxTableBody>
                <S.DaysBoxTableRow>
                  <S.DaysBoxTableBodyCell>Manhã</S.DaysBoxTableBodyCell>
                  {Object.keys(daySelection).map((day: any) => {
                    return (
                      <S.DaysBoxTableBodyCell>
                        <S.DaysBoxTableInput
                          type={'checkbox'}
                          defaultChecked={daySelection[day].manha == 'S'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setDaySelection((prev) => {
                              return {
                                ...prev,
                                [day]: {
                                  tarde: prev[day].tarde,
                                  manha: daySelection[day].manha == 'S' ? 'N' : 'S',
                                },
                              };
                            });
                          }}
                        />
                      </S.DaysBoxTableBodyCell>
                    );
                  })}
                </S.DaysBoxTableRow>
                <S.DaysBoxTableRow>
                  <S.DaysBoxTableBodyCell>Tarde</S.DaysBoxTableBodyCell>
                  {Object.keys(daySelection).map((day: any) => {
                    return (
                      <S.DaysBoxTableBodyCell>
                        <S.DaysBoxTableInput
                          type={'checkbox'}
                          defaultChecked={daySelection[day].tarde == 'S'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setDaySelection((prev) => {
                              return {
                                ...prev,
                                [day]: {
                                  manha: prev[day].manha,
                                  tarde: daySelection[day].tarde == 'S' ? 'N' : 'S',
                                },
                              };
                            });
                          }}
                        />
                      </S.DaysBoxTableBodyCell>
                    );
                  })}
                </S.DaysBoxTableRow>
              </S.DaysBoxTableBody>
            </S.DaysBoxTable>
          </S.DaysBox>
        </S.SelectDayMainBox>
      );
    };

    const handleDataStrucuteAdjust = (data: any, mode: 'fowards' | 'backwords', origin?: 'C' | 'M') => {
      if (mode == 'fowards') {
        if (origin == 'C') {
          setDaySelection({
            domingo: {
              manha: data.DOMINGO_MANHA,
              tarde: data.DOMINGO_TARDE,
            },
            segunda: {
              manha: data.SEGUNDA_MANHA,
              tarde: data.SEGUNDA_TARDE,
            },
            terca: {
              manha: data.TERCA_MANHA,
              tarde: data.TERCA_TARDE,
            },
            quarta: {
              manha: data.QUARTA_MANHA,
              tarde: data.QUARTA_TARDE,
            },
            quinta: {
              manha: data.QUINTA_MANHA,
              tarde: data.QUINTA_TARDE,
            },
            sexta: {
              manha: data.SEXTA_MANHA,
              tarde: data.SEXTA_TARDE,
            },
            sabado: {
              manha: data.SABADO_MANHA,
              tarde: data.SABADO_TARDE,
            },
          });
        } else {
          setDaySelection({
            domingo: {
              manha: data.domingoManha,
              tarde: data.domingoTarde,
            },
            segunda: {
              manha: data.segundaManha,
              tarde: data.segundaTarde,
            },
            terca: {
              manha: data.tercaManha,
              tarde: data.tercaTarde,
            },
            quarta: {
              manha: data.quartaManha,
              tarde: data.quartaTarde,
            },
            quinta: {
              manha: data.quintaManha,
              tarde: data.quintaTarde,
            },
            sexta: {
              manha: data.sextaManha,
              tarde: data.sextaTarde,
            },
            sabado: {
              manha: data.sabadoManha,
              tarde: data.sabadoTarde,
            },
          });
        }
      } else {
        if (origin == 'C') {
          data.DOMINGO_MANHA = daySelection.domingo.manha;
          data.DOMINGO_TARDE = daySelection.domingo.tarde;
          data.SEGUNDA_MANHA = daySelection.segunda.manha;
          data.SEGUNDA_TARDE = daySelection.segunda.tarde;
          data.TERCA_MANHA = daySelection.terca.manha;
          data.TERCA_TARDE = daySelection.terca.tarde;
          data.QUARTA_MANHA = daySelection.quarta.manha;
          data.QUARTA_TARDE = daySelection.quarta.tarde;
          data.QUINTA_MANHA = daySelection.quinta.manha;
          data.QUINTA_TARDE = daySelection.quinta.tarde;
          data.SEXTA_MANHA = daySelection.sexta.manha;
          data.SEXTA_TARDE = daySelection.sexta.tarde;
          data.SABADO_MANHA = daySelection.sabado.manha;
          data.SABADO_TARDE = daySelection.sabado.tarde;
          return data;
        } else {
          data.domingoManha = daySelection.domingo.manha;
          data.domingoTarde = daySelection.domingo.tarde;
          data.segundaManha = daySelection.segunda.manha;
          data.segundaTarde = daySelection.segunda.tarde;
          data.tercaManha = daySelection.terca.manha;
          data.tercaTarde = daySelection.terca.tarde;
          data.quartaManha = daySelection.quarta.manha;
          data.quartaTarde = daySelection.quarta.tarde;
          data.quintaManha = daySelection.quinta.manha;
          data.quintaTarde = daySelection.quinta.tarde;
          data.sextaManha = daySelection.sexta.manha;
          data.sextaTarde = daySelection.sexta.tarde;
          data.sabadoManha = daySelection.sabado.manha;
          data.sabadoTarde = daySelection.sabado.tarde;
          return data;
        }
      }
    };

    const selectMapDayHook = {
      daySelection: daySelection,
      setDaySelection: setDaySelection,
      Selectdays: Selectdays,
      handleDataStrucuteAdjust: handleDataStrucuteAdjust,
    };
    return selectMapDayHook;
  } catch (err) {
    trackGCatchError(err, 'mapa/components/mapRouterDaySelector/mapRouterDaySelector.index.tsx');
  }
};

export default UseMapRouterDaySelector;
