import useMediaQuery from 'hooks/useMediaQuery';
import * as S from './novaRestricaoModal';
import { Dialog } from 'primereact/dialog';
import { NovaRestricaoModalProps, RegraRestricaoProps } from 'client/interfaces';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useSupervisores } from 'client/hooks/setores';
import { useVendedores } from 'client/hooks/vendedores';
import { useManagers } from 'client/hooks/gerentes';
import { useGroupClients } from 'client/hooks/groupClients';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { postDeleteRegraRestricao, postEditRegraRestricao, postNovaRegraRestricao } from 'client/api/regraComercial';
import { getCidadesBySearchText, getClienteBySearchText } from 'client/api';
import { trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import InputText from 'components/Inputs/InputText/text.input';
import InputSwitch from 'components/Inputs/InputSwitch/switch.input';
import Dropdown from 'components/Dropdown/dropdown';

export default function NovaRestricaoModal({
  showNovaRestricaoModal,
  setShowNovaRestricaoModal,
  codEmpresa,
  handleGetRegrasComerciais,
  selectedRegra,
}: NovaRestricaoModalProps) {
  try {
    const isWebScreen = useMediaQuery('(min-width: 1060px)');
    const { data: groupClients } = useGroupClients(codEmpresa);
    const { data: vendedores } = useVendedores(codEmpresa);
    const { data: gerentes } = useManagers(codEmpresa);
    const { data: supervisores } = useSupervisores(codEmpresa);

    const tipoRestricoes = [
      {
        code: 'GRU',
        name: 'Grupo',
        data: adjustRestricaoListIndexes(groupClients ?? [], 'codgrupo', 'nomeGrupo', 'GRU'),
      },
      {
        code: 'RCA',
        name: 'Vendedor',
        data: adjustRestricaoListIndexes(vendedores ?? [], 'codVendedor', 'nome', 'RCA'),
      },
      { code: 'PC', name: 'Praça', data: [] },
      {
        code: 'GG',
        name: 'Gerente geral',
        data: adjustRestricaoListIndexes(gerentes ?? [], 'codGerente', 'nomeGerente', 'GG'),
      },
      {
        code: 'GA',
        name: 'Gerente de área',
        data: adjustRestricaoListIndexes(supervisores ?? [], 'codSupervisor', 'nomesup', 'GA'),
      },
      { code: 'C', name: 'Cliente', data: [] },
    ];

    const [selectedTipo, setSelectedTipo] = useState(tipoRestricoes[0]);
    const [selectedItems, setSelectedItems] = useState<any[]>(selectedRegra ? selectedRegra.regraRestricao : []);
    const [tableData, setTableData] = useState([]);
    const [isSaveButtonActive, setIsSaveButtonActive] = useState(false);
    const searchRef = useRef(null);

    useEffect(() => {
      handleCheckIfTheresAnyAction();
    }, [selectedItems]);

    useEffect(() => {
      if (showNovaRestricaoModal) {
        setSelectedTipo(tipoRestricoes[0]);
        const itensToBeSelected = [];
        for (let i = 0; i < selectedRegra.regraRestricao.length; i++) {
          const newItem = selectedRegra.regraRestricao[i];
          newItem.dtaltera = new Date().toLocaleDateString().split('/').reverse().join('-');
          itensToBeSelected.push(newItem);
        }
        setSelectedItems(itensToBeSelected);
      }
    }, [showNovaRestricaoModal]);

    useEffect(() => {
      if (selectedTipo.data) {
        handleTableDataFilter();
      }
    }, [selectedTipo, showNovaRestricaoModal]);

    const handleTableDataFilter = () => {
      const searchText = searchRef.current ? searchRef.current.value.toString().toUpperCase() : '';
      let newTableData = selectedTipo.data ?? [];
      if (searchText)
        newTableData = newTableData?.filter(
          (newData: any) =>
            newData.codigorestricao.toString().includes(searchText) ||
            newData.descricao.toString().toUpperCase().includes(searchText),
        );
      setTableData(newTableData);
    };

    function adjustRestricaoListIndexes(arr: any[], txt1: string, txt2: string, tipoRestricaoString: string): any[] {
      const newTxt1 = 'codigorestricao';
      const newTxt2 = 'descricao';

      const keyMap: { [key: string]: string } = {
        [txt1]: newTxt1,
        [txt2]: newTxt2,
      };

      return arr.map((obj) => {
        const renamedObject: any = {};

        for (const key in obj) {
          if (obj.hasOwnProperty(key) && keyMap[key]) {
            const newKey = keyMap[key];
            renamedObject[newKey] = obj[key];
          }
        }

        const filteredObject: any = {};
        if (renamedObject[newTxt1] !== undefined) filteredObject[newTxt1] = renamedObject[newTxt1];
        if (renamedObject[newTxt2] !== undefined) filteredObject[newTxt2] = renamedObject[newTxt2];
        filteredObject['gerarbenef'] = 'S';
        filteredObject['tiporestricao'] = tipoRestricaoString;
        filteredObject['dtaltera'] = new Date().toLocaleDateString().split('/').reverse().join('-');
        filteredObject['codEmpresa'] = codEmpresa;
        filteredObject['codregra'] = selectedRegra ? selectedRegra.codregra : 0;

        return filteredObject;
      });
    }

    const validateFormFields = () => {
      handleInsertOrEditRestricao();
    };

    const handleInsertOrEditRestricao = () => {
      const restricoesToInsert = selectedItems.filter(
        (item: any) => !(selectedRegra.regraRestricao ?? []).includes(item),
      );
      const restricoesToEdit = selectedRegra.regraRestricao.filter((item: any) => (selectedItems ?? []).includes(item));
      const restricoesToDelete = selectedRegra.regraRestricao.filter(
        (item: any) => !(selectedItems ?? []).includes(item),
      );
      if (restricoesToInsert.length > 0) {
        postNovaRegraRestricao(restricoesToInsert)
          .then((res) => {
            if (res.succeeded) {
              handleGetRegrasComerciais();
              toast.success('Nova restrição inserida com sucesso');
              setShowNovaRestricaoModal(false);
            }
          })
          .catch((err) => {
            toast.error('Falha inesperada ao inserir nova restrição');
            console.log('err :', err);
          });
      }

      if (restricoesToDelete.length > 0) {
        postDeleteRegraRestricao(restricoesToDelete)
          .then((res) => {
            if (res.succeeded) {
              handleGetRegrasComerciais();
            }
          })
          .catch((err) => {
            toast.error('Falha inesperada ao remover restrição');
            console.log('err :', err);
          });
      }

      for (let i = 0; i < restricoesToEdit.length; i++) {
        postEditRegraRestricao(restricoesToEdit[i])
          .then((res) => {
            if (res.succeeded) {
              handleGetRegrasComerciais();
              setShowNovaRestricaoModal(false);
            }
          })
          .catch((err) => {
            toast.error(
              `Falha inesperada ao editar restrição [${restricoesToEdit[i].codigorestricao}] ${
                restricoesToEdit[i].descricao ?? ''
              }`,
            );
            console.log('err :', err);
          });
      }
    };

    const onSelectionChange = (e: { value: any[] }) => {
      setSelectedItems(e.value);
    };

    const rowSelectionTemplate = (rowData: any) => {
      return (
        <S.NovaRegraModalChecboxTemplate>
          <Checkbox
            checked={selectedItems.some((item) => item.id === rowData.id)}
            onChange={(e) => {
              let _selectedItems = [...selectedItems];
              if (e.checked) {
                _selectedItems.push(rowData);
              } else {
                _selectedItems = _selectedItems.filter((item) => item.id !== rowData.id);
              }
              setSelectedItems(_selectedItems);
            }}
            style={{
              marginLeft: '10px',
            }}
          />
        </S.NovaRegraModalChecboxTemplate>
      );
    };

    const geraBenefBodyTemplate = (rule: RegraRestricaoProps) => {
      const [checked, setChecked] = useState(rule.gerarbenef === 'S');
      const handleSwitchChange = (e) => {
        const newValue = e ? 'S' : 'N';
        setChecked(e);
        rule.gerarbenef = newValue;
      };
      return <InputSwitch checked={checked} onChange={handleSwitchChange} />;
    };

    const handleGetClientes = () => {
      const searchText = searchRef.current ? searchRef.current.value.toString().toUpperCase() : '';
      getClienteBySearchText(codEmpresa, searchText)
        .then((res) => {
          if (res.succeeded && res.data.length > 0) {
            const clientesToHandle = adjustRestricaoListIndexes(res.data ?? [], 'codCli', 'razaosocial', 'C');
            setSelectedTipo((prev: any) => {
              return {
                ...prev,
                data: Array.from(new Set([...prev.data, ...clientesToHandle])),
              };
            });
          }
        })
        .catch((err) => {
          console.log('err :', err);
        });
    };

    const handleGetCidades = () => {
      const searchText = searchRef.current ? searchRef.current.value.toString().toUpperCase() : '';
      getCidadesBySearchText(searchText)
        .then((res) => {
          if (res.succeeded && res.data.length > 0) {
            const cidadesToHandle = adjustRestricaoListIndexes(res.data ?? [], 'codcidade', 'cidade', 'PC');
            setSelectedTipo((prev: any) => {
              return {
                ...prev,
                data: Array.from(new Set([...prev.data, ...cidadesToHandle])),
              };
            });
          }
        })
        .catch((err) => {
          console.log('err :', err);
        });
    };

    const handleCheckIfTheresAnyAction = () => {
      if (selectedRegra) {
        const restricoesToInsert = selectedItems.filter(
          (item: any) => !(selectedRegra.regraRestricao ?? []).includes(item),
        );
        const restricoesToEdit = selectedRegra.regraRestricao.filter((item: any) =>
          (selectedItems ?? []).includes(item),
        );
        const restricoesToDelete = selectedRegra.regraRestricao.filter(
          (item: any) => !(selectedItems ?? []).includes(item),
        );

        if (
          restricoesToInsert.length > 0 ||
          restricoesToDelete.length > 0 ||
          (JSON.stringify(selectedRegra.regraRestricao) != JSON.stringify(restricoesToEdit) &&
            restricoesToEdit.length > 0)
        ) {
          setIsSaveButtonActive(true);
        } else if (isSaveButtonActive) {
          setIsSaveButtonActive(false);
        }
      }
    };

    return (
      <Dialog
        header={
          selectedRegra
            ? `Gerenciar restrições da regra - [${selectedRegra.codregra}] ${selectedRegra.nomeregra}`
            : 'Nova restrição'
        }
        visible={showNovaRestricaoModal}
        style={{ width: isWebScreen ? '50vw' : '90%' }}
        onHide={() => {
          setShowNovaRestricaoModal(false);
        }}
      >
        <S.NovaRegraModalFormBox>
          <S.NovaRegraModalFormRow>
            <S.NovaRegraModalFormInputs>
              <Dropdown
                label="Tipo"
                value={tipoRestricoes?.filter((tp: any) => tp.code == selectedTipo.code)[0] ?? []}
                options={tipoRestricoes}
                onChange={(e) => {
                  if (e.value.code == 'C' || e.value.code == 'PC') {
                    let dataToHandle = selectedItems?.filter((item: any) => item.tiporestricao == e.value.code);
                    setSelectedTipo({
                      code: e.value.code,
                      name: e.value.name,
                      data: Array.from(new Set([...e.value.data, ...dataToHandle])),
                    });
                  } else {
                    setSelectedTipo(e.value);
                  }
                }}
                optionLabel="code"
                placeholder="Tipo da Restrição"
                className="p-column-filter"
                itemTemplate={(tipo: any) => {
                  return `[${tipo.code}] ${tipo.name}`;
                }}
              />

              <InputText
                label="Buscar"
                placeholder="Buscar pelo cód. ou desc."
                ref={searchRef}
                onChange={() => {
                  if (selectedTipo.code != 'C' && selectedTipo.code != 'PC') {
                    handleTableDataFilter();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key == 'Enter') {
                    if (selectedTipo.code == 'C') {
                      handleGetClientes();
                    } else if (selectedTipo.code == 'PC') {
                      handleGetCidades();
                    } else {
                      handleTableDataFilter();
                    }
                  }
                }}
              />
            </S.NovaRegraModalFormInputs>
            <Button
              text="Salvar"
              color="green"
              icon={<icons.Check />}
              disabled={!isSaveButtonActive}
              onClick={() => {
                isSaveButtonActive && validateFormFields();
              }}
              tooltip={isSaveButtonActive ? 'Salvar restrições selecionadas' : 'Nenhuma restrição selecionada'}
            />
          </S.NovaRegraModalFormRow>

          <S.NovaRegraModalTable>
            <DataTable
              value={tableData ?? []}
              paginator={true}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rows={10}
              rowHover
              stripedRows
              scrollable
              scrollHeight="55dvh"
              style={{ minWidth: '100%', minHeight: '51dvh' }}
              emptyMessage={'Nenhum restrição desse tipo disponível'}
              selection={selectedItems}
              onSelectionChange={onSelectionChange}
              selectionMode="checkbox"
              size="small"
            >
              <Column
                selectionMode="multiple"
                body={rowSelectionTemplate}
                sortable
                align="left"
                style={{
                  maxWidth: '100px',
                }}
              />
              <Column field="codigorestricao" header="Código" sortable />
              <Column field="descricao" header="Descrição" sortable />
              <Column field="gerarbenef" header="Gerar Benef." sortable body={geraBenefBodyTemplate} />
            </DataTable>
          </S.NovaRegraModalTable>
        </S.NovaRegraModalFormBox>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'regrasComerciais/components/novaRestricaoModal/novaRestricaoModal.index.tsx');
  }
}
