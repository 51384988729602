import { BoxTable, Container, Header } from 'components/Containers';
import styled from 'styled-components';

export const CompaniesMainBox = styled(Container)`
  .p-datatable-thead {
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 9;
  }
`;

export const CompaniesHeaderBox = styled(Header)``;

export const CompaniesTableBox = styled(BoxTable)`
  width: 100%;
  overflow-x: scroll;
  max-height: 77dvh;

  .p-datatable-thead {
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 9;
  }
`;

export const IconTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
