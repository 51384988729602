import styled from 'styled-components';

export const MapContainer = styled.section`
  position: relative;
  background-color: #00000010;

  .leaflet-control-layers {
    z-index: 9 !important;
    max-width: 120px !important;
  }

  .leaflet-control-container .leaflet-top {
    position: absolute !important;
    top: calc(100% - 135px);
    left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 9 !important;
  }

  .leaflet-control {
    float: none !important;
  }

  .leaflet-control-layers-list {
    min-height: 80px;
    min-width: 100px;
    z-index: 9 !important;
    padding: 2px;
    z-index: 9 !important;
    background-color: #fff !important;
  }

  .leaflet-control-layers:hover {
    margin-top: -30px;
  }

  .leaflet-control-zoom {
    position: absolute !important;
    top: calc(100% - 205px);
    left: 0px;
    z-index: 8 !important;
  }

  @media only screen and (max-width: 1024px) {
    .leaflet-control-container .leaflet-top {
      position: absolute !important;
      top: calc(100% - 105px);
      left: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      z-index: 9 !important;
    }

    .leaflet-control-zoom {
      position: absolute !important;
      top: calc(100% - 175px);
      left: 0px;
      z-index: 8 !important;
    }
  }
`;

export const MarkerPopUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }

  a {
    border: none;
    outline: none;
    background-color: #228f6b;
    padding: 10px 10px;
    border-radius: 5px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    align-items: center;
    color: #fff;
    font-size: 1.2rem;

    &:hover {
      background-color: #176148;
    }

    svg {
      color: #fff !important;
    }
  }
`;

export const MarkerPopUpCoordinatesBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  align-items: center;
  justify-content: center;

  span {
  }
`;

export const MarkerPopUpSvgBox = styled.div`
  height: 3rem;
  width: 3rem;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  svg {
    font-size: 2rem;
    cursor: pointer;
    color: #60606060;
    transition: all 0.2s ease;

    &:hover {
      color: #000;
    }
  }

  &:hover {
    background-color: #00000010;
  }
`;
