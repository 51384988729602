import React from 'react';
import * as SI from 'styles/inputs.styles';

interface InputTextProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (value: string) => void;
  width?: string;
  label?: string;
  placeholder?: string;
  number?: boolean;
  mask?: (value: string) => string;
}

const InputText = React.forwardRef<HTMLInputElement, InputTextProps>(
  ({ onChange, width, label = '', placeholder = '', number = false, mask, ...rest }, ref) => {
    const widthCalculated = label.length * 28 > 200 ? 200 : label.length * 28;

    const handleChange = (e: string) => {
      let value = e;

      if (number) {
        value = value.replace(/\D/g, '');
      }

      if (onChange) {
        onChange(value);
      }
    };

    return (
      <SI.InputMainBox width={width} widthCalculated={widthCalculated}>
        <label>{label}</label>
        <SI.InputTextBox
          type="text"
          onChange={(e) => {
            let value = e.target.value;
            if (mask) {
              value = mask(value);
              e.target.value = value;
              handleChange(value);
            } else {
              handleChange(e.target.value);
              e.target.value = number ? e.target.value.replace(/\D/g, '') : e.target.value;
            }
          }}
          placeholder={placeholder}
          ref={ref}
          {...rest}
        />
      </SI.InputMainBox>
    );
  },
);

export default InputText;
