import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { trackGCatchError } from 'utils/analytics';
import * as XLSX from 'xlsx';

export async function exportTableToExcel(
  showDayColumns: boolean,
  uniqueDays: any[],
  tableData: any,
  selectedDtIni: string,
  selectedDtFim: string,
) {
  try {
    const borderStyle: Partial<ExcelJS.Borders> = {
      top: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } },
    };
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    if (showDayColumns) {
      const firstHeaderRow = [
        'CÓD SUPERVISOR',
        'SUPERVISOR',
        'CÓD VENDEDOR',
        'VENDEDOR',
        ...uniqueDays.flatMap((day) => [`DIA ${day}`, '', '', '', '', '', '']),
        'TOTAIS',
        '',
        '',
        '',
        '',
        '',
        '',
      ];
      const firstRow = worksheet.addRow(firstHeaderRow);

      worksheet.getColumn(1).width = 20;
      worksheet.getColumn(2).width = 25;
      worksheet.getColumn(3).width = 20;
      worksheet.getColumn(4).width = 25;

      let startColumn = 5;
      uniqueDays.forEach(() => {
        worksheet.mergeCells(1, startColumn, 1, startColumn + 6);
        startColumn += 7;
      });

      worksheet.mergeCells(1, startColumn, 1, startColumn + 6);

      firstRow.eachCell((cell) => {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = borderStyle;
      });

      const secondHeaderRow = [
        '',
        '',
        '',
        '',
        ...uniqueDays.flatMap(() => ['ROTA', '', '', 'POSITIVAÇÃO', '', '', 'GPS']),
        'ROTA',
        '',
        '',
        'POSITIVAÇÃO',
        '',
        '',
        'GPS',
      ];
      const secondRow = worksheet.addRow(secondHeaderRow);
      startColumn = 5;
      uniqueDays.forEach(() => {
        worksheet.mergeCells(2, startColumn, 2, startColumn + 2);
        worksheet.mergeCells(2, startColumn + 3, 2, startColumn + 5);
        startColumn += 7;
      });

      worksheet.mergeCells(2, startColumn, 2, startColumn + 2);
      worksheet.mergeCells(2, startColumn + 3, 2, startColumn + 5);

      secondRow.eachCell((cell) => {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = borderStyle;
      });

      const thirdHeaderRow = [
        '',
        '',
        '',
        '',
        ...uniqueDays.flatMap(() => ['PREV', 'REAL', 'NÃO.R', '%', 'REAL', '%', 'KM']),
        'PREV',
        'REAL',
        'NÃO.R',
        '%',
        'REAL',
        '%',
        'KM',
      ];
      const thirdRow = worksheet.addRow(thirdHeaderRow);
      thirdRow.eachCell((cell) => {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = borderStyle;
      });

      tableData.vendedores.forEach((vend: any) => {
        const row = [
          vend.codSupervisor,
          vend.supervisor,
          vend.codVendedor,
          vend.vendedor,
          ...vend.dias.flatMap((dia: any) => [
            dia.qde_cli_dia,
            dia.qde_visitado_dia,
            dia.qde_nao_visitado,
            dia.perc_visitado,
            dia.positivacoes,
            dia.perc_positivado,
            dia.km_exec ? dia.km_exec : 0,
          ]),
          vend.prev_visita_total,
          vend.real_visita_total,
          vend.pendencia_visita_total,
          vend.perc_visita_total,
          vend.positivacao_total,
          vend.perc_positivacao_total,
          vend.km_total ? vend.km_total : 0,
        ];
        const addedRow = worksheet.addRow(row);

        addedRow.eachCell((cell, colNumber) => {
          if (colNumber > 4) {
            cell.numFmt = '#,##0.00';
          }
          cell.border = borderStyle;
        });
      });
    } else {
      let startColumn = 5;

      const headerRow = ['CÓD SUPERVISOR', 'SUPERVISOR', 'CÓD VENDEDOR', 'VENDEDOR', 'TOTAIS', '', '', '', '', '', ''];
      const firstRow = worksheet.addRow(headerRow);
      firstRow.eachCell((cell) => {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      });
      [1].forEach(() => {
        worksheet.mergeCells(1, startColumn, 1, startColumn + 6);
      });

      const secondHeaderRow = ['', '', '', '', 'ROTA', '', '', 'POSITIVAÇÃO', '', '', 'GPS'];
      const secondRow = worksheet.addRow(secondHeaderRow);
      startColumn = 5;

      worksheet.mergeCells(2, startColumn, 2, startColumn + 2);
      worksheet.mergeCells(2, startColumn + 3, 2, startColumn + 5);

      secondRow.eachCell((cell) => {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = borderStyle;
      });

      const thirdHeaderRow = ['', '', '', '', 'PREV', 'REAL', 'NÃO.R', '%', 'REAL', '%', 'KM'];
      const thirdRow = worksheet.addRow(thirdHeaderRow);
      thirdRow.eachCell((cell) => {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = borderStyle;
      });

      tableData.vendedores.forEach((vend: any) => {
        const row = [
          vend.codSupervisor,
          vend.supervisor,
          vend.codVendedor,
          vend.vendedor,
          vend.prev_visita_total,
          vend.real_visita_total,
          vend.pendencia_visita_total,
          vend.perc_visita_total,
          vend.positivacao_total,
          vend.perc_positivacao_total,
          vend.km_total ? vend.km_total : 0,
        ];
        const addedRow = worksheet.addRow(row);
        addedRow.eachCell((cell, colNumber) => {
          if (colNumber > 4) {
            cell.numFmt = '#,##0.00';
          }
          cell.border = borderStyle;
        });
      });
    }

    worksheet.mergeCells('A1:A3');
    worksheet.mergeCells('B1:B3');
    worksheet.mergeCells('C1:C3');
    worksheet.mergeCells('D1:D3');

    worksheet.columns.forEach((column) => {
      const maxLength = Math.max(
        10,
        ...column.values.filter((val) => val !== null && val !== undefined).map((val) => val.toString().length),
      );
      column.width = maxLength;
    });

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, `visitas-detalhadas-${selectedDtIni} a ${selectedDtFim}.xlsx`);
  } catch (err) {
    trackGCatchError(err, 'AcompanhamentoDiario/utils/exportHandler.ts@handleKmTotalValue');
  }
}

export const handleKmTotalValue = (checkinOutVendedores: any[]) => {
  try {
    const formatter = new Intl.NumberFormat('pt-BR');
    if (checkinOutVendedores) {
      let totalKm = 0;
      for (let i = 0; i < checkinOutVendedores.length; i++) {
        const info = checkinOutVendedores[i];
        totalKm = totalKm + +info.km;
      }
      return formatter.format(totalKm) ?? '0';
    } else {
      return '0';
    }
  } catch (err) {
    trackGCatchError(err, 'AcompanhamentoDiario/utils/exportHandler.ts@exportTableToExcel');
  }
};

export function exportCliToExcel(
  selectedDtIni: string,
  selectedDtFim: string,
  checkinOutVendedores: any[],
  viewMode: string,
  visitasVendedores: any[],
) {
  try {
    const fileName: string = `checkin-checkout-${selectedDtIni}-
    ${selectedDtFim}`;
    const handledCheckinoutVendedores: any = checkinOutVendedores;
    for (let i = 0; i < handledCheckinoutVendedores.length; i++) {
      handledCheckinoutVendedores[i].km = +handledCheckinoutVendedores[i].km;
    }

    handledCheckinoutVendedores.push({ km: handleKmTotalValue(checkinOutVendedores) });

    const ws = XLSX.utils.json_to_sheet(viewMode == 'checkinout' ? handledCheckinoutVendedores : visitasVendedores);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, fileName);
  } catch (err) {
    trackGCatchError(err, 'AcompanhamentoDiario/utils/exportHandler.ts@exportCliToExcel');
  }
}
