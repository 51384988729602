import styled from 'styled-components';

interface IBoxTab {
  isActive: boolean;
}

export const BoxTab = styled.div`
  width: 300px;
  height: 30px;
  background-color: ${(props: IBoxTab) => (props.isActive ? 'var(--primary-green)' : '#e4e7f1')};

  display: flex;
  align-items: center;

  cursor: pointer;

  p {
    margin-left: 10px;
    color: ${(props: IBoxTab) => (props.isActive ? 'white' : '#646670')};
  }
  @media (max-width: 1080px) {
    height: 35px;
  }
`;

export const ContainerTabs = styled.div`
  display: flex;
  border-bottom: 1px solid var(--dark-200);
`;
