import * as S from './analiseRoteiro.styles';

import { MapContainer, TileLayer, LayersControl, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useAnaliseRoteiro } from './analiseRoteiro.context';
import Header from './components/header/header.index';
import SideBar from './components/sideBar/sideBar.index';
import MapToggle from './components/mapToggle/mapToggle.index';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
const { BaseLayer } = LayersControl;

export default function AnaliseRoteiro() {
  try {
    useEffect(() => {
      trackGPageView('/mapa/analise-roteiro');
      alterPageTitle('Análise Roteiro');
    }, []);

    const { viewMode, mapSettings, setMapSettings, toRenderObjects, setSelectedCodVendedor } = useAnaliseRoteiro();

    const { codVendedor } = useParams<{ codVendedor: string }>();

    useEffect(() => {
      if (codVendedor) {
        setSelectedCodVendedor(+codVendedor);
      }
    }, [codVendedor]);

    useEffect(() => {
      handleAdjustMapCenter();
    }, [mapSettings]);

    const mapRef = useRef<any>(null);
    const handleAdjustMapCenter = () => {
      if (mapRef.current) {
        const duration = 1000;
        if (mapRef.current) {
          mapRef.current.flyTo(mapSettings.center, mapSettings.zoom, {
            duration: duration / 1000,
          });
        }
      }
    };

    const CustomZoomControl = () => {
      const map = useMap(); // Access the map instance
      useEffect(() => {
        const zoomControl = L.control.zoom({ position: 'bottomleft' });
        zoomControl.addTo(map);
        return () => {
          map.removeControl(zoomControl);
        };
      }, [map]);
      return null;
    };

    return (
      <S.AnaliseRoteiroMainBox>
        <MapContainer
          center={mapSettings.center}
          zoom={mapSettings.zoom}
          maxZoom={mapSettings.maxZoom}
          style={{ height: '100%', width: '100%' }}
          ref={mapRef}
          zoomControl={false}
        >
          <Header />
          <MapToggle />
          {toRenderObjects.lines}
          {toRenderObjects.markers}
          <CustomZoomControl />
          <LayersControl position="bottomleft">
            <BaseLayer name="CartoDB Positron">
              <TileLayer
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                attribution="&copy; OpenStreetMap &copy; CARTO"
              />
            </BaseLayer>
            <BaseLayer checked name="OpenStreetMap">
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="&copy; OpenStreetMap" />
            </BaseLayer>

            <BaseLayer name="CartoDB Dark Matter">
              <TileLayer
                url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                attribution="&copy; OpenStreetMap &copy; CARTO"
              />
            </BaseLayer>
          </LayersControl>
        </MapContainer>
        {viewMode == 'map' && <SideBar />}
      </S.AnaliseRoteiroMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'AnaliseRoteiro/analiseRoteiro.index.tsx');
  }
}
