import { BoxTable, Container, Header } from 'components/Containers';
import styled from 'styled-components';

export const AnaliseFormMainBox = styled(Container)``;

export const AnaliseFormHeader = styled(Header)`
  align-items: flex-end;
`;

export const AnaliseFormTable = styled(BoxTable)``;

export const AnaliseFormContent = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px;
`;

interface AFInputBoxProps {
  width?: string;
}

export const AFInputBox = styled.div<AFInputBoxProps>`
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: ${({ width }) => width || 'clamp(100px, 170px, 170px)'};
`;
