import styled from 'styled-components';

export const EyeIcon = styled.i`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

interface isMobileProps {
  isMobile: boolean;
}

export const Header = styled.div<isMobileProps>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;

  img {
    height: ${(props) => (props.isMobile ? '70%' : '70%')};
    width: ${(props) => (props.isMobile ? '30%' : '40%')};
  }
`;

export const Container = styled.div`
  padding: 0 10px;

  @media (max-width: 1060px) {
    padding: 0px !important;
  }
`;

// ---------- # ----------
// Ajustes CCorgz Refactoring

export const LoginMainBox = styled.section``;

export const RegisterButtonFixed = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #00000010;
  display: flex;
  justify-content: center;

  button {
    width: 95% !important;
  }
`;

export const DtNascInputBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  #vm-text-input {
    border: none;
    background-color: transparent;
    &:focus {
      outline: none;
      border-color: transparent;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
    }

    &:disabled {
      background-color: #e9ecef;
      color: #6c757d;
      border-color: #ced4da;
      cursor: not-allowed;
    }
  }

  #vm-date-input {
    width: 0px;
    opacity: 0;
    padding: 0px;
  }
`;

export const DtNascIcon = styled.div`
  position: relative;
`;
