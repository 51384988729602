import { BoxColumn, BoxRow } from 'components/Containers';
import styled from 'styled-components';

export const ItemFotoModalMainBox = styled(BoxRow)`
  box-shadow: none;
  justify-content: space-between;
  flex: 1;
`;

export const ItemFotoModalInfoBox = styled(BoxColumn)`
  height: 100%;
  width: 60%;
  box-shadow: none;
  flex: 1;
  justify-content: stretch;
`;

export const ItemFotoModalImageInfoBox = styled(BoxColumn)`
  min-height: 10dvh;
  height: 100%;
  width: 100%;
  box-shadow: none;
  flex: 1;
  gap: 10px;
  p {
    flex: 1;
  }
`;
