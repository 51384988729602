import { EventSourceInput } from '@fullcalendar/core';
import { Identity } from '@fullcalendar/core/internal';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteRotaPonto } from 'client/api/pontoAgenda';
import { useClientsCompany, usePontoAgenda } from 'client/hooks/pontoAgenda';
import { useCalendarStore } from 'storesZustand/calendarStore';

import { ModalAddPoint } from './components/ModalAddPoint';
import * as S from './styles';

import 'moment/locale/pt-br';

import { useVendedores } from 'client/hooks/vendedores';
import { dialog } from 'reactivus';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { usePageTitle } from 'providers/pageTitle';
import { useLoaderEffect } from 'providers/loaderEffect';

export function CalendarRoterizador() {
  moment.locale('pt-br');

  const { setLoader, loader } = useLoaderEffect();

  const {
    codEmpresa: codEmpresaParam,
    codVendedor: codVendedorParam,
    codMapa: codMapaParam,
    codSetor: codSetorParam,
    codUsuario: codUsuarioParam,
  } = useParams<{ codEmpresa: string; codVendedor: string; codMapa: string; codSetor: string; codUsuario: string }>();

  try {
    const { setPageTitle } = usePageTitle();

    const { data: vendedores } = useVendedores(+codEmpresaParam);

    const { data: pontoAgenda } = usePontoAgenda(Number(codEmpresaParam), Number(codVendedorParam));
    const { data: listPoints } = useClientsCompany(Number(codEmpresaParam), Number(codSetorParam));

    const queryClient = useQueryClient();

    const [modalAddPoint, setModalAddPoint] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');

    const setPoints = useCalendarStore((state) => state.setPoints);
    const points = useCalendarStore((state) => state.points);

    const mapEvents: Identity<EventSourceInput> | any = points?.map((event) => {
      return {
        title: event.descricao,
        start: moment(event.dtAgenda).format('YYYY-MM-DD'),
        codPonto: event.codPonto,
        codVendedor: event.codVendedor,
        codAgenda: event.codAgenda,
        sequencia: event.sequencia,
      };
    });

    const handleDateSelect = (eventInfo) => {
      const strDate = new Date(new Date(eventInfo.startStr).getTime() + 24 * 60 * 60 * 1000);
      if (strDate < new Date()) {
        return;
      }
      setModalAddPoint(true);
      setSelectedDate(eventInfo.startStr);
    };

    const validRange = {
      // Formato: "AAAA-MM-DD"
      // start: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toLocaleDateString('en-GB'),
      start: '2023-01-01',
    };

    const handleEventClick = (clickInfo: any) => {
      const filteredArray = mapEvents.filter((event) => {
        return (
          event.title === clickInfo.event.title && moment(clickInfo.event.start).format('YYYY-MM-DD') === event.start
        );
      });
      const strDate = new Date(new Date(clickInfo.event.start).getTime() + 24 * 60 * 60 * 1000);

      if (strDate < new Date()) {
        return;
      }

      let data = {
        CodAgenda: filteredArray[0].codAgenda,
        CodEmpresa: codEmpresaParam,
      };

      dialog
        .show({
          title: 'Tem certeza que deseja deletar esse ponto ?',
          text: `${clickInfo.event.title} - ${moment(clickInfo.event.start).format('DD/MM/YYYY')}`,
          icon: 'danger',
          confirmButtonText: 'Deletar ponto',
          cancelButtonText: 'Cancelar',
          showCloseButton: true,
          showCancelButton: true,
          showConfirmButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteRotaPonto(data)
              .then(() => {
                queryClient.invalidateQueries({
                  queryKey: ['rotaPontoAgenda'],
                });
                toast.success('Ponto excluído com sucesso.');
              })
              .catch(() => {
                queryClient.invalidateQueries({
                  queryKey: ['rotaPontoAgenda'],
                });
                toast.error('Não foi possível deletar ponto.');
              });
          }
        });
    };

    useEffect(() => {
      setLoader({
        show: true,
        text: 'Por favor aguarde, buscando dados...',
      });
    }, []);

    useEffect(() => {
      setPoints(pontoAgenda);
    }, [pontoAgenda, mapEvents]);

    useEffect(() => {
      loader.show &&
        setLoader({
          show: false,
          text: 'Por favor aguarde, buscando dados...',
        });
    }, [mapEvents]);

    function renderEventContent(eventInfo) {
      return (
        <div
          style={{
            padding: '1px',
            fontSize: '10px',
            textAlign: 'center',
            borderRadius: '3px',
            background: eventInfo.event && eventInfo.event.extendedProps.codPonto === 0 ? '#FE5770' : '#375E97',
          }}
        >
          <b>{eventInfo.event && eventInfo.event.title ? eventInfo.event.title : ''}</b>
        </div>
      );
    }

    const calendarOptions = {
      buttonText: {
        today: 'Mês atual',
      },
    };

    useEffect(() => {
      const actualVendedor = vendedores?.filter((rca: any) => +rca.codVendedor == +codVendedorParam);
      trackGPageView('/agenda-vendedor');
      alterPageTitle('Agenda Vendedor');
      setPageTitle((prev) => ({
        text: `${'Agenda do Vendedor'} [${codVendedorParam}] ${
          actualVendedor && actualVendedor[0] ? actualVendedor[0].nome.slice(0, 20) : ''
        }`,
        customObject: prev.customObject,
        tree: [
          {
            text: 'Regioes',
            icon: '',
            path: `/mapa/regioes/${codEmpresaParam}/${codUsuarioParam}/${codMapaParam}`,
          },
          {
            text: 'Roteirizador',
            icon: '',
            path: `/roteirizador/${codMapaParam}/${codSetorParam}/${codVendedorParam}/${codEmpresaParam}/${codUsuarioParam}`,
          },
        ],
      }));
    }, [vendedores, codMapaParam, codSetorParam, codVendedorParam, codEmpresaParam, codUsuarioParam]);

    return (
      <S.ContainerPage>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          locale="pt-br"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          eventColor="transparent"
          events={mapEvents}
          select={handleDateSelect}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          validRange={validRange}
          height={'700px'}
          eventOrder={'sequencia'}
          eventClassNames={(arg) => {
            return arg.event.extendedProps.ponto === 0 ? '#FE5770' : '#375E97';
          }}
          {...calendarOptions}
        />

        <ModalAddPoint
          codEmpresa={parseInt(codEmpresaParam)}
          setOpenModal={setModalAddPoint}
          openModal={modalAddPoint}
          date={selectedDate}
          listPoints={listPoints}
          codVendedor={parseInt(codVendedorParam)}
        />
      </S.ContainerPage>
    );
  } catch (err) {
    trackGCatchError(err, 'Roteirizador/FullCalendar/index.tsx');
  }
}
