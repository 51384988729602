import { CiMap, CiViewTable } from 'react-icons/ci';
import { useAnaliseRoteiro } from '../../analiseRoteiro.context';
import * as S from './mapToggle.styles';
import { trackGCatchError } from 'utils/analytics';

export default function MapToggle() {
  try {
    const { viewMode, setViewMode } = useAnaliseRoteiro();

    return (
      <S.HeaderMapToggle>
        <S.HeaderMapToggleButton
          active={viewMode == 'map'}
          onClick={() => {
            setViewMode('map');
          }}
        >
          <CiMap /> Mapa
        </S.HeaderMapToggleButton>
        <S.HeaderMapToggleButton
          active={viewMode == 'list'}
          onClick={() => {
            setViewMode('list');
          }}
        >
          <CiViewTable /> Grid
        </S.HeaderMapToggleButton>
      </S.HeaderMapToggle>
    );
  } catch (err) {
    trackGCatchError(err, 'AnaliseRoteiro/components/mapToggle/mapToggle.index.tsx');
  }
}
