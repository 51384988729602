import { BoxRow, BoxTable, Container, Header } from 'components/Containers';
import InputText from 'components/Inputs/InputText/text.input';
import styled from 'styled-components';

export const ParamGeralListMainBox = styled(Container)`
  width: 100%;
`;
export const ParamGeralListHeaderBox = styled(Header)`
  justify-content: space-between;
`;

export const ContentBox = styled(BoxRow)`
  box-shadow: none;
  padding: 0;
  align-items: flex-end;
`;
export const ParamGeralListTableBox = styled(BoxTable)`
  height: 75dvh;
  overflow: scroll;

  .p-datatable-thead {
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 9;
  }
`;

export const ParamGeralConfigInputText = styled(InputText)`
  font-size: 1rem;
  padding: 0.1rem;
  padding-left: 0.5rem;
`;
