import { useAppSelector } from 'store';
import { selectedUsuarioSelector } from 'modules/acessoUsuario/reducer/menuAcesso';
import { useForm } from 'react-hook-form';
import { formatCellphone } from 'utils/formatCellphone';
import moment from 'moment';
import { useUser } from 'client/hooks';
import { postUserEditInfo, postUserResetPassword } from 'client/api';
import { toast } from 'react-toastify';
import { dialog } from 'reactivus';

interface IForm {
  name: string;
  birth_date: string;
  cellphone: string;
  email: string;
}

export const UseResetUserData = () => {
  const { data: user } = useUser();

  const selectedUsuario = useAppSelector(selectedUsuarioSelector);

  const { handleSubmit, control, watch, reset, setValue } = useForm<IForm>({
    defaultValues: {
      name: selectedUsuario?.nome,
      birth_date: selectedUsuario?.dtNascimento,
      cellphone: selectedUsuario?.celular,
      email: selectedUsuario?.email,
    },
  });
  const formatBirthDate = moment(selectedUsuario?.dtNascimento).format('YYYY-MM-DD');
  const updateInputValues = () => {
    setValue('name', selectedUsuario?.nome);
    setValue('birth_date', formatBirthDate);
    setValue('email', selectedUsuario?.email);
    setValue('cellphone', formatCellphone(selectedUsuario?.celular));
  };

  const onSubmit = async (data) => {
    const UserInfoToEdit = {
      codUsuario: selectedUsuario?.codUsuario,
      nome: data.name,
      codEmpresa: selectedUsuario?.codEmpresa,
      email: data.email,
      celular: data.cellphone.replace(/[^\w\s]/g, '').replace(' ', ''),
      dtNascimento: new Date(data.birth_date).toISOString(),
    };

    postUserEditInfo(UserInfoToEdit)
      .then((res: any) => {
        if (res.succeeded) {
          toast.success('Dados do usuário atualizados com sucesso');
        } else {
          toast.error(res.errors ?? 'Falha inesperada ao editar dado do usuário');
        }
      })
      .catch((err: any) => {
        toast.error('Falha inesperada ao editar dado do usuário');
        console.log('err :', err);
      });
  };

  const handleResetPassword = () => {
    dialog
      .show({
        title: 'Tem certeza?',
        text: `O usuário "${selectedUsuario?.nome}" terá de cadastrar uma nova senha de acesso.`,
        icon: 'danger',
        showConfirmButton: true,
        confirmButtonText: 'Apagar Senha',
        showCancelButton: true,
        cancelButtonText: 'Voltar',
        showCloseButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          postUserResetPassword(selectedUsuario?.codUsuario, selectedUsuario?.codEmpresa, user?.codUsuario)
            .then(() => {
              toast.success('Senha Redefinida com Sucesso!');
            })
            .catch(() => {
              toast.error('Falha ao Redefinir Senha!');
            });
        } else {
          toast.warning('Redefinição de acesso cancelada!');
        }
      });
  };

  return {
    handleSubmit,
    control,
    watch,
    reset,
    onSubmit,
    handleResetPassword,
    updateInputValues,
    selectedUsuario,
    setValue,
  };
};
