import { Dialog } from 'primereact/dialog';
import useMediaQuery from 'hooks/useMediaQuery';
import * as S from './styles';
import { trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';

type ExecRouterModalProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  handlePostRouter: () => void;
};

export const ExecRouterModal = ({ showModal, setShowModal, handlePostRouter }: ExecRouterModalProps) => {
  try {
    const isWebScreen = useMediaQuery('(min-width: 1060px)');

    return (
      <Dialog
        header={``}
        visible={showModal}
        onHide={() => {
          setShowModal(!showModal);
        }}
        style={{ width: isWebScreen ? '30vw' : '90vw', height: '50vh' }}
      >
        <S.ExecRouterModalBox>
          <S.ExecRouterModalHeaderBox>
            <S.ExecRouterModalHeaderTitle>
              Nenhum roteiro disponível :/ <br />
              Deseja calcular um roteiro para o vendedor?
            </S.ExecRouterModalHeaderTitle>
          </S.ExecRouterModalHeaderBox>

          <S.ExecRouterModalButtonsBox>
            <Button
              text="Sim, calcular"
              color="green"
              onClick={() => {
                setShowModal(false);
                handlePostRouter();
              }}
            />
            <Button
              text="Não, fechar"
              color="red"
              onClick={() => {
                setShowModal(false);
              }}
            />
          </S.ExecRouterModalButtonsBox>
        </S.ExecRouterModalBox>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'Roteirizador/execRouterModal.tsx');
  }
};
