import { InputText } from 'primereact/inputtext';
import * as S from './styles';
import { Controller } from 'react-hook-form';
import editIcon from 'assets/svgs/edit.svg';
import { UseEditUserProfile } from 'hooks/useUserProfile/useEditUserProfile';
import { useEffect } from 'react';
import { postUserEditInfo } from 'client/api';
import { toast } from 'react-toastify';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';

export const EditUserProfile = () => {
  const { control, isEdit, user, updateInputValues, setIsEdit, getValues } = UseEditUserProfile();

  try {
    useEffect(() => {
      updateInputValues();
    }, [user]);

    useEffect(() => {
      trackGPageView('/usuario');
      alterPageTitle('Usuário');
    }, []);

    const handleEditUserInfo = () => {
      const userUpdatedValues = getValues();
      const UserInfoToEdit = {
        codUsuario: user.codUsuario,
        nome: userUpdatedValues.name,
        codEmpresa: user.codEmpresa,
        email: userUpdatedValues.email,
        celular: userUpdatedValues.cellphone.replace(/[^\w\s]/g, '').replace(' ', ''),
        dtNascimento: new Date(userUpdatedValues.birth_date).toISOString(),
      };

      postUserEditInfo(UserInfoToEdit)
        .then((res: any) => {
          if (res.succeeded) {
            toast.success('Dados do usuário atualizados com sucesso');
            setIsEdit(false);
          } else {
            toast.error(res.errors ?? 'Falha inesperada ao editar dado do usuário');
          }
        })
        .catch((err: any) => {
          toast.error('Falha inesperada ao editar dado do usuário');
          console.log('err :', err);
        });
    };

    return (
      <S.Body>
        <S.TitleSection>Dados do usuário</S.TitleSection>

        <S.ResetFormBox>
          <S.Row>
            <Controller
              name="name"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>Nome</label>
                  <InputText disabled={!isEdit} {...field} />
                </S.Box>
              )}
            />

            <Controller
              name="birth_date"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>Data de nascimento</label>
                  <InputText type="date" disabled={!isEdit} {...field} />
                </S.Box>
              )}
            />

            <S.Box />
          </S.Row>

          <S.Row>
            <Controller
              name="email"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <S.Box>
                  <label>Email</label>
                  <InputText type="email" disabled={!isEdit} {...field} />
                </S.Box>
              )}
            />

            <Controller
              name="cellphone"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <S.Box>
                  <label>Celular</label>
                  <InputText maxLength={14} disabled={!isEdit} {...field} />
                </S.Box>
              )}
            />

            <S.Box style={{ justifyContent: 'end' }}>
              {isEdit ? (
                <>
                  <Button
                    text="Salvar"
                    type="submit"
                    icon={<icons.Check />}
                    color="green"
                    onClick={() => {
                      handleEditUserInfo();
                    }}
                  />
                </>
              ) : (
                <img
                  onClick={() => setIsEdit(!isEdit)}
                  style={{ height: '2rem', width: '2rem' }}
                  src={editIcon}
                  alt="Editar"
                />
              )}
            </S.Box>
          </S.Row>
        </S.ResetFormBox>
      </S.Body>
    );
  } catch (err) {
    trackGCatchError(err, 'usuario/pages/EditUserProfile/index.tsx');
  }
};
