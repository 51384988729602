import * as S from './selectColor.styles';
import colors from './colors';
import { Dispatch, SetStateAction, useState } from 'react';
import Dropdown from 'components/Dropdown/dropdown';

interface SingleColorProps {
  COR: string;
  NOME: string;
}

interface ColorProps {
  COR: string;
  NOME: string;
  CORES: SingleColorProps[];
}

interface SelectColorProps {
  onChange?: (color: SingleColorProps) => void;
}

interface useSelectColorProps {
  selectedColor: SingleColorProps | null;
  setSelectedColor?: Dispatch<SetStateAction<SingleColorProps | null>>;
  SelectColor: React.FC<SelectColorProps>;
  colorsList: ColorProps[];
  handleUpdateSelectedColorByColorCode: (color: string) => void;
}

interface SelectColorProps {
  onChange?: (cor: SingleColorProps) => void;
  width?: string;
  label?: string;
  placeholder?: string;
}

const UseSelectColor = (): useSelectColorProps => {
  const [selectedColor, setSelectedColor] = useState<SingleColorProps | null>({ NOME: 'VERDE LIMO', COR: '42bb7d' });

  const SelectColor = ({
    onChange,
    width = '100%',
    label = 'Cor',
    placeholder = 'Selecione uma Cor',
  }: SelectColorProps) => {
    const corValueTemplate = (): React.ReactNode => {
      if (selectedColor) {
        return (
          <S.ColorItemValueTemplate
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <S.ColorItemTemplateTitle color={selectedColor.COR}>
              {selectedColor.NOME} #{selectedColor.COR}
            </S.ColorItemTemplateTitle>
          </S.ColorItemValueTemplate>
        );
      } else {
        return 'Selecione uma cor';
      }
    };

    const corItemTemplate = (cor: ColorProps): React.ReactNode => {
      return (
        <S.ColorItemTemplate
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <S.ColorItemTemplateTitle color={cor.COR}>{cor.NOME}</S.ColorItemTemplateTitle>
          <S.ColorItemTemplatePalette>
            {cor.CORES?.map((corItem: SingleColorProps) => (
              <S.ColorItemTemplatePaletteItem
                color={corItem.COR}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedColor(corItem);
                  onChange?.(corItem);
                }}
                key={corItem.COR}
                active={selectedColor && selectedColor.COR === corItem.COR}
              />
            ))}
          </S.ColorItemTemplatePalette>
        </S.ColorItemTemplate>
      );
    };

    return (
      <S.SelectColorMainBox>
        <Dropdown
          value={selectedColor}
          options={colors as ColorProps[]}
          optionLabel="NOME"
          placeholder={placeholder}
          label={label}
          itemTemplate={corItemTemplate}
          valueTemplate={corValueTemplate}
          emptyMessage="Nenhum dado encontrado"
          emptyFilterMessage="Nenhum dado encontrado"
          showClear
          width={width}
        />
      </S.SelectColorMainBox>
    );
  };

  const handleUpdateSelectedColorByColorCode = (color: string) => {
    for (let i = 0; i < colors.length; i++) {
      let atualColor = colors[i];
      for (let j = 0; j < atualColor.CORES.length; j++) {
        let actualColorItem = atualColor.CORES[j];
        if (color.toUpperCase().replace('#', '') == actualColorItem.COR.toUpperCase().replace('#', '')) {
          setSelectedColor(actualColorItem);
          i = colors.length + 1;
          j = atualColor.CORES.length + 1;
          return;
        }
      }
    }
  };

  return {
    selectedColor,
    setSelectedColor,
    SelectColor,
    colorsList: colors as ColorProps[],
    handleUpdateSelectedColorByColorCode: handleUpdateSelectedColorByColorCode,
  };
};

export default UseSelectColor;
