import { BoxTable, Container, Header } from 'components/Containers';
import styled from 'styled-components';

export const ReportMainBox = styled(Container)``;

export const ReportHeaderBox = styled(Header)``;

export const ReportBodyBox = styled(BoxTable)`
  height: 72dvh;
  overflow: hidden;
`;

export const ReportTableScriptField = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
interface ReportTableAtivoFieldProps {
  ativo?: string;
}

export const ReportTableAtivoField = styled.div<ReportTableAtivoFieldProps>`
  color: ${(props) => (props.ativo == 'S' ? `green` : 'red')} !important;
  svg {
    font-weight: bolder !important;
    font-size: 1.5rem !important;
  }
`;

export const ReportTableActionField = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  svg {
    font-weight: bolder !important;
    font-size: 1.5rem !important;
  }
`;
