import * as S from './supervTable.styles';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Accordion } from 'reactivus';
import { SupervProps } from 'modules/verbas/verbas.types';
import { useVerbas } from 'modules/verbas/verbas.context';
import { trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';

interface SupervTableProps {
  codEmpr: number;
}

export default function SupervTable({ codEmpr }: SupervTableProps) {
  try {
    const { supervisores } = useVerbas();

    const supervisorTemplate = (superv: SupervProps) => {
      return <div>{`[${superv.codSupervisor}] ${superv.supervisor}`}</div>;
    };

    const extratoTemplate = () => {
      return <Button icon={<icons.List />} color="black" tooltip="Extrato" rounded />;
    };

    return (
      <Accordion title={'Valores por supervisor'}>
        <S.VerbasTableMainBox>
          <S.VerbasBodyBox>
            <DataTable
              value={supervisores ?? []}
              paginator={false}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rows={100}
              rowHover
              stripedRows
              scrollable
              scrollHeight="57dvh"
              style={{ maxWidth: '100%', minHeight: '57dvh' }}
              emptyMessage={'Nenhum supervisor vinculado'}
              size="small"
            >
              <Column field="codSupervisor" header="Cód. GA" sortable align="left" body={supervisorTemplate} />
              <Column field="vlDistSuperv" header="Vl. Dist. GA" sortable align="left" />
              <Column field="vlDistVendedor" header="Vl. Dist. RCA" sortable align="left" />
              <Column field="vlAplic" header="Vl. Aplic." sortable align="left" />
              <Column field="vlSaldo" header="Vl. Saldo" sortable align="left" />
              <Column field="" header="Extrato" sortable align="left" body={extratoTemplate} />
            </DataTable>
          </S.VerbasBodyBox>
        </S.VerbasTableMainBox>
      </Accordion>
    );
  } catch (err) {
    trackGCatchError(err, 'verbas/components/supervTable/supervTable.index.tsx');
  }
}
