import L from 'leaflet';
import { MapLayersListProps } from './mapLayers.types';

const Voyager = L.tileLayer('https://tiles.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}@2x.png', {
  attribution: 'Tiles &copy; Esri',
  minZoom: 3,
});

const OpenStreetMap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
  attribution: '&copy; OpenStreetMap contributors',
  minZoom: 3,
});

const CartoDbDark = L.tileLayer(
  'https://cartodb-basemaps-a.global.ssl.fastly.net/rastertiles/dark_all/{z}/{x}/{y}@2x.png',
  {
    attribution: 'Tiles &copy; Esri',
    minZoom: 3,
  },
);

const CartoDbLight = L.tileLayer(
  'https://cartodb-basemaps-a.global.ssl.fastly.net/rastertiles/light_all/{z}/{x}/{y}@2x.png',
  {
    attribution: 'Tiles &copy; Esri',
    minZoom: 3,
  },
);

const mapLayersObject = {
  Voyager: Voyager,
  OpenStreetMap: OpenStreetMap,
  CartoDbDark: CartoDbDark,
  CartoDbLight: CartoDbLight,
};

const mapLayersList: MapLayersListProps[] = [
  {
    name: 'Voyager',
    url: 'https://tiles.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}@2x.png',
    layer: Voyager,
  },
  {
    name: 'OpenStreetMap',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    layer: OpenStreetMap,
  },
  {
    name: 'CartoDbDark',
    url: 'hhttps://cartodb-basemaps-a.global.ssl.fastly.net/rastertiles/dark_all/{z}/{x}/{y}@2x.png',
    layer: CartoDbDark,
  },
  {
    name: 'CartoDbLight',
    url: 'https://cartodb-basemaps-a.global.ssl.fastly.net/rastertiles/light_all/{z}/{x}/{y}@2x.png',
    layer: CartoDbLight,
  },
];

export { mapLayersObject, mapLayersList };
