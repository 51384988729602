import { toast } from 'react-toastify';
import * as S from './styles';

import copyFile from 'assets/svgs/copyFile.svg';
import history from 'assets/svgs/history.svg';
import marker from 'assets/svgs/marker.svg';
import user from 'assets/svgs/user.svg';
import calendarSvg from 'assets/svgs/calendar.svg';
import { useEffect, useState } from 'react';
import { postCliVisitaBloqueio } from 'client/api';
import { trackGCatchError } from 'utils/analytics';

type CliProps = {
  codCli: number;
  nomeCli: string;
  sequencia: number;
  freqVisita: number;
  distancia: number;
  diaSemana: string;
  dtProxVisita: string;
  codVendedor: number;
  lat: string;
  lng: string;
  idRota: string;
  visitaBloqueio: string;
  tempoMinVisita: string;
  estaEmRota: string;
  urlIcon: string;
};

export default function CliPopUp({
  cli,
  handleGetCliData,
  compPositions,
}: {
  cli: CliProps | any;
  handleGetCliData: any;
  compPositions: any[];
}) {
  try {
    const [cliVisitaBloqueio, setCliVisitaBloqueio] = useState(cli.visitaBloqueio == 'N' ? true : false);

    const [showModal, setShowModal] = useState(false);

    const handleAlteraCliVisitaBloqueio = (bloqueio: boolean) => {
      postCliVisitaBloqueio(cli.idRota)
        .then((res) => {
          cli.visitaBloqueio = cli.visitaBloqueio == 'N' ? 'S' : 'N';
          setCliVisitaBloqueio(bloqueio);
          window.sessionStorage.setItem('routerSelectedMarker', JSON.stringify(cli));
          handleGetCliData();
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error('Falha ao bloquear visita!');
          }
        });
    };

    useEffect(() => {
      setCliVisitaBloqueio(cli.visitaBloqueio == 'N' ? true : false);
    }, [cli]);

    async function copyContentToClipboard(content: string, message: string) {
      try {
        const textoNoParagrafo = content;

        const textarea = document.createElement('textarea');
        textarea.value = textoNoParagrafo;

        document.body.appendChild(textarea);
        textarea.select();

        document.execCommand('copy');
        document.body.removeChild(textarea);
        toast.success('Coordenadas copiadas para a área de transferência com sucesso!');
      } catch (error) {
        console.error('Error copying to clipboard:', error);
      }
    }

    return (
      <S.CliPopMainBox>
        <S.MarkerHeader>
          <S.Badge style={{ background: `#CCCCCC70`, border: 'none' }}>
            <img src={user} />
          </S.Badge>
          {cli ? (
            <p>
              {cli.codCli && '[' + cli.codCli + ']'} {cli.nomeCli}
            </p>
          ) : (
            '--'
          )}
        </S.MarkerHeader>
        <S.MarkerBody>
          <S.MarkerRow>
            <span>
              <S.Badge>
                <img src={calendarSvg} />
              </S.Badge>
              <p>Data Visita:</p>
            </span>
            <S.DataDescription style={{ cursor: 'pointer' }} onClick={() => setShowModal(true)}>
              {cli.diaSemana} -{' '}
              {cli.estaEmRota && cli.estaEmRota == 'N'
                ? '*NÃO DEFINIDO*'
                : new Date(cli.dtProxVisita).toLocaleDateString()}
            </S.DataDescription>
          </S.MarkerRow>
          <S.Separator />

          <S.MarkerRow>
            <span>
              <S.Badge>
                <img src={history} />
              </S.Badge>
              <p>Frequência Visita:</p>
            </span>
            <S.Description style={{ cursor: 'pointer' }}>{cli.freqVisita}</S.Description>
          </S.MarkerRow>
          <S.Separator />

          <S.MarkerRow>
            <span>
              <S.Badge>
                <img src={marker} />
              </S.Badge>

              <p>Coordenadas</p>
            </span>

            <S.Description
              onClick={() =>
                copyContentToClipboard(`${cli.lat}, ${+cli.lng}`, 'Coordenadas copiadas para a área de transferência!')
              }
              style={{ cursor: 'pointer' }}
            >
              {cli.lat}, {cli.lng} <img src={copyFile} />
            </S.Description>
          </S.MarkerRow>
          <S.Separator />

          {/* {(cli.estaEmRota && cli.estaEmRota == 'N') || cli.urlIcon ? (
          <></>
        ) : (
          <S.MarkerRow>
            <span>
              <p>
                Visita <b>{cliVisitaBloqueio ? 'Agendada' : 'Bloqueada'}</b>
              </p>
            </span>

            <S.Description style={{ cursor: 'pointer' }}>
              <InputSwitch
                checked={cliVisitaBloqueio}
                onChange={(e) => {
                  handleAlteraCliVisitaBloqueio(!cliVisitaBloqueio);
                }}
              />
            </S.Description>
          </S.MarkerRow>
        )} */}
        </S.MarkerBody>
        {/* {showModal && (
        <RouterHistModal showModal={showModal} setShowModal={setShowModal} compPositions={compPositions} cli={cli} />
      )} */}
      </S.CliPopMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'AnaliseRoteiro/components/cliPopUp/index.tsx');
  }
}
