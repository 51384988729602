import { Container, Header } from 'components/Containers';
import styled from 'styled-components';

interface IMapProps {
  showMenu?: boolean;
  codColorSetor?: string;
}

export const Content = styled.section<IMapProps>`
  background-color: #fafafa;
  height: 85%;
  width: 100%;
  overflow-y: scroll;
  opacity: ${(props) => (props.showMenu ? 'none' : '0')};
  transition: ${(props) => (!props.showMenu ? 'opacity 0.4s ease' : '')};

  @media (max-width: 992px) {
    border-radius: none;
    height: calc(80% - 10px);
  }
`;
export const ResetMainBox = styled(Container)``;
export const ResetFormBox = styled(Header)``;
