import { BoxRow, BoxTable } from 'components/Containers';
import styled from 'styled-components';

export const PedListTableBox = styled(BoxTable)`
  flex: 1;
  display: flex;
  flex-direction: column;

  .p-datatable {
    height: 100% !important;
    width: 100% !important;
  }
`;

export const PedListTableHeader = styled(BoxRow)`
  background-color: transparent;
  box-shadow: none;
  justify-content: space-between;
`;
