import { BoxTable, Container, Header } from 'components/Containers';
import InputText from 'components/Inputs/InputText/text.input';
import styled from 'styled-components';

export const ParamGeralConfigMainBox = styled(Container)`
  flex: 1;
  overflow-x: scroll;
`;
export const ParamGeralConfigHeaderBox = styled(Header)``;

export const ParamGeralConfigTableBox = styled(BoxTable)`
  flex: 1;
  overflow-x: scroll;
`;

export const ParamGeralConfigInputText = styled(InputText)`
  font-size: 1rem;
  padding: 0.1rem;
  padding-left: 0.5rem;
`;
