import { Dialog } from 'primereact/dialog';
import { SetStateAction, useEffect, useState } from 'react';
import { Image } from 'primereact/image';

import * as S from './ItemFotoModal.styles';
import useMediaQuery from 'hooks/useMediaQuery';
import { trackGCatchError } from 'utils/analytics';
import { RespostaItem } from 'client/interfaces';
import { BASE_URL } from 'services/api';
import { LayersControl, MapContainer, Marker, TileLayer, useMap } from 'react-leaflet';
import { mapLayersList } from 'components/MapLayers/mapLayers.index';
import redPinMarker from 'assets/svgs/redPin';
import L from 'leaflet';

interface RespostaItemFotoModal {
  resposta: RespostaItem;
  setShowItemFotoModal: React.Dispatch<SetStateAction<RespostaItem>>;
}

interface ICoordinates {
  lat: number;
  lng: number;
}

const redPinIcon = new L.DivIcon({
  html: redPinMarker,
  className: '',
  iconSize: [44, 44],
  iconAnchor: [22, 44],
  popupAnchor: [0, -44],
});

export default function RespostaItemFotoModal({ resposta, setShowItemFotoModal }: RespostaItemFotoModal) {
  try {
    const isWebScreen = useMediaQuery('(min-width: 1060px)');

    const [currentCoords, setCurrentCoords] = useState<ICoordinates>({
      lat: -15.787268021105954,
      lng: -47.89989674447985,
    });

    function UpdateMapCenter({ center }: { center: [number, number] }) {
      const map = useMap();
      useEffect(() => {
        if (center) {
          map.setView(center);
        }
      }, [center, map]);
      return null;
    }

    return (
      <Dialog
        header={'Resposta do formulário'}
        visible={resposta != null}
        style={{ width: isWebScreen ? '50vw' : '90vw' }}
        onHide={() => {
          setShowItemFotoModal(null);
        }}
      >
        {resposta && (
          <S.ItemFotoModalMainBox>
            <Image src={`${BASE_URL}/fotos_formulario/${resposta.resposta}`} alt="Image" width="250" />
            {/* <S.ItemFotoModalInfoBox>
              <S.ItemFotoModalImageInfoBox>
                <p>Avenida teste, Nº123</p>
                <p>Centro - Testelândia</p>
                <p>Minas Gerais</p>
              </S.ItemFotoModalImageInfoBox>
              <MapContainer center={currentCoords} zoom={18} style={{ height: '30vh', width: '100%' }}>
                <LayersControl position="topright">
                  {mapLayersList?.map((layer, i) => {
                    return (
                      <LayersControl.BaseLayer checked={i == 0} name={layer.name}>
                        <TileLayer url={layer.url} />
                      </LayersControl.BaseLayer>
                    );
                  })}
                </LayersControl>{' '}
                <Marker position={[currentCoords.lat, currentCoords.lng]} icon={redPinIcon} />
                <UpdateMapCenter center={[currentCoords.lat, currentCoords.lng]} />
              </MapContainer>
            </S.ItemFotoModalInfoBox> */}
          </S.ItemFotoModalMainBox>
        )}
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(
      err,
      'AnaliseFormularios/RespostaFormulariosItem/components/ItemFotoModal/itemFotoModal.index.tsx',
    );
  }
}
