import { useEffect, useRef, useState } from 'react';
import * as S from './styles';
import { getVisitAnalisysByRole } from 'client/api';
import ActionBar from '../actionBar';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import UseSelectUsuario from 'hooks/UseSelectUsuario/selectUsuario';
import UseSelectFilial from 'hooks/UseSelectFilial/selectFilial';
import UseSelectDate from 'hooks/UseSelectDate/selectDate';
import { trackGCatchError } from 'utils/analytics';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';
import Dropdown from 'components/Dropdown/dropdown';
import { useLoaderEffect } from 'providers/loaderEffect';

export default function AnaliseHeader({ setSearchResult, selectedCli, setSelectedCli, showMenu }: any) {
  const { setLoader } = useLoaderEffect();
  try {
    const [data, setData] = useState([]);

    const [selectedStatus, setSelectedStatus] = useState({ name: 'A - Análise ', code: 'A' });

    const statusList = [
      { name: 'T - Todos ', code: 'T' },
      { name: 'A - Análise ', code: 'A' },
      { name: 'L - Liberado ', code: 'L' },
      { name: 'R - Rejeitado ', code: 'R' },
    ];

    // ---------- # ----------

    const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();
    const { SelectUsuario, selectedUsuario } = UseSelectUsuario(selectedEmpresa.codEmpresa);
    const { SelectFilial, selectedFilial } = UseSelectFilial(selectedEmpresa.codEmpresa, selectedUsuario.codUsuario);

    const defaultStartDate = new Date(Date.now() - 86400000);
    const { SelectDate: SelectDtIni, selectedDate: selectedDtIni } = UseSelectDate(defaultStartDate);
    const defaultEndDate = new Date();
    const { SelectDate: SelectDtFim, selectedDate: selectedDtFim } = UseSelectDate(defaultEndDate);

    useEffect(() => {
      if (selectedEmpresa && selectedUsuario) {
        setLoader({
          show: true,
          text: `Por favor aguarde, buscando dados...`,
        });
        handleGetMainData();
      }
    }, [selectedEmpresa, selectedUsuario]);

    async function handleGetMainData() {
      let dataList = await getVisitAnalisysByRole(selectedEmpresa.codEmpresa, selectedUsuario.codUsuario);

      setLoader({
        show: false,
        text: `Por favor aguarde, buscando dados...`,
      });
      setSearchResult(dataList);
      setData(dataList);
    }

    useEffect(() => {
      handleFullScreenMode();
    }, []);

    const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(false);

    const handleFullScreenMode = () => {
      const header = document.getElementById('kt_header');

      const ktBody = document.getElementById('kt_body');

      if (isFullScreenEnabled) {
        if (header) {
          header.style.display = 'flex';
          ktBody.removeAttribute('data-kt-aside-minimize');
        }
        setIsFullScreenEnabled(false);
      } else {
        if (header) {
          ktBody.setAttribute('data-kt-aside-minimize', 'on');
        }
        setIsFullScreenEnabled(true);
      }
    };

    const [textFilter, setTextFilter] = useState('');
    const workerRef = useRef(null);
    useEffect(() => {
      if (!workerRef.current) {
        workerRef.current = new Worker(new URL('./analiseVisitasFilterWorker.ts', import.meta.url));
      }

      const filters = {
        selectedStatus,
        textFilter,
        selectedDtIni,
        selectedDtFim,
        selectedFilial,
      };

      workerRef.current.postMessage({ data, filters });

      workerRef.current.onmessage = (e) => {
        setSearchResult(e.data);
      };

      return () => {
        workerRef.current.terminate();
        workerRef.current = null;
      };
    }, [data, selectedStatus, textFilter, selectedDtIni, selectedDtFim, selectedFilial]);

    return (
      <>
        <S.AnaliseHeaderMainBox showMenu={showMenu}>
          <S.AnaliseHeaderFormBox>
            <SelectEmpresa label="Empresa *" width="150px" />
            <SelectUsuario label="Usuário *" width="150px" />
            <SelectFilial label="Filial *" width="150px" />

            <Dropdown
              label="Posição"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.value)}
              options={statusList}
              optionLabel="name"
              className="md:w-10rem"
            />

            <SelectDtIni label="Dt. Ini" width="90px" />
            <SelectDtFim label="Dt. Fim" width="90px" />

            <Button icon={<icons.Refresh />} onClick={() => handleGetMainData()} color="black" />
          </S.AnaliseHeaderFormBox>
        </S.AnaliseHeaderMainBox>
        {selectedCli != null && selectedCli.status == 'A' && (
          <ActionBar selectedCli={selectedCli} setSelectedCli={setSelectedCli} handleGetMainData={handleGetMainData} />
        )}
      </>
    );
  } catch (err) {
    trackGCatchError(err, 'AnaliseVisitas/components/analiseHeader/index.tsx');
  }
}
