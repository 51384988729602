/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, ReactNode, useContext, useState } from 'react';

export interface LoaderEffectProviderProps {
  children: ReactNode;
}

interface LoaderProps {
  show: boolean;
  text?: string;
}

export interface LoaderEffectContextProps {
  loader: LoaderProps;
  setLoader: React.Dispatch<React.SetStateAction<LoaderProps>>;
  loaderHeader: boolean;
  setLoaderHeader: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoaderEffectContext = createContext({} as LoaderEffectContextProps);

export const LoaderEffectProvider = ({ children }: LoaderEffectProviderProps) => {
  const [loader, setLoader] = useState<LoaderProps>({
    show: false,
    text: '',
  });
  const [loaderHeader, setShowLoaderHeader] = useState<boolean>(false);

  const setLoaderHeader = (show: boolean) => {
    if (show) {
      setShowLoaderHeader(show);
    } else {
      setTimeout(() => {
        setShowLoaderHeader(show);
      }, 1000);
    }
  };

  return (
    <LoaderEffectContext.Provider
      value={{
        loader,
        setLoader,
        loaderHeader,
        setLoaderHeader,
      }}
    >
      {children}
    </LoaderEffectContext.Provider>
  );
};

export const useLoaderEffect = () => useContext(LoaderEffectContext);
