import * as S from './verbasTable.styles';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useEffect, useState } from 'react';
import { Accordion, Container, Tag } from 'reactivus';
import { VerbasProps } from 'modules/verbas/verbas.types';
import { useVerbas } from 'modules/verbas/verbas.context';
import { MdArrowForwardIos, MdOutlineCancel } from 'react-icons/md';
import { trackGCatchError } from 'utils/analytics';

interface VerbassTableProps {
  codEmpr: number;
}

export default function VerbasTable({ codEmpr }: VerbassTableProps) {
  try {
    const { verbasFilter, setVerbasFilter, statusFilterOptions, verbas, selectedVerba, setSelectedVerba } = useVerbas();

    const [isTableOpen, setIsTableOpen] = useState<boolean>(true);

    useEffect(() => {
      if (selectedVerba) {
        setIsTableOpen(false);
      } else if (!isTableOpen) {
        setIsTableOpen(true);
      }
    }, [selectedVerba]);

    const AaccordionHeaderTemplate = () => {
      const isVerbaAtiva = selectedVerba && selectedVerba.status == 'S' ? true : false;
      return (
        <Container gap="10px">
          {selectedVerba && <MdOutlineCancel onClick={() => setSelectedVerba(null)} />}
          <span>{selectedVerba ? `[${selectedVerba.nro}] ${selectedVerba.descricao}` : 'Verbas'}</span>
          {selectedVerba && (
            <Tag label={!isVerbaAtiva ? 'Expirada' : 'Vigente'} color={!isVerbaAtiva ? 'danger' : 'success'} text />
          )}
        </Container>
      );
    };

    const selectedRowClassName = (verba: VerbasProps) => {
      const isVerbaAtiva = verba.status == 'S' ? true : false;

      if (selectedVerba && verba.nro === selectedVerba.nro) {
        return {
          'selected-table-row': selectedVerba && verba.nro === selectedVerba.nro,
        };
      } else {
        return {
          'expirada-table-row': !isVerbaAtiva,
          'vigente-table-row': isVerbaAtiva,
        };
      }
    };

    const selectVerbaTemplate = (verba: VerbasProps) => {
      return (
        <MdArrowForwardIos
          onClick={(e) => {
            verba.nro != 0 && setSelectedVerba(verba);
          }}
        />
      );
    };

    const codFuncTemplate = (verba: VerbasProps) => {
      return <div>{`[${verba.codFuncCad}] ${verba.nomeFuncCad}`}</div>;
    };

    const verbaTemplate = (verba: VerbasProps) => {
      return <div>{`[${verba.nro}] ${verba.descricao}`}</div>;
    };

    const vigenciaTemplate = (verba: VerbasProps) => {
      return (
        <S.VigenciaTemplate>
          <span>{`De  ${verba.dtInicio}`}</span>
          <span>{`até ${verba.dtFim}`}</span>
        </S.VigenciaTemplate>
      );
    };

    return (
      <Accordion
        title={selectedVerba ? `[${selectedVerba.nro}] ${selectedVerba.descricao}` : 'Verbas'}
        isOpenControl={isTableOpen}
        setIsOpenControl={setIsTableOpen}
        headerTemplate={<AaccordionHeaderTemplate />}
      >
        <S.VerbasTableMainBox>
          <S.VerbasBodyBox>
            <DataTable
              value={verbas.filtered ?? []}
              paginator={false}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rows={100}
              rowHover
              stripedRows
              scrollable
              scrollHeight="66dvh"
              style={{ minWidth: '110dvw', minHeight: '66dvh' }}
              rowClassName={selectedRowClassName}
              emptyMessage={'Nenhuma verba encontrada'}
              size="small"
            >
              <Column
                field="nro"
                header=""
                body={selectVerbaTemplate}
                align="left"
                style={{
                  maxWidth: '50px',
                }}
              />
              <Column
                field="filial"
                header="Filial"
                sortable
                align="left"
                style={{
                  maxWidth: '75px',
                }}
              />
              <Column
                field="nro"
                header="Verba"
                sortable
                align="left"
                style={{
                  minWidth: '20rem',
                }}
                body={verbaTemplate}
              />
              <Column field="dtInicio" header="Vigencia" sortable body={vigenciaTemplate} />
              <Column field="valor" header="Valor. VB" sortable />
              <Column field="valorDistribuido" header="Vl. Distribuído" sortable />
              <Column field="saldoDistribuido" header="Saldo Distribuído" sortable />
              <Column field="valorAplicado" header="Valor Aplicado" sortable />
              <Column field="saldoAplicado" header="Saldo Aplicado" sortable />
              <Column field="codFuncCad" header="Func. Cad" body={codFuncTemplate} sortable />
              <Column field="dtCadastro" header="Dt. Cad" sortable />
              <Column
                field="observacao"
                header="Observação"
                sortable
                style={{
                  minWidth: '20rem',
                }}
              />
            </DataTable>
          </S.VerbasBodyBox>
        </S.VerbasTableMainBox>
      </Accordion>
    );
  } catch (err) {
    trackGCatchError(err, 'verbas/components/verbasTable/verbasTable.index.tsx');
  }
}
