export const sendGEvent = (eventName: string, eventParams: Record<string, any> = {}) => {
  if (typeof window.gtag !== 'function') {
    console.warn('Google Analytics is not initialized');
    return;
  }
  window.gtag('event', eventName, eventParams);
};

export const trackGPageView = (pagePath: string) => {
  sendGEvent('v_page_view', {
    v_page_path: pagePath,
  });
};

export const trackGaGeolocation = () => {
  sendGEvent('v_google_geolocations');
};

export const trackGaDirections = () => {
  sendGEvent('v_google_directions');
};

import { toast } from 'react-toastify';
import debouncer from 'utils/debounce';

let debouncedTrackGCatchError = debouncer((err: any, fileName: string) => {
  try {
    // toast.error(err.message ? `Falha inesperada: ${err.message}` : 'Falha inesperada');
    sendGEvent('v_catch_error', {
      v_catched_error_info: `${fileName} # ${err}`,
    });
  } catch (err) {
    console.log('Error dispatching GA errror event :', err);
  }
}, 1000);

export const trackGCatchError = (err: any, pagePath: string) => {
  console.log('err :', err);
  debouncedTrackGCatchError(err, pagePath);
};

export const trackGCustomEvent = (eventName: string, eventParams: string | any) => {
  sendGEvent(eventName, eventParams);
};
