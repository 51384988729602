import styled from 'styled-components';

import { BoxTable, Container, Header } from 'components/Containers/index';

export const FormRespMainBox = styled(Container)``;

export const FormRespHeaderBox = styled(Header)`
  align-items: flex-end;
`;

export const FormRespTableBox = styled(BoxTable)``;

export const BoxInput = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: clamp(200px, 100px, 100px);
`;

export const BoxTextInput = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  @media (max-width: 1024px) {
    width: 100% !important;
    width: clamp(50px, 100%, 100%);
  }
  input {
    width: 100%;
  }
`;
