import { Container, Header } from 'components/Containers';
import styled from 'styled-components';

export const ParamRcaMainBox = styled(Container)`
  width: 100%;
  overflow-x: scroll;
`;
export const ParamRcaHeaderBox = styled(Header)``;

export const ParamRcaNameTemplate = styled.a`
  color: var(--v-blue);
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;

  @media (max-width: 1024px) {
    max-width: 60% !important;
  }

  &:hover {
    text-decoration: underline !important;
    color: var(--v-blue);
  }
`;

export const ParamRcaHeaderVendedorTemplate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  p {
    font-size: 1.1rem;
  }

  span {
    font-size: 0.9rem;
  }
`;

export const ParamRcaVersaoTemplate = styled.div``;

export const ParamRcaDataTemplate = styled.div``;

export const ParamRcaListTableTemplateBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ParamRcaListTableBox = styled(Container)`
  overflow-x: scroll;
  border-radius: 5px;
  padding: 0px !important;
  flex: 1;

  .p-sortable-column {
    white-space: nowrap;
  }

  @media (max-width: 1024px) {
    .p-datatable {
      overflow-x: hidden !important;
      padding-bottom: 15px !important;
    }
    .p-datatable-tbody > tr > td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .p-paginator {
      padding-bottom: 10px !important;
    }
  }

  .p-paginator {
    justify-content: center !important;
    position: sticky !important;
    left: 0px !important;
    z-index: 9 !important;
    max-width: 100dvw;
  }

  .p-datatable-thead {
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 9;
  }

  .fixed-vendedor-header {
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 101 !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px 0px;
    @media (max-width: 1080px) {
      position: relative;
    }
  }

  .fixed-vendedor-column {
    position: sticky;
    left: 0px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 3px 0px;

    &:hover {
      background-color: #e2e3e990 !important;
    }
    @media (max-width: 1080px) {
      position: relative;
    }
  }

  .colored-header-1 {
    cursor: pointer;
    background-color: #e2e3e9 !important;
    &:hover {
      background-color: rgb(208, 209, 214) !important;
    }
  }

  .colored-header-2 {
    cursor: pointer;
    background-color: #e2e3e9 !important;
    &:hover {
      background-color: rgb(208, 209, 214) !important;
    }
  }
`;
