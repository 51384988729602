import styled from 'styled-components';

interface InputMainBoxProps {
  width?: string;
  widthCalculated?: number;
  type?: string;
}

export const InputMainBox = styled.div<InputMainBoxProps>`
  display: flex;
  flex-direction: column;
  gap: 0px;
  min-width: ${({ type }) => (type == 'date' ? '85px' : undefined)};
  width: ${({ width, widthCalculated }) =>
    width
      ? width
      : `clamp(${widthCalculated ?? 100}px, ${widthCalculated ?? 100}px, ${widthCalculated ?? 170}px)`} !important;

  .p-dropdown,
  .p-inputtext,
  .p-multiselect {
    border-radius: 5px !important;
  }
  input::focus {
    outline: none !important;
    border: none !important;
  }

  .p-inputtext::focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
`;

export const InputTextBox = styled.input`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.5rem;
  padding-left: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;

  &:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &:disabled {
    background-color: #e9ecef;
    color: #6c757d;
    border-color: #ced4da;
    cursor: not-allowed;
  }
  &:hover {
    border-color: var(--primary-green);
  }
`;
