import styled from 'styled-components';

export const ContainerMainBox = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100svh;
  width: 100vw;
  overflow: hidden !important;
  position: relative;
  background-color: #F5F7FA;

  @media only screen and (max-width: 1024px) {
    /* height: 93.5vh; */
    overflow: scroll;
    padding-bottom: 10vh;
  }
`;
