import { BoxColumn, BoxTable, Container } from 'components/Containers';
import styled from 'styled-components';

export const FormRespBox = styled(Container)``;

export const FormRespImageBox = styled.div`
  position: relative;
  z-index: 8;
  height: 50px;
  width: 50px;
  text-align: center;
  img {
    max-height: 50px;
    width: auto;
    max-width: 50px;
  }
`;

export const FormRespImageOverlayBox = styled.div`
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;

  svg {
    opacity: 0;
    color: #fff;
    transition: all 0.4s ease;
    font-size: 1rem;
  }

  &:hover {
    background-color: #00000050;

    svg {
      opacity: 1;
      scale: 1.5;
    }
  }
`;

export const FormRespHeader = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 15px 5px;
`;

export const FormRespTable = styled(BoxTable)``;

export const FormRespCard = styled(BoxColumn)`
  justify-content: space-between;
  align-items: stretch;
  color: #606060;
  height: clamp(110px, 115px, 120px);
  padding: 10px;
  @media (max-width: 1024px) {
    width: 100% !important;
    width: clamp(50px, 100%, 100%);
    height: clamp(120px, 135px, 150px);
  }
`;

export const FormRespCardRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

type FormRespCardRowTitleProps = {
  color?: string;
};

export const FormRespCardRowTitle = styled.div<FormRespCardRowTitleProps>`
  display: flex;
  gap: 5px;
  color: ${(props) => props.color ?? '#606060'};
  max-height: 2rem;
  i {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: ${(props) => props.color ?? '#606060'};
    padding: 5px;
    border-radius: 4px;
    font-size: 0.8rem;
  }
`;

export const FormRespCardRowContent = styled.div`
  display: flex;
`;

export const FormRespCardColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FormRespGridSearchBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 5px;
  gap: 10px;

  span {
    width: clamp(20rem, 25rem, 100%);
    input {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    span {
      width: 100%;
    }
  }
`;
