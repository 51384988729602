import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useState } from 'react';
import { BsFillTrash3Fill } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { deleteMenuRestricao } from 'client/api/menuRestricao';
import { useMenuRestricao } from 'client/hooks/menuRestricao';
import { MenuRestrincao } from 'client/interfaces';
import ClickIcon from 'components/ClickIcon';

import { IRowDataMenu } from '../../ItensMenu';
import * as S from './styles';
import { trackGCatchError } from 'utils/analytics';

interface IModal {
  data: IRowDataMenu;
}

const ModalSeeRegisteredCompanies = ({ data }: IModal) => {
  try {
    const queryClient = useQueryClient();

    const { data: menus } = useMenuRestricao(data.codMenu !== 0 ? data.codMenu : null);

    const [filter, setFilter] = useState('');
    const [removeCompany, setRemoveCompany] = useState(0);

    const filteredMenus = menus?.filter((item) => item.nomeEmpresa.toLowerCase().includes(filter.toLowerCase()));

    const deletePermissionToCompany = (menu: MenuRestrincao) => {
      deleteMenuRestricao(menu.codEmpresa, menu.codMenu)
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ['MenuRestrincao'],
          });
        })
        .catch(() => {
          toast.error('Não foi possível deletar acesso.');
        });
    };

    return (
      <S.Container>
        <S.Title>Empresas com Acesso</S.Title>

        {menus?.length ? (
          <>
            <S.InputFilter
              type="text"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              placeholder="Filtrar por empresa"
            />
            <S.ContainerScroll>
              {!filteredMenus.length ? (
                <>Não encontramos empresas com esse nome :/</>
              ) : (
                <>
                  {filteredMenus?.map((menu) => (
                    <S.Box remove={removeCompany === menu.codEmpresa} key={menu.codRestricao}>
                      <S.Description>
                        <h6> {menu.nomeEmpresa}</h6>

                        <S.ContainerBox>
                          <S.Description>
                            <p>Data</p>
                            <span> {moment(menu.dtLibera).format('DD/MM/YYYY')}</span>
                          </S.Description>

                          <S.Description style={{ paddingLeft: '10px' }}>
                            <p>Liberador</p>
                            <span>
                              {menu.nomeUsuario} [{menu.codFuncLibera}]
                            </span>
                          </S.Description>
                        </S.ContainerBox>
                      </S.Description>

                      {removeCompany === menu.codEmpresa ? (
                        <S.BoxConfirm>
                          <p>Deseja remover o acesso dessa empresa </p>
                          <div>
                            <S.Button
                              style={{ background: 'var(--primary-green' }}
                              onClick={() => deletePermissionToCompany(menu)}
                            >
                              Sim
                            </S.Button>
                            <S.Button style={{ background: 'var(--danger-500)' }} onClick={() => setRemoveCompany(0)}>
                              Não
                            </S.Button>
                          </div>
                        </S.BoxConfirm>
                      ) : (
                        <ClickIcon
                          variant="danger"
                          onClick={() => setRemoveCompany(menu.codEmpresa)}
                          icon={<BsFillTrash3Fill />}
                        />
                      )}
                    </S.Box>
                  ))}
                </>
              )}
            </S.ContainerScroll>
          </>
        ) : (
          <S.Void>
            <h2>Você não possui empresas vinculadas neste menu :/</h2>
          </S.Void>
        )}
      </S.Container>
    );
  } catch (err) {
    trackGCatchError(err, 'itensMenu/modalsMenu/ModalSeeRegisteredCompanies/index.tsx');
  }
};

export default ModalSeeRegisteredCompanies;
