import { useEffect, useState } from 'react';
import * as S from './styles';
import { TABS, useAccessDataStore } from 'storesZustand/accessData';
import { selectedUsuarioSelector } from 'modules/acessoUsuario/reducer/menuAcesso';
import { useAppSelector } from 'store';
import { trackGCatchError } from 'utils/analytics';

export const MenuAccessData = () => {
  try {
    const selectedUsuario = useAppSelector(selectedUsuarioSelector);
    const tabActive = useAccessDataStore((state) => state.tabActive);
    const setTabActive = useAccessDataStore((state) => state.setTabActive);

    const handleTabClick = (tabActive: string) => {
      setTabActive(tabActive);
    };

    useEffect(() => {
      selectedUsuario === null && setTabActive(TABS.ACCESS_MENU);
    }, [selectedUsuario]);

    return (
      <S.ContainerTabs>
        <S.BoxTab onClick={() => handleTabClick(TABS.ACCESS_MENU)} isActive={tabActive === TABS.ACCESS_MENU}>
          <p>Acesso Menu</p>
        </S.BoxTab>
        <S.BoxTab onClick={() => handleTabClick(TABS.ACCESS_DATA)} isActive={tabActive === TABS.ACCESS_DATA}>
          <p>Acesso a Dados</p>
        </S.BoxTab>

        {selectedUsuario !== null && (
          <S.BoxTab onClick={() => handleTabClick(TABS.ALTER_PASSWORD)} isActive={tabActive === TABS.ALTER_PASSWORD}>
            <p>Manutenção de Cadastro</p>
          </S.BoxTab>
        )}
      </S.ContainerTabs>
    );
  } catch (err) {
    trackGCatchError(err, 'acessoUsuario/UsuarioItensMenu/MenuAccessData/index.tsx');
  }
};
