import { useQueryClient } from '@tanstack/react-query';
import { Column, ColumnEditorOptions, ColumnEventParams } from 'primereact/column';
import { DataTable, DataTableRowReorderParams } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { updateItemsFormData } from 'client/api';
import { useCabecalhoItems, useTipos } from 'client/hooks';
import { FormularioCab, FormularioItem, FormularioItemUpdate } from 'client/interfaces';
import { numberEditor, textEditor } from 'components/datatable';

import * as S from './cadastroFormularios.styles';
import FormImagesModal from '../../components/FormImagesModal/FormImagesModal.index';
import { trackGCatchError } from 'utils/analytics';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';
import Dropdown from 'components/Dropdown/dropdown';

interface FormularioItemTableProps {
  cab: FormularioCab;
  onClose: () => void;
  visible: boolean;
  codCompany: number;
}

const FormularioItemTable = ({ cab, onClose, visible, codCompany }: FormularioItemTableProps) => {
  try {
    const [items, setItems] = useState<FormularioItemUpdate[]>([]);
    const { data, isLoading, refetch } = useCabecalhoItems(cab.nroform, codCompany);
    const { data: options } = useTipos();
    const queryClient = useQueryClient();

    const [showFormImagesModal, setShowFormImagesModal] = useState(false);
    const [toEditItemImage, setToEditItemImage] = useState(false);

    useEffect(() => {
      if (data?.length) {
        setItems(data.map((i) => ({ ...i, edited: false })));
      }
    }, [data]);

    const tipoEditor = (editorOptions: ColumnEditorOptions) => (
      <Dropdown
        options={options ?? []}
        value={editorOptions.value}
        onChange={(e) => {
          const newItems = [...items];
          newItems[editorOptions.rowIndex].edited = true;
          newItems[editorOptions.rowIndex].tipo = e.target.value;
          setItems(newItems);
        }}
      />
    );

    const tipoTemplate = (rowData: FormularioItem) => {
      const itemTypeToDisplay = options?.filter((op: any) => op.value == rowData.tipo)[0].label ?? rowData.tipo;
      return <span>{itemTypeToDisplay ?? rowData.tipo}</span>;
    };

    const handleRemove = (rowData: FormularioItem) => {
      const newItems = [...items].filter((i) => i.ordem !== rowData.ordem);
      setItems(newItems.map((v, i) => ({ ...v, ordem: i + 1, edited: true })));
    };

    const actionBodyTemplate = (rowData: FormularioItem) => {
      return (
        <Button
          icon={<icons.Remove />}
          textMode
          bgOnlyOnHover
          tooltip={
            cab.temRespostas ? 'Não é possível excluir items de um fomulário que já contenha respostas.' : 'Remover'
          }
          disabled={cab.temRespostas}
          onClick={() => handleRemove(rowData)}
        />
      );
    };

    const createNew = useCallback(() => {
      setItems([
        ...items,
        {
          limiteMaximo: 1,
          limiteMinimo: 1,
          nomeCampo: '',
          nroform: cab.nroform,
          opcoes: '',
          pk: 0,
          tipo: 'INPUT',
          ordem: items.length ? Math.max(...items.map((i) => i.ordem)) + 1 : 1,
          edited: true,
          codEmpresa: codCompany,
          fotoExemplo: '',
        },
      ]);
    }, [items]);

    const header = (
      <div className="d-grid gap-2 d-md-flex">
        <Button text="Adicionar" color="green" icon={<icons.Plus />} onClick={createNew} />
      </div>
    );

    const onSave = useCallback(async () => {
      const validateInput = items?.some(
        (item) =>
          String(item?.limiteMaximo) === '' || String(item?.limiteMinimo) === '' || String(item?.nomeCampo) === '',
      );

      if (validateInput) {
        toast.warning('Colunas Limite Mínimo, Limite Máximo, Campo são obrigatórias.');
      } else {
        try {
          await updateItemsFormData(cab.nroform, items);
          await queryClient.invalidateQueries();
          toast.success('Items salvos com sucesso');
          onClose();
        } catch {
          toast.error('Erro ao salvar items');
        }
      }
    }, [cab.nroform, items]);

    const renderFooter = () => (
      <S.RowItens>
        <Button text="Salvar" color="green" icon={<icons.Check />} onClick={onSave} />
        <Button text="Cancelar" color="red" icon={<icons.X />} onClick={onClose} />
      </S.RowItens>
    );

    const onRowReorder = (e: DataTableRowReorderParams) => {
      const newValues: FormularioItemUpdate[] = e.value;
      setItems(newValues.map((v, i) => ({ ...v, ordem: i + 1, edited: true })));
    };

    const onCellEditComplete = (e: ColumnEventParams) => {
      const { rowData, newValue, field, rowIndex } = e;

      const newItems = [...items];
      rowData[field] = newValue;
      rowData.edited = true;
      newItems[rowIndex] = rowData;
    };

    const imageExemploTemplate = (rowData: any) => {
      const imageBaseUrl =
        rowData.img1 && rowData.img1Url
          ? rowData.img1Url
          : `${process.env.REACT_APP_BASE_URL}/fotos_formulario_perguntas_itens/${
              !rowData.fotoExemplo || (rowData.fotoExemplo && rowData.fotoExemplo == '')
                ? 'sem-imagem.jpeg'
                : rowData.fotoExemplo
            }`;
      return (
        <S.ItemImageTemplateBox>
          <S.FomImageTableIcon
            onClick={(e) => {
              e.stopPropagation();
              if (rowData.tipo == 'TITULO_IMG') {
                setShowFormImagesModal(true);
                setToEditItemImage(rowData);
              }
            }}
          >
            <img alt={'BannerImage'} src={imageBaseUrl} />
          </S.FomImageTableIcon>
        </S.ItemImageTemplateBox>
      );
    };

    const handleImagesInsert = (image: any) => {
      const newItems = items.map((item: any) => {
        if (item.ordem == image.ordem) {
          item.edited = true;
          item.img1 = image.img1;
          item.img1Url = image.img1Url;
          item.fotoExemploDelete = item.fotoExemplo;
          item.fotoExemplo = image.img1.name;
        }
        return item;
      });
      setItems(newItems);
    };

    return (
      <Dialog
        header={`Itens Formulário Nº ${cab.nroform}`}
        visible={visible}
        style={{ width: '80vw' }}
        footer={renderFooter}
        onHide={onClose}
        closeOnEscape={false}
      >
        <S.ItensTable>
          <DataTable
            value={items}
            header={header}
            loading={isLoading}
            dataKey="ordem"
            reorderableRows
            onRowReorder={onRowReorder}
            editMode="cell"
            emptyMessage="Nenhum dado encontrado"
            size="small"
          >
            {!cab.temRespostas && <Column rowReorder style={{ width: '3em' }} />}
            <Column header="Ordem" field="ordem" />
            <Column
              header="Campo*"
              field="nomeCampo"
              dataType="text"
              editor={!cab.temRespostas ? textEditor : undefined}
              onCellEditComplete={!cab.temRespostas ? onCellEditComplete : undefined}
            />
            <Column
              header="Tipo"
              field="tipo"
              editor={!cab.temRespostas ? tipoEditor : undefined}
              onCellEditComplete={!cab.temRespostas ? onCellEditComplete : undefined}
              body={tipoTemplate}
            />
            <Column
              header="Limite Mínimo*"
              field="limiteMinimo"
              dataType="numeric"
              editor={!cab.temRespostas ? numberEditor : undefined}
              onCellEditComplete={!cab.temRespostas ? onCellEditComplete : undefined}
            />
            <Column
              header="Limite Máximo*"
              field="limiteMaximo"
              dataType="numeric"
              editor={!cab.temRespostas ? numberEditor : undefined}
              onCellEditComplete={!cab.temRespostas ? onCellEditComplete : undefined}
            />
            <Column
              header="Opções (separador '#')"
              field="opcoes"
              dataType="text"
              editor={!cab.temRespostas ? textEditor : undefined}
              onCellEditComplete={!cab.temRespostas ? onCellEditComplete : undefined}
            />
            <Column header="Foto Exemplo" field="fotoExemplo" dataType="text" body={imageExemploTemplate} />
            <Column body={(rowData: FormularioItem) => actionBodyTemplate(rowData)} />
          </DataTable>
        </S.ItensTable>
        <FormImagesModal
          showFormImagesModal={showFormImagesModal}
          setShowFormImagesModal={setShowFormImagesModal}
          item={toEditItemImage}
          refetchForms={refetch}
          handleImagesInsert={handleImagesInsert}
        />
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'AnaliseFormularios/components/FormularioCabTable/formularioItemTable.index.tsx');
  }
};

export default FormularioItemTable;
