import { useUser } from '../../client/hooks/usuarios';
import { SetStateAction, useEffect, useState } from 'react';
import { getEmpresas } from 'client/api';
import Dropdown from 'components/Dropdown/dropdown';

type SelectedEmpresaProps = {
  codEmpresa: number;
  fantasia?: string;
};

const UseSelectEmpresa = (setEmpresa?: React.Dispatch<SetStateAction<SelectedEmpresaProps>>) => {
  const { data: user } = useUser();

  const [empresas, setEmpresas] = useState<any>([]);

  const GetSelectedEmpresa = () => {
    let empr = JSON.parse(localStorage.getItem('vmEmpData'));
    empr = empr
      ? empr
      : {
          codEmpresa: user
            ? user.codEmpresa
            : empresas.length > 0
            ? empresas.find((empr: any) => empr.codEmpresa === 2072021)
            : 2072021,
          fantasia: '',
        };

    return empr;
  };

  const [selectedEmpresa, setSelectedEmpresa] = useState<SelectedEmpresaProps>(GetSelectedEmpresa());

  useEffect(() => {
    if (user && empresas.length == 0) {
      if (user.codEmpresa == 2072021) {
        getEmpresas().then((emps: any) => {
          for (let i = 0; i < emps.length; i++) {
            emps[i].label = `[${emps[i].codEmpresa}] - ${emps[i].fantasia}`;
          }
          setEmpresas(emps);
          if (selectedEmpresa.codEmpresa == 2072021) {
            setSelectedEmpresa(emps.find((empr: any) => empr.codEmpresa === 2072021));
          }
        });
      } else {
        setSelectedEmpresa({
          codEmpresa: user.codEmpresa,
          fantasia: '',
        });
        localStorage.removeItem('vmEmpData');
        localStorage.setItem(
          'vmEmpData',
          JSON.stringify({
            codEmpresa: user.codEmpresa,
            fantasia: '',
          }),
        );
      }
    }
  }, [user]);

  const SelectEmpresa = ({
    onChange,
    width,
    label = 'Empresa',
    placeholder = 'Selecione uma Empresa',
  }: {
    onChange?: (empr: SelectedEmpresaProps) => void;
    width?: string;
    label?: string;
    placeholder?: string;
  }) => {

    const handleEmpChange = (emp: SelectedEmpresaProps) => {
      setEmpresa && setEmpresa(selectedEmpresa);
      if (onChange) {
        onChange(selectedEmpresa);
      }
      setSelectedEmpresa(
        emp ?? {
          codEmpresa: user.codEmpresa,
          fantasia: '',
        },
      );
      localStorage.setItem('vmEmpData', JSON.stringify(emp));
    };

    if (user && user.codEmpresa === 2072021) {
      return (
        <Dropdown
          value={selectedEmpresa}
          onChange={(e) => {
            handleEmpChange(e.value ?? null);
          }}
          options={empresas}
          optionLabel="label"
          filter
          itemTemplate={(emp) => `[${emp.codEmpresa}] - ${emp.fantasia}`}
          placeholder={placeholder}
          emptyMessage="Nenhum dado encontrado"
          emptyFilterMessage="Nenhum dado encontrado"
          label={label}
          width={width}
          showClear
        />
      );
    }
  };

  const selectEmpresaHook = {
    SelectEmpresa: SelectEmpresa,
    GetSelectedEmpresa: GetSelectedEmpresa,
    selectedEmpresa: selectedEmpresa,
    setSelectedEmpresa: setSelectedEmpresa,
    empresas: empresas
  };
  return selectEmpresaHook;
};

export default UseSelectEmpresa;
