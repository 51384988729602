import { getRouterData, getRouterCompData } from 'client/api';
import { trackGCatchError } from 'utils/analytics';

export async function getCliData(codSetor: string, codVendedor: string, codEmpresa: string) {
  try {
    return await getRouterData(codEmpresa, codSetor, codVendedor)
      .then((res: any) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  } catch (err) {
    trackGCatchError(err, 'Roteirizador/clientes.tsx@getCliData');
  }
}

export async function getCliCompData(codSetor: string, codEmpresa: string) {
  try {
    return await getRouterCompData(codEmpresa, codSetor)
      .then((res: any) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  } catch (err) {
    trackGCatchError(err, 'Roteirizador/clientes.tsx@getCliCompData');
  }
}

const paginate = (positions: any) => {
  try {
    if (positions.length == 0) {
      return [];
    }
    let week = positions[0].semana + '/' + positions[0].mes;
    let pages = [week];
    positions.map((pos: any) => {
      if (week !== pos.semana + '/' + pos.mes) {
        pages.push(pos.semana + '/' + pos.mes);
      }
      week = pos.semana + '/' + pos.mes;
    });

    return pages;
  } catch (err) {
    trackGCatchError(err, 'Roteirizador/clientes.tsx@paginate');
  }
};

export { paginate };
