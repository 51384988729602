import * as S from './analiseMapa.styles';
import { useEffect, useRef } from 'react';
import clientSvg1 from '../../../../../../assets/svgs/clientSvg1';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { VisitaProps } from '../../analiseVisitas.types';
import ReactDOM from 'react-dom';
import { FaRegCopy, FaStreetView } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { mapLayersObject } from 'components/MapLayers/mapLayers.index';
import { trackGCatchError } from 'utils/analytics';

export default function AnaliseMapa({ visita, showMenu }: { visita: VisitaProps; showMenu: boolean }) {
  try {
    const mapRef = useRef(null);
    const mapInstance = useRef(null);
    const canvasLayerRef = useRef(null);

    const handleCreateMapPaneUrl = (lat: number, lng: number) => {
      return `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat ?? 0},${lng ?? 0}`;
    };

    const copyText = (textoNoParagrafo: string) => {
      const textarea = document.createElement('textarea');
      textarea.value = textoNoParagrafo;

      document.body.appendChild(textarea);
      textarea.select();

      document.execCommand('copy');
      document.body.removeChild(textarea);
      toast.info('Coordenadas copiadas para a área de transferência!');
    };

    const MarkerPopUp = ({ type }: { type: 'C' | 'V' }) => {
      const urlToOpen =
        type == 'C'
          ? handleCreateMapPaneUrl(+visita.latitudeCliFv, +visita.longitudeCliFv)
          : handleCreateMapPaneUrl(+visita.latitude, +visita.longitude);
      return (
        <S.MarkerPopUpContainer>
          {type == 'C' ? (
            <div>
              <span>
                [{visita.codcli}] {visita.nomeCliente}
              </span>
              <S.MarkerPopUpCoordinatesBox>
                <span>
                  {visita.latitudeCliFv}, {visita.longitudeCliFv}
                </span>
                <S.MarkerPopUpSvgBox
                  onClick={() => {
                    copyText(`${visita.latitudeCliFv}, ${visita.longitudeCliFv}`);
                  }}
                >
                  <FaRegCopy />
                </S.MarkerPopUpSvgBox>
              </S.MarkerPopUpCoordinatesBox>
            </div>
          ) : (
            <div>
              <span>
                [{visita.codusur}] {visita.nomeVendedor}
              </span>
              <S.MarkerPopUpCoordinatesBox>
                <span>
                  {visita.latitude}, {visita.longitude}
                </span>
                <S.MarkerPopUpSvgBox
                  onClick={() => {
                    copyText(`${visita.latitude}, ${visita.longitude}`);
                  }}
                >
                  <FaRegCopy />
                </S.MarkerPopUpSvgBox>
              </S.MarkerPopUpCoordinatesBox>
            </div>
          )}

          <a href={urlToOpen} target="_blank">
            <span>Street View</span> <FaStreetView />
          </a>
        </S.MarkerPopUpContainer>
      );
    };

    const drawCanvas = () => {
      if (!mapInstance?.current) {
        return;
      }

      const canvas = document.createElement('canvas');
      const size = mapInstance.current.getSize();
      canvas.width = size.x;
      canvas.height = size.y;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      drawMarker();

      if (canvasLayerRef.current) {
        mapInstance.current.removeLayer(canvasLayerRef.current);
      }

      const boundsOverlay = mapInstance.current.getBounds();
      canvasLayerRef.current = L.imageOverlay(canvas.toDataURL(), boundsOverlay);
      canvasLayerRef.current.addTo(mapInstance.current);
    };

    const customMarkerIconUrl = (color: string, code: number | string) => {
      let svgTemplate: any = '';
      svgTemplate = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(clientSvg1(code, color));
      return svgTemplate;
    };

    const drawMarker = () => {
      if (!mapInstance.current || !visita) return;

      const { latitude, longitude, latitudeCliFv, longitudeCliFv, status } = visita;

      const mainColor = status == 'L' ? '#228F6B' : status == 'R' ? '#cb2a29' : status == 'A' ? '#FFA500' : '#cb2a29';

      const customClientIcon = L.divIcon({
        html: `<img src="${customMarkerIconUrl('#007fff', 'C')}" style="width: 45px; height: 60px;" />`,
        className: 'custom-marker-icon',
        iconSize: [80, 80],
        iconAnchor: [20, 40],
      });

      const leafletClientMarker = L.marker([+latitudeCliFv, +longitudeCliFv], { icon: customClientIcon });
      leafletClientMarker.addTo(mapInstance.current);

      const clientPopupContent = document.createElement('div');
      ReactDOM.render(<MarkerPopUp type={'C'} />, clientPopupContent);
      leafletClientMarker.bindPopup(clientPopupContent, {
        offset: L.point(2, -20),
      });

      const customRcaIcon = L.divIcon({
        html: `<img src="${customMarkerIconUrl(mainColor, 'V')}" style="width: 45px; height: 60px;" />`,
        className: 'custom-marker-icon',
        iconSize: [40, 40],
        iconAnchor: [20, 40],
      });

      const leafletRcaMarker = L.marker([+latitude, +longitude], { icon: customRcaIcon });
      leafletRcaMarker.addTo(mapInstance.current);

      const rcaPopupContent = document.createElement('div');
      ReactDOM.render(<MarkerPopUp type={'V'} />, rcaPopupContent);
      leafletRcaMarker.bindPopup(rcaPopupContent, {
        offset: L.point(2, -20),
      });

      const polyline = L.polyline(
        [
          [+latitudeCliFv, +longitudeCliFv],
          [+latitude, +longitude],
        ],
        { color: mainColor, weight: 3, dashArray: '5, 8' },
      );

      polyline.addTo(mapInstance.current);
    };

    useEffect(() => {
      if (!mapRef.current) {
        return;
      }

      let visistDistance =
        visita && visita.distancia ? visita.distancia.replace('M', '').replace('km', '').replace(',', '.') : 0;
      if (visita && visita.distancia && visita.distancia.includes('M')) {
        visistDistance = +visistDistance / 1000;
      }
      const defaultMapZoom = visita
        ? +visistDistance < 0.55
          ? 16
          : +visistDistance < 1.55
          ? 15
          : +visistDistance < 4.55
          ? 13
          : +visistDistance > 6.55 && +visistDistance > 200
          ? 7
          : 12
        : 8;

      mapInstance.current = L.map(mapRef.current, {
        zoomControl: false,
      }).setView(
        visita && visita.latitude && visita.longitude ? [+visita.latitude, +visita.longitude] : [-19.9286, -43.9409],
        defaultMapZoom,
      );

      const layers = mapLayersObject;

      const savedLayer = localStorage.getItem('selectedLayer');
      const defaultLayer = 'Voyager';

      const initialLayer = layers[savedLayer] ? savedLayer : defaultLayer;
      layers[initialLayer].addTo(mapInstance.current);

      const layerControl = L.control.layers(layers).addTo(mapInstance.current);

      mapInstance.current.on('baselayerchange', (e) => {
        localStorage.setItem('selectedLayer', e.name);
      });

      L.control.zoom({ position: 'bottomleft' }).addTo(mapInstance.current);

      drawCanvas();

      return () => {
        mapInstance.current.off('click');
        mapInstance.current.remove();
      };
    }, [visita]);

    return (
      <S.MapContainer>
        <div ref={mapRef} style={{ height: '100svh', width: '100%' }} />
      </S.MapContainer>
    );
  } catch (err) {
    trackGCatchError(err, 'AnaliseVisitas/components/analiseMapa/analiseMapa.tsx');
  }
}
