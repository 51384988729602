import { useEffect, useState } from 'react';
import { useUser } from 'client/hooks';
import { useAddGroupClients } from 'hooks/useGroupClients/useAddGroupClients';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import UseSelectFilial from 'hooks/UseSelectFilial/selectFilial';
import InputText from 'components/Inputs/InputText/text.input';
import { Dialog } from 'primereact/dialog';

import * as S from './novoGrupoModal.index.styles';
import useMediaQuery from 'hooks/useMediaQuery';

export default function NovoGroupModal({
  visible,
  onHide,
  codUsuario,
}: {
  visible: boolean;
  onHide: () => void;
  codUsuario: number;
}) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');
  try {
    const [isValid, setIsValid] = useState(false);

    const { handleSubmit, values, setValue, handleAddGroupClients, handleClearFilial } = useAddGroupClients();
    const { data: user } = useUser();
    const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();
    const { SelectFilial, selectedFilial } = UseSelectFilial(selectedEmpresa?.codEmpresa, codUsuario);

    useEffect(() => {
      trackGPageView('/grupo-clientes/criar');
      alterPageTitle('Criar Grupo Cliente');
    }, []);

    useEffect(() => {
      if (selectedEmpresa && selectedEmpresa.codEmpresa) {
        setValue('codEmpresa', selectedEmpresa.codEmpresa.toString());
      }

      if (selectedFilial && selectedFilial.codFilial) {
        setValue('codFilial', selectedFilial.codFilial.toString());
      }
    }, [selectedEmpresa, selectedFilial]);

    useEffect(() => {
      if (values.nomeGrupo && values.nomeGrupo !== '' && selectedEmpresa?.codEmpresa && selectedFilial?.codFilial) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }, [values, selectedEmpresa, selectedFilial]);

    useEffect(() => {
      handleClearFilial();
    }, [values.codEmpresa]);

    useEffect(() => {
      setValue('codEmpresa', String(user?.codEmpresa));
    }, [user]);

    return (
      <Dialog
        header="Cadastrar novo Grupo de Clientes"
        visible={visible}
        style={{ width: isWebScreen ? '50vw' : '95dvw' }}
        onHide={onHide}
      >
        <S.NovoGrupoModalForm onSubmit={handleSubmit(handleAddGroupClients)}>
          <S.NovoGrupoModalFormContentRow>
            <SelectEmpresa
              label="Empresa *"
              onChange={(e) => {
                setValue('codEmpresa', e.codEmpresa.toString());
              }}
              width={!isWebScreen ? "100%" : "45%"}
            />
            <SelectFilial
              label="Filial *"
              onChange={(e) => {
                setValue('codFilial', e.codFilial.toString());
              }}
              width={!isWebScreen ? "100%" : "45%"}
            />
            <InputText
              label="Nome do Grupo de Clientes *"
              onChange={(e) => {
                setValue('nomeGrupo', e);
              }}
              width={!isWebScreen ? "100%" : "45%"}
            />
          </S.NovoGrupoModalFormContentRow>

          <S.NovoGrupoModalFormFooterRow>
            <Button
              type="submit"
              text="Adicionar"
              color="green"
              icon={<icons.Plus />}
              disabled={!isValid}
              tooltip={isValid ? 'Salvar novo grupo de clientes' : 'Preencha todos os campos'}
              onClick={handleSubmit(handleAddGroupClients)}
              width="45%"
            />
          </S.NovoGrupoModalFormFooterRow>
        </S.NovoGrupoModalForm>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'groupClients/ListGroupClients/TableGroupClients/NewGroupModal/newGroupModal.index.tsx');
  }
}
