import { Dialog } from 'primereact/dialog';
import { Controller } from 'react-hook-form';
import { UseEditFrequenceVisit } from 'hooks/useMarkerMapClientSector/UseEditFrequenceVisit';
import * as S from './mapEditVisit.styles';
import UseMapRouterDaySelector from 'modules/mapa/components/mapRouterDaySelector/mapRouterDaySelector';
import { useEffect, useState } from 'react';
import { updateFrequenceVisit } from 'client/api/mapaCli';
import { toast } from 'react-toastify';
import { useLoaderEffect } from 'providers/loaderEffect';
import { useMap } from '../../regioes.context';
import { ClientesProps } from '../../regioes.types';
import { trackGCatchError } from 'utils/analytics';
import InputText from 'components/Inputs/InputText/text.input';
import InputSwitch from 'components/Inputs/InputSwitch/switch.input';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';

interface IEditFrequenceVisit {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export const MapEditVisit = ({ showModal, setShowModal }: IEditFrequenceVisit) => {
  try {
    const { params, selectedCli, handleGetAllData } = useMap();
    const { handleSubmit, control, errors, isValid, setValue, reset } = UseEditFrequenceVisit({
      setShowModal: setShowModal,
      selectedMarker: selectedCli,
      codCompany: params.codEmpresa.toString(),
      codMap: params.codMapa.toString(),
    });
    const { Selectdays, daySelection, handleDataStrucuteAdjust } = UseMapRouterDaySelector();

    const { setLoader } = useLoaderEffect();

    useEffect(() => {
      if (selectedCli && showModal) handleDataStrucuteAdjust(selectedCli, 'fowards', 'C');
      if (selectedCli) setAllowEditDailyVisit(selectedCli.AGENDAMENTO_DO_CLIENTE == 'S');
    }, [selectedCli, showModal]);

    const [allowEditDailyVisit, setAllowEditDailyVisit] = useState(false);

    const handleSubmitForm = async (data: ClientesProps | any) => {
      data.BLOQ_ROTEIRO = data.BLOQ_ROTEIRO && data.BLOQ_ROTEIRO == true ? 'S' : 'N';

      try {
        await updateFrequenceVisit({
          codEmpresa: +params.codEmpresa,
          codMapa: +params.codMapa,
          codCli: selectedCli.CODCLI,
          freqVisita: +data.freqVisita,
          tempoMinVisita: +data.tempoMinVisita,
          dtProxVisita: data.dtProxVisita ?? '',
          sequencia: +data.sequenciaVisita,
          bloqRoteiro: data.bloqRoteiro,
          agendamentoDoCliente: data.agendamentoDoCliente,
          domingoManha: daySelection.domingo.manha ?? 'N',
          domingoTarde: daySelection.domingo.tarde ?? 'N',
          segundaManha: daySelection.segunda.manha ?? 'N',
          segundaTarde: daySelection.segunda.tarde ?? 'N',
          tercaManha: daySelection.terca.manha ?? 'N',
          tercaTarde: daySelection.terca.tarde ?? 'N',
          quartaManha: daySelection.quarta.manha ?? 'N',
          quartaTarde: daySelection.quarta.tarde ?? 'N',
          quintaManha: daySelection.quinta.manha ?? 'N',
          quintaTarde: daySelection.quinta.tarde ?? 'N',
          sextaManha: daySelection.sexta.manha ?? 'N',
          sextaTarde: daySelection.sexta.tarde ?? 'N',
          sabadoManha: daySelection.sabado.manha ?? 'N',
          sabadoTarde: daySelection.sabado.tarde ?? 'N',
        });
        toast.success('Editado com sucesso!');
        handleGetAllData();
        reset();
        setShowModal(false);
        setTimeout(() => {
          setLoader({
            show: true,
            text: 'Por favor aguarde...',
          });
        }, 1000);
      } catch {
        toast.error('Não foi possível Editar.');
      }
    };

    return (
      <Dialog
        visible={showModal}
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={() => {
          return (
            <span>
              Editar Visita do Cliente: <br />
              <S.ModalCliName>{selectedCli && `[${selectedCli.CODCLI}] ${selectedCli.RAZAOSOCIAL}.`}</S.ModalCliName>
            </span>
          );
        }}
        modal
        className="p-fluid"
        onHide={() => setShowModal(!showModal)}
      >
        <S.Form onSubmit={handleSubmit(handleSubmitForm)}>
          <S.ItemRow>
            <S.ItemRowContent>
              <S.TitleInput error={errors.freqVisita && true}>Frequência Visita</S.TitleInput>
              <Controller
                rules={{
                  required: false,
                }}
                name="freqVisita"
                control={control}
                render={({ field }) => <InputText {...field} type="number" width="100%" />}
              />
              <S.DescriptionInput error={errors.freqVisita && true}>Digite a frequência visita.</S.DescriptionInput>
            </S.ItemRowContent>
            <S.ItemRowContent>
              <S.TitleInput error={errors.tempoMinVisita && true}>Tempo Mínimo Visita</S.TitleInput>
              <Controller
                rules={{
                  required: false,
                }}
                name="tempoMinVisita"
                control={control}
                render={({ field }) => (
                  <InputText {...field} type="number" width="100%" />
                )}
              />
              <S.DescriptionInput error={errors.tempoMinVisita && true}>
                Digite o tempo mínimo visita.
              </S.DescriptionInput>
            </S.ItemRowContent>
          </S.ItemRow>

          <S.ItemRow>
            <S.ItemRowContent>
              <S.TitleInput error={errors.tempoMinVisita && true}>Sequência Visita</S.TitleInput>
              <Controller
                rules={{
                  required: false,
                }}
                name="sequenciaVisita"
                control={control}
                render={({ field }) => <InputText {...field} type="number" width="100%" />}
              />
              <S.DescriptionInput error={errors.tempoMinVisita && true}>
                Digite a sequencia da visita.
              </S.DescriptionInput>
            </S.ItemRowContent>
            <S.ItemRowContent>
              <S.TitleInput error={errors.tempoMinVisita && true}>Data Próxima Visita</S.TitleInput>
              <Controller
                rules={{
                  required: false,
                }}
                name="dtProxVisita"
                control={control}
                render={({ field }) => <InputText {...field} type="date" width="100%" />}
              />
              <S.DescriptionInput error={errors.tempoMinVisita && true}>
                Digite a data da próxima visita.
              </S.DescriptionInput>
            </S.ItemRowContent>
          </S.ItemRow>

          <S.ItemRow>
            <S.ItemRowContent>
              <S.TitleInput error={errors.tempoMinVisita && true}>
                <Controller
                  rules={{
                    required: false,
                  }}
                  name="bloqRoteiro"
                  control={control}
                  render={({ field }) => (
                    <InputSwitch
                      checked={field.value === 'S'}
                      onChange={(e) => {
                        setValue('bloqRoteiro', e ? 'S' : 'N');
                        field.onChange(e ? 'S' : 'N');
                      }}
                    />
                  )}
                />
                Bloqueio Roteirização
              </S.TitleInput>
              <S.DescriptionInput error={errors.tempoMinVisita && true} style={{ color: 'blue', textAlign: 'right' }}>
                Marque se o cliente não pode ser roteirizado.
              </S.DescriptionInput>
            </S.ItemRowContent>

            <S.ItemRowContent>
              <S.TitleInput error={errors.tempoMinVisita && true}>
                <Controller
                  rules={{
                    required: false,
                  }}
                  name="agendamentoDoCliente"
                  control={control}
                  render={({ field }) => (
                    <InputSwitch
                      checked={field.value === 'S'}
                      onChange={(e) => {
                        setValue('agendamentoDoCliente', e ? 'S' : 'N');
                        field.onChange(e ? 'S' : 'N');
                        setAllowEditDailyVisit(e);
                      }}
                    />
                  )}
                />
                Agendamento Manual
              </S.TitleInput>
              <S.DescriptionInput error={errors.tempoMinVisita && true} style={{ color: 'blue', textAlign: 'right' }}>
                Marque se o cliente deve acatar agendamento manual.
              </S.DescriptionInput>
            </S.ItemRowContent>
          </S.ItemRow>

          {allowEditDailyVisit && (
            <S.ItemRow>
              <S.ItemRowContent width="100%">
                <S.TitleInput error={errors.tempoMinVisita && true}>Dia/Turno da Roteirização</S.TitleInput>
                <Selectdays />
                <S.DescriptionInput error={errors.tempoMinVisita && true}>
                  Informe os dias/turnos de atendimento do cliente.
                </S.DescriptionInput>
              </S.ItemRowContent>
            </S.ItemRow>
          )}

          <S.ModalButtonsBox>
            <Button text="Editar" color="green" icon={<icons.Check />} width="100px" tooltip="Editar visita" />
          </S.ModalButtonsBox>
        </S.Form>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'mapEditVisit.index.tsx');
  }
};
