import { useQueryClient } from '@tanstack/react-query';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';

import { saveFotos } from 'client/api';

import * as S from './newFotoModal.styles';
import useMediaQuery from 'hooks/useMediaQuery';
import icons from 'components/Icons/icons.index';
import { Image } from 'primereact/image';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatDate } from 'utils/formatDate';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import Button from 'components/Button/button.index';
import { SelectButton } from 'primereact/selectbutton';
import { trackGCatchError } from 'utils/analytics';

interface NewPhotoDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

interface NewFotoProps {
  statusCode: 1 | 2 | 3;
  statusMessage: string;
  dtCadastro: string;
  foto: string;
  codProd: string;
  nome: string;
  dtAlteracao: string | null;
  descricao: string;
  arquivo: File | string | any;
  fotoType: number;
  codEmpresa: number;
}

type InsertModeType = 'EAN' | 'INTERNO';

interface InsertModeProps {
  value: InsertModeType;
  label: string;
}

const InsertModeOptions: InsertModeProps[] = [
  {
    value: 'EAN',
    label: 'Cód. Barras (EAN)',
  },
  {
    value: 'INTERNO',
    label: 'Cód. Interno (Específico)',
  },
];

export default function NewFotoModal({ isOpen, onClose }: NewPhotoDialogProps) {
  try {
    const isWebScreen = useMediaQuery('(min-width: 1060px)');

    const [newFotos, setNewFotos] = useState<NewFotoProps[]>([]);
    const [newFotosTable, setNewFotosTable] = useState<NewFotoProps[]>([]);
    const [isFotosLoading, setIsFotosLoading] = useState<boolean>(false);
    const [insertMode, setInsertMode] = useState<InsertModeProps>(InsertModeOptions[0]);

    useEffect(() => {
      setNewFotos([]);
      setNewFotosTable([]);
    }, [insertMode]);

    const { selectedEmpresa, SelectEmpresa } = UseSelectEmpresa();

    const queryClient = useQueryClient();

    const handleFotosPostLoop = async () => {
      setIsFotosLoading(true);

      const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

      for (let i = 0; i < newFotos.length; i++) {
        const size = +(newFotos[i].arquivo.size / 1000000).toFixed(2);
        try {
          if (size < 0.1) {
            await delay(1000);

            await saveFotos(newFotos[i]);
            handleUpdateFotoStatus(newFotos[i].nome, 2);
          } else {
            handleUpdateFotoStatus(newFotos[i].nome, 3, 'Imagem maior que 100kb');
          }
        } catch (err) {
          handleUpdateFotoStatus(newFotos[i].nome, 3, err?.response?.data?.message ?? 'Error during processing');
        }
      }

      setIsFotosLoading(false);
    };

    const handleUpdateFotoStatus = (name: string, code: 1 | 2 | 3, message?: string) => {
      setNewFotosTable((prevFotos) =>
        prevFotos.map((foto) =>
          foto.nome === name
            ? {
                ...foto,
                statusCode: code,
                statusMessage: message
                  ? message
                  : code === 1
                  ? 'Na fila para processamento'
                  : code === 2
                  ? 'Processada'
                  : 'Erro no processamento',
              }
            : foto,
        ),
      );
    };

    const handleUpdateAllFotoStatus = () => {
      setNewFotosTable((prevFotos) =>
        prevFotos.map((foto) => {
          return {
            ...foto,
            statusCode: 1,
            statusMessage: 'Na fila para processamento',
          };
        }),
      );
    };

    const handleFotosUpload = (fotos: any) => {
      const files = [];
      for (let i = 0; i < fotos?.target?.files.length; i++) {
        const file: File = fotos?.target?.files[i];
        const newFoto = {
          statusCode: 1,
          statusMessage: 'Na fila para processamento',
          codProd: file.name.split('.')[0],
          nome: file.name,
          dtCadastro: formatDate(new Date(), 'EN'),
          descricao: '',
          arquivo: file,
          fotoType: insertMode.value == 'EAN' ? 0 : 1,
          codEmpresa: selectedEmpresa.codEmpresa,
          foto: file,
          dtAlteracao: formatDate(new Date(), 'EN'),
        };
        files.push(newFoto);
      }
      setNewFotos(files ?? []);
      setNewFotosTable(files ?? []);
    };

    const renderFooter = () => (
      <S.FooterButtonBox>
        <Button
          text="Salvar"
          icon={<icons.Check />}
          color="green"
          onClick={() => {
            handleUpdateAllFotoStatus();
            handleFotosPostLoop();
          }}
          loading={isFotosLoading}
        />
      </S.FooterButtonBox>
    );

    const fotoTemplate = (foto: NewFotoProps) => {
      return (
        <S.FotosImageBox>
          <Image src={URL.createObjectURL(foto.arquivo)} alt={foto.nome} width="100" preview />
        </S.FotosImageBox>
      );
    };

    const nameTemplate = (foto: NewFotoProps) => {
      return `${foto.arquivo.name}`;
    };

    const sizeTemplate = (foto: NewFotoProps) => {
      return `${(foto.arquivo.size / 1000000).toFixed(2)} MB`;
    };

    const statusTemplate = (foto: NewFotoProps) => {
      const color = foto.statusCode == 1 ? 'blue' : foto.statusCode == 2 ? 'green' : 'red';
      const label = foto.statusCode == 1 ? 'Fila' : foto.statusCode == 2 ? 'Concluído' : 'Erro';
      return (
        <Button
          style={{ maxWidth: '100px' }}
          icon={<icons.Dot />}
          iconPosition={'left'}
          color={color}
          text={label}
          textMode
          tag
        />
      );
    };

    const statusMessageTemplate = (foto: NewFotoProps) => {
      return `${foto.statusMessage}`;
    };

    return (
      <Dialog
        header={'Novas fotos de produtos'}
        visible={isOpen}
        style={{ width: isWebScreen ? '80vw' : '90%' }}
        footer={renderFooter}
        onHide={onClose}
      >
        <S.NewFotosMainBox>
          <SelectButton
            unselectable
            value={insertMode.value}
            options={InsertModeOptions}
            onChange={(e) => {
              setInsertMode(InsertModeOptions.filter((IO: InsertModeProps) => IO.value == e.value)[0]);
            }}
          />

          {insertMode.value != 'EAN' && (
            <S.InternalImageDataBox>
              <SelectEmpresa label={'Empresa *'} />
            </S.InternalImageDataBox>
          )}

          {newFotos && (
            <S.NewFotosInputBox>
              <S.NewFotosInputButtonBox>
                <Button text="Escolher" icon={<icons.Plus />} color="green" />
                <span>Arraste e solte os arquivos aqui para fazer upload.</span>
              </S.NewFotosInputButtonBox>

              <S.NewFotosInput
                type="file"
                accept="image/*"
                multiple={insertMode.value == 'EAN'}
                onChange={handleFotosUpload}
                style={{ display: 'block', marginBottom: '20px' }}
              />
            </S.NewFotosInputBox>
          )}

          <S.NewFotosTableBox>
            <DataTable value={newFotosTable} width={'100%'} size="small">
              <Column field="arquivo.name" header="Foto" body={fotoTemplate} alignHeader="center" />
              <Column field="arquivo.name" header="Nome" body={nameTemplate} />
              <Column field="arquivo.size" header="Tamanho" body={sizeTemplate} />
              <Column field="statusCode" header="Status" body={statusTemplate} />
              <Column field="statusMessage" header="Mensagem" body={statusMessageTemplate} />
            </DataTable>
          </S.NewFotosTableBox>
        </S.NewFotosMainBox>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'cadastro/components/newFotoModal.index.tsx');
  }
}
