import * as S from './regrasTable.styles';

import { RegraProps } from 'client/interfaces';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SetStateAction, useEffect, useState } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { Accordion, Container, Tag } from 'reactivus';
import { MdArrowForwardIos, MdOutlineCancel } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { regraTypeOptions } from '../../regraComercial.types';
import RegrasImagesModal from '../regrasImagesModal/regrasImagesModal.index';
import EditRegraModal from '../editRegraModal/editRegraModal.index';
import { postEditRegra } from 'client/api/regraComercial';
import { toast } from 'react-toastify';
import { trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';

type regrasTableProps = {
  regrasList: RegraProps[];
  selectedRegra?: RegraProps;
  setSelectedRegra: React.Dispatch<SetStateAction<RegraProps>>;
  codEmpr: number;
  handleGetRegrasComerciais: () => void;
};

export default function RegrasTable({
  regrasList,
  selectedRegra,
  setSelectedRegra,
  codEmpr,
  handleGetRegrasComerciais,
}: regrasTableProps) {
  try {
    const [isTableOpen, setIsTableOpen] = useState<boolean>(true);

    const [showRegrasImagesModal, setShowRegrasImagesModal] = useState(false);
    const [toEditRegraImages, setToEditRegraImages] = useState(null);

    const [showEditRegraModal, setShowEditRegraModal] = useState(false);
    const [toEditRegra, setToEditRegra] = useState(null);

    useEffect(() => {
      if (!showRegrasImagesModal && toEditRegraImages) {
        setToEditRegraImages(null);
      }
      if (!showEditRegraModal && toEditRegra) {
        setToEditRegra(null);
      }
    }, [showRegrasImagesModal, showEditRegraModal]);

    const { id: regraType } = useParams();

    useEffect(() => {
      if (selectedRegra) {
        setIsTableOpen(false);
      } else if (!isTableOpen) {
        setIsTableOpen(true);
      }
    }, [selectedRegra]);

    const today = new Date();
    const parseDate = (dateStr: string) => {
      const [day, month, year] = dateStr.split('/').map(Number);
      return new Date(year, month - 1, day);
    };

    const activeRuleBodyTemplate = (rule: RegraProps) => {
      return (
        <div
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <InputSwitch
            checked={rule.ativa == 'S'}
            onChange={(e) => {
              e.stopPropagation();
              handleEditRegra(rule, 'ativa', e.value ? 'S' : 'N');
            }}
          />
        </div>
      );
    };

    const enviaFvRuleBodyTemplate = (rule: RegraProps) => {
      return (
        <div
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <InputSwitch
            checked={rule.enviafv == 'S'}
            onChange={(e) => {
              handleEditRegra(rule, 'enviafv', e.value ? 'S' : 'N');
            }}
          />
        </div>
      );
    };

    const AaccordionHeaderTemplate = () => {
      const isRegraAtiva = selectedRegra && selectedRegra.enviafv == 'S' ? true : false;
      const isEnviaEcommerceAtivo = selectedRegra && selectedRegra.enviaecommerce == 'S' ? true : false;
      const isEnviaFvAtivo = selectedRegra && selectedRegra.enviafv == 'S' ? true : false;
      return (
        <S.RegrasAccordionTitle>
          {selectedRegra && <MdOutlineCancel onClick={() => setSelectedRegra(null)} />}
          <span>{selectedRegra ? `[${selectedRegra.codregra}] ${selectedRegra.nomeregra}` : 'Regras'}</span>
          <br></br>
          <br></br>
          {selectedRegra && (
            <Tag label={isRegraAtiva ? 'Ativa' : 'Inativa'} color={isRegraAtiva ? 'success' : 'danger'} text />
          )}
          {selectedRegra && <Tag label={'Envia FV'} color={isEnviaFvAtivo ? 'success' : 'danger'} text />}
          {selectedRegra && <Tag label={'Envia EC'} color={isEnviaEcommerceAtivo ? 'success' : 'danger'} text />}
          {selectedRegra && (
            <Tag
              label={parseDate(selectedRegra.dataFim) < today ? 'Expirada' : 'Vigente'}
              color={parseDate(selectedRegra.dataFim) < today ? 'danger' : 'success'}
              text
            />
          )}
        </S.RegrasAccordionTitle>
      );
    };

    const selectedRowClassName = (rowData: RegraProps) => {
      const dataFimDate = parseDate(rowData.dataFim);

      if (selectedRegra && rowData.codregra === selectedRegra.codregra) {
        return {
          'selected-table-row': selectedRegra && rowData.codregra === selectedRegra.codregra,
        };
      } else {
        return {
          'expirada-table-row': dataFimDate < today,
          'vigente-table-row': dataFimDate >= today,
        };
      }
    };

    const selectRegraTemplate = (rowData: RegraProps) => {
      return (
        <MdArrowForwardIos
          onClick={(e) => {
            rowData.codregra != 0 && setSelectedRegra(rowData);
          }}
        />
      );
    };

    const imageRegraIconTemplate = (rowData: RegraProps) => (
      <S.RegrasTableIcon
        onClick={(e) => {
          e.stopPropagation();
          setShowRegrasImagesModal(true);
          setToEditRegraImages(rowData);
        }}
      >
        <img
          alt={'BannerImage'}
          src={`${process.env.REACT_APP_BASE_URL}/fotos_campanhas/${
            rowData.img1 ? rowData.img1 : 'sem-imagem.jpeg'
          }?t=${new Date().getTime()}_${Math.random()}`}
        />
      </S.RegrasTableIcon>
    );

    const editRegraIconTemplate = (rowData: RegraProps) => {
      return (
        <Button
          color="black"
          icon={<icons.Edit />}
          onClick={(e) => {
            e.stopPropagation();
            setShowEditRegraModal(true);
            setToEditRegra(rowData);
          }}
          bgOnlyOnHover
          rounded
        />
      );
    };

    const handleEditRegra = (regraToEdit: RegraProps, field: string, newValue: string) => {
      regraToEdit[field] = newValue;
      postEditRegra(regraToEdit)
        .then((res) => {
          if (res.succeeded) {
            handleGetRegrasComerciais();
            setShowEditRegraModal(false);
          }
        })
        .catch((err) => {
          toast.error('Falha inesperada ao editar regra');
          console.log('err :', err);
        });
    };

    const funcionarioTemplate = (rowData: RegraProps) => {
      return (
        <S.RegrasFuncTemplate>
          {rowData.codfunccad && `[${rowData.codfunccad}] ${rowData.nomefunccad}`}
        </S.RegrasFuncTemplate>
      );
    };

    return (
      <Accordion
        title={selectedRegra ? `[${selectedRegra.codregra}] ${selectedRegra.nomeregra}` : 'Regras'}
        isOpenControl={isTableOpen}
        setIsOpenControl={setIsTableOpen}
        headerTemplate={<AaccordionHeaderTemplate />}
      >
        <S.RegrasTableMainBox>
          <S.RegrasBodyBox>
            <DataTable
              value={regrasList ?? []}
              paginator={false}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rows={100}
              rowHover
              stripedRows
              scrollHeight="69dvh"
              style={{ minWidth: '50rem', minHeight: '69dvh' }}
              rowClassName={selectedRowClassName}
              emptyMessage={'Nenhuma regra encontrada'}
              size="small"
              responsiveLayout="scroll"

            >
              <Column field="" header="" body={selectRegraTemplate} />
              <Column field="filial" header="Filial" sortable />
              <Column field="codregra" header="Cód. Regra" sortable />
              <Column field="nomeregra" header="Regra" sortable />
              <Column
                field="validarcasadinha"
                header="Validar Regra"
                sortable
                hidden={
                  regraTypeOptions[regraType] &&
                  (regraTypeOptions[regraType].type == 'K' || regraTypeOptions[regraType].type == 'I')
                }
              />

              <Column field="dataInicio" header="Dt. Início" sortable />
              <Column field="dataFim" header="Dt. Fim" sortable />

              <Column field="ativa" header="Ativo" sortable body={activeRuleBodyTemplate} />
              <Column field="enviafv	" header="Envia FV" sortable body={enviaFvRuleBodyTemplate} />
              <Column
                field="nomefunccad"
                header="Funcionário"
                sortable
                body={funcionarioTemplate}
                align="left"
              />
              <Column header="Editar" body={editRegraIconTemplate} align="left" headerClassName="header-center" />
              <Column
                header="Imagem"
                body={imageRegraIconTemplate}
                align="left"
                hidden={regraTypeOptions[regraType] && regraTypeOptions[regraType].type != 'K'}
              />
            </DataTable>
          </S.RegrasBodyBox>
        </S.RegrasTableMainBox>
        {showRegrasImagesModal && (
          <RegrasImagesModal
            showRegrasImagesModal={showRegrasImagesModal}
            setShowRegrasImagesModal={setShowRegrasImagesModal}
            codEmpresa={codEmpr}
            handleGetRegrasComerciais={handleGetRegrasComerciais}
            regra={toEditRegraImages}
          />
        )}
        <EditRegraModal
          showEditRegraModal={showEditRegraModal}
          setShowEditRegraModal={setShowEditRegraModal}
          handleGetRegrasComerciais={handleGetRegrasComerciais}
          regraToEdit={toEditRegra}
          codEmpresa={codEmpr}
        />
      </Accordion>
    );
  } catch (err) {
    trackGCatchError(err, 'regrasComerciais/components/regrasTable/regrasTable.index.tsx');
  }
}
