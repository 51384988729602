import styled, { css } from 'styled-components';

export const NovoGrupoModalForm = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  align-items: flex-end;
`;

export const NovoGrupoModalFormContentRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  align-items: flex-end;
  justify-content: space-between;
`;

export const NovoGrupoModalFormFooterRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  align-items: flex-end;
  justify-content: flex-end;
`;
