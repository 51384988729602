import * as S from './analiseVisitas.styles';
import { useState, useEffect } from 'react';

import useMediaQuery from 'hooks/useMediaQuery';
import AnaliseSideBar from './components/analiseSideBar/mapSidebar';
import MapSubtitle from './components/analiseMapa/mapSubtitle';
import AnaliseHeader from './components/analiseHeader';
import AnaliseMapa from './components/analiseMapa/analiseMapa.index';
import { VisitaProps } from './analiseVisitas.types';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { alterPageTitle } from 'utils/alterPageTitle';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';

export default function AnaliseVisitas() {
  try {
    useEffect(() => {
      trackGPageView('/analise-visitas');
      alterPageTitle('Análise Visitas');
    }, []);

    const [selectedCli, setSelectedCli] = useState<VisitaProps>(null);

    const [searchResult, setSearchResult] = useState<VisitaProps[]>([]);

    const isWebScreen = useMediaQuery('(min-width: 1060px)');

    const [showMenu, setShowMenu] = useState(false);

    return (
      <S.MapMainBox>
        {isWebScreen ? (
          <AnaliseHeader
            setSearchResult={setSearchResult}
            selectedCli={selectedCli}
            setSelectedCli={setSelectedCli}
            showMenu={showMenu}
          />
        ) : (
          <S.MobileHeader>
            <Button
              color="white"
              icon={<icons.List />}
              id={'kt_aside_mobile_toggle'}
              style={{
                boxShadow: 'var(--v-box-shadow)',
              }}
            />
          </S.MobileHeader>
        )}
        <MapSubtitle selectedCli={selectedCli} />
        <AnaliseMapa visita={selectedCli} showMenu={showMenu} />
        <AnaliseSideBar
          data={searchResult}
          selectedCli={selectedCli}
          setSearchResult={setSearchResult}
          setSelectedCli={setSelectedCli}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
        />
      </S.MapMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'AnaliseVisitas/analiseVisitas.index.tsx');
  }
}
