import { PolygonRegionProps } from 'modules/mapa/pages/Regioes/regioes.types';
import * as S from './managePolygon.styles';
import { useEffect, useState } from 'react';
import UseSelectColor from 'hooks/UseSelectColor/selectColor.index';
import { trackGCatchError } from 'utils/analytics';
import icons from 'components/Icons/icons.index';
import InputText from 'components/Inputs/InputText/text.input';

interface SingleColorProps {
  COR: string;
  NOME: string;
}

interface PolyEditDataProps {
  name: string;
  cor: SingleColorProps;
}

export default function ManagePolygon({
  enableEditing,
  onConfirm,
  onCancel,
  removePopUp,
  handlePolygonRemoval,
  polygon,
}: {
  enableEditing?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  removePopUp?: () => void;
  handlePolygonRemoval: () => void;
  polygon: number | string;
}) {
  try {
    const [poly, setPoly] = useState<PolygonRegionProps>(null);

    const [isEditEnabled, setIsEditEnabled] = useState(false);

    const [polyEditData, setPolyEditData] = useState<PolyEditDataProps>({
      name: '',
      cor: { NOME: 'VERDE LIMO', COR: '42bb7d' },
    });

    const { SelectColor, selectedColor, setSelectedColor } = UseSelectColor();

    useEffect(() => {
      if (polygon) {
        const existingPolygons = JSON.parse(localStorage.getItem('vmais_polygons') || '[]');
        const polygonData = existingPolygons.find((p: PolygonRegionProps) => +p.id === +polygon);
        if (polygonData) {
          setSelectedColor(polygonData.colorData ? polygonData.colorData : { NOME: 'VERDE LIMO', COR: '42bb7d' });
        }
        setPolyEditData({
          name: polygonData.name ? polygonData.name : 'Polygono',
          cor: polygonData.colorData ? polygonData.colorData : { NOME: 'VERDE LIMO', COR: '42bb7d' },
        });
      }
    }, [polygon]);

    const existingPolygons = JSON.parse(localStorage.getItem('vmais_polygons') || '[]');

    const handleChangePolygonColorById = (polygonId: number | String, cor: SingleColorProps) => {
      const pathElement: any = document.querySelector(`.polygon-vm${polygonId}`);

      if (pathElement) {
        if (pathElement.getAttribute('fill') !== `#${cor.COR}`) {
          pathElement.setAttribute('stroke', `#${cor.COR}`);
          pathElement.setAttribute('fill', `#${cor.COR}`);
        }

        const updatedPolygons = existingPolygons.map((polygon: any) => {
          if (+polygon.id === +polygonId) {
            polygon.color = `#${cor.COR}`;
            polygon.colorData = cor;
          }
          return polygon;
        });
        localStorage.setItem('vmais_polygons', JSON.stringify(updatedPolygons));
      }
    };

    const handleChangePolygoNameById = (polygonId: number | String, name: string) => {
      const updatedPolygons = existingPolygons.map((polygon: any) => {
        if (+polygon.id === +polygonId) {
          polygon.name = name;
        }
        return polygon;
      });
      localStorage.setItem('vmais_polygons', JSON.stringify(updatedPolygons));
    };

    useEffect(() => {
      const savedPolygons = localStorage.getItem('vmais_polygons');
      const polygonsArray = JSON.parse(savedPolygons);

      if (savedPolygons && polygonsArray) {
        setPoly(polygonsArray?.find((pol?: PolygonRegionProps) => +pol.id === +polygon));
      } else {
        setPoly(null);
      }
    }, [polygon]);

    const numberFormat = (number: number) => {
      return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(number);
    };

    function countLeadingZerosAfterDot(num) {
      const numStr = num.toString();
      if (numStr.includes('.')) {
        const fractionalPart = numStr.split('.')[1];
        let zerosCount = 0;
        for (let i = 0; i < fractionalPart.length; i++) {
          if (fractionalPart[i] === '0') {
            zerosCount++;
          } else {
            break;
          }
        }
        return zerosCount;
      }
      return 0;
    }

    const formatArea = (area: number): string => {
      const newArea = area / 1000;
      const decimalPlaces = countLeadingZerosAfterDot(newArea);

      if (newArea < 1 && decimalPlaces == 0) {
        return `${numberFormat(area)} m²`;
      } else {
        return `${numberFormat(area / 100000)} km²`;
      }
    };

    const handleSavePolygonData = () => {
      handleChangePolygonColorById(polygon, selectedColor);
      handleChangePolygoNameById(polygon, polyEditData.name);
      setIsEditEnabled(false);
    };

    return (
      <S.ManagePolygonMainBox>
        <S.ManagePolygonNameBox inputBorder={isEditEnabled}>
          <InputText
            value={polyEditData ? polyEditData.name : 'Polygono'}
            onChange={(e) =>
              setPolyEditData((prev: PolyEditDataProps) => {
                return {
                  ...prev,
                  name: e,
                };
              })
            }
            disabled={!isEditEnabled}
          />
        </S.ManagePolygonNameBox>

        <S.ManagePolygonButtonsBox>
          {!isEditEnabled && (
            <S.ManagePolygonButtonInfo
              onClick={() => {
                setIsEditEnabled(true);
              }}
            >
              <icons.Edit />
            </S.ManagePolygonButtonInfo>
          )}

          {!isEditEnabled && (
            <S.ManagePolygonButtonDangerTrash
              onClick={() => {
                handlePolygonRemoval && handlePolygonRemoval();
              }}
            >
              <icons.RemoveFill />
            </S.ManagePolygonButtonDangerTrash>
          )}
        </S.ManagePolygonButtonsBox>

        {!isEditEnabled && (
          <S.ManagePolygonAreaBox>Área: {poly ? formatArea(poly.area) : '0 m²'}</S.ManagePolygonAreaBox>
        )}

        {isEditEnabled && (
          <S.ManagePolygonColorBox>
            <S.ManagePolygonColorTitle>Cor:</S.ManagePolygonColorTitle>
            <SelectColor />
          </S.ManagePolygonColorBox>
        )}

        <S.ManagePolygonFooterButtonsBox>
          {isEditEnabled && (
            <S.ManagePolygonButtonSuccess
              onClick={() => {
                handleSavePolygonData();
              }}
            >
              <icons.Check />
              <span>Salvar</span>
            </S.ManagePolygonButtonSuccess>
          )}
          {isEditEnabled && (
            <S.ManagePolygonButtonDangerCancel
              onClick={() => {
                setIsEditEnabled(false);
              }}
            >
              <icons.X />
            </S.ManagePolygonButtonDangerCancel>
          )}
        </S.ManagePolygonFooterButtonsBox>
      </S.ManagePolygonMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'mapPolygon.index.tsx');
  }
}
