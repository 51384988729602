import * as SI from 'styles/inputs.styles';
import { InputSwitch as PrimeInputSwitch, InputSwitchProps } from 'primereact/inputswitch';

export default function InputSwitch({
  onChange,
  width,
  label = '',
  checked = false,
  ...rest
}: {
  onChange?: (checked: boolean) => void;
  width?: string;
  label?: string;
  checked?: boolean;
} & InputSwitchProps) {
  const widthCalculated = label.length * 7 + 10 > 200 ? 200 : label.length * 7 + 10;

  const handleChange = (checked: boolean) => {
    if (onChange) {
      onChange(checked);
    }
  };

  return (
    <SI.InputMainBox width={width} widthCalculated={widthCalculated} className="v-input-switch">
      <label>{label}</label>
      <PrimeInputSwitch
        checked={checked}
        onChange={(e) => {
          handleChange(e.value as boolean);
        }}
        {...rest}
      />
    </SI.InputMainBox>
  );
}
