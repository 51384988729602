import { useEffect } from 'react';
import ClientAccordeon from './components/clientAccordeon/clientAccordeon';
import PedList from './components/pedList/pedList.index';
import DebitSalesCharts from './components/debitSalesCharts/debitSalesCharts';
import * as S from './styles';
import { usePedido } from 'providers/pedidoProvider';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import axios from 'axios';
import InputText from 'components/Inputs/InputText/text.input';
import InputDate from 'components/Inputs/InputDate/date.input';

export default function Pedido() {
  try {
    useEffect(() => {
      trackGPageView('/pedido');
      alterPageTitle('Pedidos');
    }, []);

    const { cliData, setCliData }: any = usePedido();

    const getCliData = (codCli: string) => {
      axios({
        url: 'http://10.122.65.57:29292/api/venda/cliente',
        method: 'GET',
        params: {
          codCli: codCli.replace(/\D/g, '') ?? null,
        },
      })
        .then((res: any) => {
          if (res.data.length > 0) {
            setCliData(res.data[0]);
          }
        })
        .catch((err) => {
          console.log('Error: ' + err.name + ' - ' + err.code + '');
        });
    };

    return (
      <S.PedidoMainBox>
        <S.PedidoHeaderMainBox>
          <InputText
            label="Cliente"
            width="220px"
            placeholder="COD / CPF / CNPJ"
            onChange={(e) => {
              getCliData(e);
            }}
            onKeyDown={(e: any) => {
              if (e.key == 'Enter') {
                getCliData(e.target.value);
              }
            }}
          />

          <InputDate label="Dt. Ini" />
          <InputDate label="Dt. Fim" />

          <InputText
            label="Buscar"
            width="220px"
            placeholder="Pesquisar no grid..."
            onChange={(e) => {
              // getCliData(e);
            }}
          />
        </S.PedidoHeaderMainBox>

        <DebitSalesCharts cliente={cliData} />

        {/* <DebitList cliente={cliData} showDebList={showDebList} setShowDebList={setShowDebList} /> */}

        {cliData && <ClientAccordeon cliente={cliData} />}

        <PedList cliente={cliData} />
      </S.PedidoMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'pedido/pages/pedido/index.tsx');
  }
}
