import styled, { css } from 'styled-components';

export const ContainerPage = styled.div`
  padding: 40px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;
`;

export const ItemRowFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 15px;
`;

interface InputProps {
  error?: boolean;
}

export const DescriptionInput = styled.small<InputProps>`
  ${(props) =>
    props.error &&
    css`
      color: red;
    `}
`;

export const TitleInput = styled.label<InputProps>`
  ${(props) =>
    props.error &&
    css`
      color: red;
    `}
`;

interface IResponsiveProps {
  mobile?: boolean;
  tablet?: boolean;
}

export const Select = styled.select<IResponsiveProps>`
  height: 40px;
  border: solid 1px #ced4da;
  background-color: #ffffff;
  border-radius: 6px;
  color: #495057;
  font-size: 1rem;
  padding: 5px;

  ${(props) =>
    props.mobile &&
    css`
      @media (max-width: 768px) {
        width: 100px;
        font-size: 10px;
        padding: 3px;
      }
    `}

  ${(props) =>
    props.tablet &&
    css`
      @media (max-width: 992px) {
        width: 100px;
        font-size: 10px;

        padding: 3px;
      }
    `}
`;

export const Option = styled.option`
  height: 20px;
  border: solid 1px #ced4da;
  background-color: #ffffff;
  color: #495057;
  font-size: 1rem;
  margin: 10px;
`;
