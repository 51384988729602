import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
   :root {
       --primary-green: #228F6B;
       --primary-green-darker:rgb(27, 116, 86);
       --primary-green-hover: #228F6B20;
       --secondary-blue: #375E97;

       --green-300: #59BACB;
       --orange-300: #F28C3D;

       --gray-100: #e4e7f1;
       --gray-200: #63667f;
       --gray-300: #f5f8fa;
       --gray-400: #fafafa;
       --gray-500: #e7e7e7;

       --dark-100: #F3F3F3;
       --dark-200: #E8E8E8;
       --dark-300: #BABABA;
       --dark-400: #495057;
       --dark-500: #1B1B1B;
       --dark-900: #090908;

       --blue-600: #217CCC;
       --blue-700: #185AA6;
       --blue-800: #113F82;
       --blue-900: #0D2D69;

       --yellow-100: #FFFACA;
       --yellow-200: #FFF498;
       --yellow-300: #FFEE6B;
       --yellow-400: #FFE74F;
       --yellow-500: #FFDD35;
       --yellow-600: #D6B62C;

       --orange-400: #ff9102;

       --red-100: #FFE1DB;
       --red-200: #FEBFB8;
       --red-300: #FE9796;
       --red-400: #FE7D86;
       --red-500: #FE5770;
       --red-600: #D53F62;
       --red-700: #AD2C55;
       --red-800: #871D48;
       --red-900: #6E1441;
       --danger-500: #FE5770;

       --green-100: #EEFAC8;
       --green-200: #DBF598;
       --green-300: #BAE468;
       --green-400: #97CB47;
       --green-500: #6BAC29;
       --green-600: #559022;
       --green-700: #41761C;
       --green-800: #305D16;
       --green-900: #254C12;

       --green: #BAE468;
       --light-green: #A9C58E;
       --dark-green: #254C12;

       --blue: #088CE8;
       --light-blue: #9DD7FF;

       --pink: #FE7D86;
       --light-pink: #FFC8C8;

       --red: #F64F3D;

       --white: #FFFFFF;

       --dark-gray: #767676;
       --light-gray: #ACACAC;



        /* ---------- # ---------- */
        /* VMAIS COLORS */

        --v-text-black: #000;
        --v-text-gray:rgb(120, 120, 122);

        --v-green-aqua: #27b1a7;
        --v-green: #228f6b;
        --v-green-dark: #176148;
        --v-green-20: #228f6b20;
        --v-green-40: #228f6b40;

        --v-red: #f32929;
        --v-red-dark: #cb2a29;
        --v-red-20: #f3292920;
        --v-red-40: #f3292940;
        
        --v-orange: #f64f3d;
        --v-orange-dark: #d63826;
        
        --v-blue: #375e97;
        --v-blue-dark: #1e4279;
        --v-blue-20: #217CCC20;
        --v-blue-40: #217CCC40;
        
        --v-gray: #606062;
        --v-gray-dark: #424246;
        
        --v-white: #FFFFFF;
        --v-white-dark: #FAFAFA;
        
        --v-black: #1B1B1B;
        --v-black-dark: #090908;
        
        --v-dark: #606062;
        --v-dark-dark: #424246;
        --v-dark-20: #60606220;

        --v-box-shadow-1: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
        --v-box-shadow-2: rgba(0, 0, 0, 0.075) 0px 1px 3px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;

   }

   * { 
        .button-vmais-primary {
            background-color: var(--primary-green);
            border: none;
        }
        .p-calendar .p-inputtext {
            width: 100%;
            height: 100%;
        }
        
        p {
            margin: 0px;
        }        

        font-weight: 400; 
        .p-inputtext:enabled:hover {
            border-color: transparent;
        }
        .p-inputtext:enabled:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem;
            border-color: transparent;
        }

        //Full Calendar
        .fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
            background-color: var(--primary-green);
        }
        .fc.fc-theme-standard .fc-toolbar .fc-button {
            background-color: var(--primary-green);
        }
        .fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
            background-color: var(--primary-green);
        }

        //SweetAlert 
        .swal2-styled.swal2-confirm {
            background-color: var(--primary-green) !important;
        }
        .swal2-styled.swal2-cancel {
            background-color: var(--red-400) !important;
        }

        //Button choose file
        .p-button.p-fileupload-choose.p-component {
            background-color: var(--primary-green);
            border:none;

            :hover {
                background-color: var(--dark-green);
            }
        }

        //Button toggle
        .p-button.p-component.p-highlight {
            background-color: var(--primary-green);

            :hover {
                background-color: var(--dark-green);
            }
        }

        // Switch
        .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
            background-color: var(--primary-green);

            :hover {
                background-color: var(--dark-green);
            }
        }

        .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
            background-color: var(--dark-green);
        }

        //Se mobile 
        .p-dropdown .p-dropdown-label {
            @media (max-width: 992px) {
                font-size: 10px;
            }
        } 
        
        .p-dropdown-items {
            @media (max-width: 992px) {
                font-size: 10px;
            }
        }

        //Mapa Setores/Clientes (esconde as options do header)
        .gmnoprint {
            @media (max-width: 992px) {
                display: none;

            }
        }

        * .p-inputtext:enabled:hover {
            border-color: var(--primary-green);
        }

        .p-dropdown:not(.p-disabled):hover {
            border-color: var(--primary-green);
        }

        .p-multiselect-header > .p-checkbox {
            margin-right: 0.5rem;
        }

        .show.menu-dropdown > &,
        &.menu.show,
        &.show[data-popper-placement] {
            display: flex;
            will-change: transform;
            position: absolute !important;
            z-index: 9999 !important;
            
            // Animation
            @if ($menu-dropdown-animation == true) {
                // Move up
                animation: menu-sub-dropdown-animation-fade-in #{$menu-dropdown-animation-speed} ease 1, menu-sub-dropdown-animation-move-up #{$menu-dropdown-animation-speed} ease 1;
            }                    
        }

   }
`;
