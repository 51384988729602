import useMediaQuery from 'hooks/useMediaQuery';
import { Dialog } from 'primereact/dialog';
import { SetStateAction, useRef, useState } from 'react';
import styled from 'styled-components';
import { InputTextarea } from 'primereact/inputtextarea';

import * as S from './editKpiModal.styles';
import { KpiProps } from 'client/interfaces';
import { useKpi } from 'modules/kpi/kpi.context';
import { putEditKpi } from 'client/api/kpis';
import { toast } from 'react-toastify';
import { trackGCatchError } from 'utils/analytics';
import InputText from 'components/Inputs/InputText/text.input';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';
import Dropdown from 'components/Dropdown/dropdown';

interface editKpiModalProps {
  showEditKpiModal: boolean;
  setShowEditKpiModal: React.Dispatch<SetStateAction<boolean>>;
}

export default function EditKpiModal({ showEditKpiModal, setShowEditKpiModal }: editKpiModalProps) {
  try {
    const isWebScreen = useMediaQuery('(min-width: 1060px)');

    const { selectedKpi, handleGetKpiList, agrupadorOptions } = useKpi();

    const [toEditKpi, setToEditKpi] = useState<KpiProps>({ ...selectedKpi });

    const textareaRef = useRef(null);

    const handleEditRegra = () => {
      console.log(agrupadorOptions.filter((agrupador: any) => +agrupador.code == toEditKpi.agrupador)[0]);
      const kpiDataToEdit = { ...toEditKpi };
      kpiDataToEdit.sqlleitura = textareaRef.current ? textareaRef.current.value : '';
      kpiDataToEdit.itens = null;
      putEditKpi(kpiDataToEdit)
        .then((res) => {
          if (res.succeeded) {
            handleGetKpiList();
            toast.success('KPI editado com sucesso');
            setShowEditKpiModal(false);
          }
        })
        .catch((err) => {
          toast.error('Falha inesperada ao editar KPI.');
          console.log('err :', err);
        });
    };

    const StyledTextarea = styled(InputTextarea)`
      resize: none;
    `;

    return (
      <Dialog
        header={toEditKpi ? `Editar KPI [${toEditKpi.nrokpi}] - ${toEditKpi.titulo}` : 'Editar KPI'}
        visible={showEditKpiModal}
        style={{ width: isWebScreen ? '55vw' : '90%' }}
        onHide={() => {
          setShowEditKpiModal(false);
        }}
      >
        <S.EditKpiModalFormBox>
          <S.EditKpiModalFormRow>
            <InputText
              label="Título"
              id="titulo_kpi"
              placeholder="Título"
              value={toEditKpi.titulo}
              onChange={(e) => {
                e = e.toUpperCase();
                setToEditKpi((prevState: any) => {
                  return {
                    ...prevState,
                    titulo: e,
                  };
                });
              }}
              width="clamp(200px, 250px, 300px)"
            />

            <InputText
              label="Freq. Atualiza"
              id="freqatualiza_kpi"
              placeholder="Freq. Atualiza"
              value={toEditKpi.freqatualiza}
              onChange={(e) => {
                setToEditKpi((prevState: any) => {
                  return {
                    ...prevState,
                    freqatualiza: e,
                  };
                });
              }}
              width="clamp(75px, 90px, 120px)"
            />

            <Dropdown
              label="Agrupador"
              options={agrupadorOptions}
              value={(toEditKpi.agrupador ?? 1).toString()}
              onChange={(e) => {
                setToEditKpi((prevState: any) => {
                  return {
                    ...prevState,
                    agrupador: e.value,
                  };
                });
              }}
              placeholder="Agrupador"
              optionLabel="name"
              optionValue="code"
              itemTemplate={(agrupador) => {
                return '[' + agrupador.code + '] - ' + agrupador.name;
              }}
              id="agrupador_kpi"
            />
          </S.EditKpiModalFormRow>

          <S.EditKpiModalFormRow>
            <S.KpiInputBox>
              <label htmlFor="sqlleitura_kpi">SQL Apuração</label>
              <StyledTextarea
                id="sqlleitura_kpi"
                ref={textareaRef}
                defaultValue={toEditKpi ? toEditKpi.sqlleitura : ''}
                rows={19}
                cols={100}
              />
            </S.KpiInputBox>
          </S.EditKpiModalFormRow>

          <S.EditKpiModalFormFooter>
            <Button
              text="Salvar"
              color="green"
              icon={<icons.Check />}
              onClick={() => {
                handleEditRegra();
              }}
              tooltip="Salvar alterações no KPI"
            />
          </S.EditKpiModalFormFooter>
        </S.EditKpiModalFormBox>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'kpi/components/editKpiModal/editKpiModal.index.tsx');
  }
}
