import * as S from './paramRcaList.styles';
import { useCallback, useEffect, useRef, useState } from 'react';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { alterPageTitle } from 'utils/alterPageTitle';
import UseSelectFilial from 'hooks/UseSelectFilial/selectFilial';
import { useParamsRca } from '../paramRca.context';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { ParamsRcaProps } from '../paramRca.types';
import InputText from 'components/Inputs/InputText/text.input';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import { useNavigate } from 'react-router-dom';
import debouncer from 'utils/debounce';
import { formatDate } from 'utils/formatDate';
import { FilterMatchMode } from 'primereact/api';
import { useLoaderEffect } from 'providers/loaderEffect';
import MultiSelect from 'components/Multiselect/multiselect';

export default function ParamRcaList() {
  const { setLoaderHeader } = useLoaderEffect();
  try {
    const navigate = useNavigate();

    useEffect(() => {
      trackGPageView('/prametro/vendedor/lista');
      alterPageTitle('Parâmetros Vendedor');
    }, []);

    const {
      paramsRca,
      setParamsRca,
      tableConfig,
      setTableConfig,
      setSelectedParamRca,
      setSelectedToEditParamRca,
      handleGetParamsRcaList,
      SelectEmpresa,
      selectedEmpresa,
      SelectUsuario,
      selectedUsuario,
    } = useParamsRca();

    const { SelectFilial, selectedFiliais } = UseSelectFilial(selectedEmpresa.codEmpresa, selectedUsuario.codUsuario);

    const [rcasCount, setRcasCount] = useState(0);

    useEffect(() => {
      setRcasCount(paramsRca.unfiltered.length);
      setLoaderHeader(false);
    }, [paramsRca.unfiltered]);

    useEffect(() => {
      handleGetParamsRcaList(selectedEmpresa.codEmpresa, true);
      setLoaderHeader(true);
    }, [selectedEmpresa, selectedUsuario]);

    useEffect(() => {
      handleSearchParams();
    }, [selectedEmpresa, selectedFiliais, paramsRca.unfiltered]);

    const worker = new Worker(new URL('./filterWorker.ts', import.meta.url));

    const searchTextRef = useRef(null);
    const handleSearchParams = useCallback(
      debouncer(() => {
        const searchText = searchTextRef.current ? searchTextRef.current.value : '';
        worker.postMessage({ paramsRca, searchText, selectedFiliais });
        worker.onmessage = (e: MessageEvent) => {
          setParamsRca((prev) => ({ ...prev, filtered: e.data ?? [] }));
        };
      }, 300),
      [selectedEmpresa, selectedFiliais, paramsRca.unfiltered],
    );

    const headerColumnGroup = useCallback(() => {
      const groupsAvailable = tableConfig.filter((group) => group.display);
      return (
        <ColumnGroup>
          <Row>
            {groupsAvailable?.map((group, groupIndex) => {
              const columnsAvailable = group?.columns?.filter((col) => col.display);
              if (groupIndex == 0) {
                return (
                  <Column
                    key={`group-${groupIndex}`}
                    header={() => {
                      return (
                        <S.ParamRcaHeaderVendedorTemplate>
                          <p>{group.header}</p>
                          <span>{` - ${rcasCount} registros`}</span>
                        </S.ParamRcaHeaderVendedorTemplate>
                      );
                    }}
                    colSpan={1}
                    rowSpan={2}
                    className={'fixed-vendedor-header'}
                    field="nomeVendedor"
                  />
                );
              }
              return (
                <Column
                  key={`group-${groupIndex}`}
                  header={group.header}
                  colSpan={columnsAvailable.length}
                  className={
                    groupIndex % 2 > 0 ? 'colored-header-1' : '' + (groupIndex == 0 ? ' fixed-vendedor-header' : '')
                  }
                />
              );
            })}
          </Row>
          <Row>
            {groupsAvailable?.map((group, colIndex) => {
              const columnsAvailable = group?.columns?.filter((col) => col.display);
              return columnsAvailable?.map((col) => {
                if (colIndex == 0) {
                  return;
                }
                return (
                  <Column
                    sortable
                    key={`col-${colIndex}`}
                    header={col.header}
                    field={col.field}
                    className={
                      (typeof col.className === 'function' ? '' : col.className || '') +
                      (colIndex % 2 > 0 ? ' colored-header' : '')
                    }
                    filter
                  />
                );
              });
            })}
          </Row>
        </ColumnGroup>
      );
    }, [tableConfig, rcasCount]);

    const RcaTemplate = ({ rca }: { rca: ParamsRcaProps }) => {
      const url = `/parametros/vendedor/editar/${selectedEmpresa.codEmpresa}/${selectedUsuario.codUsuario}/${rca.codUsur}`;

      return (
        <S.ParamRcaNameTemplate
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.preventDefault();
            setSelectedParamRca(rca);
            setSelectedToEditParamRca(rca);
            navigate(url);
          }}
        >
          [{rca.codUsur}] {rca.nomeVendedor}
        </S.ParamRcaNameTemplate>
      );
    };

    const ActiveTemplate = ({ active }: { active: boolean | string | null | undefined }) => {
      let handledActive: boolean;

      if (active === null || active === undefined) {
        handledActive = false;
      } else if (typeof active === 'string') {
        handledActive = active === 'S';
      } else {
        handledActive = active;
      }

      if (handledActive) {
        return (
          <S.ParamRcaListTableTemplateBox>
            <Button icon={<icons.Check />} color="green" textMode rounded bgOnlyOnHover size="sm" />
            {handledActive}
          </S.ParamRcaListTableTemplateBox>
        );
      }
      return (
        <S.ParamRcaListTableTemplateBox>
          <Button icon={<icons.X />} color="red" textMode rounded bgOnlyOnHover size="sm" />
          {handledActive}
        </S.ParamRcaListTableTemplateBox>
      );
    };

    const VersãoTemplate = ({ rca, field }: { rca: ParamsRcaProps; field: string }) => {
      if (rca[field]) {
        return (
          <S.ParamRcaVersaoTemplate>
            <Button text={rca[field]} textMode color={rca.ultversao != rca.versaoAtual ? 'red' : 'green'} tag />
          </S.ParamRcaVersaoTemplate>
        );
      } else {
        return <></>;
      }
    };

    const DataTemplate = ({ rca }: { rca: ParamsRcaProps }) => {
      try {
        return <S.ParamRcaDataTemplate>{formatDate(rca.dtaltversao, 'BR', true)}</S.ParamRcaDataTemplate>;
      } catch (err) {
        return <S.ParamRcaDataTemplate>{rca.dtaltversao}</S.ParamRcaDataTemplate>;
      }
    };

    return (
      <S.ParamRcaMainBox>
        <S.ParamRcaHeaderBox>
          <SelectEmpresa label="Empresa *" />
          <SelectUsuario label="Usuário *" />
          <SelectFilial label="Filial" multiselect />
          <MultiSelect
            label="Grupos / Colunas"
            value={tableConfig.filter((group) => group.display && group.listOnGrid).map((group) => group.id)}
            options={tableConfig
              .filter((group) => group.listOnGrid)
              .map((group) => ({ label: group.header, value: group.id }))}
            onChange={(e) => {
              const visibleIds = e.value;
              setTableConfig((prevConfig) =>
                prevConfig.map((group) => ({
                  ...group,
                  display: group.id === 1 || visibleIds.includes(group.id),
                })),
              );
            }}
          />
          <InputText
            label="Buscar"
            placeholder="Buscar Vendedor"
            ref={searchTextRef}
            onChange={() => {
              handleSearchParams();
            }}
          />
        </S.ParamRcaHeaderBox>

        <S.ParamRcaListTableBox>
          <DataTable
            value={paramsRca.filtered}
            headerColumnGroup={headerColumnGroup()}
            paginator={paramsRca.filtered.length > 10}
            rows={50}
            rowsPerPageOptions={[10, 20, 50, 100]}
            rowHover
            size="small"
            globalFilterFields={['nomeVendedor', 'versaoAtual']}
            onValueChange={(e) => {
              setRcasCount(e.length ?? 0);
            }}
            responsiveLayout="scroll"
          >
            {tableConfig
              .filter((group) => group.display)
              .flatMap((group) =>
                group.columns
                  .filter((col) => col.display)
                  .map((col, colIndex) => {
                    let filterMatchMode;
                    switch (col.type) {
                      case 'boolean':
                        filterMatchMode = FilterMatchMode.EQUALS;
                        break;
                      case 'string':
                        filterMatchMode = FilterMatchMode.CONTAINS;
                        break;
                      case 'date':
                        filterMatchMode = FilterMatchMode.DATE_IS;
                        break;
                      case 'number':
                        filterMatchMode = FilterMatchMode.EQUALS;
                        break;
                      default:
                        filterMatchMode = FilterMatchMode.CONTAINS;
                    }

                    return (
                      <Column
                        key={`col-${colIndex}`}
                        field={col.field}
                        header={col.header}
                        style={{ whiteSpace: col.whiteSpace }}
                        className={
                          (typeof col.className === 'function' ? '' : col.className || '') +
                          (colIndex % 2 > 0 ? ' colored-header' : '')
                        }
                        body={(rca: ParamsRcaProps) => {
                          if (col.field === 'nomeVendedor') {
                            return <RcaTemplate rca={rca} />;
                          } else if (col.field === 'dtaltversao') {
                            return <DataTemplate rca={rca} />;
                          } else if (col.field === 'versaoAtual' || col.field === 'ultversao') {
                            return <VersãoTemplate rca={rca} field={col.field} />;
                          } else if (col.type === 'boolean') {
                            return <ActiveTemplate active={rca[col.field]} />;
                          } else {
                            return rca[col.field];
                          }
                        }}
                        sortable
                        filter
                        filterPlaceholder={`Search ${col.header}`}
                        filterMatchMode={filterMatchMode}
                      />
                    );
                  }),
              )}
          </DataTable>
        </S.ParamRcaListTableBox>
      </S.ParamRcaMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'paramRcaList.index.tsx');
  }
}
