import useMediaQuery from 'hooks/useMediaQuery';
import { Dialog } from 'primereact/dialog';
import { SetStateAction, useEffect, useState } from 'react';

import * as S from './cloneParamGeral.styles';
import { trackGCatchError } from 'utils/analytics';
import InputText from 'components/Inputs/InputText/text.input';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';
import { ParamsGeralItemProps } from 'modules/parametrosGeral/paramGeral.types';
import UseSelectFilial from 'hooks/UseSelectFilial/selectFilial';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import { toast } from 'react-toastify';
import MultiSelect from 'components/Multiselect/multiselect';
import { ToCloneParametroGeralItem } from 'client/interfaces';
import { postClonseParametrosGeralItem } from 'client/api';
import { useParamsGeral } from 'modules/parametrosGeral/paramGeral.context';

interface CloneParamGeralProps {
  toCloneParamItem: ParamsGeralItemProps;
  setToCloneParamItem: React.Dispatch<SetStateAction<ParamsGeralItemProps>>;
}

export default function CloneParamGeral({ toCloneParamItem, setToCloneParamItem }: CloneParamGeralProps) {
  const { handleGetCabParamsGeralList } = useParamsGeral();
  try {
    const isWebScreen = useMediaQuery('(min-width: 1060px)');

    const [toCloneParam, setToCloneParam] = useState(toCloneParamItem ?? null);

    const [isLoading, setIsLoading] = useState(false);

    const { selectedEmpresa, empresas } = UseSelectEmpresa();
    const { SelectFilial, selectedFiliais, filiais } = UseSelectFilial(
      toCloneParamItem ? toCloneParamItem.codEmpresa : selectedEmpresa.codEmpresa,
      0,
    );

    const [selectedFiliaisList, setSelectedFiliaisList] = useState([]);

    useEffect(() => {
      setToCloneParam(toCloneParamItem);
    }, [toCloneParamItem]);

    const handleCloneParam = () => {
      if (toCloneParam.valor == '') {
        toast.warn("Campo 'Valor' não informado");
        return;
      } else if (!selectedFiliaisList || (selectedFiliaisList && selectedFiliaisList.length == 0)) {
        toast.warn('Filiais não selecionadas');
        return;
      }
      const paramsToClone: ToCloneParametroGeralItem = {
        ...toCloneParam,
        codfilial: selectedFiliaisList?.map((fil) => fil.codFilial),
      };
      setIsLoading(true);
      postClonseParametrosGeralItem(paramsToClone)
        .then((resp) => {
          if (resp.succeeded) {
            toast.success('Sucesso ao clonar parâmetro');
            handleGetCabParamsGeralList();
            setToCloneParamItem(null);
          } else {
            toast.error(
              resp.message && resp.message.includes('PK_FV_PARAM_GERAL')
                ? 'Parâmetro já existente na filial informada'
                : 'Falha inesperada ao clonar parâmetro',
            );
          }
        })
        .catch((err) => {
          toast.error(
            err.message && err.message.includes('PK_FV_PARAM_GERAL')
              ? 'Parâmetro já existente na filial informada'
              : 'Falha inesperada ao clonar parâmetro',
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    return (
      <Dialog
        header={'Clonar Parâmetro'}
        visible={toCloneParamItem != null}
        style={{ width: isWebScreen ? '45vw' : '90%' }}
        onHide={() => {
          setToCloneParamItem(null);
        }}
      >
        {toCloneParam && (
          <S.EditParamModalFormBox>
            <S.EditParamModalHeader>
              <S.EditParamModalTitle>{`[${toCloneParam.codpar}] ${toCloneParam.parametroDescricao}`}</S.EditParamModalTitle>
              <S.EditParamModalSubTitle>{`[${toCloneParam.codEmpresa}] ${toCloneParam.empresa}`}</S.EditParamModalSubTitle>
            </S.EditParamModalHeader>

            <S.EditParamModalFormRow>
              <InputText
                label="Valor"
                id="VALOR"
                placeholder=""
                value={toCloneParam.valor}
                onChange={(e) => {
                  e = e.toUpperCase();
                  setToCloneParam((prevState: any) => {
                    return {
                      ...prevState,
                      valor: e,
                    };
                  });
                }}
                width="clamp(200px, 250px, 300px)"
              />

              <MultiSelect
                value={selectedFiliaisList}
                onChange={(e) => {
                  setSelectedFiliaisList(e);
                }}
                options={filiais}
                optionLabel="label"
                filter
                placeholder="Filiais"
                emptyFilterMessage="Nenhum dado encontrado"
                label={'Filiais *'}
                showClear
                width="clamp(200px, 250px, 300px)"
              />

              <Button
                text="Salvar"
                color="green"
                icon={<icons.Check />}
                onClick={() => {
                  !isLoading && handleCloneParam();
                }}
                tooltip="Clonar parâmetro"
                loading={isLoading}
              />
            </S.EditParamModalFormRow>
          </S.EditParamModalFormBox>
        )}
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'parametrosGeral/components/CloneParamModal/cloneParamModal.index.tsx');
  }
}
