import * as S from './styles';
import { UseResetUserData } from 'hooks/useAccessData/useResetUserData';
import { Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { trackGCatchError } from 'utils/analytics';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';
import InputText from 'components/Inputs/InputText/text.input';
import InputDate from 'components/Inputs/InputDate/date.input';
import useMediaQuery from 'hooks/useMediaQuery';

export const ResetUserData = () => {
  try {
    const { selectedUsuario, updateInputValues, handleSubmit, onSubmit, control, handleResetPassword, setValue } =
      UseResetUserData();

    const isWebScreen = useMediaQuery('(min-width: 1060px)');

    useEffect(() => {
      updateInputValues();
    }, [selectedUsuario]);

    return (
      <S.ResetMainBox>
        {selectedUsuario && (
          <S.ResetFormBox onSubmit={handleSubmit(onSubmit)}>
            <S.Row>
              <Controller
                name="name"
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => <InputText width={isWebScreen ? '40%' : '100%'} label="Nome" {...field} />}
              />

              <InputDate
                value={new Date(selectedUsuario.dtNascimento)}
                width={isWebScreen ? '40%' : '100%'}
                label={'Data de nascimento'}
                onChange={(e) => {
                  setValue('birth_date', e);
                }}
              />
            </S.Row>

            <S.Row>
              <Controller
                name="email"
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => (
                  <InputText width={isWebScreen ? '40%' : '100%'} label="Email" type="email" {...field} />
                )}
              />

              <Controller
                name="cellphone"
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => (
                  <InputText width={isWebScreen ? '40%' : '100%'} label="Celular" {...field} maxLength={14} />
                )}
              />

              <Button text="Salvar" color="green" type="submit" icon={<icons.Check />} />
            </S.Row>
          </S.ResetFormBox>
        )}
        {selectedUsuario && (
          <S.BoxPassword>
            <p>Apagar senha do usuário {selectedUsuario?.nome}</p>
            <Button text="Apagar Senha" color="red" icon={<icons.RemoveFill />} onClick={() => handleResetPassword()} />
          </S.BoxPassword>
        )}
      </S.ResetMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'acessoUsuario/ResetUserData/index.tsx');
  }
};
