import useMediaQuery from 'hooks/useMediaQuery';
import * as S from './novaRegraModal.styles';
import { Dialog } from 'primereact/dialog';
import { NovaRegraModalProps, RegraProps } from 'client/interfaces';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { postNovaRegra } from 'client/api/regraComercial';
import { useParams } from 'react-router-dom';
import { regraTypeOptions } from '../../regraComercial.types';
import { useUser } from 'client/hooks';
import { InputTextarea } from 'primereact/inputtextarea';
import styled from 'styled-components';
import { trackGCatchError } from 'utils/analytics';
import InputSwitch from 'components/Inputs/InputSwitch/switch.input';
import InputText from 'components/Inputs/InputText/text.input';
import UseSelectFilial from 'hooks/UseSelectFilial/selectFilial';
import { formatDate } from 'utils/formatDate';
import InputDate from 'components/Inputs/InputDate/date.input';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';

export default function NovaRegraModal({
  showNovaRegraModal,
  setShowNovaRegraModal,
  codEmpresa,
  handleGetRegrasComerciais,
}: NovaRegraModalProps) {
  try {
    const isWebScreen = useMediaQuery('(min-width: 1060px)');

    const { SelectFilial, selectedFilial, handleSelectFilialByCode } = UseSelectFilial(codEmpresa, 0);

    const { data: user } = useUser();

    const { id: regraType } = useParams();

    const textareaRef = useRef(null);

    const [newRegra, setNewRegra] = useState({
      codregra: 0,
      nomeregra: '',
      ativa: 'S',
      dataInicio: new Date().toLocaleDateString().split('/').reverse().join('-'),
      dataFim: new Date().toLocaleDateString().split('/').reverse().join('-'),
      descricao: '',
      qtdemin: 0,
      mixmin: 0,
      valormin: 0,
      premiodesconto: 0,
      premiotipodesc: 'Z',
      premiocodprod: 0,
      aplicaAuto: 'N',
      acumulaBenef: 'N',
      filial: 0,
      premiodescontomin: 0,
      percminproduto: 0,
      mixfamiliaproduto: 'N',
      acumulaBenefLim: 0,
      validarcasadinha: 'N',
      creditobnf: 'N',
      valormax: 0,
      enviafv: 'S',
      codregramedalha: '0',
      validartotped: 'N',
      tipo: regraTypeOptions[regraType].type,
      alertarabertura: 'N',
      alertarfechamento: 'N',
      vendaobrig: 'N',
      tipodescbnf: 'N',
      codfunccad: 0,
      sql: '',
      validarqtemb: 'N',
      dtaltera: new Date().toLocaleDateString().split('/').reverse().join('-'),
      bnfComoVenda: 'S',
      qdemingrupo: 0,
      qdemaxgrupo: 0,
      valordesconto: 0,
      dtfimimportacao: new Date().toLocaleDateString().split('/').reverse().join('-'),
      bnfNoPedidoDeVenda: 'N',
      qdemax: 0,
      gerarbnfantecipada: 'N',
      enviaecommerce: 'N',
      codigoschavemedalha: 'N',
      enviaTelemarketing: 'N',
      alertaAtingiuCombo: 'N',
      alertaNaoAtingiuCombo: 'N',
      codgrupocombos: 0,
      aplicaOferta: 'N',
      codEmpresa: codEmpresa,
      regraItems: [],
      regraRestricao: [],
      img1: '',
      img2: '',
      img3: '',
      nomefunccad: '',
    });

    useEffect(() => {
      if (selectedFilial && selectedFilial.codFilial) {
        setNewRegra((prevState: RegraProps) => {
          return {
            ...prevState,
            filial: selectedFilial.codFilial,
          };
        });
      }
    }, [selectedFilial]);

    useEffect(() => {
      if (user && user.codUsuario) {
        setNewRegra((prevState: RegraProps) => {
          return {
            ...prevState,
            codfunccad: user.codUsuario ?? 0,
          };
        });
      }
    }, [user]);

    const validateFormFields = () => {
      for (const key in newRegra) {
        if (newRegra.hasOwnProperty(key)) {
          if (
            newRegra[key] === '' &&
            key !== 'sql' &&
            key !== 'descricao' &&
            key !== 'img1' &&
            key !== 'img2' &&
            key !== 'img3' &&
            key !== 'nomefunccad'
          ) {
            toast.warn(`O campo '${key}' está vazio.`);
            return;
          }
          if (key === 'filial' && newRegra[key] === 0) {
            toast.warn(`O campo '${key}' está vazio.`);
            return;
          }
        }
      }
      handleInsertNewRegra();
    };

    const handleInsertNewRegra = () => {
      const regraToInsert = newRegra;
      regraToInsert.descricao = textareaRef.current ? textareaRef.current.value : '';
      postNovaRegra(regraToInsert)
        .then((res) => {
          if (res.succeeded) {
            handleGetRegrasComerciais();
            toast.success('Nova regra inserida com sucesso');
            setShowNovaRegraModal(false);
          }
        })
        .catch((err) => {
          toast.error('Falha inesperada ao inserir nova regra');
          console.log('err :', err);
        });
    };

    const StyledTextarea = styled(InputTextarea)`
      resize: none;
    `;

    return (
      <Dialog
        header={'Nova regra'}
        visible={showNovaRegraModal}
        style={{ width: isWebScreen ? '35vw' : '90%' }}
        onHide={() => {
          setShowNovaRegraModal(false);
        }}
      >
        <S.NovaRegraModalFormBox>
          <S.NovaRegraModalFormRow>
            <InputText
              label="Regra"
              placeholder="Nome da regra"
              value={newRegra.nomeregra}
              onChange={(e) => {
                e = e.toUpperCase();
                setNewRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    nomeregra: e,
                  };
                });
              }}
              width="clamp(40%, 220px, 45%)"
            />

            <SelectFilial label={'Filial'} width="clamp(40%, 220px, 45%)" />
          </S.NovaRegraModalFormRow>

          <S.NovaRegraModalFormRow>
            <InputDate
              label="Data Inicial"
              value={
                newRegra && newRegra.dataInicio
                  ? new Date(`${newRegra.dataInicio.split('/').reverse().join('-')}T00:00`)
                  : new Date()
              }
              onChange={(e) => {
                setNewRegra((prevState: RegraProps) => ({
                  ...prevState,
                  dataInicio: formatDate(e, 'BR'),
                }));
              }}
            />

            <InputDate
              label="Data Final"
              value={
                newRegra && newRegra.dataFim
                  ? new Date(`${newRegra.dataFim.split('/').reverse().join('-')}T00:00`)
                  : new Date()
              }
              onChange={(e) => {
                setNewRegra((prevState: RegraProps) => ({
                  ...prevState,
                  dataFim: formatDate(e, 'BR'),
                }));
              }}
            />

            <InputSwitch
              label="Ativa"
              checked={newRegra.ativa == 'S'}
              onChange={(e) => {
                setNewRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    ativa: e ? 'S' : 'N',
                  };
                });
              }}
              style={{
                maxWidth: '100px',
              }}
            />
            <InputSwitch
              label="Envia FV"
              checked={newRegra.enviafv == 'S'}
              onChange={(e) => {
                setNewRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    enviafv: e ? 'S' : 'N',
                  };
                });
              }}
              style={{
                maxWidth: '100px',
              }}
            />
          </S.NovaRegraModalFormRow>

          <S.NovaRegraModalFormRow>
            <S.RegraInputBox>
              <label htmlFor="report">Decrição da regra</label>
              <StyledTextarea
                defaultValue={textareaRef.current ? textareaRef.current.value : newRegra.descricao}
                ref={textareaRef}
                rows={5}
                cols={65}
              />
            </S.RegraInputBox>
          </S.NovaRegraModalFormRow>

          <S.NovaRegraModalFormFooter>
            <Button
              text="Salvar"
              icon={<icons.Check />}
              color="green"
              onClick={() => {
                validateFormFields();
              }}
            />
          </S.NovaRegraModalFormFooter>
        </S.NovaRegraModalFormBox>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'regrasComerciais/components/novaRegraModal/novaRegraModal.index.tsx');
  }
}
