import * as S from './styles';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatarCnpj } from 'utils/formatCNPJ';
import { useEmpresasList } from 'client/hooks';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import InputText from 'components/Inputs/InputText/text.input';

export const Companies = () => {
  try {
    const { data: empresas } = useEmpresasList();

    const [searchText, setSearchText] = useState('');

    useEffect(() => {
      trackGPageView('/empresas');
      alterPageTitle('Empresas');
    }, []);

    const navigate = useNavigate();

    const filteredCompanies = useMemo(() => {
      if (searchText == '') return empresas;
      return empresas?.filter((company) => {
        return (
          company.codEmpresa.toString().toUpperCase().includes(searchText.toUpperCase()) ||
          company.cnpj.toUpperCase().includes(searchText.toUpperCase()) ||
          company.razaosocial.toUpperCase().includes(searchText.toUpperCase()) ||
          company.fantasia.toUpperCase().includes(searchText.toUpperCase())
        );
      });
    }, [empresas, searchText]);

    return (
      <S.CompaniesMainBox>
        <S.CompaniesHeaderBox>
          <InputText
            value={searchText}
            label={'Buscar'}
            placeholder="Buscar Empresas"
            onChange={(e) => {
              setSearchText(e);
            }}
          />
          <Button
            text="Adicionar"
            color="green"
            tooltip="Cadastrar nova empresa"
            icon={<icons.Plus />}
            onClick={() => {
              navigate('/empresas/adicionar');
            }}
          />
        </S.CompaniesHeaderBox>

        <S.CompaniesTableBox>
          <DataTable
            value={filteredCompanies}
            header=""
            dataKey="id"
            rows={20}
            editMode="cell"
            emptyMessage="Nenhum dado encontrado"
            size="small"
          >
            <Column header="Cod Empresa" field="codEmpresa" />
            <Column header="CNPJ" field="cnpj" dataType="text" body={(rowData) => formatarCnpj(rowData.cnpj)} />
            <Column header="Razão Social" field="razaosocial" />
            <Column header="Fantasia" field="fantasia" />
            <Column
              header="Ativo"
              field="ativo"
              body={(rowData) => {
                return (
                  <S.IconTable>
                    <i
                      className={classNames('pi', {
                        'true-icon pi-check-circle text-success': rowData.ativo === 'S',
                        'false-icon pi-times-circle text-danger': rowData.ativo === 'N',
                      })}
                    />
                  </S.IconTable>
                );
              }}
            />
            <Column
              header=""
              field="ativo"
              body={(rowData) => {
                return (
                  <Button
                    color="black"
                    bgOnlyOnHover
                    tooltip="Editar dados da empresa"
                    tooltipPosition="left"
                    icon={<icons.Edit />}
                    rounded
                    onClick={() => navigate('/empresas/editar', { state: { company: rowData } })}
                  />
                );
              }}
            />
          </DataTable>
        </S.CompaniesTableBox>
      </S.CompaniesMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'companies/index.tsx');
  }
};
