import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';

import { Calendar } from 'primereact/calendar';

export const textFilterTemplate = (options: ColumnFilterElementTemplateOptions) => (
  <InputText
    type="text"
    value={options.value}
    onChange={(e) => options.filterCallback(e.target.value, options.index)}
  />
);

export const numberFilterTemplate = (options: ColumnFilterElementTemplateOptions) => (
  <InputNumber
    useGrouping={false}
    value={options.value}
    onChange={(e) => options.filterCallback(e.value, options.index)}
  />
);

export const booleanFilterTemplate = (options: ColumnFilterElementTemplateOptions) => (
  <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />
);

export const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => (
  <Calendar
    value={options.value}
    dateFormat={'dd/mm/yy'}
    placeholder={'dd/mm/yyyy'}
    mask={'99/99/9999'}
    onChange={(e) => options.filterCallback(e.value, options.index)}
  />
);

export const filterByGlobalFilter = <T extends Record<string, unknown>>(values: T[], globalFilter: string | null) => {
  if (!globalFilter) return values;
  const uppercaseGlobalFilter = globalFilter.toUpperCase();
  return values.filter((obj) =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-explicit-any
    Object.values(obj).some((value) => `${value as any}`.toUpperCase().includes(uppercaseGlobalFilter)),
  );
};
