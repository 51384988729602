import { useEffect, useState } from 'react';
import * as S from './styles';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import { postUserResetPassword } from 'client/api';
import { toast } from 'react-toastify';
import { useUser } from 'client/hooks';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import UseSelectUsuario from 'hooks/UseSelectUsuario/selectUsuario';
import Button from 'components/Button/button.index';
import { dialog } from 'reactivus';
import icons from 'components/Icons/icons.index';

export default function ResetUserAccess() {
  try {

    const { data: user } = useUser();
    const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();
    const { SelectUsuario, selectedUsuario } = UseSelectUsuario(selectedEmpresa.codEmpresa);

    const defaultSelectedUser = {
      admin: false,
      codEmpresa: 2072021,
      codUsuario: null,
      nome: 'Selecione um usuário',
      permisions: [],
    };

    const [selectedUser, setSelectedUser] = useState(defaultSelectedUser);

    useEffect(() => {
      trackGPageView('/acesso-usuarios/redefinir-acesso');
      alterPageTitle('Redefinir Acesso');
    }, []);

    const handleUserAccesReset = () => {
      if (!selectedUser || selectedUser.codUsuario == 0 || !selectedUser.codUsuario) {
        toast.warning('Selecione um usuário!');
        return;
      }
      if (selectedUser.nome) {
        dialog
          .show({
            icon: 'question',
            title: 'Tem certeza?',
            text: `O usuário "${selectedUser.nome}" terá de cadastrar uma nova senha de acesso.`,
            showConfirmButton: true,
            confirmButtonText: 'Redefinir!',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            showCloseButton: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              postUserResetPassword(selectedUser.codUsuario, selectedEmpresa.codEmpresa, user.codUsuario)
                .then((res) => {
                  setSelectedUser(defaultSelectedUser);
                  toast.success('Acesso redefinido com sucesso.');
                })
                .catch((err: Error) => {
                  console.log('err :', err);
                  toast.error('Erro inesperado ao redefinir acesso.');
                });
            } else {
              toast.warning('Redefinição de acesso cancelada!');
            }
          });
      } else {
        toast.error('Usuário não encontrado!');
      }
    };

    useEffect(() => {
      if (selectedUsuario) {
        setSelectedUser({
          admin: false,
          codEmpresa: selectedEmpresa.codEmpresa,
          codUsuario: selectedUsuario.codUsuario,
          nome: selectedUsuario.nomeUsuario,
          permisions: [],
        });
      }
    }, [selectedUsuario]);

    return (
      <S.ResetMainBox>
        <S.ResetFormBox>
          <SelectEmpresa label="Empresa *" />
          <SelectUsuario label="Usuário *" />
          <Button
            icon={<icons.Refresh />}
            text="Redefinir Acessos"
            color="green"
            onClick={() => handleUserAccesReset()}
            tooltip="Redefinir acessos do usuário"
          />
        </S.ResetFormBox>
      </S.ResetMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'acessoUsuario/ResetUserAccess/index.tsx');
  }
}
