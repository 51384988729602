import { useQueryClient } from '@tanstack/react-query';
import { Column, ColumnEditorOptions, ColumnEventParams } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { updateRestricoes } from 'client/api';
import { useRestricoes } from 'client/hooks';
import { RestricaoUpdate } from 'client/interfaces';
import { booleanBodyTemplate, booleanEditor, textEditor } from 'components/datatable';

import { InputText, InputTextProps } from 'primereact/inputtext';
import { useVendedoresByUser } from 'client/hooks/vendedores';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import { useManagersByUser } from 'client/hooks/gerentes';
import { useGroupClients } from 'client/hooks/groupClients';
import { useSupervisorByUser } from 'client/hooks/supervisor';

import * as S from './cadastroFormularios.styles';
import { trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import Dropdown from 'components/Dropdown/dropdown';

interface FormularioRestricoesTableProps {
  id: number;
  onClose: () => void;
  visible: boolean;
  codEmpresa: string;
  codUsuario: string;
}

const options = [
  { label: 'Ramo Atividade', value: 'RA' },
  { label: 'Grupo Cliente', value: 'GC' },
  { label: 'Ger. Geral', value: 'GG' },
  { label: 'Supervisor', value: 'SUP' },
  { label: 'Vendedor', value: 'RCA' },
  { label: 'Cliente', value: 'C' },
];

const tipoRestricaoTemplate = (rowData: RestricaoUpdate) => {
  switch (rowData.tiporestricao) {
    case 'RA':
      return 'Ramo Atividade';
    case 'GC':
      return 'Grupo Cliente';
    case 'GG':
      return 'Ger. Geral';
    case 'SUP':
      return 'Supervisor';
    case 'RCA':
      return 'Vendedor';
    case 'C':
      return 'Cliente';
    default:
      return '';
  }
};

const FormularioRestricoesTable = ({
  id,
  onClose,
  visible,
  codEmpresa,
  codUsuario,
}: FormularioRestricoesTableProps) => {
  try {
    const [isSaving, setIsSaving] = useState(false);
    const [items, setItems] = useState<RestricaoUpdate[]>([]);
    const { selectedEmpresa } = UseSelectEmpresa();
    const { data, isLoading } = useRestricoes(id, +codEmpresa);
    const queryClient = useQueryClient();
    const { data: supervisores } = useSupervisorByUser(+codEmpresa, codUsuario);
    const { data: vendedores } = useVendedoresByUser(codEmpresa, codUsuario);
    const { data: gerentes } = useManagersByUser(+codEmpresa, +codUsuario);
    const { data: groupClients } = useGroupClients(codEmpresa);

    useEffect(() => {
      if (!items.length && data?.length) {
        setItems(data.map((i) => ({ ...i, edited: false })));
      }
    }, [data]);

    const tipoEditor = (editorOptions: ColumnEditorOptions) => (
      <Dropdown
        options={options}
        value={editorOptions.value}
        onChange={(e) => {
          const newItems = [...items];
          newItems[editorOptions.rowIndex].edited = true;
          newItems[editorOptions.rowIndex].tiporestricao = e.target.value;
          setItems(newItems);
        }}
      />
    );

    const handleRemove = (rowData: RestricaoUpdate) => {
      const newItems = [...items].filter((i) => i.id !== rowData.id);
      setItems(newItems.map((v, i) => ({ ...v, edited: true })));
    };

    const actionBodyTemplate = (rowData: RestricaoUpdate) => {
      return (
        <Button
          icon={<icons.Remove />}
          color="red"
          tooltip="Remover"
          onClick={() => handleRemove(rowData)}
          rounded
          bgOnlyOnHover
          textMode
        />
      );
    };

    const createNew = useCallback(() => {
      const id = items.length ? Math.max(...items.map((i) => Math.abs(i.id))) + 1 : 1;
      setItems([
        ...items,
        {
          id: -id,
          nroform: id,
          tiporestricao: 'GC',
          codigorestricao: id,
          descricao: '',
          visualiza: true,
          edited: true,
          codEmpresa: codEmpresa ? +codEmpresa : selectedEmpresa.codEmpresa,
        },
      ]);
    }, [items]);

    const header = (
      <div className="d-grid gap-2 d-md-flex">
        <Button text="Adicionar" color="green" icon={<icons.Plus />} onClick={createNew} />
      </div>
    );

    const onSave = useCallback(async () => {
      setIsSaving(true);
      try {
        await updateRestricoes(id, items).then((res) => {});
        await queryClient.invalidateQueries();
        toast.success('Restrições salvas com sucesso');
        onClose();
      } catch {
        toast.error('Erro ao salvar restrição');
      } finally {
        setIsSaving(false);
      }
    }, [id, items]);

    const renderFooter = () => (
      <S.RowItens>
        <Button text="Salvar" color="green" icon={<icons.Check />} onClick={onSave} />
        <Button text="Cancelar" color="red" icon={<icons.X />} onClick={onClose} />
      </S.RowItens>
    );

    const onCellEditComplete = (e: ColumnEventParams) => {
      const { rowData, newValue, field, rowIndex } = e;
      const newItems = [...items];
      rowData[field] = newValue;
      rowData.edited = true;
      newItems[rowIndex] = rowData;
      if (field == 'tiporestricao') {
        rowData.codigorestricao = 1;
        rowData.descricao = '';
      }
    };

    const restricoesEditors = {
      RA: (options: ColumnEditorOptions, props?: InputTextProps) => {
        return ordinaryTextEditor(options, props);
      },
      GC: (options: ColumnEditorOptions, props?: InputTextProps) => {
        return grupoClientesDropdown(options);
      },
      GG: (options: ColumnEditorOptions, props?: InputTextProps) => {
        return gerentesDropdown(options);
      },
      SUP: (options: ColumnEditorOptions, props?: InputTextProps) => {
        return supervisorDropdown(options);
      },
      RCA: (options: ColumnEditorOptions, props?: InputTextProps) => {
        return vendedorDropdown(options);
      },
      C: (options: ColumnEditorOptions, props?: InputTextProps) => {
        return ordinaryTextEditor(options, props);
      },
    };

    const supervisorDropdown = (options: ColumnEditorOptions) => {
      return (
        <Dropdown
          onChange={(e) => {
            options.editorCallback?.(e.target.value.codSupervisor);
            const newItems = [...items];
            newItems[options.rowIndex].codigorestricao = Number(e.target.value.codSupervisor);
            newItems[options.rowIndex].descricao = e.target.value.nomesup;
            setItems(newItems);
          }}
          options={supervisores}
          optionLabel="nomesup"
          placeholder="Selecione um Supervisor"
          itemTemplate={(sup) => {
            return sup.nomesup + ' [' + sup.codSupervisor + ']';
          }}
          filter
          filterBy="nomesup,codSupervisor"
        />
      );
    };

    const vendedorDropdown = (options: ColumnEditorOptions) => {
      return (
        <Dropdown
          onChange={(e) => {
            options.editorCallback?.(e.target.value.codVendedor);
            const newItems = [...items];
            newItems[options.rowIndex].codigorestricao = Number(e.target.value.codVendedor);
            newItems[options.rowIndex].descricao = e.target.value.nome;
            setItems(newItems);
          }}
          options={vendedores}
          optionLabel="nome"
          placeholder="Selecione um Vendedor"
          itemTemplate={(rca) => {
            return rca.nome + ' [' + rca.codVendedor + ']';
          }}
          filter
          filterBy="nome,codVendedor"
        />
      );
    };

    const gerentesDropdown = (options: ColumnEditorOptions) => {
      return (
        <Dropdown
          onChange={(e) => {
            options.editorCallback?.(e.target.value.codGerente);
            const newItems = [...items];
            newItems[options.rowIndex].codigorestricao = Number(e.target.value.codgrupo);
            newItems[options.rowIndex].descricao = e.target.value.nomeGerente;
            setItems(newItems);
          }}
          options={gerentes}
          optionLabel="nomeGerente"
          placeholder="Selecione um Gerente"
          itemTemplate={(gg) => {
            return gg.nomeGerente + ' [' + gg.codGerente + ']';
          }}
          filter
          filterBy="nomeGerente,codGerente"
        />
      );
    };

    const grupoClientesDropdown = (options: ColumnEditorOptions) => {
      return (
        <Dropdown
          onChange={(e) => {
            options.editorCallback?.(e.target.value.codgrupo);
            const newItems = [...items];
            newItems[options.rowIndex].codigorestricao = Number(e.target.value.codgrupo);
            newItems[options.rowIndex].descricao = e.target.value.nomeGrupo;
            setItems(newItems);
          }}
          options={groupClients}
          optionLabel="nomeGrupo"
          placeholder="Selecione um Grupo"
          itemTemplate={(gg) => {
            return gg.nomeGrupo + ' [' + gg.codgrupo + ']';
          }}
          filter
          filterBy="nomeGrupo"
        />
      );
    };

    const ordinaryTextEditor = (options: ColumnEditorOptions, props?: InputTextProps) => {
      return (
        <InputText
          {...props}
          keyfilter="num"
          value={options.value}
          onChange={(e) => options.editorCallback?.(e.target.value)}
        />
      );
    };

    const numberEditor = (options: ColumnEditorOptions, props?: InputTextProps) => {
      return restricoesEditors[items[options.rowIndex].tiporestricao](options, props);
    };

    return (
      <Dialog
        header={`Restrições do Formulário Nº ${id}`}
        visible={visible}
        style={{ width: '80vw' }}
        footer={renderFooter}
        onHide={onClose}
        closeOnEscape={false}
      >
        <S.RestricaoTable>
          <DataTable
            value={items}
            header={header}
            loading={isLoading}
            dataKey="id"
            editMode="cell"
            emptyMessage="Nenhum dado encontrado"
            size="small"
          >
            <Column
              header="Tipo de Restrição"
              field="tiporestricao"
              editor={tipoEditor}
              onCellEditComplete={onCellEditComplete}
              body={tipoRestricaoTemplate}
            />
            <Column
              header="Código"
              field="codigorestricao"
              dataType="numeric"
              editor={numberEditor}
              onCellEditComplete={onCellEditComplete}
            />
            <Column
              header="Descrição"
              field="descricao"
              dataType="text"
              editor={textEditor}
              onCellEditComplete={onCellEditComplete}
            />
            <Column
              header="Visualiza"
              field="visualiza"
              dataType="numeric"
              editor={booleanEditor}
              body={(rowData: RestricaoUpdate) => booleanBodyTemplate(rowData.visualiza)}
              onCellEditComplete={onCellEditComplete}
            />
            <Column body={(rowData: RestricaoUpdate) => actionBodyTemplate(rowData)} />
          </DataTable>
        </S.RestricaoTable>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'AnaliseFormularios/components/FormularioCabTable/formularioRestricoesTable.index.tsx');
  }
};

export default FormularioRestricoesTable;
