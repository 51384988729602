import * as S from '../styles';
import { UserData } from 'client/interfaces';
import { useSupervisorAccessData } from 'hooks/useAccessData/useSupervisorAccessData';
import { Controller } from 'react-hook-form';
import { MultiSelect } from 'primereact/multiselect';
import { OrderList } from 'primereact/orderlist';
import { IGetAccessData } from 'client/interfaces/acessoDados.interface';
import { trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';

interface IContainerSupervisor {
  selectedUsuario: UserData;
  user: UserData;
  itemListTemplate: ({ data, cod }: { data: IGetAccessData; cod: string }) => JSX.Element;
}

const ContainerSupervisor = ({ selectedUsuario, user, itemListTemplate }: IContainerSupervisor) => {
  try {
    const { values, isLoading, optionsSupervisor, accessDataSupervisor, handleSubmit, onSubmit, control, setValue } =
      useSupervisorAccessData({
        codEmpresa: selectedUsuario?.codEmpresa,
        codUsuario: selectedUsuario?.codUsuario,
        codfuncLib: user?.codUsuario,
      });

    const onShow = () => {
      let selectAllCheckbox = document.querySelector('.p-multiselect-header > .p-checkbox');
      selectAllCheckbox.after(' TODOS SUPERVISORES');
    };

    return (
      <S.Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p>Supervisor (GA)</p>
          <Controller
            name="supervisor"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => (
              <MultiSelect
                disabled={!optionsSupervisor?.length || isLoading}
                style={{ width: '100%', marginBottom: '10px' }}
                id="multiselect"
                placeholder="Selecione um Supervisor "
                value={field.value}
                options={optionsSupervisor ? optionsSupervisor.sort((a, b) => a.label.localeCompare(b.label)) : []}
                onChange={(e) => setValue('supervisor', e.value)}
                onShow={onShow}
                filter
                filterBy="label"
              />
            )}
          />

          <Button
            text="Salvar Supervisor"
            color="green"
            icon={<icons.Check />}
            type="submit"
            disabled={!values || !values?.supervisor?.length}
          />
        </form>

        <S.ListPermissions>
          <OrderList
            value={accessDataSupervisor}
            header="Acessos a Supervisores"
            filter
            dataKey="pk"
            filterBy="nomesup,codSupervisor"
            itemTemplate={(rowData: IGetAccessData) => itemListTemplate({ data: rowData, cod: 'codSupervisor' })}
          />
        </S.ListPermissions>
      </S.Box>
    );
  } catch (err) {
    trackGCatchError(err, 'acessoUsuario/AccessData/ContainerSUpervisor/index.tsx');
  }
};

export default ContainerSupervisor;
