import { BoxTable, Container, Header } from 'components/Containers';
import styled, { css } from 'styled-components';

export const GrouClientsMembersMainBox = styled(Container)``;

export const GrouClientsMembersHeaderBox = styled(Header)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const GrouClientsMembersTableBox = styled(BoxTable)`
  .p-datatable {
    width: 100%;
    max-height: 50dvh;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    border-radius: 5px;
  }
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 10px 0px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Form = styled.form``;

interface InputProps {
  error?: boolean;
}

export const DescriptionInput = styled.small<InputProps>`
  ${(props) =>
    props.error &&
    css`
      color: red;
    `}
`;

export const TitleInput = styled.label<InputProps>`
  ${(props) =>
    props.error &&
    css`
      color: red;
    `}
`;

export const ClientsListHeader = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 15px;
  flex-wrap: wrap;
  padding: 10px;
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
