import { IGroupClient } from 'client/interfaces/groupClient';
import { UseDeleteGroupClient } from 'hooks/useGroupClients/useDeleteGroupClient';
import { Dialog } from 'primereact/dialog';
import * as S from './styles';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useMediaQuery from 'hooks/useMediaQuery';
import { trackGCatchError } from 'utils/analytics';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';

interface IDeleteGroupClient {
  rowGroupClient: IGroupClient;
  setIsOpenModalDelete: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenModalDelete: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>,
  ) => Promise<QueryObserverResult<IGroupClient[], AxiosError<unknown, any>>>;
}

export const DeleteGroupClient = ({
  rowGroupClient,
  isOpenModalDelete,
  setIsOpenModalDelete,
  refetch,
}: IDeleteGroupClient) => {
  try {
    const { handleRemoveGroup } = UseDeleteGroupClient({ rowGroupClient, setIsOpenModalDelete, refetch });
    const isWebScreen = useMediaQuery('(min-width: 1060px)');

    return (
      <Dialog
        header={`Deseja remover o ${rowGroupClient?.nomeGrupo}`}
        visible={isOpenModalDelete}
        onHide={() => {
          setIsOpenModalDelete(!isOpenModalDelete);
        }}
        style={{ width: isWebScreen ? '30vw' : '90vw' }}
      >
        <p>Código do Grupo {rowGroupClient?.codgrupo}</p>
        <p>Código da Empresa: {rowGroupClient?.codempresa}</p>
        <p>Código da Filial: {rowGroupClient?.codfilial}</p>

        <S.BoxButtons>
          <Button
            text="Remover"
            color="red"
            icon={<icons.RemoveFill />}
            onClick={() => {
              handleRemoveGroup();
            }}
          />
          <Button
            text="Cancelar"
            color="green"
            icon={<icons.X />}
            onClick={() => {
              handleRemoveGroup();
            }}
          />
        </S.BoxButtons>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'groupClients/DeleteGroupClient/index.tsx');
  }
};
