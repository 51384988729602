import clientSvg1 from 'assets/svgs/clientSvg1';
import styled from 'styled-components';
import { trackGCatchError } from 'utils/analytics';

export default function MapSubtitle({ selectedCli }: { selectedCli: any }) {
  try {
    const MarkersSubtitle = styled.div`
      display: flex;
      flex-direction: row;
      gap: 15px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #fff;
      padding: 10px 10px 5px 5px;
      border-top-right-radius: 10px;
      padding-right: 25px;
      z-index: 6;

      @media (max-width: 992px) {
        display: none;
      }
    `;

    const Subtitle = styled.span``;

    if (selectedCli) {
      const color =
        selectedCli.status == 'L'
          ? '#228F6B'
          : selectedCli.status == 'R'
          ? '#cb2a29'
          : selectedCli.status == 'A'
          ? '#FFA500'
          : '#cb2a29';
      return (
        <MarkersSubtitle>
          <Subtitle>
            <img src={'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(clientSvg1('V', color))}></img>
            <span>VENDEDOR</span>
          </Subtitle>
          <Subtitle>
            <img src={'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(clientSvg1('C', '#007fff'))}></img>
            <span>CLIENTE</span>
          </Subtitle>
        </MarkersSubtitle>
      );
    }
  } catch (err) {
    trackGCatchError(err, 'AnaliseVisitas/components/analiseMapa/mapSubtitle.tsx');
  }
}
