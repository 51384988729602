import { useUser } from '../../client/hooks/usuarios';
import { useEffect, useState } from 'react';
import { getByRoleManagersCompany } from 'client/api';
import Dropdown from 'components/Dropdown/dropdown';

type SelectedGerenteProps = {
  codGerente?: number;
  codEmpresa?: number;
  nomeGerente?: string;
  label?: string;
};

const UseSelectGerente = (codEmpresa: number, codUsuario: number) => {
  const { data: user } = useUser();
  const [gerentes, setGerentes] = useState<any[]>([]);

  const defaultGerente = () => {
    const ger = JSON.parse(localStorage.getItem('vmGerData') || 'null');
    return (
      ger || {
        codGerente: ger ? ger.codGerente : -1,
        nomeGerente: '',
      }
    );
  };

  const [selectedGerente, setSelectedGerente] = useState<SelectedGerenteProps>(defaultGerente);

  useEffect(() => {
    localStorage.removeItem('vmGerData');
    if (user && codEmpresa && codUsuario) {
      getByRoleManagersCompany(codEmpresa, codUsuario).then((rcas: any[]) => {
        const formattedRcas = rcas.map((v) => ({
          ...v,
          label: `[${v.codGerente}] - ${v.nomeGerente}`,
        }));
        setGerentes(formattedRcas);
      });
    }
  }, [user, codEmpresa, codEmpresa, codUsuario]);

  const handleGerenteChange = (ger: SelectedGerenteProps) => {
    localStorage.setItem('vmGerData', ger ? JSON.stringify(ger) : null);
    setSelectedGerente(ger ?? defaultGerente);
  };

  const SelectGerente = ({
    onChange,
    width,
    label = 'Gerente',
    placeholder = 'Selecione um Gerente',
  }: {
    onChange?: (codUsuario: number) => void;
    width?: string;
    label?: string;
    placeholder?: string;
  }) => {

    const handleChange = (emp: SelectedGerenteProps) => {
      handleGerenteChange(emp);
      if (onChange) {
        onChange(emp.codGerente);
      }
    };

    return (
      <Dropdown
        value={selectedGerente}
        onChange={(e) => handleChange(e.value)}
        options={gerentes}
        optionLabel="label"
        filter
        placeholder={placeholder}
        emptyMessage="Nenhum dado encontrado"
        emptyFilterMessage="Nenhum dado encontrado"
        width={width}
        label={label}
        showClear
      />
    );
  };

  return {
    SelectGerente,
    selectedGerente,
    setSelectedGerente,
  };
};

export default UseSelectGerente;
