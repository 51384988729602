import { PageTitle } from '_metronic/layout/core';

import DashboardPage from './DashboardPage';
import { trackGCatchError } from 'utils/analytics';

const DashboardWrapper = () => {
  try {
    return (
      <>
        <PageTitle breadcrumbs={[]}></PageTitle>
        <DashboardPage />
      </>
    );
  } catch (err) {
    trackGCatchError(err, 'main/pages/Dashboard/DashboardWrapper.tsx');
  }
};

export default DashboardWrapper;
