import { useEffect, useState } from 'react';
import * as S from './styles';

import { useLocation } from 'react-router-dom';
import { trackGCatchError } from 'utils/analytics';

export default function VmContent({ children }: any) {
  try {
    const [useVmHeader, setUseVmHeader] = useState<boolean>(true);

    const location = useLocation();

    useEffect(() => {
      if (
        location.pathname == '/pedido' ||
        location.pathname == '/pedido/novo' ||
        location.pathname.includes('/mapa/regioes') ||
        location.pathname.includes('/regioes') ||
        location.pathname.includes('/controle-visitas') ||
        location.pathname.includes('/analise-visitas')
      ) {
        setUseVmHeader(false);
      } else if (!useVmHeader) {
        setUseVmHeader(true);
      }
    }, [location.key]);

    return <S.ContentMainBox fullSize={!useVmHeader}>{children}</S.ContentMainBox>;
  } catch (err) {
    trackGCatchError(err, 'main/components/Content/index.tsx');
  }
}
