import styled, { keyframes } from 'styled-components';

export const NewFotosMainBox = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
`;

export const FooterButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const FirstRow = styled.div`
  display: flex;
  margin-bottom: 20px;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const NewFotosInputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  border-radius: 5px;
  border: 1px solid #cccccc70;
  cursor: pointer !important;
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: #fff;

  &:hover {
    border: 1px dashed var(--v-blue);
  }
`;
export const NewFotosInputButtonBox = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0%, -50%);
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
`;
export const NewFotosInput = styled.input`
  width: 100%;
  padding: 25px;
  opacity: 0;
`;

export const NewFotosTableBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  border-radius: 5px;
  cursor: pointer !important;
  background-color: #00000010;
  z-index: 8;

  table {
    width: 100% !important;
  }
`;

export const FotosImageBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  img {
    max-height: 50px;
    width: auto;
    max-width: 50px;
  }
`;

export const InternalImageDataBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const pulse = keyframes`
  0% {
    transform: scale(0);
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

interface SpinnerGrowProps {
  color: number;
}

export const SpinnerGrow = styled.div.attrs((props: SpinnerGrowProps) => ({
  color: props.color,
}))<SpinnerGrowProps>`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border-radius: 50%;
  opacity: 0;
  animation: ${pulse} 1.75s ease infinite;
  background-color: ${(props) =>
    props.color === 1 ? 'var(--v-blue)' : props.color === 2 ? 'var(--v-green)' : 'var(--v-red)'};
`;
