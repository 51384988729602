import { Dialog } from 'primereact/dialog';
import { useEffect, useMemo, useState } from 'react';
import { usePonto } from 'client/hooks/ponto';
import useMediaQuery from 'hooks/useMediaQuery';

import * as S from './styles';
import { IPontoAgendaCompany } from 'client/interfaces/pontoAgenda.interface';
import { InputText } from 'primereact/inputtext';
import { useModalSchedule } from 'hooks/useSchedule/useModalSchedule';
import { ListPoints } from './ListPoints';
import { ListClients } from './ListClients';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';

interface IModalAddPoint {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  date: string;
  codEmpresa: number;
  listPoints: IPontoAgendaCompany[];
  codVendedor: number;
}

export function ModalAddPoint({
  setOpenModal,
  openModal,
  date,
  codEmpresa,
  codVendedor,
  listPoints: listClientCompany,
}: IModalAddPoint) {
  const {
    selectedCard,
    setSelectedCard,
    TYPES_SCHEDULING,
    handleSubmit,
    register,
    setValue,
    watch,
    control,
    handleOnSubmit,
  } = useModalSchedule({ date, setOpenModal });

  const { data: dataPontoVendedor } = usePonto(codEmpresa, codVendedor);

  const values = watch();

  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const [filter, setFilter] = useState('');

  const handleCardSelect = (card) => {
    setSelectedCard(card);
  };

  const filteredDataClient = listClientCompany?.filter((item) => {
    const lowerCaseFilter = filter.toLowerCase();

    return Object.values(item).some((value) => {
      if (typeof value === 'string') {
        return value.toLowerCase().includes(lowerCaseFilter);
      }
      if (typeof value === 'number') {
        return value.toString().includes(lowerCaseFilter);
      }
      return false;
    });
  });

  const filteredDataPonto = dataPontoVendedor?.filter((item) => {
    const lowerCaseFilter = filter.toLowerCase();

    return Object.values(item).some((value) => {
      if (typeof value === 'string') {
        return value.toLowerCase().includes(lowerCaseFilter);
      }
      if (typeof value === 'number') {
        return value.toString().includes(lowerCaseFilter);
      }
      return false;
    });
  });

  useMemo(() => {
    setValue('sequencia', 0);
  }, [selectedCard]);

  useEffect(() => {
    setSelectedCard(null);
    setFilter('');
    setValue('typeScheduling', TYPES_SCHEDULING.POINT);
  }, [openModal]);

  useEffect(() => {
    setSelectedCard(null);
    setFilter('');
  }, [values.typeScheduling]);

  return (
    <>
      {
        <Dialog
          visible={openModal}
          onHide={() => setOpenModal(false)}
          style={{ width: isWebScreen ? '55vw' : '40vw', height: isWebScreen ? '590px' : '400px' }}
          header={
            <S.Header>
              <h3>TIPO DE AGENDAMENTO</h3>

              <span>
                <input
                  type="radio"
                  value={TYPES_SCHEDULING.POINT}
                  {...register('typeScheduling')}
                  defaultChecked
                  onChange={() => setValue('typeScheduling', TYPES_SCHEDULING.POINT)}
                />
                <label>PONTO</label>
              </span>
              <span>
                <input
                  type="radio"
                  value={TYPES_SCHEDULING.CLIENT}
                  {...register('typeScheduling')}
                  onChange={() => setValue('typeScheduling', TYPES_SCHEDULING.CLIENT)}
                />
                <label>CLIENTE</label>
              </span>
            </S.Header>
          }
        >
          <S.Body>
            <form onSubmit={handleSubmit(handleOnSubmit)}>
              <InputText
                style={{ width: '100%' }}
                type="text"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Filtrar"
              />
              <S.Container>
                {TYPES_SCHEDULING.CLIENT === values.typeScheduling ? (
                  <>
                    {dataPontoVendedor?.length ? (
                      <>
                        {filteredDataClient?.map((item, index) => (
                          <ListClients
                            handleCardSelect={handleCardSelect}
                            selectedCard={selectedCard}
                            values={values}
                            index={index}
                            item={item}
                            isWebScreen={isWebScreen}
                            control={control}
                          />
                        ))}
                      </>
                    ) : (
                      <h3>Clientes não encontrados /:</h3>
                    )}
                  </>
                ) : (
                  <>
                    {listClientCompany?.length ? (
                      <>
                        {filteredDataPonto?.map((item, index) => (
                          <ListPoints
                            item={item}
                            index={index}
                            isWebScreen={isWebScreen}
                            selectedCard={selectedCard}
                            handleCardSelect={handleCardSelect}
                          />
                        ))}
                      </>
                    ) : (
                      <h3>Pontos não encontrados /:</h3>
                    )}
                  </>
                )}
              </S.Container>

              <S.Footer style={{ marginTop: '10px' }}>
                <Button
                  text="Salvar"
                  color="green"
                  icon={<icons.Check />}
                  type="submit"
                  disabled={
                    !selectedCard || (TYPES_SCHEDULING.CLIENT === values.typeScheduling && values.sequencia === 0)
                  }
                />
              </S.Footer>
            </form>
          </S.Body>
        </Dialog>
      }
    </>
  );
}
