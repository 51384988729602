import { BoxTable, Container, Header } from 'components/Containers';
import styled from 'styled-components';

export const ReportMainBox = styled(Container)`
  gap: 25px;
`;

export const ReportHeaderBox = styled(Header)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: auto;
  position: sticky;
  top: 20px;
  left: 0px;
  z-index: 9;
`;

export const ReportPlayerFormInputsBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: auto;
  padding: 0px 0px 10px 5px;
`;

export const ReportPlayerFormButtonsBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const ReportBodyBox = styled(BoxTable)`
  width: 100%;
  height: 72dvh;
  background-color: #00000010;
  border-radius: 7.5px;
  overflow: auto;
  border: 1px solid #cccccc70;
  background-color: #fff;
  position: relative;

  table tr th {
    position: sticky;
    top: 0px;
  }

  table tr td {
    white-space: nowrap;
    font-size: 0.9rem;
    padding: 7.5px !important;
  }
`;

export const ReportPlayerInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ReportPlayerInput = styled.input`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  border-radius: 2.5px;
  border: 1px solid #60606060;
  width: 160px;

  &:focus {
    outline: 1px solid green;
  }
`;
