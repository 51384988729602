import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import storage from 'services/storage';

interface UseExpireTokenAndStopApiProtocols {
  logoutUser: () => void;
  statusApi: unknown;
  statusModalStopApi: unknown;
  locationPath: string;
}

interface IUseExpireTokenAndStopApiParams {
  errorUseUser: AxiosError<unknown, any>;
  statusUseUser: 'error' | 'success' | 'loading';
}

//API rodando/parada
export enum STATUS_API {
  RUNNING = 'running',
  STOP = 'stop',
}

export enum STATUS_REQUEST {
  SUCCESS = 'success',
  API_STOP = 'ERR_NETWORK',
}

export enum STATUS_MODAL_STOP_API {
  IS_OPEN = 'open',
  IS_CLOSE = 'close',
}

export const UseExpireTokenAndStopApi = ({
  errorUseUser,
  statusUseUser,
}: IUseExpireTokenAndStopApiParams): UseExpireTokenAndStopApiProtocols => {
  const { pathname } = useLocation();

  const statusApi = storage.getItem('status:api');
  const statusModalStopApi = storage.getItem('statusModal');

  const logoutUser = () => {
    const cpfCodErp = storage.getItem<string>('cpfCodErp');
    const empresaCNPJ = storage.getItem<string>('empresaCNPJ');
    storage.clearStorage();
    // storage.setItem('token', null);
    // storage.setItem('user', null);
    storage.setItem('cpfCodErp', cpfCodErp);
    storage.setItem('empresaCNPJ', empresaCNPJ);
    localStorage.removeItem('vmUsrData');
    localStorage.removeItem('vmEmpData');
    !window.location.pathname.includes('login') && window.location.reload();
  };

  const urlsParts = pathname.split('/');
  const locationPath = urlsParts[urlsParts.length - 1];

  if (errorUseUser?.code === STATUS_REQUEST.API_STOP) {
    storage.setItem('status:api', STATUS_API.STOP);
  }

  if (errorUseUser?.response.status === 404) {
    logoutUser();
  }

  if (statusApi === STATUS_API.STOP && statusModalStopApi !== STATUS_MODAL_STOP_API.IS_OPEN) {
    storage.setItem('statusModalStopApi', STATUS_MODAL_STOP_API.IS_OPEN);
    // Swal.fire({
    //   title: 'Erro de conexão',
    //   html: `Estamos trabalhando para melhorar sua experiência!`,
    //   icon: 'warning',
    //   confirmButtonText: 'Restabelecer conexão',
    //   cancelButtonText: 'Deslogar',
    //   allowOutsideClick: false,
    //   allowEscapeKey: false,
    //   showCancelButton: true,
    // })
    //   .then((result) => {
    //     if (result.isConfirmed) window.location.reload();
    //     else if (result.dismiss === Swal.DismissReason.cancel) {
    //       logoutUser();
    //     }
    //   })
    //   .catch(() => {
    //     storage.setItem('statusModalStopApi', STATUS_MODAL_STOP_API.IS_CLOSE);
    //   });
  }

  useEffect(() => {
    //API parou e user está em outra tela
    // if (locationPath !== 'dashboard' && statusApi !== STATUS_API.RUNNING) {
    //   window.location.pathname = '/dashboard';
    // }

    //API voltou
    if (statusApi === STATUS_API.STOP) {
      storage.setItem('statusModalStopApi', null);
    }

    if (statusUseUser === STATUS_REQUEST.SUCCESS) {
      storage.setItem('status:api', STATUS_API.RUNNING);
    }
  }, [statusUseUser, errorUseUser]);

  return {
    logoutUser,
    statusModalStopApi,
    statusApi,
    locationPath,
  };
};
