import styled, { css } from 'styled-components';

interface IResponsiveProps {
  mobile?: boolean;
  tablet?: boolean;
}

export const TitlePage = styled.p<IResponsiveProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--dark-400);
  margin-right: 20px;

  ${(props) =>
    props.tablet &&
    css`
      @media (max-width: 992px) {
        font-size: 15px;
      }
    `}
`;

export const BoxInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  p {
    color: var(--dark-400);
  }

  span {
    margin-right: 40px;
  }
`;

export const BoxAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 999px;
  background-color: var(--gray-100);
  width: 40px;
  height: 40px;
`;

export const BoxHeader = styled.div`
  background-color: #f2f4f7;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100svw;
  background-color: #00000010 !important;

  .container-fluid {
    padding: 0 25px;
  }
`;

export const Header = styled.div`
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

interface IResponsiveProps {
  mobile?: boolean;
  tablet?: boolean;
  gap?: string;
}

export const Box = styled.div<IResponsiveProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70%;
  gap: ${(props) => props.gap ?? ''};
  gap: 20px;

  ${(props) =>
    props.mobile &&
    css`
      @media (max-width: 768px) {
        width: 100%;
        justify-content: space-between;
      }
    `}

  ${(props) =>
    props.tablet &&
    css`
      @media (max-width: 992px) {
        width: 100%;
        justify-content: space-between;
      }
    `}
`;

export const BoxUser = styled.div`
  display: flex;
  flex-direction: column;

  span {
    cursor: pointer;
    font-size: 13px;
    color: #9898a4;
    font-weight: 600;

    :hover {
      opacity: 0.7;
    }
  }
`;

export const ReportBackButton = styled.div`
  margin-right: 10px;
  cursor: pointer;
  font-size: 1.5rem;
`;
