import { useEffect, useState } from 'react';
import * as S from './styles';
import { useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getRelatorio, postPlayRelatorio } from 'client/api/relatorios';
import { useUser } from 'client/hooks';
import { toast } from 'react-toastify';
import { Buffer } from 'buffer';
import handleExportJsonToSheet from 'utils/exportJsonToSheet';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { dialog } from 'reactivus';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import { usePageTitle } from 'providers/pageTitle';
import { useLoaderEffect } from 'providers/loaderEffect';

type ReportParamProps = {
  codrel: number;
  ordem: number;
  obrigatorio: string;
  nome_param: string;
  legenda: string;
  componente: string;
  valor_padrao: string;
  itens: string;
  consulta: string;
  busca: string;
};

type ReportProps = {
  codrel: number;
  descricao: string;
  script: string;
  layout: string;
  layout_p: string;
  ativo: string;
  tipo: string;
  exibir_fv: string;
  parametros: ReportParamProps[];
};

export default function Relatorios7000Player() {
  const { data: user } = useUser();
  const { setPageTitle } = usePageTitle();
  try {
    const { id } = useParams();

    const { setLoaderHeader } = useLoaderEffect();

    const [report, setReport] = useState<ReportProps | null>(null);
    const [tableData, setTableData] = useState<any[] | null>([]);
    const [formData, setFormData] = useState<any>(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [reportInputs, setReportInputs] = useState([]);

    useEffect(() => {
      if (id && user) {
        if (formData) {
          setFormData(null);
        }
        handleGetReportInfo();
      }
    }, [id, user]);

    async function handleGetReportInfo() {
      const relatorio = await getRelatorio(id);
      setReport(relatorio.data);

      const newFormData = {};
      (relatorio.data.parametros ? relatorio.data.parametros : [])?.map((param: ReportParamProps) => {
        newFormData[param.nome_param] = param.valor_padrao ?? '';
      });

      if (user.codEmpresa != 2072021 && newFormData['PCODEMPRESA'] == '') {
        newFormData['PCODEMPRESA'] = user.codEmpresa.toString();
      }
      const reportInputList = relatorio.data.parametros?.map((param: ReportParamProps) =>
        handleParamInputRender(param, relatorio.data.codrel),
      );
      setReportInputs(reportInputList);
      setFormData(newFormData);
    }

    useEffect(() => {
      if (report) {
        setPageTitle((prev) => ({
          text: `${report.codrel} - ${report.descricao}`,
          customObject: prev.customObject,
          tree: prev.tree,
        }));
        trackGPageView(`/relatorios/${report.codrel}`);
      }
    }, [report]);
    useEffect(() => {
      alterPageTitle('Apurar Relatório');
    }, []);

    const handleParamInputRender = (param: ReportParamProps, codrel: number) => {
      if (user.codEmpresa != 2072021 && param.nome_param == 'PCODEMPRESA') {
        param.valor_padrao = user.codEmpresa.toString();
      }
      return (
        <S.ReportPlayerInputBox key={`${codrel}.${param.nome_param}`}>
          <label>
            {param.obrigatorio == 'S' ? '*' : ''}
            {param.legenda ?? param.nome_param}
          </label>
          <S.ReportPlayerInput
            defaultValue={param.valor_padrao ?? ''}
            type="text"
            onChange={(e) => {
              setFormData((prev: any) => {
                prev[param.nome_param] = e.target.value;
                return {
                  ...prev,
                };
              });
            }}
            id={param.nome_param}
            name={param.nome_param}
            key={param.nome_param}
            disabled={user.codEmpresa != 2072021 && param.nome_param == 'PCODEMPRESA'}
          />
        </S.ReportPlayerInputBox>
      );
    };

    const isAnyMandatoryParamEmpty = () => {
      const mandatoryFields: ReportParamProps[] = report.parametros?.filter(
        (p: ReportParamProps) => p.obrigatorio == 'S',
      );
      if (mandatoryFields.length == 0) {
        return false;
      } else {
        let isAnyMandatoryFieldEmpty = false;
        for (let i = 0; i < mandatoryFields.length; i++) {
          if (!formData[mandatoryFields[i].nome_param] || formData[mandatoryFields[i].nome_param] == '') {
            isAnyMandatoryFieldEmpty = true;
          }
        }
        return isAnyMandatoryFieldEmpty;
      }
    };

    const handleParamsCheck = () => {
      const reportDataToExecIndexes = Object.keys(formData);
      const reportDataToExec = [];
      for (let i = 0; i < reportDataToExecIndexes.length; i++) {
        reportDataToExec.push({
          nome_param: reportDataToExecIndexes[i],
          valor_parametro:
            formData[reportDataToExecIndexes[i]] != ''
              ? `'${formData[reportDataToExecIndexes[i]]}'`
              : formData[reportDataToExecIndexes[i]],
        });
      }
      reportDataToExec.push({
        nome_param: 'codempresa',
        valor_parametro: user.codEmpresa.toString() ?? '0',
      });

      return reportDataToExec;
    };

    async function handleExecRel() {
      if (isAnyMandatoryParamEmpty()) {
        toast.warn('Preencha os campos obrigatórios *');
        return;
      }
      setLoaderHeader(true);
      setIsLoadingData(true);
      const reportDataToExec = handleParamsCheck();
      const reportData = await postPlayRelatorio(id, reportDataToExec);
      if (reportData.succeeded && reportData.data) {
        setTableData(reportData.data ?? []);
      } else {
        if (reportData.message || reportData.errors) {
          toast.error(`${reportData.message}. ${reportData.errors}`);
        } else {
          toast.warn(`Nenhum registro encontrado com os filtros informados`);
        }
      }

      setIsLoadingData(false);

      setLoaderHeader(false);
    }

    async function handleViewOrEditRel(reportMode: 'designer' | 'viewer') {
      if (isAnyMandatoryParamEmpty()) {
        toast.warn('Preencha os campos obrigatórios *');
        return;
      }
      const userTokenHashString = localStorage.getItem('vmais_token')
        ? localStorage.getItem('vmais_token').replace('"', '')
        : '';
      const userTokenBearer = `Bearer ${userTokenHashString}`;
      const b64TokenData = Buffer.from(userTokenBearer, 'utf-8').toString('base64');

      const reportDataToView = handleParamsCheck();
      const paramsToken = Buffer.from(JSON.stringify(reportDataToView), 'utf-8').toString('base64');
      if (reportMode == 'designer') {
        dialog
          .show({
            icon: 'question',
            title: 'Qual o layout?',
            text: 'Escolha a versão do layout a editar:',
            showCloseButton: true,
            showConfirmButton: true,
            confirmButtonText: 'G - Desktop',
            showCancelButton: true,
            cancelButtonText: 'P - Mobile',
          })
          .then((res) => {
            if (res.isConfirmed) {
              window.open(
                `${process.env.REACT_APP_REPORT_BASE_URL}/designer?rel=${report.codrel}&t=${b64TokenData}&lft=layout&pl=${paramsToken}`,
              );
            } else if (res.isCanceled) {
              window.open(
                `${process.env.REACT_APP_REPORT_BASE_URL}/designer?rel=${report.codrel}&t=${b64TokenData}&lft=layout_p&pl=${paramsToken}`,
              );
            }
          });
      } else {
        window.open(
          `${process.env.REACT_APP_REPORT_BASE_URL}/${reportMode}?rel=${report.codrel}&t=${b64TokenData}&lft=layout&pl=${paramsToken}`,
        );
      }
    }

    const handleExportReportDataToSheet = () => {
      handleExportJsonToSheet(tableData, `${report.codrel}_${report.descricao}_${new Date().toLocaleDateString()}`);
    };

    const templateHandler = (reportData: ReportProps, field: string) => {
      if (
        reportData[field] &&
        reportData[field].length == 19 &&
        new Date(reportData[field]).toDateString() != 'Invalid Date'
      ) {
        const formattedDate = new Date(reportData[field])
          .toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
          })
          .replace(',', '');

        return formattedDate;
      } else {
        return reportData[field];
      }
    };

    return (
      <S.ReportMainBox>
        <S.ReportHeaderBox>
          {reportInputs && <S.ReportPlayerFormInputsBox>{reportInputs}</S.ReportPlayerFormInputsBox>}

          <S.ReportPlayerFormButtonsBox>
            <Button
              text="Apurar"
              icon={<icons.Play />}
              color="green"
              loading={isLoadingData}
              onClick={() => {
                if (!isLoadingData) {
                  handleExecRel();
                }
              }}
            />
            {report && (
              <Button
                text="Imprimir"
                icon={<icons.Print />}
                color="green"
                tooltip={report.layout == '' ? 'Sem layout para impressão' : 'Imprimir relatório'}
                disabled={report.layout == ''}
                onClick={() => {
                  report.layout != '' && handleViewOrEditRel('viewer');
                }}
              />
            )}
            <Button
              text="Exportar"
              icon={<icons.Excel />}
              color="green"
              tooltip="Exportar dados do relatório"
              onClick={() => {
                if (tableData.length > 0) {
                  handleExportReportDataToSheet();
                } else {
                  toast.dismiss();
                  toast.warn('Nenhum dado a ser exportado');
                }
              }}
            />
          </S.ReportPlayerFormButtonsBox>
        </S.ReportHeaderBox>

        <S.ReportBodyBox>
          <DataTable
            value={tableData}
            rows={100}
            rowHover
            stripedRows
            emptyMessage={'Nenhum dado a ser exibido...'}
            paginator={tableData.length > 10}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            size="small"
          >
            {Object.keys(tableData[0] ?? []).map((key: string) => (
              <Column
                field={key}
                header={key.toUpperCase()}
                sortable
                key={key}
                body={(reportData) => {
                  return templateHandler(reportData, key);
                }}
              />
            ))}
          </DataTable>
        </S.ReportBodyBox>
      </S.ReportMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'relatorios/relatorioPlayer/index.tsx');
  }
}
