'use client';

import { createContext, SetStateAction, useContext, useEffect, useState } from 'react';

import {
  SupervProps,
  VendedorProps,
  VerbasFilterProps,
  VerbasFilterStatusProps,
  VerbasProps,
  VerbasStateProps,
} from './verbas.types';
import verbasList from './mockHeader';
import supervisorList from './mockSupervisor';
import vendedorList from './mockVendedor';
import { trackGCatchError } from 'utils/analytics';

export interface ProdContextProps {
  verbas: VerbasStateProps;
  verbasFilter: VerbasFilterProps;
  setVerbasFilter: React.Dispatch<SetStateAction<VerbasFilterProps>>;
  handleGetVerbasList: () => void;
  statusFilterOptions: VerbasFilterStatusProps[];
  selectedVerba: VerbasProps;
  setSelectedVerba: React.Dispatch<SetStateAction<VerbasProps>>;
  supervisores: SupervProps[];
  setSupervisores: React.Dispatch<SetStateAction<SupervProps[]>>;
  vendedores: VendedorProps[];
  setVendedores: React.Dispatch<SetStateAction<VendedorProps[]>>;
}

export const VerbasContext = createContext({} as ProdContextProps);

export const VerbasProvider = ({ children }: any) => {
  const statusFilterOptions: VerbasFilterStatusProps[] = [
    { name: 'Todas', code: 'T' },
    { name: 'Ativas', code: 'S' },
    { name: 'Inativas', code: 'N' },
  ];

  const [verbas, setVerbas] = useState<VerbasStateProps>({
    filtered: [],
    unfiltered: [],
  });

  const [supervisores, setSupervisores] = useState<SupervProps[]>([]);

  const [vendedores, setVendedores] = useState<VendedorProps[]>([]);

  const [verbasFilter, setVerbasFilter] = useState<VerbasFilterProps>({
    text: '',
    filial: '',
    status: statusFilterOptions[0].code,
  });

  const [selectedVerba, setSelectedVerba] = useState<VerbasProps>(null);

  useEffect(() => {
    handleGetVerbasList();
  }, []);

  useEffect(() => {
    if (selectedVerba) {
      setSupervisores(supervisorList);
      setVendedores(vendedorList);
    } else {
      setSupervisores([]);
      setVendedores([]);
    }
  }, [selectedVerba]);

  const handleGetVerbasList = () => {
    const verbasListToSet = verbasList;

    setVerbas({
      filtered: verbasListToSet,
      unfiltered: verbasListToSet,
    });
  };

  return (
    <VerbasContext.Provider
      value={{
        verbas,
        verbasFilter,
        setVerbasFilter,
        handleGetVerbasList,
        statusFilterOptions,
        selectedVerba,
        setSelectedVerba,
        supervisores,
        setSupervisores,
        vendedores,
        setVendedores,
      }}
    >
      {children}
    </VerbasContext.Provider>
  );
};

export const useVerbas = () => useContext(VerbasContext);
