import { classNames } from 'primereact/utils';
import { Parametro } from 'client/interfaces';
import { formatDateTime } from 'utils/datetime';

export const styleHeaderCenter = {
  justifyContent: 'center',
  alignItems: 'center',
};

export const booleanBodyTemplate = (value: boolean) => (
  <div>
    <i
      className={classNames('pi', {
        'true-icon pi-check-circle text-success': value,
        'false-icon pi-times-circle text-danger': !value,
      })}
    />
  </div>
);

export const StringBodyTemplate = (value) => (
  <>
    {value === 'S' ? (
      <i className={classNames('pi true-icon pi-check-circle text-success')} />
    ) : (
      <i className={classNames('pi false-icon pi-times-circle text-danger')} />
    )}
  </>
);

export const textBodyTemplate = (name: string, cod: string | number) => {
  return <span>{name && cod ? `${name?.toUpperCase()} [${cod}]` : cod ? `código [${cod}]` : name}</span>;
};

export const BlockTableTemplate = (text: string) => {
  return (
    <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
      <i className="pi pi-lock mb-4" style={{ fontSize: '3rem', color: 'white' }} />
      <h4 style={{ color: 'white' }}>{text}</h4>
    </div>
  );
};

export const dateTimeTemplate = (rowData: Parametro, field: keyof Parametro) =>
  rowData[field] ? formatDateTime('dd/MM/yyyy hh:mm:ss', new Date(rowData[field] as string)) : '';
