import styled from 'styled-components';

interface IControlBox {
  isWebScreen: boolean;
}

export const ControlBox = styled.div<IControlBox>`
  display: flex;
  @media (max-width: 1080px) {
    padding: 5px;
    border-radius: 5px;
    width: 50dvw;
    overflow-x: scroll;
  }
`;

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  font-size: 0.8rem;
  margin-left: 20px;
  background-color: ${(isWebScreen) => (isWebScreen ? '' : '#FFF')};

  img {
    max-height: 2.5rem;
  }
`;
