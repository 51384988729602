import styled from 'styled-components';

export const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 25px 10px 25px;
  gap: 15px;
  position: relative;
  transition: all 0.4s ease;

  @media (max-width: 1080px) {
    padding: 10px 5px 10px 5px;
  }
`;

export const StyledBox = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  gap: 15px;
  background-color: #fff;
  box-shadow: var(--v-box-shadow-1);
  border-radius: 5px;
  transition: all 0.4s ease;
`;

export const StyledBoxTable = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  box-shadow: var(--v-box-shadow-1);
  border-radius: 5px;
  gap: 15px;
  transition: all 0.4s ease;
`;

export const StyledHeader = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 10px;
  gap: 20px;
  position: sticky;
  top: 0px;
  z-index: 66;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: var(--v-box-shadow-1);
  position: sticky;
  top: 0;
  transition: all 0.4s ease;

  @media (max-width: 1080px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  gap: 15px;
  background-color: #fff;
  box-shadow: var(--v-box-shadow-1);
  transition: all 0.4s ease;
  border-radius: 5px;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  gap: 15px;
  background-color: #fff;
  box-shadow: var(--v-box-shadow-1);
  transition: all 0.4s ease;
  border-radius: 5px;
`;
