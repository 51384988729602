import { useQueryClient } from '@tanstack/react-query';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  duplicateItems,
  duplicateRestricoes,
  postDeleteImageFormCategoria,
  postImageFormCategoria,
  saveForm,
  updateForm,
} from 'client/api';
import { FormularioCab, MotivoNaoVendaProps } from 'client/interfaces';

import * as S from './cadastroFormularios.styles';
import useMediaQuery from 'hooks/useMediaQuery';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import { useMotivosNaoVenda } from 'client/hooks';
import { trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import InputSwitch from 'components/Inputs/InputSwitch/switch.input';
import InputText from 'components/Inputs/InputText/text.input';
import UseSelectFilial from 'hooks/UseSelectFilial/selectFilial';
import InputDate from 'components/Inputs/InputDate/date.input';
import MultiSelect from 'components/Multiselect/multiselect';

interface NewFormDialogProps {
  form: FormularioCab | null;
  onClose: () => void;
  visible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  clonedItem: number | null;
  codEmpresa?: number;
  codUsuario?: number;
  refetchForms: any;
}

const NewFormDialog = ({
  form,
  visible,
  setIsVisible,
  onClose,
  clonedItem,
  codEmpresa,
  codUsuario,
  refetchForms,
}: NewFormDialogProps) => {
  try {
    const {
      handleSubmit,
      control,
      watch,
      formState: { errors, dirtyFields },
      setValue,
    } = useForm<FormularioCab>({ defaultValues: form ?? undefined });

    const { SelectFilial, selectedFilial, selectedFiliais, filiais, setSelectedFiliais } = UseSelectFilial(
      codEmpresa,
      codUsuario,
    );

    useEffect(() => {
      if (form && form.filiais && filiais) {
        const filiaisList = form.filiais.split(',');
        const filiaisToSelect = [];
        for (let i = 0; i < filiaisList.length; i++) {
          let filialToSelect = filiais?.find((fil) => +fil.codFilial == +filiaisList[i]);
          filiaisToSelect.push(filialToSelect ?? []);
        }
        setSelectedFiliais(filiaisToSelect);
      }
    }, [form, filiais]);

    useEffect(() => {
      if (selectedFilial) {
        setValue('codfilial', selectedFilial.codFilial);
      }
    }, [selectedFilial]);

    const queryClient = useQueryClient();
    const isWebScreen = useMediaQuery('(min-width: 1060px)');
    const { selectedEmpresa } = UseSelectEmpresa();
    const { data: motivosNaoVenda } = useMotivosNaoVenda(codEmpresa);

    const [formImage, setFormImage] = useState({
      fotoCategoriaUrl: '',
      fotoCategoria: '',
      codEmpresa: 2072021,
      Nroform: 0,
    });

    const onSubmit: SubmitHandler<FormularioCab> = async (data) => {
      data.codEmpresa = codEmpresa ?? selectedEmpresa.codEmpresa;
      data.filiais = selectedFiliais?.map((fil) => fil.codFilial).join(',');
      try {
        if (form?.nroform) {
          await updateForm(data).then(() => {
            toast.success('Formulário atualizado com sucesso!');
          });
        } else {
          if (clonedItem == null) {
            await saveForm(data);
            toast.success('Formulário salvo com sucesso!');
          } else {
            confirmDialog({
              message: 'Deseja replicar também as restrições?',
              header: 'Replicar Restrições',
              icon: 'pi pi-question-circle',
              accept: async () => {
                const nro = await saveForm(data);
                await duplicateItems(clonedItem, nro, selectedEmpresa.codEmpresa);
                await duplicateRestricoes(clonedItem, nro, selectedEmpresa.codEmpresa);
                toast.success('Formulário clonado com sucesso!');
              },
              reject: async () => {
                const nro = await saveForm(data);
                await duplicateItems(clonedItem, nro, selectedEmpresa.codEmpresa);
                toast.success('Formulário clonado com sucesso!');
              },
            });
          }
        }
        setIsVisible(false);
        queryClient.invalidateQueries(['forms/cabecalho']);
      } catch (e) {
        setIsVisible(false);
        toast.error('Falha ao salvar formulário');
      }
    };

    useEffect(() => {
      setValue('exibeNaoVendaMotivos', form ? form.exibeNaoVendaMotivos ?? '' : '');
      setValue('fotoCategoria', form ? form.fotoCategoria ?? '' : '');
      setValue('exibeVenda', form ? form.exibeVenda ?? '' : 'N');
      setFormImage({
        fotoCategoriaUrl: `${process.env.REACT_APP_BASE_URL}/foto_categoria_formulario/${
          !form || (form.fotoCategoria && form.fotoCategoria == '')
            ? 'sem-imagem.jpeg'
            : form.fotoCategoria ?? 'sem-imagem.jpeg'
        }`,
        fotoCategoria: null,
        codEmpresa: codEmpresa,
        Nroform: form && form.nroform ? form.nroform : 0,
      });
    }, [form]);

    const foraDeVigencia = useMemo(
      () => watch().dtfimvigencia != null && new Date(watch().dtfimvigencia) < new Date(),
      [watch().dtfimvigencia],
    );

    const header = useMemo(() => {
      if (form?.nroform) return `Editar Formulário ${form.nroform}`;
      if (clonedItem) return `Clonar Formulário ${clonedItem}`;
      return 'Novo Formulário';
    }, [form]);

    const handleMotivosNaoVendaSelection = (selectedString: string): MotivoNaoVendaProps[] | null => {
      const selectedArrayString = selectedString.split(`,`);
      const selectedArray = [];
      for (let i = 0; i < selectedArrayString.length; i++) {
        const selectedMotivo = motivosNaoVenda?.filter(
          (motivo: MotivoNaoVendaProps) => motivo.codMotivo == +selectedArrayString[i],
        );
        selectedMotivo && selectedArray.push(selectedMotivo[0].codMotivo);
      }
      return selectedArray ? selectedArray : null;
    };

    const handleFormImageSelect = (file: any, imageName: string) => {
      if (file.files && file.files[0]) {
        let img = file.files[0];
        if (
          file.files[0].type != 'image/png' &&
          file.files[0].type != 'image/webp' &&
          file.files[0].type != 'image/jpg' &&
          file.files[0].type != 'image/jpeg'
        ) {
          toast.error('Arquivo inválido!');
          return;
        }
        setFormImage((prevState: any) => {
          prevState[imageName + 'Url'] = URL.createObjectURL(img) ?? img.name ?? '';
          prevState[imageName] = img;
          return { ...prevState };
        });
        handleInsertOrEditImage();
      }
    };

    const handleInsertOrEditImage = () => {
      const imageDataToInsertOrEdit = {
        fotoCategoria: formImage ? formImage.fotoCategoria : null,
        fotoCategoriaUrl: formImage ? formImage.fotoCategoriaUrl : null,
        codEmpresa: formImage ? formImage.codEmpresa : 0,
        nroForm: formImage ? formImage.Nroform : 0,
      };
      postImageFormCategoria(imageDataToInsertOrEdit)
        .then((res: any) => {
          toast.success('Imagem Inserida');
          refetchForms();
        })
        .catch((err: any) => {
          console.log('err :', err);
          toast.error('Falha ao inserir imagem do formulário');
          setFormImage({
            fotoCategoriaUrl: `${process.env.REACT_APP_BASE_URL}/foto_categoria_formulario/${
              !form || (form.fotoCategoria && form.fotoCategoria == '')
                ? 'sem-imagem.jpeg'
                : form.fotoCategoria ?? 'sem-imagem.jpeg'
            }`,
            fotoCategoria: '',
            codEmpresa: 2072021,
            Nroform: 0,
          });
        });
    };

    const handleDeleteImage = () => {
      if (form?.fotoCategoria) {
        postDeleteImageFormCategoria(form?.fotoCategoria, formImage.codEmpresa, formImage.Nroform)
          .then((res: any) => {
            if (res.succeeded) {
              toast.success('Imagem Removida');
              setFormImage({
                fotoCategoriaUrl: `${process.env.REACT_APP_BASE_URL}/foto_categoria_formulario/sem-imagem.jpeg`,
                fotoCategoria: '',
                codEmpresa: 2072021,
                Nroform: 0,
              });
              refetchForms();
            }
          })
          .catch((err: any) => {
            console.log('err :', err);
            toast.success('Falha ao remover imagem');
          });
      }
    };

    return (
      <Dialog header={header} visible={visible} style={{ width: isWebScreen ? '30vw' : '90vw' }} onHide={onClose}>
        <S.FormContainer onSubmit={handleSubmit(onSubmit)} className="form w-100">
          <S.NewFormRow>
            <Controller
              rules={{
                required: false,
              }}
              name="descricao"
              control={control}
              render={({ field }) => <InputText label="Descrição *" {...field} width="100%" />}
            />
          </S.NewFormRow>

          <S.NewFormRow>
            <S.NewFormRow>
              <SelectFilial label="Filial *" showClear={false} multiselect />

              <S.ButtonsBox>
                <Controller
                  control={control}
                  name="obrigatorio"
                  render={({ field: { onChange, value } }) => (
                    <InputSwitch label="Obrigatório" checked={value} onChange={onChange} />
                  )}
                />

                <Controller
                  control={control}
                  name="ativo"
                  render={({ field: { onChange, value } }) => (
                    <InputSwitch
                      label="Ativo"
                      disabled={foraDeVigencia}
                      checked={value && !foraDeVigencia}
                      onChange={onChange}
                      tooltip="Fora do período de vigência"
                      tooltipOptions={{
                        disabled: !foraDeVigencia,
                        showOnDisabled: true,
                      }}
                    />
                  )}
                />
              </S.ButtonsBox>
            </S.NewFormRow>
          </S.NewFormRow>

          <S.NewFormRow>
            <Controller
              control={control}
              name="dtiniciovigencia"
              render={({ field: { onChange, value } }) => (
                <InputDate
                  label="Data Inicio *"
                  value={new Date(value)}
                  onChange={(e) => {
                    setValue('dtiniciovigencia', (e as Date).toISOString());
                  }}
                  width="47%"
                />
              )}
            />
            <Controller
              control={control}
              name="dtfimvigencia"
              render={({ field: { onChange, value } }) => (
                <InputDate
                  label="Data Fim *"
                  value={new Date(value)}
                  onChange={(e) => {
                    setValue('dtfimvigencia', (e as Date).toISOString());
                  }}
                  width="47%"
                />
              )}
            />
          </S.NewFormRow>

          <S.NewFormRow>
            <Controller
              control={control}
              name="exibeVenda"
              render={({ field: { onChange, value } }) => (
                <InputSwitch
                  label="Exibir na Venda"
                  checked={value == 'S'}
                  onChange={(e) => {
                    onChange(e ? 'S' : 'N');
                  }}
                />
              )}
            />

            <Controller
              name="exibeNaoVendaMotivos"
              control={control}
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  label="Exibir p/ Motivo de Não Venda"
                  placeholder="Exibir p/ Motivo de Não Venda"
                  value={value ? handleMotivosNaoVendaSelection(value ?? '') : ''}
                  options={motivosNaoVenda ?? []}
                  onChange={(e) => {
                    onChange(e.value.join(`,`));
                  }}
                  optionLabel="descMotivo"
                  optionValue="codMotivo"
                  itemTemplate={(motivo) => {
                    return `[${motivo.codMotivo}] ${motivo.descMotivo}`;
                  }}
                  filter
                  display="chip"
                  emptyFilterMessage={'Nenhum motivo disponível'}
                />
              )}
            />
          </S.NewFormRow>

          <S.NewFormRow
            style={{
              alignItems: `flex-end`,
            }}
          >
            <S.NewFormInputBox>
              <label>Imagem FV</label>
              <S.ImageFvSizeSuggestion>*Tamanho sugerido: 400 x 225 px*</S.ImageFvSizeSuggestion>
              <S.ImagemCard
                onClick={(e) => {
                  if (form?.nroform) {
                    const imageIinput = document.getElementById('newFormImage1');
                    imageIinput.click();
                  }
                }}
                image={form && form.fotoCategoria && form.nroform ? true : undefined}
              >
                <img alt={'ImagemFV'} src={formImage.fotoCategoriaUrl} />
                <input
                  type="file"
                  id="newFormImage1"
                  name="newFormImage1"
                  accept="image/png, image/webp, image/jpeg, image/jpg"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleFormImageSelect(e.target, 'fotoCategoria');
                  }}
                />
                <S.FloatIcons>
                  {form?.fotoCategoria && (
                    <icons.RemoveFill
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDeleteImage();
                      }}
                    />
                  )}
                </S.FloatIcons>
              </S.ImagemCard>
            </S.NewFormInputBox>

            <S.ButtonsBox>
              <Button text="Salvar" color="green" type="submit" icon={<icons.Check />} width="120px" />
            </S.ButtonsBox>
          </S.NewFormRow>
        </S.FormContainer>

        <ConfirmDialog />
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'AnaliseFormularios/components/FormularioRestricoesTable/NewFormDialog.index.tsx');
  }
};

export default NewFormDialog;
