import { Suspense, useEffect, useRef, useState } from 'react';
import * as S from './acompanhamento.styles';
import { visitas } from './utils/dataHandler';
import { useVendedorAcompanhamento, useVendedorCheckinCheckout } from 'client/hooks/mapa';
import { RadioButton } from 'primereact/radiobutton';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import React from 'react';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { alterPageTitle } from 'utils/alterPageTitle';
import UseSelectVendedor from 'hooks/UseSelectVendedor/selectVendedor';
import UseSelectSupervisor from 'hooks/UseSelectSupervisor/selectSupervisor';
import UseSelectGerente from 'hooks/UseSelectGerente/selectGerente';
import UseSelectUsuario from 'hooks/UseSelectUsuario/selectUsuario';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import { exportCliToExcel, exportTableToExcel } from './utils/exportHandler';
import { formatDate } from 'utils/formatDate';
import InputDate from 'components/Inputs/InputDate/date.input';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';
import { useLoaderEffect } from 'providers/loaderEffect';

const CheckInOut = React.lazy(() => import('./models/checkinout'));
const ConsolidadoTable = React.lazy(() => import('./models/consolidado/consolidadoTable'));
const DetailedTable = React.lazy(() => import('./models/detailed/detailedTable'));

export default function AcompanhamentoDiario() {
  useEffect(() => {
    trackGPageView('/mapa/acompanhamento-diario');
    alterPageTitle('Acompanhamento');
  }, []);

  const { setLoader } = useLoaderEffect();

  const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();
  const { SelectUsuario, selectedUsuario } = UseSelectUsuario(selectedEmpresa.codEmpresa);

  const { SelectGerente, selectedGerente } = UseSelectGerente(selectedEmpresa.codEmpresa, selectedUsuario.codUsuario);

  const { SelectSupervisor, selectedSupervisor } = UseSelectSupervisor(
    selectedEmpresa.codEmpresa,
    selectedUsuario.codUsuario,
    selectedGerente.codGerente,
  );

  const { SelectVendedor, selectedVendedor, setSelectedVendedor } = UseSelectVendedor(
    selectedEmpresa.codEmpresa,
    selectedUsuario.codUsuario,
    selectedGerente.codGerente,
    selectedSupervisor.codSupervisor,
  );

  const currentDate = new Date();
  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  const [selectedDtIni, setSelectedDtIni] = useState(currentDate);
  const [selectedDtFim, setSelectedDtFim] = useState(currentDate);

  const [viewMode, setViewMode] = useState<'daily' | 'detailed' | 'checkinout'>('daily');

  try {
    const { data: visitasVendedores, refetch } = useVendedorAcompanhamento(
      selectedEmpresa.codEmpresa,
      String(selectedGerente.codGerente ?? -1) ?? '-1',
      String(selectedSupervisor.codSupervisor ?? -1) ?? '-1',
      String(selectedVendedor && selectedVendedor.codVendedor ? selectedVendedor.codVendedor : '-1'),
      formatDate(selectedDtIni, 'EN'),
      formatDate(selectedDtFim, 'EN'),
      String(selectedUsuario.codUsuario),
    );
    const { data: checkinOutVendedores, refetch: refetchCheckinOut } = useVendedorCheckinCheckout(
      selectedEmpresa.codEmpresa,
      String(selectedVendedor && selectedVendedor.codVendedor ? selectedVendedor.codVendedor : '-1'),
      formatDate(selectedDtIni, 'EN'),
      formatDate(selectedDtFim, 'EN'),
      String(selectedUsuario.codUsuario),
    );

    useEffect(() => {
      setLoader({
        show: false,
        text: `Por favor aguarde, buscando dados...`,
      });
    }, [visitasVendedores, checkinOutVendedores]);

    const defaultOldFilters = {
      oldVendedor: {
        codEmpresa: selectedEmpresa.codEmpresa,
        codVendedor: -1,
        nome: 'Todos',
      },
      oldDtIni: currentDate,
      oldDtFim: currentDate,
      oldMode: 'daily',
    };

    const [oldFilters, setOldFilters] = useState(defaultOldFilters);

    useEffect(() => {
      if (oldFilters && oldFilters.oldMode == 'checkinout') {
        setSelectedVendedor(oldFilters.oldVendedor);
        setSelectedDtIni(new Date(oldFilters.oldDtIni));
        setSelectedDtFim(new Date(oldFilters.oldDtFim));
      }
    }, [viewMode]);

    useEffect(() => {
      setLoader({
        show: true,
        text: `Por favor aguarde, buscando dados...`,
      });

      if (viewMode == 'checkinout') {
        refetchCheckinOut().finally(() => {
          setLoader({
            show: false,
            text: `Por favor aguarde, buscando dados...`,
          });
        });
      } else {
        refetch().finally(() => {
          setLoader({
            show: false,
            text: `Por favor aguarde, buscando dados...`,
          });
        });
      }
    }, [
      selectedDtIni,
      selectedDtFim,
      selectedVendedor,
      selectedUsuario.codUsuario,
      selectedSupervisor.codSupervisor,
      selectedGerente.codGerente,
      selectedEmpresa.codEmpresa,
      viewMode,
    ]);

    // useEffect(() => {
    //   setTableData({
    //     vendedores: [],
    //   });
    // }, [
    //   selectedDtIni,
    //   selectedDtFim,
    //   selectedVendedor,
    //   selectedUsuario.codUsuario,
    //   selectedSupervisor.codSupervisor,
    //   selectedGerente.codGerente,
    //   selectedEmpresa.codEmpresa,
    // ]);

    const [tableData, setTableData] = useState<any>({
      vendedores: [],
    });
    const [uniqueSemanas, setUniqueSemanas] = useState<any>([]);
    const [uniqueDays, setUniqueDays] = useState<any>([]);
    const [mesList, setMesList] = useState<any>([]);

    useEffect(() => {
      if (!visitasVendedores) {
        return;
      }
      const rcaDataList = visitas(visitasVendedores);
      setTableData({
        vendedores: rcaDataList,
      });

      let indexWithHigherNumberOfDays = 0;
      let higherLength = 0;
      for (let i = 0; i < rcaDataList.length; i++) {
        if (rcaDataList[i] && rcaDataList[i].dias.length > higherLength) {
          higherLength = rcaDataList[i].dias.length;
          indexWithHigherNumberOfDays = i;
        }
      }
      rcaDataList[indexWithHigherNumberOfDays] &&
        setUniqueSemanas(
          rcaDataList.length == 0
            ? []
            : Array.from(new Set(rcaDataList[indexWithHigherNumberOfDays].dias.map((dia) => dia.semana))),
        );
      setUniqueDays(
        rcaDataList.length == 0
          ? []
          : Array.from(new Set(rcaDataList[indexWithHigherNumberOfDays].dias.map((dia) => dia.dia))),
      );
      setMesList(rcaDataList.length == 0 ? [] : rcaDataList[indexWithHigherNumberOfDays].dias);
    }, [visitasVendedores]);

    const dailyTableRef = useRef<any>(null);
    const detailedTableRef = useRef<any>(null);
    const checkInOutRef = useRef<any>(null);

    const [showDayColumns, setShowDayColumns] = useState(false);

    return (
      <S.ReportMainBox>
        <S.ReportFormBox>
          <SelectEmpresa label="Empresa *" width="160px" />
          <SelectUsuario label="Usuário *" width="160px" />
          <SelectGerente label="Gerente" width="160px" />
          <SelectSupervisor label="Supervisor" width="160px" />
          <SelectVendedor label="Vendedor" width="160px" />
          <InputDate
            label="Dt. Ini"
            value={selectedDtIni}
            onChange={(e) => {
              setSelectedDtIni(e);
              setOldFilters((prev: any) => {
                return {
                  ...prev,
                  oldDtIni: formatDate(e, 'EN'),
                };
              });
            }}
          />
          <InputDate
            label="Dt. Fim"
            value={selectedDtFim}
            onChange={(e) => {
              setSelectedDtFim(e);
              setOldFilters((prev: any) => {
                return {
                  ...prev,
                  oldDtFim: formatDate(e, 'EN'),
                };
              });
            }}
          />

          <S.ReportViewModeBox>
            <S.ReportViewOption>
              <RadioButton
                inputId="daily"
                name="Diário"
                value="daily"
                onChange={(e) => {
                  setViewMode(e.value);
                  setOldFilters((prev: any) => {
                    return {
                      ...prev,
                      oldMode: viewMode,
                    };
                  });
                }}
                checked={viewMode === 'daily'}
              />
              <label htmlFor="daily">Consolidado</label>
            </S.ReportViewOption>
            <S.ReportViewOption>
              <RadioButton
                inputId="detailed"
                name="Detalhado"
                value="detailed"
                onChange={(e) => {
                  setViewMode(e.value);
                  setOldFilters((prev: any) => {
                    return {
                      ...prev,
                      oldMode: viewMode,
                    };
                  });
                }}
                checked={viewMode === 'detailed'}
              />
              <label htmlFor="detailed">Detalhado</label>
            </S.ReportViewOption>
            <S.ReportViewOption>
              <RadioButton
                inputId="checkinout"
                name="Check In Out"
                value="checkinout"
                onChange={(e) => {
                  setViewMode(e.value);
                  setOldFilters((prev: any) => {
                    return {
                      ...prev,
                      oldMode: viewMode,
                    };
                  });
                }}
                checked={viewMode === 'checkinout'}
              />
              <label htmlFor="checkinout">Check In Out</label>
            </S.ReportViewOption>
          </S.ReportViewModeBox>
          <S.ReportHeaderButtonsBox>
            {viewMode == 'daily' ? (
              <DownloadTableExcel
                currentTableRef={dailyTableRef.current}
                filename={`visitas-consolidadas-${selectedDtIni}-
              ${selectedDtFim}`}
              >
                <Button text="Exportar" color="green" icon={<icons.Excel />} />
              </DownloadTableExcel>
            ) : viewMode == 'detailed' ? (
              <Button
                text="Exportar"
                color="green"
                icon={<icons.Excel />}
                onClick={() => {
                  exportTableToExcel(
                    showDayColumns,
                    uniqueDays,
                    tableData,
                    formatDate(selectedDtIni, 'EN'),
                    formatDate(selectedDtFim, 'EN'),
                  );
                }}
              />
            ) : (
              <Button
                text="Exportar"
                color="green"
                icon={<icons.Excel />}
                onClick={() => {
                  exportCliToExcel(
                    formatDate(selectedDtIni, 'EN'),
                    formatDate(selectedDtFim, 'EN'),
                    checkinOutVendedores,
                    viewMode,
                    visitasVendedores,
                  );
                }}
              />
            )}
          </S.ReportHeaderButtonsBox>
        </S.ReportFormBox>

        <Suspense fallback={<>Carregando</>}>
          <ConsolidadoTable
            mesList={mesList}
            tableData={tableData}
            uniqueDays={uniqueDays}
            uniqueSemanas={uniqueSemanas}
            setSelectedDtIni={setSelectedDtIni}
            setSelectedDtFim={setSelectedDtFim}
            setSelectedVendedor={setSelectedVendedor}
            setViewMode={setViewMode}
            dailyTableRef={dailyTableRef}
            viewMode={viewMode}
          />
        </Suspense>

        <Suspense fallback={<>Carregando</>}>
          <DetailedTable
            mesList={mesList}
            tableData={tableData}
            uniqueDays={uniqueDays}
            uniqueSemanas={uniqueSemanas}
            setSelectedDtIni={setSelectedDtIni}
            setSelectedDtFim={setSelectedDtFim}
            setSelectedVendedor={setSelectedVendedor}
            setViewMode={setViewMode}
            detailedTableRef={detailedTableRef}
            viewMode={viewMode}
            showDayColumns={showDayColumns}
            setShowDayColumns={setShowDayColumns}
          />
        </Suspense>

        <Suspense fallback={<>Carregando</>}>
          {viewMode == 'checkinout' && (
            <CheckInOut viewMode={viewMode} checkinOutVendedores={checkinOutVendedores} checkInOutRef={checkInOutRef} />
          )}
        </Suspense>
      </S.ReportMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'AcompanhamentoDiario/acompanhamentoDiario.index.tsx');
  }
}
