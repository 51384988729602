import styled from 'styled-components';

interface HeaderMainBoxProps {
  showMenu: boolean
}

export const AnaliseHeaderMainBox = styled.section<HeaderMainBoxProps>`
  min-height: 12vh;
  border: 1px solid #60606020;
  border-radius: 5px;
  background-color:rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 6;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: ${(props) => (props.showMenu ? 'calc(100% - 35dvw)' : 'auto')};

  .p-float-label {
    padding-bottom: 5px;
  }

  @media (max-width: 1036px) {
    position: relative;
    box-shadow: none;
    left: 0;
    margin-bottom: 10px;
    border: none;
    height: auto;
  }
`;
export const AnaliseHeaderFormBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;
  padding: 5px;
  padding-left: 10px;

  @media (max-width: 992px) {
    width: 100%;
    overflow-x: scroll;
    flex-wrap: wrap;
    gap: 10px;
  }
`;
export const AnaliseHeaderDateInput = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  border: 1px solid #60606050;
  border-radius: 2.5px;
  padding: 0 5px;
  position: relative;
  color: #606060;

  input {
    color: #606060;
    border: none;
    outline: none;
    background-color: #fff !important;
  }

  label {
    position: absolute;
    bottom: 100%;
    font-size: 0.9rem;
  }

  @media (max-width: 992px) {
    input {
      max-width: 100px;
    }
  }
`;

export const AnaliseHeaderTextInput = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  border: 1px solid #60606050;
  border-radius: 2.5px;
  padding: 0 5px;
  position: relative;
  color: #606060;

  input {
    color: #606060;
    border: none;
    outline: none;
  }

  label {
    position: absolute;
    bottom: 100%;
    font-size: 0.9rem;
  }
`;

export const AnaliseHeaderInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;
