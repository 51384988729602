import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createCliSetorVinculo } from 'client/api/areaMapa';
import * as S from './mapVinculoCli.styles';
import { useMap } from '../../regioes.context';
import { useVendedoresByUser } from 'client/hooks/vendedores';
import { SetoresProps } from 'client/interfaces';
import { trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import { dialog } from 'reactivus';
import Dropdown from 'components/Dropdown/dropdown';

interface IVinculoSetor {
  showVinculoSetor: boolean;
  setShowVinculoSetor: React.Dispatch<React.SetStateAction<boolean>>;
  cliToEdit?: any;
}

export default function MapVinculoCli({ showVinculoSetor, setShowVinculoSetor, cliToEdit }: IVinculoSetor) {
  try {
    const { setores, handleGetAllData, selectedCli, params, lastCoordinateZoomed } = useMap();

    const { data: vendedores, refetch: refetchVendedores } = useVendedoresByUser(params.codEmpresa, params.codUser);

    const [actualSetor, setActualSetor] = useState<SetoresProps>(null);
    const [actualVendedor, setActualVendedor] = useState<any>(null);

    useEffect(() => {
      setCliToEditVinculo(cliToEdit ? cliToEdit : selectedCli);
    }, [selectedCli, cliToEdit]);

    const [cliToEditVinculo, setCliToEditVinculo] = useState(null);

    const DialogFooterPolygon = (
      <S.ModalButtonsBox>
        <Button
          text="Cancelar"
          icon={<icons.X />}
          tooltip="Cancelar vinculo"
          color="red"
          onClick={() => {
            setShowVinculoSetor(false);
          }}
        />
        <Button
          text="Salvar"
          icon={<icons.Check />}
          tooltip="Salvar vinculo"
          color="green"
          onClick={(e: any) => {
            if (actualSetor !== null) {
              handleCreateCliSetorVinculo();
              setShowVinculoSetor(false);
            }
          }}
        />
      </S.ModalButtonsBox>
    );

    const handleCreateCliSetorVinculo = () => {
      let toCreateCliSetorVinculo = {
        codSetor: actualSetor.CODSETOR,
        codCli: +cliToEditVinculo.CODCLI,
        codFilial: 1,
        codEmpresa: +params.codEmpresa,
        inclusaoManual: 'S',
      };
      if (+cliToEditVinculo.CODSETOR == +actualSetor.CODSETOR) {
        handleCLiInSameSectorWarn();
      } else {
        createCliSetorVinculo(toCreateCliSetorVinculo)
          .then((res: any) => {
            lastCoordinateZoomed.current = [+cliToEditVinculo.LATITUDE, +cliToEditVinculo.LONGITUDE];
            handleGetAllData();
            toast.success('Vinculo do cliente x setor criado!');
            setShowVinculoSetor(false);
          })
          .catch((err: Error) => {
            toast.error('Falha ao criar vinculo do cliente x setor!');
          });
      }
    };

    const handleCLiInSameSectorWarn = () => {
      dialog.show({
        icon: 'warning',
        title: 'Cliente já vinculado!',
        html: `O cliente <b>[${cliToEditVinculo.CODCLI}] ${cliToEditVinculo.RAZAOSOCIAL} já está vinculado ao setor [${actualSetor.CODSETOR}] ${actualSetor.NOMESETOR}</b>`,
        showConfirmButton: true,
        confirmButtonText: 'Entendi',
      });
    };

    const sectorItemTemplate = (setor: any) => {
      return (
        <S.SectorItemTemplateBox>
          <span>{`[${setor.CODSETOR}] ${setor.NOMESETOR}`}</span>
          <S.SectorItemVendedor>
            {setor.COD_RCA ? `[${setor.COD_RCA}] ${setor.NOMERCA}` : 'Nenhum vendedor vinculado'}
          </S.SectorItemVendedor>
        </S.SectorItemTemplateBox>
      );
    };

    const [setoresOtions, setSetoresOptions] = useState(
      setores && setores?.unfiltered?.length
        ? setores?.unfiltered?.filter(
            (setor: any) => setor.polygono !== undefined && setor.polygono.length === 0 && setor.CODSETOR != -1,
          )
        : [],
    );

    useEffect(() => {
      setActualSetor(null);
      const newSectors = setores?.unfiltered?.filter(
        (setor: any) => setor.polygono !== undefined && setor.polygono.length === 0 && setor.CODSETOR != -1,
      );
      for (let i = 0; i < newSectors.length; i++) {
        newSectors[i].NOMERCA = vendedores?.filter((rca: any) => newSectors[i].COD_RCA == rca.codVendedor)[0]
          ? vendedores?.filter((rca: any) => newSectors[i].COD_RCA == rca.codVendedor)[0].nome
          : '';
      }
      setSetoresOptions(newSectors);
    }, [showVinculoSetor]);

    return (
      <Dialog
        visible={showVinculoSetor}
        style={{ width: '30rem' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={`Vincular Cliente em Setor:`}
        modal
        className="p-fluid"
        onHide={() => {
          setShowVinculoSetor(false);
        }}
        footer={DialogFooterPolygon}
      >
        <S.ModalBody>
          <p>
            Cliente:[{cliToEditVinculo?.CODCLI}] {cliToEditVinculo?.RAZAOSOCIAL}
          </p>
          <S.Row>
            <Dropdown
              options={setoresOtions}
              optionLabel="setor"
              placeholder={(actualSetor && actualSetor.NOMESETOR) || 'Vincule a um Setor'}
              filter
              filterBy="CODSETOR,NOMESETOR,COD_RCA,NOMERCA"
              style={{ width: '100%' }}
              onChange={(e) => {
                setActualSetor(e.target.value);
                setActualVendedor(vendedores.filter((rca: any) => e.target.value.CODRCA == rca.codVendedor)[0] ?? null);
              }}
              itemTemplate={sectorItemTemplate}
              width="100%"
            />
          </S.Row>
          {actualSetor && actualSetor.NOMESETOR && actualVendedor && (
            <p>
              Vendedor: {actualVendedor?.nome} [{actualVendedor?.codVendedor}]
            </p>
          )}
        </S.ModalBody>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'mapVinculoCli.index.tsx');
  }
}
