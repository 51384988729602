/* eslint-disable react/jsx-no-target-blank */
import { FC, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logoVendaMais from 'assets/images/logo/v-mais-white.svg';
import { AsideMenu } from './AsideMenu';
import { useLayout } from '_metronic/layout/core';
import { KTSVG } from '_metronic/helpers';

import * as S from './styles';
import { trackGCatchError } from 'utils/analytics';

const Aside: FC = () => {
  try {
    const { config, classes } = useLayout();
    const asideRef = useRef<HTMLDivElement | null>(null);
    const { aside } = config;
    const [ktMenuMinimized, setKtMenuMinimized] = useState(true);

    const minimize = () => {
      handleMenuClick();
      asideRef.current?.classList.add('animating');
      setTimeout(() => {
        asideRef.current?.classList.remove('animating');
      }, 300);
    };

    const handleMenuClick = () => {
      const ktBody = document.getElementById('kt_body');
      if (ktBody) {
        setKtMenuMinimized(ktBody.getAttribute('data-kt-aside-minimize') == 'on');
      } else if (ktMenuMinimized) {
        setKtMenuMinimized(false);
      }
    };

    useEffect(() => {
      handleMenuClick();

      const observer = new MutationObserver(() => {
        handleMenuClick();
      });

      const ktBody = document.getElementById('kt_body');
      if (ktBody) {
        observer.observe(ktBody, { attributes: true, attributeFilter: ['data-kt-aside-minimize'] });
      }

      return () => {
        observer.disconnect();
      };
    }, []);

    const { pathname } = useLocation();

    const [handledPathName, setHandledPathName] = useState('');

    useEffect(() => {
      const filteredPathname = pathname
        .split('/')
        .slice(1, 4)
        .filter((segment) => !/\d/.test(segment))
        .join('/');
      setHandledPathName(filteredPathname);
    }, [pathname]);

    return (
      <S.MenuMainBox
        id="kt_aside"
        className="aside aside-light aside-hoverable"
        data-kt-drawer="true"
        data-kt-drawer-name="aside"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_aside_mobile_toggle"
        ref={asideRef}
        onMouseEnter={() => {
          ktMenuMinimized && setKtMenuMinimized(false);
        }}
        onMouseLeave={() => handleMenuClick()}
        handledPathName={handledPathName}
      >
        <div className="aside-logo flex-column-auto" id="kt_aside_logo">
          <Link to="/dashboard">
            <img alt="Logo" className="h-30px logo" src={logoVendaMais} />
          </Link>

          {aside.minimize && (
            <div
              id="kt_aside_toggle"
              className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
              data-kt-toggle="true"
              data-kt-toggle-state="active"
              data-kt-toggle-target="body"
              data-kt-toggle-name="aside-minimize"
              onClick={minimize}
            >
              <KTSVG
                path={'/media/icons/duotune/arrows/arr080-left.svg'}
                className={`svg-icon-1 rotate-180`}
                color="var(--primary-green)"
              />
            </div>
          )}
        </div>
        <div className="aside-menu flex-column-fluid">
          <AsideMenu asideMenuCSSClasses={classes.asideMenu} ktMenuMinimized={ktMenuMinimized} />
        </div>
        <S.MenuVersionBox show={!ktMenuMinimized}>V{process.env.REACT_APP_VERSION} &copy; VMais</S.MenuVersionBox>
      </S.MenuMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'main/components/Aside/Aside.tsx');
  }
};

export default Aside;
