import styled from 'styled-components';

export const EditParamModalFormBox = styled.section`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 25px;
`;

export const EditParamModalFormRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 25px;
`;

export const EditParamModalHeader = styled.h5`
  width: 100%;
`;

export const EditParamModalTitle = styled.h5`
  width: 100%;
`;

export const EditParamModalSubTitle = styled.p`
  width: 100%;
  font-size: 1rem;
`;
