import { SplitButton } from 'primereact/splitbutton';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useEmpresas } from 'client/hooks';
import { ComandoSql } from 'client/interfaces';
import { useAppDispatch, useAppSelector } from 'store';

import { runSqlCommandAsync } from '../reducer/vmaissql';

import { empresaSelector, setEmpresa } from '../reducer/vmaissql';
import NewSqlCommandModal from './NewSqlCommandModal';
import SqlCommandModal from './SqlCommandModal';
import * as S from './styles';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import { trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';

function SqlForm() {
  try {
    const [isOpen, setIsOpen] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [isNewOpen, setIsNewOpen] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [sqlText, setSqlText] = useState('');
    const [comandoInsert, setComandoInsert] = useState('');
    const [comandoDelete, setComandoDelete] = useState('');
    const [selectedCommand, setSelectedCommand] = useState<ComandoSql | null>(null);
    const empresa = useAppSelector(empresaSelector);
    const dispatch = useAppDispatch();

    const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();

    const onCloseModal = (command: ComandoSql | null) => {
      setSelectedCommand(command);
      if (command != null) {
        setSqlText(command.txtSql);
        setComandoInsert(command.comandoInsert ?? '');
        setComandoDelete(command.comandoDelete ?? '');
      }
      setIsOpen(false);
    };

    const actions = [
      {
        label: 'Atualizar',
        icon: 'pi pi-pencil',
        disabled: selectedCommand?.codSql == null || selectedCommand?.codSql === 0,
        command: () => {
          setIsNew(false);
          setIsNewOpen(true);
        },
      },
    ];

    const onExecute = async () => {
      if (sqlText != null && empresa != null) {
        try {
          setIsRunning(true);
          await dispatch(runSqlCommandAsync({ empresaId: empresa.codEmpresa ?? 0, sqlText })).unwrap();
        } catch (e: any) {
          toast.error(
            <>
              <p>Erro ao rodar comando</p>
              <p>{e}</p>
            </>,
          );
        } finally {
          setIsRunning(false);
        }
      }
    };

    const { data: empresas } = useEmpresas();

    useEffect(() => {
      if (empresas) {
        dispatch(setEmpresa(empresas.filter((emp) => +emp.codEmpresa == +selectedEmpresa.codEmpresa)[0]));
      }
    }, [selectedEmpresa, empresas]);

    return (
      <S.SqlMainBox>
        <S.SqlHeaderBox>
          <SelectEmpresa label="Empresa *" />
          <Button
            text="Consulta"
            icon={<icons.Search />}
            color="green"
            onClick={() => setIsOpen(true)}
            tooltip="Consultar SQL's"
          />
        </S.SqlHeaderBox>

        <S.ContainerSQL>
          <div className="fv-row">
            <label className="form-label fs-6 fw-bolder text-dark">Query SQL</label>
            <textarea
              value={sqlText}
              onChange={(e) => setSqlText(e.target.value)}
              className="form-control"
              style={{ minHeight: '200px' }}
            />
          </div>
          <S.SqlBoxButtons>
            <Button
              text="Limpar"
              icon={<icons.X />}
              color="red"
              onClick={() => setSqlText('')}
              disabled={sqlText.length === 0}
            />
            <Button
              text="Executar"
              icon={<icons.Play />}
              color="green"
              tooltip={sqlText.length === 0 || isRunning ? 'Preencha uma Query SQL' : undefined}
              tooltipPosition="top"
              onClick={onExecute}
              disabled={empresa == null || sqlText.length === 0 || isRunning}
            />

            <SplitButton
              disabled={sqlText.length === 0 || isRunning}
              label="Salvar Novo"
              icon="pi pi-save"
              onClick={() => {
                setIsNewOpen(true);
                setIsNew(true);
              }}
              model={actions}
              style={{
                backgroundColor: '#228F6B',
                width: '200px',
                border: 'none',
              }}
            />
          </S.SqlBoxButtons>
          {!!selectedCommand?.intervalo && (
            <>
              <div className="fv-row">
                <label className="form-label fs-6 fw-bolder text-dark">Comando Insert</label>
                <textarea
                  value={comandoInsert}
                  onChange={(e) => setComandoInsert(e.target.value)}
                  onBlur={(e) =>
                    selectedCommand && setSelectedCommand({ ...selectedCommand, comandoInsert: e.target.value })
                  }
                  className="form-control"
                  style={{ minHeight: '200px' }}
                />
              </div>
              <div className="fv-row">
                <label className="form-label fs-6 fw-bolder text-dark">Comando Delete</label>
                <textarea
                  value={comandoDelete}
                  onChange={(e) => setComandoDelete(e.target.value)}
                  onBlur={(e) =>
                    selectedCommand && setSelectedCommand({ ...selectedCommand, comandoDelete: e.target.value })
                  }
                  className="form-control"
                  style={{ minHeight: '200px' }}
                />
              </div>
            </>
          )}
        </S.ContainerSQL>

        {isOpen && <SqlCommandModal isOpen={isOpen} onClose={onCloseModal} />}
        {isNewOpen && (
          <NewSqlCommandModal
            command={isNew ? null : selectedCommand}
            txtSql={sqlText}
            isOpen={isNewOpen}
            onClose={(command) => {
              setIsNewOpen(false);
              setSelectedCommand(command);
            }}
          />
        )}
      </S.SqlMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'vmaissql/components/SqlForm.tsx');
  }
}

export default SqlForm;
