import { Container, BoxRow, BoxColumn, Header, Box } from 'components/Containers';
import styled, { keyframes } from 'styled-components';

export const ParamRcaEditMainBox = styled(Container)``;

export const ParamRcaEditHeader = styled(Header)``;

export const ParamRcaEditHMobileSaveButton = styled(Box)`
  flex: 1;
  position: sticky;
  top: 0px;
  bottom: 0px;
  left: 0;
`;

interface ParamRcaToggleItemProps {
  active: boolean;
}

export const ParamRcaToggleListHeaderItem = styled.div<ParamRcaToggleItemProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px 5px 10px 5px;
  background-color: ${(props) => (props.active ? 'var(--v-green-20)' : 'transparent')};
  transition: background-color 0.4s ease;
  flex-grow: 1;
  text-align: center;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-size: 1rem;
  position: relative;
  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0;
    background-color: var(--v-green);
    transition: width 0.7s ease;
  }

  &:hover::after,
  ${(props) => props.active && '&::after'} {
    width: 100%;
  }

  &:hover::after {
    width: 100%;
  }

  &:hover {
    background-color: ${(props) => (props.active ? 'var(--v-green-20)' : '#cccccc40')};
  }
`;
interface ParamRcaToggleListHeaderIButtonProps {
  isWebScreen: boolean;
}
export const ParamRcaToggleListHeaderIButton = styled.div<ParamRcaToggleListHeaderIButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
  padding: 7.5px 0px 5px 0px;
  transition: background-color 0.4s ease;
  flex-grow: 1;
  text-align: center;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-size: 1.15rem;
  position: relative;
  gap: 10px;

  position: ${(props) => (props.isWebScreen ? 'relative' : 'sticky')};
  left: ${(props) => (props.isWebScreen ? '0' : '0px')};
  background-color: ${(props) => (props.isWebScreen ? 'transparent' : '#FFF')};
  z-index: ${(props) => (props.isWebScreen ? 'auto' : '9')};
  @media (max-width: 1024px) {
    padding: 7.5px 5px 5px 5px;
  }
`;

export const ParamRcaToggleContentBox = styled(BoxRow)`
  flex-wrap: wrap;
  padding: 20px 20px 20px 20px;

  .danger-input {
    background-color: var(--v-red-20) !important;
    border: 1px solid var(--v-red-40) !important;
    color: var(--v-red) !important;
  }

  .success-input {
    background-color: var(--v-green-20) !important;
    border: 1px solid var(--v-green-40) !important;
    color: var(--v-green) !important;
  }
`;

const showFromLeft = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
`;

const displayWithOpacity = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ParamRcaToggleContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  animation: ${showFromLeft} 0.2s ease-in-out, ${displayWithOpacity} 0.4s ease-in-out;
  @media (max-width: 1024px) {
    flex: 1;
  }
`;

export const ParamRcaToggleRowBox = styled(Container)`
  padding: 0px;

  .danger-input {
    background-color: var(--v-red-20) !important;
    border: 1px solid var(--v-red-40) !important;
    color: var(--v-red) !important;
  }

  .success-input {
    background-color: var(--v-green-20) !important;
    border: 1px solid var(--v-green-40) !important;
    color: var(--v-green) !important;
  }
`;

export const ParamRcaToggleRow = styled(BoxColumn)`
  width: 100%;
`;

export const ParamRcaToggleRowTitle = styled(BoxRow)`
  font-weight: 600;
  box-shadow: none;
  padding: 0px;
`;

export const ParamRcaToggleRowTitleText = styled.span`
  font-weight: 600;
`;

export const ParamRcaToggleRowContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  border-radius: 5px;
`;
