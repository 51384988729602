import { useEmpresas } from 'client/hooks';
import { empresaCNPJSelector } from 'modules/auth/reducer/auth';
import * as S from '../styles';
import { setSelectedEmpresa, setSelectedUsuario } from '../../../reducer/menuAcesso';
import { useAppDispatch, useAppSelector } from 'store';

import { useEffect, useState } from 'react';
import { getEmpresa, getUsers } from 'client/api';
import { UserData } from 'client/interfaces';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import UseSelectUsuario from 'hooks/UseSelectUsuario/selectUsuario';
import { trackGCatchError } from 'utils/analytics';

export const FiltersCompanyUser = () => {
  try {
    const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();
    const { SelectUsuario, selectedUsuario } = UseSelectUsuario(selectedEmpresa.codEmpresa, true);

    const [users, setUsers] = useState<UserData[]>([]);
    const { data: empresas } = useEmpresas();

    const dispatch = useAppDispatch();

    const codEmpresaVMais = '2072021';
    const codEmpresa = useAppSelector(empresaCNPJSelector);

    const isClientUser = codEmpresa !== codEmpresaVMais;

    useEffect(() => {
      if (selectedEmpresa) {
        getEmpresa(codEmpresa).then((res) => {
          if(isClientUser && res)
          dispatch(setSelectedEmpresa(res));
          dispatch(setSelectedUsuario(null));

          getUsers(selectedEmpresa?.codEmpresa).then((data) => {
            setUsers(data);
          });
        });
      }
    }, [selectedEmpresa]);

    useEffect(() => {
      if (selectedEmpresa && empresas) {
        dispatch(setSelectedEmpresa(empresas?.filter((empr) => +empr.codEmpresa == +selectedEmpresa.codEmpresa)[0]));
        dispatch(setSelectedUsuario(null));
      }

      if (selectedUsuario && users) {
        dispatch(setSelectedUsuario(users?.filter((usr) => +usr.codUsuario == +selectedUsuario.codUsuario)[0]));
      }
    }, [selectedEmpresa, selectedUsuario, empresas, users]);

    return (
      <S.HeaderBox>
        <SelectEmpresa label="Empresa *" />
        <SelectUsuario label="Usuário *" />
      </S.HeaderBox>
    );
  } catch (err) {
    trackGCatchError(err, 'acessoUsuario/UsuarioItensMenu/FiltersCompanyUser/index.tsx');
  }
};
