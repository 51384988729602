import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'client/hooks/usuarios';
import * as S from './style';
import { ListMapsByCompany } from './ListMapsByCompany';
import { Controller, useForm } from 'react-hook-form';
import { useSelectActive } from 'hooks/useSelectActive/useSelectActice';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import UseSelectUsuario from 'hooks/UseSelectUsuario/selectUsuario';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { alterPageTitle } from 'utils/alterPageTitle';
import InputText from 'components/Inputs/InputText/text.input';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';
import Dropdown from 'components/Dropdown/dropdown';
import { useLoaderEffect } from 'providers/loaderEffect';

const CadastroMapa = () => {
  try {
    const { control, watch, setValue } = useForm();

    const { setLoaderHeader } = useLoaderEffect();

    const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();
    const { SelectUsuario, selectedUsuario } = UseSelectUsuario(selectedEmpresa.codEmpresa);

    const [searchText, setSearchText] = useState<string>('');

    useEffect(() => {
      trackGPageView('/mapa');
      alterPageTitle('Mapas');
    }, []);

    useEffect(() => {
      setLoaderHeader(true);
      setValue('company', selectedEmpresa.codEmpresa);
      setLoaderHeader(false);
    }, [selectedEmpresa]);

    useEffect(() => {
      setValue('user', selectedUsuario.codUsuario);
      setLoaderHeader(true);
    }, [selectedUsuario]);

    const values = watch();

    const { data: user } = useUser();
    const { optionsActiveAndInactive } = useSelectActive();

    const navigate = useNavigate();

    useEffect(() => {
      if (user?.codUsuario) {
        setValue('user', user?.codUsuario);
        setValue('company', user?.codEmpresa);
        setValue('status', 'S');
      }
    }, [user]);

    return (
      <S.MapasMainBox>
        <S.HeaderBox>
          <form>
            <SelectEmpresa label="Empresa *" />
            <SelectUsuario
              onChange={(usr) => {
                setValue('user', usr.codUsuario);
              }}
              label="Usuário *"
            />

            <Controller
              name="status"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <Dropdown
                  placeholder="Ativo"
                  value={field.value}
                  options={optionsActiveAndInactive}
                  onChange={(e) => setValue('status', e.value)}
                  label="Ativo/Inativo"
                  width="100px"
                />
              )}
            />

            <InputText
              label="Buscar"
              placeholder="Buscar mapa"
              width="200px"
              onChange={(text) => {
                setSearchText(text.toUpperCase());
              }}
            />

            <Button
              icon={<icons.Plus />}
              iconPosition="left"
              color="green"
              text="Adicionar"
              tooltip="Cadastrar novo mapa"
              tooltipPosition="right"
              onClick={() => {
                navigate(`./novo/${selectedEmpresa.codEmpresa}`);
              }}
            />
          </form>
        </S.HeaderBox>

        <S.ContainerTable mobile tablet>
          <ListMapsByCompany
            codCompany={selectedEmpresa.codEmpresa}
            codUser={selectedUsuario?.codUsuario}
            status={values?.status}
            searchText={searchText}
          />
        </S.ContainerTable>
      </S.MapasMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'CadastroMapa/CadastroMapa.tsx');
  }
};

export default CadastroMapa;
