import { MultiSelect as PRMultiSelect, MultiSelectProps } from 'primereact/multiselect';
import { useState } from 'react';

import * as SD from 'styles/inputs.styles';

export default function MultiSelect({
  onChange,
  width,
  label = '',
  placeholder = '',
  ...rest
}: {
  onChange?: (data: string[]) => void;
  width?: string;
  label?: string;
  placeholder?: string;
} & MultiSelectProps) {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const widthCalculated = +(label.length * 28) > 200 ? 200 : +(label.length * 28);

  const handleChange = (value: any) => {
    setSelectedValues(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <SD.InputMainBox width={width} widthCalculated={widthCalculated}>
      <label>{label}</label>
      <PRMultiSelect
        value={selectedValues}
        onChange={(e) => handleChange(e.value)}
        placeholder={placeholder}
        {...rest}
      />
    </SD.InputMainBox>
  );
}
